import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultasDocumentosService {

  private baseUrl = `${environment.apiUrl}`;
  
  constructor(private http: HttpClient) { }

  getClientOne(type:string, document:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/clinic-history/consultarDocumento/${type}/${document}`);
  }
}
