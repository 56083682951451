<!--<p *ngIf="quantityAttentions.length === 0" class="mt-2">
  <ngb-alert [dismissible]="false" [type]="'info'">
    <strong><i class="fas fa-info-circle"></i></strong>
    No hay atenciones registradas
  </ngb-alert>
</p>
<div class="row mt-2" *ngIf="this.quantityAttentions.length > 0">
  <div class="col-md-3" *ngFor="let el of quantityAttentions">
    <div class="card flat-card widget-purple-card" style="cursor: pointer;" (click)="this.get(el.iddoctor, el.doctor)">
      <div class="row-table">
        <div class="col-sm-2 card-body" style="padding: 20px 5px !important;">
          <i class="feather icon-folder"></i>
        </div>
        <div class="col-sm-10">
          <h6>Archivo(s) {{el.quantity}}</h6>
          <h6>{{el.doctor}}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12" *ngIf="this.list.length > 0">
    <app-card [customHeader]="true" cardClass="card-border-c-blue" [options]="false">
      <div class="app-card-header">
        <h5 class="mb-3">{{title_detail}}</h5>
        <button class="btn btn-primary float-right f-14" (click)="openModalAddAttention()">
          <i class="fas fa-plus-circle m-r-5"></i>
        </button>
      </div>
      <div class="dt-responsive table-responsive">
        <table datatable [dtOptions]="dtOptions" class="table table-striped row-border table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>Doctor / Tratamiento</th>
              <th class="w50">Cantidad</th>
              <th class="w100">Valor</th>
              <th class="w100">Descuento</th>
              <th class="w100">Total</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list_service_order_pendig">
              <td>{{item.id_service_order_detalle}} </td>
              <td>{{item.irh_created_at | date: "dd-MM-yyyy"}}</td>
              <td>{{item.d_nameQuote}}<br>
                {{item.t_name}}
              </td>
              <td class="text-center">{{item.sopd_quantity}}</td>
              <td class="text-end">{{item.co_code}} {{item.sopd_subtotal}}</td>
              <td class="text-end">{{ item.ds_valuerate || 0 }}</td>
              <td class="text-end">{{item.co_code}} {{item.Total_amount}}</td>
              <td>
                <span [class]="'badge badge-light-warning'">
                  Por facturar
                </span>
              </td>
            </tr>

            <tr *ngFor="let item of list">
              <td>{{item.id}}</td>
              <td>{{item.date | date: "dd-MM-yyyy"}}</td>
              <td>{{item.doctor.nameQuote}} <br>
                {{item.tariff.name}}
              </td>
              <td class="text-center">{{item.quantity}}</td>
              <td class="text-end">{{item.co.code}} {{item.value}}</td>
              <td class="text-end">{{item.discount_description}}</td>
              <td class="text-end">{{item.co.code}} {{item.total}}</td>
              <td>
                <span [class]="'badge badge-light-warning'" *ngIf="item.state === 1">
                  Por facturar
                </span>
                <span [class]="'badge badge-light-primary'" *ngIf="item.state === 2">
                  Facturado
                </span>
                <span [class]="'badge badge-light-danger'" *ngIf="item.state === 3">
                  Rechazada
                </span>
              </td>
              <td class="overflow-hidden">
                <button *ngIf="item.state === 1" type="button" class="btn btn-icon btn-success btn-sm"
                  (click)="openModalAutorization(item, 'edit')" ngbPopover="Editar" triggers="mouseenter:mouseleave">
                  <i class="feather icon-check-circle"></i>
                </button>
                <button *ngIf="item.state === 1" type="button" class="btn btn-icon btn-primary btn-sm ml-1"
                  (click)="openDiary(item)" ngbPopover="Agendar" triggers="mouseenter:mouseleave">
                  <i class="feather icon-calendar"></i>
                </button>
                <button type="button" class="btn btn-icon btn-danger btn-sm ml-1" ngbPopover="Eliminar"
                  triggers="mouseenter:mouseleave" (click)="openModalAutorization(item, 'delete')">
                  <i class="feather icon-trash-2"></i>
                </button>
              </td>
            </tr>

            <tr *ngFor="let item of list_service_order_billing">
              <td>{{item.id_service_order_detalle}} </td>
              <td>{{item.irh_created_at | date: "dd-MM-yyyy"}}</td>
              <td>{{item.d_nameQuote}}<br>
                {{item.t_name}}
              </td>
              <td class="text-center">{{item.sopd_quantity}}</td>
              <td class="text-end">{{item.co_code}} {{item.sopd_subtotal}}</td>
              <td class="text-end">{{ item.ds_valuerate || 0 }}</td>
              <td class="text-end">{{item.co_code}} {{item.Total_amount}}</td>
              <td>
                <span [class]="'badge badge-light-primary'">
                  Facturado
                </span>
              </td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>Doctor / Tratamiento</th>
              <th class="w100">Cantidad</th>
              <th class="w100">Valor</th>
              <th class="w100">Descuento</th>
              <th class="w100">Total</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </app-card>
  </div>
</div>-->

<div class="row">
  <div class="col-sm-12">
    <app-card [customHeader]="true" cardClass="card-border-c-blue" [options]="false">
      <div class="app-card-header">
        <div class="row mb-3">
          <div class="col-12 d-flex align-items-center">
            <div class="col-3">
              <h5>Desplegar Filtros</h5>
            </div>
            <div class="col-6 d-flex justify-content-center">
              <button class="btn btn-link" (click)="toggleFilters()">
                <i class="fas fa-filter"></i> Mostrar Filtros
              </button>
            </div>
            <div class="col-3 d-flex justify-content-end">
              <button class="btn btn-primary f-14" (click)="openModalAddAttention()">
                <i class="fas fa-plus-circle m-r-5"></i> Agregar
              </button>
            </div>
          </div>
        </div>
        
        
        <ng-container *ngIf="contenedor">
          <div class="row mb-3">
            <div class="col-12 d-flex align-items-center">
              <div class="col-3">
                  Doctor
                  <ng-select [(ngModel)]="filter.doctor" (change)="applyFilter()" [items]="doctores" 
                    bindLabel="descripcion" bindValue="id" placeholder="Buscar por Doctor" [clearable]="true" [searchable]="true">
                  </ng-select>
                </div>
              <div class="col-3">
                Especialidad
                <ng-select [(ngModel)]="filter.especialidad" (change)="applyFilter()" [items]="especialidades" 
                  bindLabel="descripcion" bindValue="id" placeholder="Buscar por Especialidad" [clearable]="true" [searchable]="true">
                </ng-select>
              </div>
              <div class="col-3">
                Estado
                <ng-select [(ngModel)]="filter.estado" (change)="applyFilter()" [items]="estados" 
                  bindLabel="descripcion" bindValue="id" placeholder="Buscar por Estado" [clearable]="true" [searchable]="true">
                </ng-select>
  
              </div>
              <div class="col-1">
                <button class="btn btn-outline-secondary float-right f-14 ml-1" (click)="reset()" >
                  <i class="fas fa-sync m-r-5"></i>
              </button>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 d-flex align-items-center">
              <div class="col-3">
                Fecha Desde
                <input type="date" [(ngModel)]="filters.since" (change)="getDataByDate()" placeholder="Desde" class="form-control">
              </div>
              <div class="col-3">
                Fecha Hasta
                <input type="date" [(ngModel)]="filters.until" (change)="getDataByDate()" placeholder="Hasta" class="form-control">
              </div>
              <div class="col-3">
                <button class="btn btn-success float-right f-14" (click)="get_xls_clinicHistoryReducer()">Descargar</button>
              </div>
  
            </div>
          </div>
        </ng-container>

        
        <!--<h5 class="mb-3">{{title_detail}}</h5>-->
        
    </div>
      <div class="dt-responsive table-responsive">
        <table  class="table table-striped row-border table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>Doctor / Tratamiento</th>
              <th class="w50">Cantidad</th>
              <th class="w100">Valor</th>
              <th class="w100">Descuento</th>
              <th class="w100">Total</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of paginatedItems">
              <td>{{item.id_service_order}}</td>
              <td>{{item.fecha_os | date: "dd-MM-yyyy"}}</td>
              <td>{{item.name_doctor}}<br>
                {{item.name_tariff}}
              </td>
              <td>{{item.cantidad}}</td>
              <td>{{item.valor}}</td>
              <td>{{item.cantidad_descuento}}</td>
              <td>{{item.total}}</td>
              <td>{{item.name_status}}</td>
              <td>
                <div lass="btn-group mb-2 mr-2 dropdown" ngbDropdown container="body"
                placement="auto">
                <button type="button" class="btn btn-outline-primary btn-sm"
                ngbDropdownToggle>✍️ Acciones</button>
                <div ngbDropdownMenu>
                    <button *ngIf="item.id_status === 2 && item.voucher !== 'Sin comprobante'" type="button" class="dropdown-item" 
                            (click)="openDocument(item.voucher)"  ngbDropdownItem ngbPopover="Imprimir">
                            🖨️ Ver Comprobante
                    </button>
                    <div *ngIf="item.id_status !== 2 || item.voucher === 'Sin comprobante'" class="dropdown-item" ngbDropdownItem><span>Sin Comprobante</span></div>
                </div>
                   
            </div>
              </td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>Doctor / Tratamiento</th>
              <th class="w100">Cantidad</th>
              <th class="w100">Valor</th>
              <th class="w100">Descuento</th>
              <th class="w100">Total</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </tfoot>
        </table>
        <!--<div class="pagination-controls">
          <button (click)="prevPage()" [disabled]="currentPage === 1">Anterior</button>
          <span> Página {{currentPage}} de {{totalPages}} </span>
          <button (click)="nextPage()" [disabled]="currentPage === totalPages">Siguiente</button>
        </div>-->
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="prevPage()" tabindex="-1" [attr.aria-disabled]="currentPage === 1">Anterior</a>
          </li>
          <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage">
            <a class="page-link" (click)="goToPage(page)">{{page}}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link"(click)="nextPage()" [attr.aria-disabled]="currentPage === totalPages">Después</a>
          </li>
        </ul>
      </nav>
    </app-card>
  </div>
</div>