import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DiscountDto } from './../models/discount.model'
import { ServiceOrderNew, ServiceOrderPaymentDetailNew } from '../models/finance/service-order-new.model';


@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http:HttpClient) { }

  getAllDiscoutn(): Observable<DiscountDto[]>{
    return this.http.get<DiscountDto[]>(`${this.baseUrl}/discount`)
  }

  createAllDiscount(discount:ServiceOrderPaymentDetailNew[]):Observable<ServiceOrderPaymentDetailNew[]>{
    return this.http.post<ServiceOrderPaymentDetailNew[]>(`${this.baseUrl}/discount`,discount)
  }
}
