import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

//Helper Services
import { ClinicHistoryService } from '../../../service/clinic-history.service';
import { DateService } from '../../../service/helpers/date.service';
import { MedicalActAttentionService } from 'src/app/service/main/medical-act-attention.service';
import { LabOrderService } from 'src/app/service/main/lab-order.service';
import { ReservationService } from '../../../service/main/reservation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  session: any = {};
  greetings: string;
  time: any;
  cantPatient: number = 0;
  cantReservation: number = 0;
  cantAttention: number = 0;
  cantLabOrder: number = 0;
  //Parametros para chart controles
  filtersControls: any = {
    month: moment().format('M'),
    year: moment().format('YYYY')
  };
  confChartControls: any;
  datachartControl: any = {
    por_confirmar: 0,
    confirmados: 0,
    atendidos: 0,
    total: 0
  };
  loadingChartControls: boolean = false;
  constructor(
    private _helperDateService: DateService,
    private store: Store<{session: any}>,
    private _clientServices : ClinicHistoryService,
    private _reservationServices: ReservationService,
    private _medicalActAttentionService: MedicalActAttentionService,
    private _labOrderService: LabOrderService
  ) {}

  ngOnInit(): void {
    this.getSession();
    this.getCurrentDate();
    this.getCantPatient();
    this.getCantReservation();
    this.getCantAttention();
    this.getCantLabOrder();
    this.getCantControls();
  }
  getSession(): void{
    this.store.select('session')
    .subscribe(sess => {
      if(sess.id){
        this.session = sess;
        this.setGreetings();
      }
    });
  }

  setGreetings(): void{
    this.greetings = `<p>${this._helperDateService.turn}
    <b>${this.session.username}</b>, bienvenido al sistema de Maxillaris</p>`;
  }

  getCurrentDate() {
    setInterval(() => {
      this.time = new Date();
    }, 1000); // set it every one seconds
  }

  getCantPatient(): void{
    this.cantPatient = 0;
    this._clientServices.getCant()
    .subscribe(
      res => {
        this.cantPatient = res;
      },
      error => {}
    );
  }

  getCantReservation(): void{
    this.cantReservation = 0;
    const month = Number(moment().format('M'));
    const year = Number(Number(moment().format('YYYY')));
    this._reservationServices.getCant(month,year)
    .subscribe(
      res => {
        this.cantReservation = res;
      },
      error => {}
    );
  }

  getCantAttention(): void{
    this.cantAttention = 0;
    const month = Number(moment().format('M'));
    const year = Number(Number(moment().format('YYYY')));
    this._medicalActAttentionService.getCant(month,year)
    .subscribe(
      res => {
        this.cantAttention = res;
      },
      error => {}
    );
  }

  getCantLabOrder(): void{
    this.cantLabOrder = 0;
    const month = Number(moment().format('M'));
    const year = Number(Number(moment().format('YYYY')));
    this._labOrderService.getCantOrder(month,year)
    .subscribe(
      res => {
        this.cantLabOrder = res;
      },
      error => {}
    );
  }

  getCantControls(): void{
    this.datachartControl = {
      por_confirmar: 0,
      confirmados: 0,
      atendidos: 0,
      total: 0
    };
    this.loadingChartControls = true;
    this._reservationServices.getControlsCant(this.filtersControls.month,this.filtersControls.year)
    .subscribe(
      res => {
        this.loadingChartControls = false;
        const { sin_confirmar,confirmados,atendidos,total } = res;
        this.datachartControl = {
          por_confirmar: sin_confirmar ? sin_confirmar : 0,
          confirmados: confirmados ? confirmados : 0,
          atendidos: atendidos ? atendidos : 0,
          total
        };
        this.setChartControl();
      },
      err => {
        this.loadingChartControls = false;
      }
    );
  }

  setChartControl(): void{
    this.confChartControls = {
      chart: {
        height: 100,
        type: 'donut',
      },
      /* dataLabels: {
        enabled: false
      },
      plotOptions: {
        pie: {
          donut: {
            size: '75%'
          }
        }
      }, */
      labels: ['Por confirmar','Confirmados', 'Atendidos'],
      series: [this.datachartControl.por_confirmar,this.datachartControl.confirmados, this.datachartControl.atendidos],
      /* legend: {
        show: false
      }, */
      /* tooltip: {
        theme: 'datk'
      },
      grid: {
        padding: {
          top: 20,
          right: 0,
          bottom: 0,
          left: 0
        },
      }, */
      colors: ['#FFB64D', '#2ed8b6', '#4099ff'],
      /* fill: {
        opacity: [1, 1, 1]
      },
      stroke: {
        width: 0,
      } */
    };
  }

}
