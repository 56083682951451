<div class="modal-header modal-header-default modal_header">
    <h5 class="modal-title titulo-modal">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <form class="editForm" [formGroup]="formData">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <!--<label class="form-control-label" for="Patient">Paciente</label>
                    <div class="p-field">
                        <p-autoComplete 
                            [suggestions]="filteredPatients" 
                            (completeMethod)="search($event)" 
                            field="fullName" 
                            [dropdown]="true" 
                            [forceSelection]="true" 
                            [minLength]="1" 
                            [formControlName]="this.formFields.selectedPatient" 
                            placeholder="Buscar Paciente"
                            (change)="setListDoctors($event)"
                            styleClass="w-100">
                            <ng-template let-patient pTemplate="item">
                                <div class="patient-item">
                                <div>{{patient.history}} {{patient.name}} {{patient.lastNameFather}} {{patient.lastNameMother}}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>-->
                    <label class="form-control-label" for="Patient">Paciente <small class="text-danger">(*)</small></label>
                    <input class="form-control input-md" [id]="formFields.selectedPatient" [name]="formFields.selectedPatient" type="text"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.selectedPatient)}" 
                        [formControlName]="this.formFields.selectedPatient" disabled>
                    <label *ngIf="getErrorMessage(formFields.selectedPatient)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar paciente
                    </label>
                </div>
            </div>
            <div class="col-md-6" >
                <div class="form-group">
                    <label class="form-control-label" for="Doctor">Doctor <small class="text-danger">(*)</small></label>
                    <ng-select [items]="listDoctors" bindLabel="name_doctor" bindValue="id_doctor" placeholder="Seleccione"
                        [name]="this.formFields.Doctor" [id]="this.formFields.Doctor" [disabled]=enableDoctor disabled
                        [formControlName]="this.formFields.Doctor" [clearable]="false" (change)="identifyRol(1)">
                    </ng-select>
                    <label *ngIf="getErrorMessage(formFields.Doctor)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar doctor
                    </label>
                </div>
            </div>
        <!--<div class="col-md-6" *ngIf="enableDoctor">
                <div class="form-group">
                    <label class="form-control-label" for="Doctor">Doctor <small class="text-danger">(*)</small></label>
                    <input class="form-control input-md" [id]="formFields.Doctor" [name]="formFields.Doctor" type="text"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.Doctor)}"
                        [formControlName]="this.formFields.Doctor" disabled>
                    <label *ngIf="getErrorMessage(formFields.Doctor)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar doctor
                    </label>
                </div>
            </div>-->
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-control-label" for="Speciality">Especialidad <small class="text-danger">(*)</small></label>
                    <ng-select [items]="listSpecialty" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Speciality" [id]="this.formFields.Speciality"
                        [formControlName]="this.formFields.Speciality" [clearable]="false" (change)="identifyRol(2)">
                    </ng-select>
                    <label *ngIf="getErrorMessage(formFields.Speciality)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar una especialidad
                    </label>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-control-label" for="Tariff">Tarifa <small class="text-danger">(*)</small></label>
                    <ng-select [items]="listTarriff" bindLabel="type_family" bindValue="id_info" placeholder="Seleccione"
                        [name]="this.formFields.Tariff" [id]="this.formFields.Tariff" (change)="identifyRol(3)"
                        [formControlName]="this.formFields.Tariff" [clearable]="false">
                    </ng-select>
                    <label *ngIf="getErrorMessage(formFields.Tariff)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar una tarifa
                    </label>
                </div>
            </div>
            <div  class="col-md-2">
                <div class="form-group">
                    <label class="form-control-label" for="Interval">Intervalo <small class="text-danger">(*)</small></label>
                    <input class="form-control input-md" [id]="formFields.Interval" [name]="formFields.Interval" type="number"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.Interval)}" (change)="validate_interval()"
                        [formControlName]="this.formFields.Interval">
                    <label *ngIf="getErrorMessage(formFields.Interval)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debes indicar intervalo
                    </label>
                </div>
            </div>
            <div  class="col-md-2">
                <div class="form-group">
                    <label class="form-control-label" >Tiempo<small class="text-danger">(*)</small></label>
                    <p [style.font-size.px]="20" [style.font-weight]="'bold'">{{ minutes_attention }} minutos</p>
                </div>
            </div>
        </div>
    </form>
</div>
        <div class="row justify-content-center">
            <div class="col-md-6 d-flex justify-content-center">
                <button type="button" uiSref="work" class="btn btn-outline-primary btn-block" (click)="setListHoursByDoctor()" >
                    Buscar
                </button>
            </div>
        </div>
        <div class="container horarios-container">
            <h2>Listado de Horarios</h2>
            <div class="row">
                <div class="col-md-4">
                    <input type="text" class="form-control" placeholder="Buscar por hora" [(ngModel)]="horaBusqueda" (change)="busqueda()">
                </div>
                <div>
                    <button  type="button" class="btn btn-secondary" (click)="resetBusqueda()">Limpiar</button>
                </div>
                <div class="input-group col-md-4">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary" type="button" (click)="accionAntes()">⬅️</button>
                </div>
                    <input type="date" class="form-control" [(ngModel)]="fechaSeleccionada" [min]="fechaDeHoy()">
                <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button" (click)="accionDespues()">➡️</button>
                    </div>
                </div>
                <div>
                    <button type="button" class="btn btn-primary" (click)="FiltrarPorFecha()">Buscar cita</button>
                </div>
                
            </div>
            <div class="row horario-container">
                <div class="col-md-4" *ngFor="let horario of horariosPaginados()" [attr.id]="horario.id">
                    <div class="horario-item" [class.selected]="horario.id === selectedHorarioId" (click)="selectHorario(horario.id)">
                        <div class="horario-title">{{ horario.horario }}</div>
                    </div>
                </div>
            </div>
          
            <nav aria-label="Page navigation">
              <ul class="pagination">
                <li class="page-item" [class.disabled]="paginaActual === 1">
                  <a class="page-link" (click)="cambiarPagina(paginaActual - 1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" *ngFor="let pagina of paginas" [class.active]="pagina === paginaActual">
                  <a class="page-link" (click)="cambiarPagina(pagina)">{{ pagina }}</a>
                </li>
                <li class="page-item" [class.disabled]="paginaActual === numeroTotalPaginas()">
                  <a class="page-link" (click)="cambiarPagina(paginaActual + 1)" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
        </div>

<div class="modal-footer">
    <button type="button" uiSref="work" class="btn btn-outline-primary"
          type="button" (click)="save()">
        Guardar
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Salir
    </button>
</div>
