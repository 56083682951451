export const environment = {
  production: false,
  //apiUrl: `http://192.168.0.133:3200/api`,
  //apiUrlDownload: `http://192.168.0.133:3200/`,
  //apiUrlReport: `http://192.168.0.133:3200/api-reports`,
  //apiUrlReport: `http://192.168.0.79/api` //api`
  //apiUrl: `http://192.168.1.150:3200/api`

  //apiUrl: `http://sistema.maxillaris.pe:3900/api`,
  //apiUrlDownload: `http://sistema.maxillaris.pe:3900/`,
  //apiUrlReport: `http://sistema.maxillaris.pe:3900/api-reports`,
  //apiphp: 'http://sistema.maxillaris.pe/APLICATIVOS/consultas_marcha_blanca/consultaDatos',
  //apiUrl: `http://localhost:3200/api`,
  //apiUrlDownload: `http://localhost:3200/`,
  //apiUrlReport: `http://localhost:3200/api-reports`,
  //apiphp: 'http://localhost/envioautomaticodecorreossmsmwsp/consultaDatos',
  //apiUrlReport: `http://192.168.0.79/api` //api`

  //apiUrl: `http://192.168.1.166:3200/api`,
  //apiUrlDownload: `http://192.168.1.166:3200/`,
  //apiUrlReport: `http://192.168.1.166:3200/api-reports`,
  //apiUrl: `http://sistema.maxillaris.pe:3200/api`,
  //apiUrlDownload: `http://sistema.maxillaris.pe:3200/`,
  //apiUrlReport: `http://sistema.maxillaris.pe:3200/api-reports`,


  //apiUrl: `http://sistema.maxillaris.pe:3200/api`,
  //apiUrlDownload: `http://sistema.maxillaris.pe:3200/`,
  //apiUrlReport: `http://sistema.maxillaris.pe:3200/api`

  //apiUrlAgenda:`http://sistema.maxillaris.pe:3800/`,
  //apiUrlAgenda:`http://192.168.0.133:7008`,
  apiUrlAgenda:`http://sistema.maxillaris.pe:7008`,


  apiUrl: `http://sistema.maxillaris.pe:3200/api`,
  apiUrlDownload: `http://sistema.maxillaris.pe:3200/`,
  apiUrlReport: `http://sistema.maxillaris.pe:3200/api-reports`,
  apiphp: 'http://sistema.maxillaris.pe/aplicativos/envioautomaticodecorreossmsmwsp/consultaDatos',
};