import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { observable, Observable } from 'rxjs';
import { AuthService } from './service/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public router: Router, public authService: AuthService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return new Observable(observer => {
      if (this.authService.loggedIn()) {
        if (next.data.permissions)
          this.authService.hasPermission(next.data.permissions).subscribe({
            next: (resp) => {
              if (resp) {
                observer.next(true);
              } else {
                observer.next(false);
                this.router.navigate(['inicio']);
              }
            },
            error: () => { observer.next(false) }
          });
        else
          observer.next(true)
      }
      else {
        this.router.navigate(['auth/signin']);
        observer.next(false);
      }
    })
  }
}
