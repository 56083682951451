import { Transferable } from '../../../../shared/interfaces/iTransferable';
import { DoctorProgrammingDto } from '../dtos/doctor-programming-dto';

export class DoctorProgramming implements Transferable<DoctorProgrammingDto> {
    id: number;
    iddoctor: number;
    doctor?: string;
    idenvironmentdoctor: number;
    environmentdoctor?: string;
    date_since: string;
    date_until: string;
    time_since: string;
    time_until: string;
    interval: number;
    idcampus: number;
    campus?: string;
    status: number;
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
    schedule_type: string;
    calendar_type: number;
    //break_time_start?:string;
    //break_time_end?:string;

    ToDTO(): DoctorProgrammingDto {
        const dto: DoctorProgrammingDto = ({
            id: this.id,
            iddoctor: this.iddoctor,
            idenvironmentdoctor: this.idenvironmentdoctor,
            date_since: this.date_since,
            date_until: this.date_until,
            time_since: this.time_since,
            time_until: this.time_until,
            interval: this.interval,
            idcampus: this.idcampus,
            status: this.status,
            mon: this.mon,
            tue: this.tue,
            wed: this.wed,
            thu: this.thu,
            fri: this.fri,
            sat: this.sat,
            sun: this.sun,
            schedule_type: this.schedule_type,
            calendar_type: this.calendar_type
            //break_time_start: this.break_time_start,
            //: this.break_time_end

        });
        return dto;
    }

    FromDTO(from: DoctorProgrammingDto): DoctorProgramming {
        this.id = from.id;
        this.iddoctor = from.iddoctor;
        this.doctor = from.doctor;
        this.idenvironmentdoctor = from.idenvironmentdoctor;
        this.environmentdoctor = from.environmentdoctor;
        this.date_since = from.date_since;
        this.date_until = from.date_until;
        this.time_since = from.time_since;
        this.time_until = from.time_until;
        this.interval = from.interval;
        this.idcampus = from.idcampus;
        this.campus = from.campus;
        this.status = from.status;
        this.mon = from.mon;
        this.tue = from.tue;
        this.wed = from.wed;
        this.thu = from.thu;
        this.fri = from.fri;
        this.sat = from.sat;
        this.sun = from.sun;
        this.schedule_type = from.schedule_type;
        this.calendar_type = from.calendar_type;
        //this.break_time_start = from.break_time_start;
        //.break_time_end = from.break_time_end
        return this;
    }
}