import { createSelector } from '@ngrx/store';
import { SETDATACLINICHISTORY } from '../actions/clinic-history.action';

export interface ClinicHistoryState {
    clinicHistory: any
};

const initialState: ClinicHistoryState = {
    clinicHistory: {}
}

export function clinicHistoryReducer(state = initialState, action): ClinicHistoryState {
    switch (action.type) {
        case SETDATACLINICHISTORY:
            return {
                ...state,
                clinicHistory: action.payload
            }
        default:
            return state;
    }
}

export const selectClinicHistoryState = (state) => state.clinicHistoryState;
export const selectClinicHistory = createSelector(selectClinicHistoryState, (state) => state.clinicHistory);