<app-card cardTitle="" hidHeader="true" cardClass="card-datatable" [options]="false">
    <pre>Fecha Seleccionada: {{ parserFormatter.format(model) }}</pre>
    <ngb-datepicker #dp [(ngModel)]="model" (ngModelChange)="setDate()"
        (navigate)="date = $event.next"></ngb-datepicker><!-- (ngModelChange)="getDentalOffice();setSchedule()" -->
    <br />
    <button class="btn btn-sm btn-outline-primary mt-1" (click)="selectToday()">Hoy</button>
    <button class="btn btn-sm btn-outline-primary mt-1 ml-1" (click)="onDiaryLock()">Bloquear
        Agenda</button>
    <hr>
    <div class="filter mt-2">
        <div class="title">
            <h5>Busqueda de Citas</h5>
        </div>
        <!-- <div class="form-group">
            <label for="doctor">Doctor</label>
            <ng-select name="doctor" [(ngModel)]="filterDoctor" [ngModelOptions]="{standalone: true}"
                [loading]="loadingSelectDoctor" placeholder="Todos" [disabled]="this.session.doctor">
                <ng-option *ngFor="let doc of listDoctors" [value]="doc.id">{{doc.nameQuote}}</ng-option>
            </ng-select>
        </div> -->
        <div class="form-group">
            <label for="patient">Paciente</label>
            <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" name="patient"
                (selectItem)="selectedItem($event)" [(ngModel)]="patient" [ngbTypeahead]="search" placeholder="Todos.."
                [resultFormatter]="resultFormatBandListValue" [inputFormatter]="inputFormatBandListValue" required />
            <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
            <div class="invalid-feedback" *ngIf="searchFailed">Error, Las sugerencias no pueden ser cargadas
            </div>
        </div>
        <div class="form-group">
            <label for="state">Estado</label>
            <ng-select name="state" [(ngModel)]="filterState" [ngModelOptions]="{standalone: true}"
                [loading]="loadingSelectStateFilter" placeholder="Todos">
                <ng-option *ngFor="let st of listState" [value]="st.id">{{st.state}}</ng-option>
            </ng-select>
        </div>
        <div class="form-group">
            <button class="btn btn-primary" (click)="onSearch()">Buscar</button>
        </div>
        <!--<div class="form-group">
            <button class="btn btn-primary" (click)="modal()">Nuevo Modal</button>
        </div>-->
    </div>
    <!--<div class="filter mt-3">
        <button class="btn btn-warning" (click)="newAttention()">Agendar Cita</button>
    </div>-->
</app-card>