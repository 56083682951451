import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Programming } from '../../../doctor-diary/models/programming.model';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ModalDoctorDiaryAppointmentComponent } from '../../../doctor-diary/components/modals/modal-doctor-diary-appointment/modal-doctor-diary-appointment.component';
import { AppointmentDetailComponent } from 'src/app/components/appointment-detail/appointment-detail.component';
import Swal from 'sweetalert2';
import { ModalDoctorDiaryAppointmentTwoComponent } from '../../modals/modal-doctor-diary-appointment-two/modal-doctor-diary-appointment-two.component';
import { AppoitmentDetailTwoComponent } from '../../modals/appoitment-detail-two/appoitment-detail-two.component';

@Component({
  selector: 'app-doctor-diary-detail-two',
  templateUrl: './doctor-diary-detail-two.component.html',
  styleUrls: ['./doctor-diary-detail-two.component.scss']
})
export class DoctorDiaryDetailTwoComponent implements OnInit {

  canInsert: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  @Input() programming: Programming[];
  @Input() usuario:any;
  @Output() setSave = new EventEmitter<string>();

  scheduleCalendar: any[] = [];
  hoursCalendar: any[] = [];
  schedule: any[] = [];

  constructor(
    private toastr: ToastrService,
    private modalSerive: NgbModal,
    private auth: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.setHours();
    this.setSchedule();
  }

  initPermissions(): void {
    this.route.data.subscribe(res => {
      this.auth.hasPermission(res.permissions).subscribe({
        next: (resp) => {
          this.canInsert = !resp.can_insert;
          this.canUpdate = !resp.can_update;
          this.canDelete = !resp.can_delete;
        }
      });
    });
  }

  setHours(): void {
    this.scheduleCalendar = [];
    for (let i = 8; i < 22; i++) {
      const hours: string[] = [];
      const hoursBeta: any[] = [];
      let intervalMin = 0;
      for (let j = 0; j < 12; j++) {
        let mins = '';
        if (intervalMin < 10) {
          mins = `0${intervalMin}`;
        } else {
          mins = `${intervalMin}`;
        }
        hours.push(
          `${i}:${mins}`
        );
        hoursBeta.push({
          houra: `${i}:${mins}`,
          hourb: `${i}${mins}`
        });
        intervalMin += 5;
      }
      this.scheduleCalendar.push({
        hour: `${i}:00`,
        hoursBeta,
        hours
      });
    }

  }

  setSchedule(): void {
    const hoursoclock = [800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100];
    this.hoursCalendar = [];
    const intervalBase = 5;
    this.programming.forEach(ele => {
      const hours = [];
      //console.log('ele',ele);
      
      ele.schedule.forEach(element => {
        //console.log('element',element);
        
        const since = Number(String(element.since).replace(':', ''));
        let until = Number(String(element.until).replace(':', ''));
        
        
        if (hoursoclock.includes(until)) {
          until -= 45;
        } else {
          until -= intervalBase;   
        }
        if (element.type === 3) {
          until = since + intervalBase;
        }
        hours.push(
          {
            mainData: ele,
            data: element,
            date: this.programming[0].date,
            sinceMod: since,
            untilMod: until,
            type: element.type,
            since: element.since,
            until: element.until,
            environtment: element.environtment,
            environtment_name: element.environtment_name,
          }
        );
        
      });
      this.hoursCalendar.push({
        doctor: ele.doctor,
        iddoctor: ele.iddoctor,
        hours
      });
      
    });
  }

  addMedicalAppointment(data: any): void {
    if (this.canInsert) {
      this.toastr.error(
        'No tiene permiso para agregar citas',
        'Atención',
        { timeOut: 5000, progressBar: true }
      );
      return;
    }

    if(this.usuario[0].agenda_sv !== '100'){
      if(this.usuario[0].cargo.nombre !== 'ASISTENTE DENTAL' && this.usuario[0].cargo.nombre !== 'EJECUTIVO DE APNEA' && this.usuario[0].cargo.nombre !== 'EJECUTIVO DE OI'
        && this.usuario[0].cargo.nombre !== 'SUPERVISOR DE ASISTENTE DENTALES' 
      ){
        Swal.fire({
          title: 'Atención!!!!',
          text: 'Su usuario no acceso para agendar citas.',
          type: 'error',
          showCloseButton: false,
          showCancelButton: false
        }).then((willInsert) => {
          if (willInsert.value){
            return;
          }
        })
        return;
      }
      else{
        this.toastr.info('Usuario admitido '+this.usuario[0].user.username, 'Bienvenid@!!', {timeOut : 3000})
      
          const modal = this.modalSerive.open(ModalDoctorDiaryAppointmentTwoComponent, { size: 'lg' });
          modal.result.then((result: any) => {
            if (result === 'Save click') {
              this.setSave.emit(this.programming[0].date);
              
            }
          });
          modal.componentInstance.data = data;
          modal.componentInstance.usuario = this.usuario[0];
        }
    }else{
      this.toastr.info('Usuario admitido '+this.usuario[0].user.username, 'Bienvenid@!!', {timeOut : 3000})
    
        const modal = this.modalSerive.open(ModalDoctorDiaryAppointmentTwoComponent, { size: 'lg' });
        modal.result.then((result: any) => {
          if (result === 'Save click') {
            this.setSave.emit(this.programming[0].date);
            
          }
        });
        modal.componentInstance.data = data;
        modal.componentInstance.usuario = this.usuario[0];
      }
  }

  setAppointmentDetail(id: number): void {
    const modal = this.modalSerive.open(AppoitmentDetailTwoComponent, { size: 'lg' });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        this.setSave.emit(this.programming[0].date);
      } else if (result === 'Refresh') {
        this.setSave.emit(this.programming[0].date);
      }
    });
    modal.componentInstance.id = id;
    modal.componentInstance.identificar = 1;
    modal.componentInstance.usuario = this.usuario[0];
  }

}
