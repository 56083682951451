
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DoctorEndpoints {

    constructor() { }

    private apiUrl(): string {
        return `${environment.apiUrl}`;
    }

    getDoctorProgrammingByDoctor(iddoctor: number) {
        return `${this.apiUrl()}/doctor-programming/doctor/${iddoctor}`;
    }

    getDoctorProgrammingById(id: number) {
        return `${this.apiUrl()}/doctor-programming/${id}`;
    }

    getDoctorProgramming() {
        return `${this.apiUrl()}/doctor-programming`;
    }

    NewgetDoctorProgramming() {
        return `${this.apiUrl()}/doctor-programming/newService`;
    }

}