  import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
  import { Programming } from '../../../doctor-diary/models/programming.model';
  import { ToastrService } from 'ngx-toastr';
  import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
  import { AuthService } from 'src/app/service/auth.service';
  import { ActivatedRoute } from '@angular/router';
  import { ModalDoctorDiaryAppointmentComponent } from '../../../doctor-diary/components/modals/modal-doctor-diary-appointment/modal-doctor-diary-appointment.component';
  import { AppointmentDetailComponent } from 'src/app/components/appointment-detail/appointment-detail.component';
  import { ModalDoctorDiaryAppointmentThreeComponent } from '../../modals/modal-doctor-diary-appointment-three/modal-doctor-diary-appointment-three.component';
  import { AppoitmentDetailThreeComponent } from '../../modals/appoitment-detail-three/appoitment-detail-three.component';
  import Swal from 'sweetalert2';

  @Component({
    selector: 'app-doctor-diary-detail-three',
    templateUrl: './doctor-diary-detail-three.component.html',
    styleUrls: ['./doctor-diary-detail-three.component.scss']
  })
  export class DoctorDiaryDetailThreeComponent implements OnInit {

    canInsert: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    @Input() programming: Programming[];
    @Input() usuario:any;
    @Output() setSave = new EventEmitter<string>();

    scheduleCalendar: any[] = [];
    hoursCalendar: any[] = [];
    hoursCalendar2: any[] = [];
    schedule: any[] = [];

    constructor(
      private toastr: ToastrService,
      private modalSerive: NgbModal,
      private auth: AuthService,
      private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
      this.setHours();
      this.setSchedule();
      console.log('usuario',this.usuario);
      
    }

    initPermissions(): void {
      this.route.data.subscribe(res => {
        this.auth.hasPermission(res.permissions).subscribe({
          next: (resp) => {
            this.canInsert = !resp.can_insert;
            this.canUpdate = !resp.can_update;
            this.canDelete = !resp.can_delete;
          }
        });
      });
    }

    setHours(): void {
      this.scheduleCalendar = [];
      for (let i = 8; i < 22; i++) {
        const hours: string[] = [];
        const hoursBeta: any[] = [];
        let intervalMin = 0;
        for (let j = 0; j < 12; j++) {
          let mins = '';
          if (intervalMin < 10) {
            mins = `0${intervalMin}`;
          } else {
            mins = `${intervalMin}`;
          }
          hours.push(
            `${i}:${mins}`
          );
          hoursBeta.push({
            houra: `${i}:${mins}`,
            hourb: `${i}${mins}`
          });
          intervalMin += 5;
        }
        this.scheduleCalendar.push({
          hour: `${i}:00`,
          hoursBeta,
          hours
        });

        //console.log('sche',this.scheduleCalendar);
        
      }

    }

    setSchedule(): void {
      const hoursoclock = [800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100];
      this.hoursCalendar = [];
      this.hoursCalendar2 = [];
      const intervalBase = 5;
      let horario_rango_inicial = 800;
      let horario_rango_final = 805;
      this.programming.forEach(ele => {
        const hours = [];
        const hours2 = [];
        //console.log('ele',ele);
        
        ele.schedule.forEach(element => {
          //console.log('element',element);
          
          const since = Number(String(element.since).replace(':', ''));
          let until = Number(String(element.until).replace(':', ''));
          
          //console.log(hoursoclock);
          //console.log(until);
          if (hoursoclock.includes(until)) {
            //console.log('1');
            until -= 45;
          } else {
            //console.log('2');
            until -= intervalBase;   
          }
          if (element.type === 3) {
            until = since + intervalBase;
          }
          /*hours.push(
            {
              mainData: ele,
              data: element,
              date: this.programming[0].date,
              sinceMod: since,
              untilMod: until,
              type: element.type,
              since: element.since,
              until: element.until,
              environtment: element.environtment,
              environtment_name: element.environtment_name,
            }
          );*/
          
          if( element.type == 0){
            const rango = hours2.filter(item => item.type == 0);
            //console.log('rango',rango);
            //console.log('element',element);
            
            if(rango.length==0){
              //console.log('ingreso');
              hours2.push(
                {
                  mainData: ele,
                  data: element,
                  date: this.programming[0].date,
                  sinceMod: since,
                  untilMod: until,
                  type: element.type,
                  since: element.since,
                  until: element.until,
                  environtment: element.environtment,
                  environtment_name: element.environtment_name,
                }
              );
            }else{

              const rango_secundario = rango[rango.length - 1];
              //console.log(rango_secundario);
                
                if(rango_secundario.until === element.since){
                  //console.log('condicional');
                  
                  //const since = Number(String(element.since).replace(':', ''));
                  rango_secundario.until = element.until;
                  rango_secundario.untilMod = until;
                  rango_secundario.data.until = element.until;
                  rango_secundario.data.rowspan += element.rowspan;

                }else{
                  //console.log('aqui');
                  hours2.push(
                    {
                      mainData: ele,
                      data: element,
                      date: this.programming[0].date,
                      sinceMod: since,
                      untilMod: until,
                      type: element.type,
                      since: element.since,
                      until: element.until,
                      environtment: element.environtment,
                      environtment_name: element.environtment_name,
                    }
                  );
                }
                
              
            }
            
          }/*else if(element.type == 1){
            const rango = hours2.filter(item => item.type == 1);
            console.log('rango',rango);
            console.log('element',element);
            
            if(rango.length==0){
              console.log('ingreso');
              hours2.push(
                {
                  mainData: ele,
                  data: element,
                  date: this.programming[0].date,
                  sinceMod: since,
                  untilMod: until,
                  type: element.type,
                  since: element.since,
                  until: element.until,
                  environtment: element.environtment,
                  environtment_name: element.environtment_name,
                }
              );
            }else{

              const rango_secundario = rango[rango.length - 1];
              console.log(rango_secundario);
                
                if(rango_secundario.until === element.since){
                  //console.log('condicional');
                  
                  //const since = Number(String(element.since).replace(':', ''));
                  rango_secundario.until = element.until;
                  rango_secundario.untilMod = until;
                  rango_secundario.data.until = element.until;
                  rango_secundario.data.rowspan += element.rowspan;

                }else{
                  console.log('aqui');
                  hours2.push(
                    {
                      mainData: ele,
                      data: element,
                      date: this.programming[0].date,
                      sinceMod: since,
                      untilMod: until,
                      type: element.type,
                      since: element.since,
                      until: element.until,
                      environtment: element.environtment,
                      environtment_name: element.environtment_name,
                    }
                  );
                }
            }
          }*/else{
            hours2.push(
              {
                mainData: ele,
                data: element,
                date: this.programming[0].date,
                sinceMod: since,
                untilMod: until,
                type: element.type,
                since: element.since,
                until: element.until,
                environtment: element.environtment,
                environtment_name: element.environtment_name,
              }
            );
          }
          
          
        });
        /*this.hoursCalendar.push({
          doctor: ele.doctor,
          iddoctor: ele.iddoctor,
          hours
        });*/

        this.hoursCalendar2.push({
          doctor: ele.doctor,
          iddoctor: ele.iddoctor,
          hours2
        });
      
        //console.log( this.hoursCalendar2);
        
      });

      //console.log('this.hoursCalendar',this.hoursCalendar);
      //console.log('this.hoursCalendar',this.hoursCalendar);
      //console.log('this.hoursCalendar2',this.hoursCalendar2);
      /*this.hoursCalendar = [
        {
          doctor: "DR. DARIO MEZA",
          iddoctor: 2,
          hours:[
            {
              since: "08:00",
              sinceMod: 800,
              type: 1,
              until: "08:00",
              untilMod: 900,
              date: "2024-08-12",
              environtment: 23,
              environtment_name: "CR-3 - Piso 101",
              data : {
                environtment: 23,
                environtment_name: "CR-3 - Piso 101",
                interval: 360,
                rowspan: 60,
                since: "08:00",
                type: 0,
                until: "08:05"

              },
              mainData:{
                iddoctor: 2,
                doctor: "DR. DARIO MEZA",
                date: "2024-08-12",
                schedule:[
                  {
                    environtment: 23,
                    environtment_name: "CR-3 - Piso 101",
                    interval: 360,
                    rowspan: 60,
                    since: "08:00",
                    type: 0,
                    until: "08:05"
                  },
                  // Agrega más objetos para completar los 86 elementos del array schedule
                ]
              }
            }
          ]
        }
      ]*/
    }

    addMedicalAppointment(data: any ,horario:any): void {
      if (this.canInsert) {
        this.toastr.error(
          'No tiene permiso para agregar citas',
          'Atención',
          { timeOut: 5000, progressBar: true }
        );
        return;
      }
      
      console.log('usuario',this.usuario);
      if(this.usuario[0].agenda_sv !== '100'){
        console.log('usuario otro validado para respaldar condicional',this.usuario[0].cargo.nombre === 'EJECUTIVO DE CONTROLES');
        
        if(this.usuario[0].cargo.nombre !== 'EJECUTIVO DE CONTROLES' && this.usuario[0].cargo.nombre !== 'ASISTENTE DENTAL'
          && this.usuario[0].cargo.nombre !== 'CERRADORAS' && this.usuario[0].cargo.nombre !== 'SUPERVISOR DE ASISTENTE DENTALES'
        ){
          Swal.fire({
            title: 'Atención!!!!',
            text: 'Su usuario no acceso para agendar citas.',
            type: 'error',
            showCloseButton: false,
            showCancelButton: false
          }).then((willInsert) => {
            if (willInsert.value){
              return;
            }
          })
          return;
        }else{
          this.toastr.info('Usuario admitido '+this.usuario[0].user.username, 'Bienvenid@!!', {timeOut : 3000})
      
        const modal = this.modalSerive.open(ModalDoctorDiaryAppointmentThreeComponent, { size: 'lg' });
        modal.result.then((result: any) => {
          //console.log('añadido resultado',result);
          
          if (result === 'Save click') {
            this.setSave.emit(this.programming[0].date);
            this.setHours();
            this.setSchedule();
          }
        });
        modal.componentInstance.data = data;
        modal.componentInstance.usuario = this.usuario[0];
        modal.componentInstance.calendario = horario;
        modal.componentInstance.total_calendario = this.hoursCalendar2;
        }
      }else{
        this.toastr.info('Usuario admitido '+this.usuario[0].user.username, 'Bienvenid@!!', {timeOut : 3000})
      
        const modal = this.modalSerive.open(ModalDoctorDiaryAppointmentThreeComponent, { size: 'lg' });
        modal.result.then((result: any) => {
          //console.log('añadido resultado',result);
          
          if (result === 'Save click') {
            this.setSave.emit(this.programming[0].date);
            this.setHours();
            this.setSchedule();
          }
        });
        modal.componentInstance.data = data;
        modal.componentInstance.usuario = this.usuario[0];
        modal.componentInstance.calendario = horario;
        modal.componentInstance.total_calendario = this.hoursCalendar2;
      }
    }

    adjustTime(time: string, minutes: number): string {

      const date = new Date(`1970-01-01T${time}Z`);
      date.setMinutes(date.getMinutes() + minutes);
      
      return date.toISOString().substr(11, 8);
    }

    setAppointmentDetail(id: number,horario:any): void {
      //console.log(horario);
      //console.log(id);
      const horario_doctor = horario.hours2[0].mainData.schedule;

      let reservaciones :any[] = [];
      //Del listado principal ,obtengo un listado de todos las reservaciones en general
      horario_doctor.forEach(item =>{
        if(item.data){
          reservaciones.push(item.data);
        }
      })

      //buscas las reservacion seleccionada
      const reservacion_selected = reservaciones.filter(reser => reser.idreservation === id);
      //console.log('1',reservacion_selected);
      
      //Se filtra el listado por todas las reservaciones que hay por cliente, en este caso id = idclinichistory(se debe cambiar el dto)
      const reservaciones_disponibles = reservaciones.filter(item => reservacion_selected[0].id == item.id);
      

      let reservacion : any[] = [];

      let until = reservacion_selected[0].until;
      let since = reservacion_selected[0].since;

        let x = 0;
        let y = reservaciones_disponibles.length -1;
        let validador = true
        while (validador) {
          const disponible = reservaciones_disponibles[x];
          const disponible_alrevez = reservaciones_disponibles[y];     
          
          if(x <= reservaciones_disponibles.length){
            if((disponible.until === until) &&
            disponible.tariff_id === reservacion_selected[0].tariff_id){
              if (!reservacion.some(res => res.idreservation === disponible.idreservation)) {
                reservacion.push(disponible);
              }
              x++
              until = this.adjustTime(until, 15);
            }else{
              x++
            }


          }
          
          if(y >= 0){
                
            if(disponible_alrevez.since === since  &&
              disponible_alrevez.tariff_id === reservacion_selected[0].tariff_id){

                if (!reservacion.some(res => res.idreservation === disponible_alrevez.idreservation)) {
                  reservacion.push(disponible_alrevez);
                }
                y--;
                
            since = this.adjustTime(since, -15);
            }else{
              y--;
            }

          }

          if(x == reservaciones_disponibles.length && y < 0 ){
            validador =false;
          }
         
        }
        //console.log(reservacion);
        
        //modal para ver vizualizar cita(a)
      const modal = this.modalSerive.open(AppoitmentDetailThreeComponent, { size: 'lg' });
      modal.result.then((result: any) => {
        if (result === 'Save click') {
          this.setSave.emit(this.programming[0].date);
        } else if (result === 'Refresh') {
          this.setSave.emit(this.programming[0].date);
        }
      });
      modal.componentInstance.id = id;
      modal.componentInstance.identificar = 1;
      modal.componentInstance.reservaciones = reservacion;
      modal.componentInstance.horario_doctor = horario;
      modal.componentInstance.usuario = this.usuario[0];
      modal.componentInstance.listado_reservaciones_general = reservaciones;

    }
  }
