import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LanguageApp } from 'src/app/config/data-table.language';
import { MedicalActAttention } from 'src/app/models/main/medical-act.model';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import * as moment from 'moment';

// Services
import { MedicalActAttentionService } from 'src/app/service/main/medical-act-attention.service';
import { ReservationService } from 'src/app/service/main/reservation.service';

// Components
import { ModalAddAttentionComponent } from './modal-add-attention/modal-add-attention.component';
import { ModalAutorizationComponent } from '../security/modal-autorization/modal-autorization.component';
import { Store } from '@ngrx/store';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { ModalAddAttentionNewVersionComponent } from './modal-add-attention-new-version/modal-add-attention-new-version.component';

// Models
import { ClinicHistoryRequest } from './../../models/clinic-history-request.model'
import { finalize, takeUntil } from 'rxjs/operators';
import { handleFile } from 'src/app/exports/lib';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-attentions',
  templateUrl: './attentions.component.html',
  styleUrls: ['./attentions.component.scss']
})
export class AttentionsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  @Input() id: number;
  @Output() closeModal = new EventEmitter<boolean>();

  list: MedicalActAttention[] = [];
  list_service_order_pendig: any[];
  list_service_order_billing: any[];
  quantityAttentions: any[] = [];
  title_detail = '';

  session: any = {};
  doctor: string;
  iddoctor: number;

  filteredList = [];
  filter = {
    doctor: null,
    estado: null,
    especialidad: null
  };

  doctores: Array<{ id: number, descripcion: string }> = [];
  especialidades: Array<{ id: number, descripcion: string }> = [];
  estados: Array<{ id: number, descripcion: string }> = [];

  request:ClinicHistoryRequest;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  paginatedItems: any[] = [];
  filter_list:any[] = [];
  contenedor = false;
  protected destroyed$ = new Subject<void>();
  filters = {
    since: moment().subtract(5, 'month').format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD')
  };
  
  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private reservationService: ReservationService,
    private router: Router,
    private maaService: MedicalActAttentionService,
    private modalSerive: NgbModal,
    private store: Store<{ session: any }>,
    private chService: ClinicHistoryService,
  ) {
      
   }

  ngOnInit(): void {
    this.getSession();
    //this.getQuantityAttentions();
    this.filteredList = this.list_service_order_pendig;
    this.getData();
  }

  toggleFilters():void{
    if(this.contenedor === false){
      this.contenedor = true;
    }else{
      this.contenedor = false;
    }

  }


  getData(): void {
   
    this.list_service_order_pendig = [];

    this.request = {
      id_clinic_history : this.id,
      date_start : this.filters.since,
      date_end : this.filters.until
    }
    this.maaService.getServiceOrderNew(this.request).subscribe(
      resp=>{
        this.list_service_order_pendig = resp;
        this.filter_list = resp;

        console.log(resp);
        
        
        this.getUniqueListFilters();
        this.updatePaginatedItems();
      }
    )
  }

  getDataByDate():void{

      const sinceDate = new Date(this.filters.since);
      const untilDate = new Date(this.filters.until);

      sinceDate.setDate(sinceDate.getDate() + 1);
      untilDate.setDate(untilDate.getDate() + 1);

      if (untilDate < sinceDate) {
        this.toastr.warning('La fecha hasta debe ser mayor a la fecha desde','Advertencia',{ timeOut: 3000, progressBar: true })
        return;
      }else{
        this.list_service_order_pendig = [];
        this.request = {
          id_clinic_history : this.id,
          date_start : this.filters.since,
          date_end : this.filters.until
        }
        this.maaService.getServiceOrderNew(this.request).subscribe(
          resp=>{
            this.list_service_order_pendig = resp;
            this.filter_list = resp;
            
            this.getUniqueListFilters();
            this.updatePaginatedItems();
          }
        )
      } 
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
        }
      });
  }

  applyFilter() {

    this.filter_list = this.list_service_order_pendig.filter(item => {
      const matchDoctor = this.filter.doctor !== undefined && this.filter.doctor !== null 
        ? item.id_doctor === this.filter.doctor 
        : true;

  const matchEspecialidad = this.filter.especialidad !== undefined && this.filter.especialidad !== null 
    ? item.id_tariff === this.filter.especialidad 
    : true;

  const matchEstado = this.filter.estado !== undefined && this.filter.estado !== null 
    ? item.id_status === this.filter.estado 
    : true;
      
      return matchEspecialidad && matchDoctor && matchEstado;
    });

    this.updatePaginatedItems();
    this.getUniqueListFilters();
  }
  

  getUniqueListFilters(){
    const uniqueDoctorsMap = new Map<number, string>();
  const uniqueSpecialsMap = new Map<number, string>();
  const uniqueStatusMap = new Map<number, string>();

  this.list_service_order_pendig.forEach(item => {
    if (!uniqueDoctorsMap.has(item.id_doctor)) {
      uniqueDoctorsMap.set(item.id_doctor, item.name_doctor);
    }
    if (!uniqueSpecialsMap.has(item.id_tariff)) {
      uniqueSpecialsMap.set(item.id_tariff, item.name_tariff);
    }
    if (!uniqueStatusMap.has(item.id_status)) {
      uniqueStatusMap.set(item.id_status, item.name_status);
    }
  });

  this.doctores = Array.from(uniqueDoctorsMap, ([id, descripcion]) => ({ id, descripcion }));
  this.especialidades = Array.from(uniqueSpecialsMap, ([id, descripcion]) => ({ id, descripcion }));
  this.estados = Array.from(uniqueStatusMap, ([id, descripcion]) => ({ id, descripcion }));
    
  }

  updatePaginatedItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedItems = this.filter_list.slice(startIndex, endIndex);
  }

  goToPage(page: number) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.updatePaginatedItems();
  }

  get totalPages(): number {
    return Math.ceil(this.filter_list.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array(this.totalPages).fill(0).map((x, i) => i + 1);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  openDocument(item:any){
    //console.log('item',item);
    window.open(item,'_blank');
  }

  getQuantityAttentions(): void {
    this.quantityAttentions = [];
    this.maaService.getQuantityAttentions(this.id)
      .subscribe(
        res => {
          this.quantityAttentions = res;
        },
        err => {
          console.log({ err });
        });
  }

  get(iddoctor: number, doctor: string): void {
    this.iddoctor = iddoctor;
    this.doctor = doctor;
    this.title_detail = `Atenciones, ${doctor}`;
    this.spinner.show();
    this.list = [];
    this.list_service_order_billing = [];
    this.list_service_order_pendig = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: LanguageApp.spanish_datatables,
      search: true,
      responsive: true,
      order: [0],
      orderClasses: true
    };
    this.maaService.getByCH(this.id, iddoctor, 'D')
      .subscribe(
        res => {
          this.list = res.map(ele => {
            let total = ele.value * ele.quantity;
            ele.discount_description = '0';
            if (ele.discount_amount > 0) {
              if (ele.discount_type === 'P') {
                total = total - ((total * ele.discount_amount) / 100);
                ele.discount_description = `${ele.discount_amount} %`;
              } else {
                total -= ele.discount_amount;
                ele.discount_description = `${ele.discount_amount} ${ele.co.code}`;
              }
            }
            ele.total = Number(total.toFixed(2));
            return ele;
          });
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'Ocurrio un error al obtener las atenciones',
            'Atención',
            { timeOut: 3000, progressBar: true }
          );
        }
      );

    /**datos
     * 
     * 
     * de service order
     */

    this.maaService.getService_order_payments(iddoctor,this.id)
      .subscribe(
        res => {
         //console.log("Respuetsa de Backend",res );
         this.list_service_order_billing = res;
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'Ocurrio un error al obtener las atenciones',
            'Atención',
            { timeOut: 3000, progressBar: true }
          );
        }
      );

     this.maaService.getService_order_pending(iddoctor,this.id).subscribe(
      res =>{
        //console.log("Respuesta del backend para pendientes: ", res);
        
        this.list_service_order_pendig = res
      },
      err =>{
        this.spinner.hide();
        this.toastr.error(
          'Ocurrio un error al obtener ordenes de servicio pendiente',
          'Atencion',
          {timeOut: 3000, progressBar:true}
        );
      }
     ); 
  }

  setMedicalAct(data: any): void {
    this.closeModal.emit(true);
    const dataAttention = {
      patient: data.reservation.patient,
      id: data.reservation.id,
      tariff: data.reservation.tariff
    };
    this.reservationService.dataAReservation = dataAttention;
    this.router.navigateByUrl('/medical-attention');
  }

  openModalAddAttention(attention: any = null): void {
    if (this.validatePersonalData()) {
      return;
    }
    //const doctor = this.list[this.list.length - 1].doctor.name;
    //const iddoctor = this.list[this.list.length - 1].doctor.id;
    /*const modal = this.modalSerive.open(ModalAddAttentionComponent, { size: 'lg', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        this.get(iddoctor, doctor);
      }
    });*/

    const modal = this.modalSerive.open(ModalAddAttentionNewVersionComponent, { size: 'xl', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        //this.get(iddoctor, doctor);
        this.getDataByDate();
      }
    });
    modal.componentInstance.idclinchistory = this.id;
    //console.log(iddoctor);
    
    //modal.componentInstance.iddoctor = iddoctor;
    //modal.componentInstance.idattention = this.list[this.list.length - 1].medicalact.id;
    modal.componentInstance.attention = attention;
  }

  reset():void{
    this.filter.especialidad = null;
    this.filter.doctor = null;
    this.filter.estado = null;

    this.getDataByDate();
  }


  openDiary(data: MedicalActAttention): void {
    this.reservationService.attention = data;
    this.modalSerive.dismissAll();
    this.router.navigateByUrl('/diary');
  }

  delete(data: any): void {
    Swal.fire({
      title: 'Atención!!!!',
      text: '¿Está seguro que desea eliminar la atención?',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sí, eliminar',
      confirmButtonColor: '#FF5370',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar'
    }).then((willDelete) => {
      if (willDelete.value) {
        this.maaService.delete(data.id)
          .subscribe(
            res => {
              Swal.fire('ok!', 'Registro eliminado satisfactoriamente', 'success');
              //this.get(this.iddoctor, this.doctor);
            },
            err => {
              Swal.fire('Error!', 'No se puedo borrar la atención', 'error');
            }
          );
      }
    });
  }

  openModalAutorization(data: any, option: string): void {
    if (this.session.roles.name === 'ADMIN' || this.session.roles.name === 'SYSTEM') {
      if (option === 'delete') {
        this.delete(data);
      } else if (option === 'edit') {
        this.openModalAddAttention(data);
      }
      return;
    }
    const modal = this.modalSerive.open(ModalAutorizationComponent, { size: 'sm', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        if (option === 'delete') {
          this.delete(data);
        } else if (option === 'edit') {
          this.openModalAddAttention(data);
        }
      }
    });
    modal.componentInstance.message = 'Para eliminar la atención es necesario que sea autorizado por un usuario con perfil ADMIN!';
    modal.componentInstance.data = data;
    modal.componentInstance.module = 'medical-act-attention';
  }

  validatePersonalData(): boolean {
    const { attorney, invoise_num_document, invoise_type_document } = this.chService.clinicHistoryData;
    if ((attorney === null || attorney === '') ||
      (invoise_num_document === null || invoise_num_document === '') ||
      (invoise_type_document === null || invoise_type_document === '')) {
      Swal.fire({
        title: 'Atención!!!!',
        text: 'Debe actualizar los datos de facturación del paciente',
        type: 'warning',
        showCloseButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#FF5370',
        showCancelButton: false
      });
      return true;
    }
    return false;
  }

  get_xls_clinicHistoryReducer():void{

    const printName = `Reporte-OS-por-Paciente ${moment().format('YYYYMMDDHHmmss')}.xlsx`;
    this.maaService.report_service_order_tariff_withPatiente(this.filters.since,this.filters.until,this.id).pipe(
      takeUntil(this.destroyed$),
      finalize(() => this.spinner.hide())
    ).subscribe(
      (result: Blob) =>
      handleFile(result,printName)
    )
  }

}
