<div class="modal-header modal-header-default modal_header">
    <h5 class="modal-title titulo-modal">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <form class="editForm" *ngIf="formData" [formGroup]="formData">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label class="form-control-label" for="date">Fecha <small class="text-danger">(*)</small></label>
                    <input class="form-control input-md" [id]="formFields.Date" [name]="formFields.Date" type="date"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.Date)}"
                        [formControlName]="this.formFields.Date" disabled>
                    <label *ngIf="getErrorMessage(formFields.Date)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar la fecha
                    </label>
                </div>
            </div>
            <div class="col-md-7">
                <div class="form-group">
                    <label class="form-control-label" for="doctor">Doctor <small class="text-danger">(*)</small></label>
                    <ng-select [items]="doctorList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Doctor" [id]="this.formFields.Doctor"
                        [formControlName]="this.formFields.Doctor" [clearable]="false">
                    </ng-select>
                    <label *ngIf="getErrorMessage(formFields.Doctor)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar un doctor
                    </label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group" >
                    <label class="form-control-label" for="coin">Moneda <small class="text-danger">(*)</small></label>
                    <select [name]="this.formFields.Coin" [id]="this.formFields.Coin" class="form-control custom-select"
                        [formControlName]="this.formFields.Coin" >
                        <option *ngFor="let coin of coinList" [value]="coin.id">{{ coin.code }}</option>
                    </select>
                    <label *ngIf="getErrorMessage(formFields.Coin)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar una moneda
                    </label>
                </div>
            </div>

        </div>
        <div class="row">     
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.BusisnessLine">Linea de Negocio <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="blList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.BusisnessLine" [id]="this.formFields.BusisnessLine" [clearable]="false"
                        [formControlName]="this.formFields.BusisnessLine" (change)="getSpecialtys()"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.BusisnessLine)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        La Linea de negocio es requerida
                    </label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.Specialty">Especialidad <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="specialtyList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Specialty" [id]="this.formFields.Specialty" [clearable]="false"
                        [formControlName]="this.formFields.Specialty" (change)="getTariff()"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.Specialty)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        La especialidad es requerida
                    </label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.Tariff">Tratamiento <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="tariffList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Tariff" [id]="this.formFields.Tariff" [clearable]="false"
                        [formControlName]="this.formFields.Tariff" (change)="setMonto(1)"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.Tariff)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        El tratamiento es requerido
                    </label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.TypeDiscount">Descuento<small
                        class="text-danger">(*)</small></label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <p-button icon="pi pi-eraser" 
                                (click)="clean()" styleClass="p-button-warning" styleClass="p-button-sm"></p-button>
                        </div>
                        <ng-select [items]="discountList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                            [name]="this.formFields.TypeDiscount" [id]="this.formFields.TypeDiscount" [clearable]="false"
                            [formControlName]="this.formFields.TypeDiscount" (change)="onDiscountChange($event)"
                            (ngModelChange)="onMultiSelectChange()" class="flex-grow-1"></ng-select>
                        <label *ngIf="getErrorMessage(formFields.TypeDiscount)" id="validation-date-error"
                            class="error jquery-validation-error small form-text invalid-feedback">
                            El tratamiento es requerido
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            
        </div>
        <div class="row" *ngIf="showDiscountType">
            <div class="col-6">

                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.IdDiscountType">Motivo descuento <small
                        class="text-danger">(*)</small></label>

                    <input [name]="this.formFields.IdDiscountType" [id]="this.formFields.IdDiscountType" type="number"
                        class="form-control" [type]="text"
                        [formControlName]="this.formFields.IdDiscountType">
                        
                    <label *ngIf="getErrorMessage(formFields.IdDiscountType)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        El campo requerido
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" for="quantity">Cantidad</label>
                    <input [name]="this.formFields.Quantity" [id]="this.formFields.Quantity" type="number"
                        class="form-control" min="1" step="1" pattern="[0-9]*"
                        [formControlName]="this.formFields.Quantity" (change)="setMonto(2)" >
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="formFields.Amount">Valor Unit...</label>
                    <input [name]="this.formFields.Amount" [id]="this.formFields.Amount" type="number"
                        class="form-control" [formControlName]="this.formFields.Amount" step=".01" [min]="this.minimalUnitValue"
                        (change)="setMonto(2)" >
                </div>
            </div>
            <div class="col-3" *ngIf="this.rol == 2">
                <div class="form-group">
                    <label for="discount" class="form-control-label">Monto de Descuento <small
                            class="text-primary">{{coinDet}}</small></label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">%</span>
                                    <div class="input-group-text">
                                        <input type="checkbox" aria-label="checkbox" (change)="setMonto(2)"
                                            [name]="this.formFields.DiscountType" [id]="this.formFields.DiscountType"
                                            [formControlName]="this.formFields.DiscountType">
                                    </div>
                                </div>
                                <input class="form-control form-control-sm" [name]="this.formFields.DiscountAmount"
                                    [id]="this.formFields.DiscountAmount" type="number" (change)="setMonto(2)"
                                    [formControlName]="this.formFields.DiscountAmount" step="0.01">
                            </div>
                </div>
            </div>
            <div [ngClass]="{'col-4': this.rol != 2, 'col-3': this.rol == 2}">
                <div class="form-group">
                    <label class="form-control-label" for="total">Total</label>
                    <input [name]="this.formFields.Total" [id]="this.formFields.Total" type="number"
                        class="form-control" [formControlName]="this.formFields.Total" step=".01" min="0" readonly>
                </div>
            </div>
        </div>
        <div class="row" >

            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" for="idpaymentmethod">Forma pago <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="pmList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.PaymentMethod" [id]="this.formFields.PaymentMethod" [clearable]="false"
                        [formControlName]="this.formFields.PaymentMethod" (change)="setComision()" (change)="setPaymentMethod()"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.PaymentMethod)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Seleccione una forma de pago
                    </label>
                </div>
            </div>

            <div class="col-md-2" *ngIf="paymentMethod == 5">
                <div class="form-group">
                    <label class="form-control-label" for="payment-method">Tipo de tarjeta <span
                            class="text-danger">(*)</span></label>
                    <ng-select [items]="listCards" bindLabel="name" bindValue="id" placeholder="Seleccione"
                            [name]="this.formFields.TypeCard" [id]="this.formFields.TypeCard" [clearable]="false"
                            [formControlName]="this.formFields.TypeCard"></ng-select>

                    <label *ngIf="getErrorMessage(formFields.TypeCard)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Campo requerido
                    </label>
                </div>
            </div>
            <div class="col-md-2" *ngIf="paymentMethod !== 1 && paymentMethod !== 7 && paymentMethod !== 14 ">
                <div class="form-group">
                    <label class="form-control-label color-cris" for="bank-account">Cuenta bancaria <span class="text-danger">(*)</span></label>
                    <select [id]="formFields.NumberBank" [name]="formFields.NumberBank"
                        class="custom-select custom-select-sm"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.NumberBank)}"
                        [formControlName]="this.formFields.NumberBank">
                        <option value="">Seleccione</option>
                        <option *ngFor="let item of listBankAccountFilter" [value]="item.id">{{item.bank_name}}
                            {{item.coin_name}} - {{item.account_num}}
                        </option>
                    </select>
                    <label *ngIf="getErrorMessage(formFields.NumberBank)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Campo requerido
                    </label>
                </div>
            </div>

            <div class="col-md-2" *ngIf="paymentMethod !== 1 && paymentMethod !== 7 && paymentMethod !== 14 ">
                <div class="form-group">
                    <label class="form-control-label color-cris" [for]="formFields.NumberOperation">Nro. Operación <span
                            class="text-danger">(*)</span></label>
                    <input class="form-control form-control-sm" [id]="formFields.NumberOperation"
                        [name]="formFields.NumberOperation" type="text" placeholder="000001"
                        [formControlName]="this.formFields.NumberOperation">
                   
                </div>
            </div>

            <div class="col-md-2"  *ngIf="paymentMethod !== 1 && paymentMethod !== 7 && paymentMethod !== 14 ">
                <div class="form-group" >
                    <label class="form-control-label color-cris" [for]="formFields.PaymentDate">Fecha de Abono <span
                            class="text-danger">(*)</span></label>
                    <input class="form-control form-control-sm" [id]="formFields.PaymentDate"
                        [name]="formFields.PaymentDate" type="date"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.PaymentDate)}"
                        [formControlName]="this.formFields.PaymentDate"
                        (change)="onPaymentDateChange()">
                    <label *ngIf="getErrorMessage(formFields.PaymentDate)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Campo requerido
                    </label>
                </div>
            </div>
            <div class="col-md-3" *ngIf="paymentMethod !== 1 && paymentMethod !== 7 && paymentMethod !== 14 ">
                <div class="form-group" >
                    <label class="form-control-label color-cris" for="payment-method">Tasa de cambio</label>
                    <select [id]="formFields.ExchanceRate" [name]="formFields.ExchanceRate"
                        class="custom-select custom-select-sm"
                        (change)="setTipoDeCambioLista()" (change)="setTotalAmount($event.target.value)" [formControlName]="this.formFields.ExchanceRate">
                        <option value=0 disabled selected>Seleccione Tipo de Cambio</option>
                        <option *ngFor="let item of listExchangeRateDay" [value]="item.id">
                            {{item.exchangehouse}} {{item.value}} S/ - Fecha {{item.date | date: "dd/MM/yyyy"}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </form>
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <button type="button" class="btn btn-success btn-lg custom-font-size" (click)="agregarFila()"
            (keyup.enter)="agregarFila()">
                <i class="pi pi-plus"></i> Agregar
            </button>
        </div>
    </div>
        <div class="row">
            <div class="col-12">
                <app-clinic-history-vouchers [id]="this.idclinchistory" (archivosCargados)="manejarArchivosCargados($event)" ></app-clinic-history-vouchers>
            </div>
          </div>

          <div class="row"  >
            <div class="col-sm-12">
                <div class="table-responsive">
                    <table class="table table-striped row-border table-hover">
                        <thead>
                          <tr>
                            <th class="color-cris">#</th>
                            <th class="color-cris">Fecha</th>
                            <th class="color-cris">Doctor</th>
                            <th class="color-cris">Servicio</th>
                            <th class="color-cris">Tip. De Descuento</th>
                            <th class="color-cris">Cantidad</th>
                            <th class="color-cris">Moneda</th>
                            <th class="color-cris">Precio</th>
                            <th class="color-cris">Descuento</th>
                            <th class="color-cris">Sub Total</th>
                            <th class="color-cris">Forma de Pago</th>
                            <th class="color-cris">Banco</th>
                            <th class="color-cris">Tarjeta</th>
                            <th class="color-cris">N° OP</th>
                            <th class="color-cris">F. de Abono</th>
                            <th class="color-cris">Tasa de Cambio</th>
                            <th class="color-cris"></th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pago of this.listFielOs; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ pago.fecha }}</td>
                                <td>{{ pago.doctor}}</td>
                                <td>{{ pago.tariff}}</td>
                                <td>{{ pago.discount }}</td>
                                <td>{{ pago.quantity }}</td>
                                <td>{{ pago.coin }}</td>
                                <td>{{ pago.value }}</td>
                                <td>{{ pago.amountDiscount }}</td>
                                <td>{{ pago.total }}</td>
                                <td>{{ pago.methodPaymennt }}</td>
                                <td>{{ pago.AccountBank }}</td>
                                <td>{{ pago.methodCard }}</td>
                                <td>{{ pago.operationNumber }}</td>
                                <td>{{ pago.paymentDate }}</td>
                                <td>{{ pago.exchangueRate }}</td>
                                <td>
                                    <button class="btn btn-danger btn-sm" (click)="eliminarFila(i)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                            
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
</div>
<div class="modal-footer">
    <button type="button" uiSref="work" class="btn btn-outline-primary"
         type="button" (click)="newSave()">
        Guardar
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Salir
    </button>
</div>