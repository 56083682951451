<div class="modal-header modal_header">
    <h5 class="modal-title titulo-modal">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <button type="button" uiSref="work" class="btn btn-primary btn-raised mb-1"
    *ngIf="origin === 'AP' || origin === 'OF'" (click)="onEditTerm()">
        Editar Condiciones
        <span style="margin-left:10px;">
            <i class="fas fa-edit"></i>
        </span>
    </button>
    <hr>
    <embed [src]="this.url | safe" type="application/pdf" width="100%" height="600px" *ngIf="type === 'pdf'"/>
    <img [src]="this.url" alt="image" class="img-fluid mx-auto d-block" *ngIf="type === 'img'">
</div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')">
      Salir
    </button>
</div>