import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UnionpaymentdetailService } from 'src/app/components/appointment-detail/union-paymen-attention/unionpaymentdetail.service';
import { AuthService } from 'src/app/service/auth.service';
import { ReservationService } from 'src/app/service/main/reservation.service';
import { ProgramationService } from 'src/app/service/programation.service';
import Swal from 'sweetalert2';
import { DoctorDiaryAttentionPatientUpdateComponent } from '../../../doctor-diary-new/doctor-diary-attention-patient-update/doctor-diary-attention-patient-update.component';
import { ModalAutorizationComponent } from 'src/app/components/security/modal-autorization/modal-autorization.component';
import { UnionPaymenAttentionTwoComponent } from './union-paymen-attention-two/union-paymen-attention-two.component';
import { MedicalAppointmentComponent } from 'src/app/components/medical-appointment/medical-appointment.component';
import { AuditComponent } from 'src/app/components/audit/audit.component';
import { ClinicHistoryFormComponent } from 'src/app/pages/clinic-history/form/clinic-history-form.component';
import { MedicalAppointmentTwoComponent } from '../medical-appointment-two/medical-appointment-two.component';

export class Formdetail {
  environment: string;
  doctor: string;
  appointment: string;
  patient: string;
  reason: string;
  data: any;
  id?: number;
  doctor2?: string;
  idenvironment?: number;
  product?: string;
  pending_payment?: boolean;
  amount?: number;
  coin?: string;
  idpatient?:number;
}

@Component({
  selector: 'app-appoitment-detail-two',
  templateUrl: './appoitment-detail-two.component.html',
  styleUrls: ['./appoitment-detail-two.component.scss']
})
export class AppoitmentDetailTwoComponent implements OnInit {

  @Input() id: number;
  @Input() identificar: number;
  @Input() usuario:any;
  formInput: Formdetail;
  formInputOld: Formdetail;
  confirm = false;
  attended = false;
  canDelete: boolean;
  canUpdate: boolean;
  iduser = 0;
  status = 0;
  refreshDiary = false;
  session: any = {};
  modalReference: NgbModalRef;
  payment_attention_state: String = "Pagos Validados de la cita";
  validation_form_payments_union: boolean = false;
  validacionUsuario = true;
  tarifa_reservacion:any;
  
  constructor(
    private store: Store<{ session: any }>,
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    private serviceReservation: ProgramationService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private reservationService: ReservationService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private modalSerive: NgbModal,
    private paymentService: UnionpaymentdetailService,
    private router: Router,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    
  }

  ngOnInit(): void {
    this.getSession();
    this.initPermissions();
    this.formInput = {
      environment: '',
      doctor: '',
      appointment: '',
      patient: '',
      reason: '',
      data: {},
      doctor2: '',
    };
    this.get();
    this.iduser = Number(sessionStorage.getItem('iduser'));
    this.validation_for_union_payment();
    //console.log('identificador',this.identificar)
    //console.log('ingreso al modal de actualizar',this.usuario , typeof this.usuario);
    this.validationUser()
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
        }
      });
  }

  validationUser(){
    if(this.usuario.agenda_sv !== '100'){
      if(
        this.usuario.cargo.nombre !== 'ASISTENTE DENTAL' && this.usuario.cargo.nombre !== 'EJECUTIVO DE APNEA' && this.usuario.cargo.nombre !== 'EJECUTIVO DE OI'
        && this.usuario.cargo.nombre !== 'SUPERVISOR DE ASISTENTE DENTALES' 
      ){
        this.validacionUsuario = false;
      }
    }else{
      this.validacionUsuario = true;
    }
  }

  initPermissions(): void {
    this.route.data.subscribe(res => {
      this.auth.hasPermissionsDelete(res.permissions).subscribe(resp => {
        this.canDelete = !resp;
      });
      this.auth.hasPermissionsUpdate(res.permissions).subscribe(resp => {
        this.canUpdate = !resp;
      });

    });
  }

  get(): void {
    this.spinner.show();
    this.serviceReservation.getOne(this.id).subscribe(
      (res: any) => {
        //console.log(res);
        this.tarifa_reservacion = res.idtariff;
        this.spinner.hide();
        this.formInput = {
          environment: res.environment,
          idenvironment: res.idenvironment,
          doctor: res.doctor,
          appointment: res.appointment,
          patient: `${res.patient}`,
          reason: res.reason,
          data: res,
          doctor2: res.doctor2,
          product: res.product,
          pending_payment: res.pending_payment,
          amount: res.amount,
          coin: res.coin,
          idpatient:res.idpatient
        };
        this.status = res.state;
        if (res.state === 2) {
          this.confirm = true;
        }
        if (res.state === 3) {
          this.attended = true;
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /*setReProgramation(): void {
    sessionStorage.removeItem('oldreservation');
    Swal.fire({
      title: 'Atención!!!!',
      text: 'Para realizar la reprogramación seleccione la nueva fecha y hora en el calendario',
      type: 'info',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete: any) => {
      // console.log(willDelete);
      if (willDelete.value) {
        // console.log(this.formInput);
        sessionStorage.setItem('oldreservation', this.formInput.data.id);
        this.activeModal.close('Close');
        /*console.log('informacion general',this.formInput );
        
        const modal_unbreak = this.modalReference = this.modalSerive.open(DoctorDiaryAttentionPatientUpdateComponent, { size: 'xl', centered: true });
        modal_unbreak.componentInstance.data = this.formInput;*/
        
        /*console.log('ingreso aqui 2');
      }else{
        console.log('ingreso aqui');
        
      }
    });
  }*/

  setReProgramation(): void {

    //console.log(this.usuario);
    //console.log(this.tarifa_reservacion);

    if(this.usuario.cargo.nombre === 'EJECUTIVO DE APNEA'){
      if(this.tarifa_reservacion !== 240){
        Swal.fire({
          title: 'Adevertencia!!!!',
          text: 'No puedes realizar esta acción!!',
          type: 'error',
          showCloseButton: true,
          showCancelButton: false
        }).then((willDelete: any) => {
          // console.log(willDelete);
          if (willDelete.value) {
            return;
            
          }
        })
      }
    }else if(this.usuario.cargo.nombre === 'EJECUTIVO DE OI'){
      if(this.tarifa_reservacion !== 193){
        Swal.fire({
          title: 'Adevertencia!!!!',
          text: 'No puedes realizar esta acción!!',
          type: 'error',
          showCloseButton: true,
          showCancelButton: false
        }).then((willDelete: any) => {
          // console.log(willDelete);
          if (willDelete.value) {
            return;
            
          }
        })
      }
    }else if(this.usuario.cargo.nombre === 'ASISTENTE DENTAL'){
      if(this.tarifa_reservacion !==193 && this.tarifa_reservacion !==240){
        Swal.fire({
          title: 'Adevertencia!!!!',
          text: 'No puedes realizar esta acción!!',
          type: 'error',
          showCloseButton: true,
          showCancelButton: false
        }).then((willDelete: any) => {
          // console.log(willDelete);
          if (willDelete.value) {
            return;
            
          }
        })
      }
    }else if(this.usuario.cargo.nombre === 'SUPERVISOR DE ASISTENTE DENTALES'){
      if(this.tarifa_reservacion !==193 && this.tarifa_reservacion !==240){
        Swal.fire({
          title: 'Adevertencia!!!!',
          text: 'No puedes realizar esta acción!!',
          type: 'error',
          showCloseButton: true,
          showCancelButton: false
        }).then((willDelete: any) => {
          // console.log(willDelete);
          if (willDelete.value) {
            return;
            
          }
        })
      }
    }else{
      sessionStorage.removeItem('oldreservation');
      Swal.fire({
        title: 'Atención!!!!',
        text: 'Para realizar la reprogramación seleccione la nueva fecha y hora en el calendario',
        type: 'info',
        showCloseButton: true,
        showCancelButton: true
      }).then((willDelete: any) => {
        // console.log(willDelete);
        if (willDelete.value) {
          // console.log(this.formInput);
          if(this.identificar == 1){
            sessionStorage.setItem('oldreservation', this.formInput.data.id);
            this.activeModal.close('Close');
          }else if(this.identificar == 2){
            console.log('informacion general',this.formInput )
            const modal_unbreak = this.modalSerive.open(DoctorDiaryAttentionPatientUpdateComponent, { size: 'xl', centered: true });
            modal_unbreak.componentInstance.data = this.formInput;
  
            modal_unbreak.result.then((resultado:any)=>{
              //console.log('resultado 1',resultado);
              if(resultado === 'Save click'){
  
                this.activeModal.close('Save click')
              }
              
            })
  
          }
          
        }
      });
    }
    

  }

  cancelAppointment(data: Formdetail): void {
    if (this.session.roles.name === 'ADMIN' || this.session.roles.name === 'SYSTEM') {
      Swal.fire({
        title: 'Atención!!!!',
        text: `Esta seguro que desea cancelar la cita del paciente ${data.patient} en el
        horario ${data.appointment} en el consultorio ${data.environment}`,
        type: 'info',
        showCloseButton: true,
        showCancelButton: true
      }).then((willDelete: any) => {
        // console.log(willDelete);
        if (willDelete.value) {
          this.spinner.show();
          this.serviceReservation.cancel(this.formInput.data.id)
            .subscribe(
              res => {
                this.spinner.hide();
                this.toastr.success(
                  'Ok!', 'La cita fue cancelada',
                  { timeOut: 4000, progressBar: true }
                );
                this.activeModal.close('Save click');
              },
              err => {
                this.spinner.hide();
                this.toastr.error(
                  'Atención!', 'Ocurrio un error al cancelar la cita',
                  { timeOut: 4000, progressBar: true }
                );
              }
            );
        }
      });
      return;
    }

    const modal = this.modalSerive.open(ModalAutorizationComponent, { size: 'sm', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        Swal.fire({
          title: 'Atención!!!!',
          text: `Esta seguro que desea cancelar la cita del paciente ${data.patient} en el
          horario ${data.appointment} en el consultorio ${data.environment}`,
          type: 'info',
          showCloseButton: true,
          showCancelButton: true
        }).then((willDelete: any) => {
          // console.log(willDelete);
          if (willDelete.value) {
            this.spinner.show();
            this.serviceReservation.cancel(this.formInput.data.id)
              .subscribe(
                res => {
                  this.spinner.hide();
                  this.toastr.success(
                    'Ok!', 'La cita fue cancelada',
                    { timeOut: 4000, progressBar: true }
                  );
                  this.activeModal.close('Save click');
                },
                err => {
                  this.spinner.hide();
                  this.toastr.error(
                    'Atención!', 'Ocurrio un error al cancelar la cita',
                    { timeOut: 4000, progressBar: true }
                  );
                }
              );
          }
        });
        return;
      }
    });
    modal.componentInstance.message = 'Para cancelar la cita es necesario que sea autorizado por un usuario con perfil ADMIN!';
    modal.componentInstance.data = this.formInput.data;
    modal.componentInstance.module = 'medical-act-attention';

  }

  setConfirm(): void {
    this.refreshDiary = false;
    if (this.confirm) {
      Swal.fire({
        title: 'Atención!!!!',
        text: '¿Está seguro que desea confirmar la cita?',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true
      }).then((willDelete) => {
        if (willDelete.value) {
          this.reservationService.confirm(this.formInput.data.id, 2)
            .subscribe(
              res => {
                Swal.fire('ok!', 'Cita confirmada correctamente', 'success');
                this.get();
                this.refreshDiary = true;
              },
              err => {
                Swal.fire('Error!', 'Confirmar la cita', 'error');
              }
            );
        } else {
          this.confirm = false;
        }
      });
    } else {
      Swal.fire({
        title: 'Atención!!!!',
        text: '¿Está seguro que desea quitar la confirmación de la cita?',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true
      }).then((willDelete) => {
        if (willDelete.value) {
          this.reservationService.confirm(this.formInput.data.id, 1)
            .subscribe(
              res => {
                Swal.fire('ok!', 'Se ha quitado el confirmado a la cita', 'success');
                this.refreshDiary = true;
              },
              err => {
                Swal.fire('Error!', 'Confirmar la cita', 'error');
              }
            );
        } else {
          this.confirm = false;
        }
      });
    }
  }

  async validation_for_union_payment(): Promise<boolean>{
    //console.log("Inicializando servicio de validacion");
    
    const data = this.paymentService.getValidationpaymentsForUnionReservation(this.id).subscribe(
      res => {
        //console.log("La respuesta es: ",res);
        if (res.length > 0) {
          this.validation_form_payments_union = true 
        }else{
          this.validation_form_payments_union = false
        }
      }
      
    );  
    //console.log("Esta es la respuesta: ",this.validation_form_payments_union);
    
    
    return this.validation_form_payments_union
  }

  async openConfirmationModal(): Promise<boolean> {
    //console.log('Se entró a la función: openConfirmationModal()');
    const modal_unbreak = this.modalReference = this.modalSerive.open(UnionPaymenAttentionTwoComponent, { size: 'xl', centered: true });
    this.modalReference.componentInstance.iduser = this.iduser;
    this.modalReference.componentInstance.idReservation = this.formInput.data.id;
    try {
      const result = await modal_unbreak.result;
      //console.log("Este es el resultado: ", result);
      return result;
    } catch (error) {
      console.error('Error al cargar los pagos', error);
      return false;
    }
    
  }

  async asignet_payment_future() {

    const data = this.paymentService.getOneReservation(this.id).subscribe(
      async res => {
        //console.log("La respuesta es: ",res);
        if (res.length > 0) {
          
          if (this.validation_form_payments_union === false) {
          //console.log('Se entró a la función: asignet_payment_future()');
          const modal_unbreak = this.modalReference = this.modalSerive.open(UnionPaymenAttentionTwoComponent, { size: 'xl', centered: true });
          this.modalReference.componentInstance.iduser = this.iduser;
          this.modalReference.componentInstance.idReservation = this.formInput.data.id;
          const result = await modal_unbreak.result;
          if (result) {
            try {
              const res = await this.reservationService.confirm(this.formInput.data.id, 3).toPromise();
              // Si la confirmación es exitosa, se muestra un mensaje de éxito, se actualiza la lista de citas y se restablece refreshDiary.
              Swal.fire('¡Ok!', 'Cita Atendida correctamente', 'success');
              this.get();
              this.refreshDiary = true;
            } catch (error) {
              console.error('Error al cargar los pagos', error);
              Swal.fire('¡Error!', 'Error al atender la cita', 'error');
            }
          }else{
            this.toastr.error(
              'Error!', 'No se seleccionaron pagos para la cita, intentalo nuevamente !!!',
              { timeOut: 4000, progressBar: true }
            );
          }
          
        }else{
          this.toastr.info(
            'ya existen pagos!',
            'Ya se tiene registados los pagos asociados a esta reserva',
            { timeOut: 4000, progressBar: true }
          );
        }
      }else{
        this.toastr.warning(
          'No existen pagos!',
          'No se tienen pagos referenciados a la cita, crea el pago e intentalo nuevamente ...',
          { timeOut: 4000, progressBar: true }
        );
      }
    }
    
  );  
}
  
  async setAttended(): Promise<void> {
    let data_existence : number = 0;
    //console.log('Se entró a la función: setConfirm()');
    //console.log("Estatus actual del paciente: ",this.status);
    const validacion_de_pagos = await this.paymentService.getByPayments(this.formInput.data.id);
    validacion_de_pagos.forEach(async result => {
      if (this.status === 2) {
        if (result.length >0) {
        //console.log("Resultaods de la consulta: 1",result);
         data_existence = 1
          this.refreshDiary = false;
        const modalResult = await this.openConfirmationModal();
        //console.log('valor de modalResult: '+modalResult);
        
        if (this.confirm) {
          if (modalResult) {
            
            try {
              // Se llama al servicio para confirmar la cita.
              const res = await this.reservationService.confirm(this.formInput.data.id, 3).toPromise();
              // Si la confirmación es exitosa, se muestra un mensaje de éxito, se actualiza la lista de citas y se restablece refreshDiary.
              Swal.fire('ok!', 'Cita Atendida correctamente', 'success');
              this.get();
              this.refreshDiary = true;
            } catch (err) {
              // Si hay un error al confirmar la cita, se muestra un mensaje de error.
              Swal.fire('Error!', 'Antendido la cita', 'error');
            }
          } else {
            // Si el usuario cancela la acción, se establece confirm como false.
            //this.payment_attention_state = "No se ha seleccionado el pago"
            const res = await this.reservationService.confirm(this.formInput.data.id, 3).toPromise();
            this.get();
            this.refreshDiary = true;
            this.toastr.warning(
              'Atención!', 'No se seleccionó un pago asociado a esta cita, quedará como pendiente !!!',
              { timeOut: 4000, progressBar: true }
            );
          }
        } else {
          const modalResult = await this.paymentService.getValidationpayments(this.formInput.data.id);
          modalResult.forEach(async result => {
            if (result.length >0) {
              //console.log("Estos es el resultado de la validacion: ", result);
              //this.payment_attention_state = "Pagos Validados a la cita"
              this.toastr.error(
                'Error!', 'No se puede cancelar la atencion, ya que existe un pagos asociados',
                { timeOut: 4000, progressBar: true }
              );
            }else{
              //this.payment_attention_state = "No se ha seleccionado el pago"
              const res = await this.reservationService.confirm(this.formInput.data.id, 2).toPromise();
              Swal.fire('ok!', 'Se ha quitado el estado de atendido a la cita', 'success');
              this.refreshDiary = true;
            }
          });
        }
        }else{
          //this.payment_attention_state = "No se ha seleccionado el pago"
            this.toastr.warning(
              'No existen pagos!',
              'No se tienen pagos referenciados a la cita, crea el pago e intentalo nuevamente ...',
              { timeOut: 4000, progressBar: true }
            );
            Swal.fire({
              title: 'Atención!!!!',
              text: '¿Está seguro que desea colocar como atendido?',
              type: 'warning',
              showCloseButton: true,
              showCancelButton: true
            }).then((willDelete) => {
              if (willDelete.value) {
                this.reservationService.confirm(this.formInput.data.id, 3)
                  .subscribe(
                    res => {
                      Swal.fire('ok!', 'Cita atendida', 'success');
                      this.refreshDiary = true;
                    },
                    err => {
                      Swal.fire('Error!', 'Al cambiar el estado de la cita', 'error');
                    }
                  );
              } else {
                this.attended = false;
              }
            });
        }
      }else{

        if (this.validation_form_payments_union === false) {
          Swal.fire({
            title: 'Atención!!!!',
            text: '¿Está seguro que desea quitar la atención de la cita?',
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true
          }).then((willDelete) => {
            if (willDelete.value) {
              this.reservationService.confirm(this.formInput.data.id, 2)
                .subscribe(
                  res => {
                    Swal.fire('ok!', 'Se ha quitado la Atencion de la cita', 'success');
                    this.refreshDiary = true;
                  },
                  err => {
                    Swal.fire('Error!', 'Quitar la atencion de la cita', 'error');
                  }
                );
            } else {
              this.confirm = false;
            }
          });
        }else{
        this.toastr.error(
          'Error!', 'No se puede cancelar la atencion, ya que existe un pagos asociados',
          { timeOut: 4000, progressBar: true }
        );
      }
        //this.payment_attention_state = "Pagos Validados a la cita"
      }
    });

    //console.log("Si existe datos entra a la data ya que la variable es: ",data_existence);
      
  }

  /*setAttended(): void {
    this.refreshDiary = false;
    if (this.attended) {
      Swal.fire({
        title: 'Atención!!!!',
        text: '¿Está seguro que desea colocar como atendido?',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true
      }).then((willDelete) => {
        if (willDelete.value) {
          this.reservationService.confirm(this.formInput.data.id, 3)
            .subscribe(
              res => {
                Swal.fire('ok!', 'Cita atendida', 'success');
                this.refreshDiary = true;
              },
              err => {
                Swal.fire('Error!', 'Al cambiar el estado de la cita', 'error');
              }
            );
        } else {
          this.attended = false;
        }
      });
    } else {
      Swal.fire({
        title: 'Atención!!!!',
        text: '¿Está seguro que desea quitar el atendo de la cita?',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true
      }).then((willDelete) => {
        if (willDelete.value) {
          this.reservationService.confirm(this.formInput.data.id, 2)
            .subscribe(
              res => {
                Swal.fire('ok!', 'Se ha quitado el atendido a la cita', 'success');
                this.refreshDiary = true;
              },
              err => {
                Swal.fire('Error!', 'Confirmar la cita', 'error');
              }
            );
        } else {
          this.attended = false;
        }
      });
    }
  }*/

  editMedicalAppointment(data: any): void {
    const modal = this.modalSerive.open(MedicalAppointmentTwoComponent, { size: 'lg' });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        this.activeModal.close('Save click');
      }
    });
    data.data.acction = 'edit';
    modal.componentInstance.data = data.data;
    modal.componentInstance.usuario = this.usuario
  }

  setAudit(id: number): void {
    const modal = this.modalSerive.open(AuditComponent, { size: 'lg' });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        //
      }
    });
    modal.componentInstance.id = this.id;
    modal.componentInstance.module = 'Reservation';
  }

  setClinicHistory(info: any): void {
    const { data } = info;
    sessionStorage.removeItem('idpatient');
    const modal = this.modalSerive.open(ClinicHistoryFormComponent, { size: 'xl' });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        //
      }
    });
    modal.componentInstance.id = data.idpatient;
  }

  onClose() {
    if (this.refreshDiary) {
      this.activeModal.close('Refresh');
    } else {
      this.activeModal.close('Close');
    }
  }

  openModalAutorization(data: Formdetail): void {
    if (this.session.roles.name !== 'ADMIN' && this.session.roles.name !== 'SYSTEM') {
      const modal = this.modalSerive.open(ModalAutorizationComponent, { size: 'sm', centered: true });
      modal.result.then((result: any) => {
        if (result === 'Save click') {
          this.editMedicalAppointment(data);
        }
      });
      data.id = data.data.id;
      modal.componentInstance.message = 'Para editar la cita es necesario que sea autorizado por un usuario con perfil ADMIN!';
      modal.componentInstance.data = data;
      modal.componentInstance.module = 'appointment-detail';
    } else {
      this.editMedicalAppointment(data);
    }
  }

  goToClinicHistory(idpatient: any): void {
    //console.log('aqui',idpatient);

   sessionStorage.setItem('idpatient', idpatient.toString());
    
    const url = this.router.serializeUrl(this.router.createUrlTree(['clinic-history']));
    window.open(url, '_blank');
  }
}
