<form class="was-validated" #registerByPatient="ngForm" (ngSubmit)="addappointmentByPatient()" ngNativeValidate>
    <div class="modal-header modal_header">
        <h5 class="modal-title titulo-modal">{{this.title}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.close('Save click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <div class="row" id="search-container">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Paciente <small class="text-danger"> (*)</small></label>
                    <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed"
                        name="patient" (selectItem)="selectedItem($event)" [(ngModel)]="patient" [ngbTypeahead]="search"
                        placeholder="Buscar paciente" [resultFormatter]="resultFormatBandListValue"
                        [inputFormatter]="inputFormatBandListValue" required />
                    <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                    <div class="invalid-feedback" *ngIf="searchFailed">Error, Las sugerencias no pueden ser cargadas
                    </div>
                </div>
                <div *ngIf="!formInput.patient" class="invalid-feedback">
                    Seleccione un paciente
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Tratamiento <small class="text-danger"> (*)</small></label>
                    <ng-select [(ngModel)]="formInput.tariff" placeholder="Seleccione el tratamiento"
                        [loading]="loadingSelectTariff" name="tariff" required>
                        <ng-option *ngFor="let sp of listTariff" [value]="sp.id">{{sp.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Doctor<small class="text-danger"> (*)</small></label>
                    <ng-select [(ngModel)]="formInput.doctor" (change)="validateDoctor()"
                        placeholder="Seleccione el doctor" [loading]="loadingSelectDoctor" required name="doctor">
                        <ng-option *ngFor="let sp of listDoctors" [value]="sp.id">{{sp.namequote}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Doctor OI</label>
                    <ng-select [(ngModel)]="formInput.doctor_id2" placeholder="Seleccione el doctor"
                        [loading]="loadingSelectDoctor2" name="doctor_oi">
                        <ng-option *ngFor="let sp2 of listDoctors2" [value]="sp2.id">{{sp2.namequote}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <!-- Datos de la cita anterior -->
            <div class="col-md-12" *ngIf="reprograming">
                <h5>Datos de la cita anterior</h5>
                <hr>
            </div>
            <div class="col-md-4" *ngIf="reprograming">
                <div class="form-group">
                    <label class="form-label">Consultorio <small class="text-danger">(*)</small></label>
                    <select name="environmentOld" class="custom-select" [(ngModel)]="formInputOld.environment" required
                        disabled>
                        <option value="">Seleccione</option>
                        <option *ngFor="let ed of this.environmentList" [value]="ed.id">{{ed.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4" *ngIf="reprograming">
                <div class="form-group">
                    <label class="form-control-label" for="date">Fecha de Cita <span
                            class="text-danger">(*)</span></label>
                    <input class="form-control form-control-sm" readonly name="dateOld" type="date"
                        [(ngModel)]="formInputOld.date" required>
                </div>
            </div>
            <div class="col-md-4" *ngIf="reprograming">
                <div class="form-group">
                    <label class="form-label">Horario <small class="text-danger">(*)</small></label>
                    <input class="form-control form-control-sm" readonly name="appointmentOld" type="text"
                        placeholder="0" [(ngModel)]="formInputOld.appointment" required>
                </div>
            </div>
            <!-- Datos de la nueva cita -->
            <div class="col-md-12" *ngIf="reprograming">
                <h5>Datos de la nueva cita</h5>
                <hr>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-label">Consultorio <small class="text-danger">(*)</small></label>
                    <select name="environment" class="custom-select" [(ngModel)]="formInput.environment" required
                        disabled>
                        <option value="">Seleccione</option>
                        <option *ngFor="let ed of this.environmentList" [value]="ed.id">{{ed.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-control-label" for="date">Fecha de Cita <span
                            class="text-danger">(*)</span></label>
                    <input class="form-control form-control-sm" readonly name="date" type="date"
                        [(ngModel)]="formInput.date" required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-label">Horario <small class="text-danger">(*)</small></label>
                    <input class="form-control form-control-sm" readonly name="appointment" type="text" placeholder="0"
                        [(ngModel)]="formInput.appointment" required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="checkbox checkbox-fill d-inline">
                    <input type="checkbox" name="pendiente_pago" id="pendiente_pago"
                        [(ngModel)]="formInput.pending_payment">
                    <label for="pendiente_pago" class="cr">Pendiente pago</label>
                </div>
            </div>
            <div class="col-md-4" *ngIf="formInput.pending_payment">
                <div class="form-group">
                    <label class="form-label">Monto <small class="text-danger">(*)</small></label>
                    <input class="form-control form-control-sm" name="amount" type="text" placeholder="0"
                        [(ngModel)]="formInput.amount" required min="1">
                </div>
            </div>
            <div class="col-md-4" *ngIf="formInput.pending_payment">
                <div class="form-group">
                    <label class="form-label">Moneda <small class="text-danger">(*)</small></label>
                    <select name="coin" class="custom-select" [(ngModel)]="formInput.coin" required>
                        <option *ngFor="let co of this.listCoin" [value]="co.id">{{co.code}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="form-control-label" for="reason">Motivo</label>
                    <textarea [(ngModel)]="formInput.reason" class="form-control" name="reason" rows="2"
                        placeholder="Motivo de la cita" maxlength="500" minlength="10"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="alert alert-danger" role="alert" *ngIf="this.showAlert">
            <b>Atención!</b> el ciente tiene cuota(s) vencida(s)
        </div>
        <button type="submit" class="btn btn-warning ml-1" ngbTooltip="Cancelar" *ngIf="this.reprograming"
            (click)="cancelRepro()">Cancelar</button>
        <button type="submit" class="btn btn-primary ml-1" ngbTooltip="Programar"
            [disabled]="!registerByPatient.valid">Programar</button>
        <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Save click')">
            Salir
        </button>
    </div>
</form>