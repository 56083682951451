import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { LabStateModel } from '../../models/mat/lab-state.model';

@Injectable({
  providedIn: 'root'
})
export class LabStateService {

  private baseUrl = `${environment.apiUrl}/lab-state`;

  constructor(private http: HttpClient) { }

  getAll(): Observable<LabStateModel[]>{
    return this.http.get<LabStateModel[]>(`${this.baseUrl}`);
  }

  insert(data: LabStateModel): Observable<LabStateModel>{
    return this.http.post<LabStateModel>(`${this.baseUrl}`, data);
  }

  update(data: LabStateModel, id: number): Observable<LabStateModel>{
    return this.http.put<LabStateModel>(`${this.baseUrl}/${id}`, data);
  }

  delete(id: number): Observable<boolean>{
    return this.http.delete<boolean>(`${this.baseUrl}/${id}`);
  }
}
