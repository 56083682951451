import {Component, DoCheck, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import { Store } from '@ngrx/store';
// Load the full build.
import * as _ from 'lodash';

import {GradientConfig} from '../../../../../app-config';
import { SetDataUser } from 'src/app/store/actions/session.action';

import { AuthService } from 'src/app/service/auth.service';
import { CampusService } from 'src/app/service/campus.service';
import { UsersService } from 'src/app/service/profile/users.service';

import { CampusModel } from 'src/app/models/campus.model';
import { PermissionsService } from "../../../../../service/permissions.service";
import { MedicalActAttentionService } from 'src/app/service/main/medical-act-attention.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;
  hidden = false;
  badgeCount: number = 0;

  session:any = {};
  listCampus: CampusModel[] = [];
  campus: number = 0;
  strCampus: string = '';
  constructor(
    private _permissions:PermissionsService ,
    private authService: AuthService,
    private store: Store<{session: any}>,
    private _userService: UsersService,
    public _campusService: CampusService,
    private _medical : MedicalActAttentionService,
    private router: Router
     ) {
    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;

  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  ngOnInit(): void{
    this.setSession();
    this.getCountMedical();
  }

  onChatToggle(friendID: any) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }

  setSession(): void{
    this.session = {};
    const iduser = Number(sessionStorage.getItem('iduser'));
    this._userService.getOne(iduser).subscribe(
      res => {
          const action = new SetDataUser(res);
          this.session = res;
          if(sessionStorage.getItem("idcampus")){
            this.session.idcampus = Number(sessionStorage.getItem("idcampus"));
          }else{
            this.session.idcampus = res.campus[0];
            sessionStorage.setItem('idcampus',res.campus[0]);
          }
          this.getCampus();
          this.store.dispatch(action);
      },
      err => {
        console.error("error al obtener los datos del usuario", err);
        this.loguot();
      }
    );
  }

  loguot(): void{
    const action = new SetDataUser({});
    this.store.dispatch(action);
    this.authService.loguot();
  }

  getCampus(){
    this._campusService.getAll().subscribe(
      res => {
        this.session.campus.map((c: number) =>{
          let campus = _.find(res,{id:c});
          this.listCampus.push(campus);
        });
        this.campus = this.session.idcampus;
        this.strCampus = _.find(this.listCampus,{id: this.campus}).name;
      },
      err => {
        console.error("Error al obtener los campus");
      }
    );
  }

  changeSessionCampus(){
    this.session = {};
    const iduser = Number(sessionStorage.getItem('iduser'));
    this._userService.getOne(iduser).subscribe(
      res => {
        const action = new SetDataUser(res);
        this.session = res;
        this.session.idcampus = this.campus;
        sessionStorage.setItem('idcampus',String(this.campus));
        this.strCampus = _.find(this.listCampus,{id: this.campus}).name;
        this.store.dispatch(action);
      },
      err => {
        console.error("error al obtener los datos del usuario", err);
        this.loguot();
      }
    );
  }

  getCountMedical() {

    this.session = {};
    const iduser = Number(sessionStorage.getItem('iduser'));
    this.badgeCount = 0;
    this._medical.countMedicalActAttentionParts(iduser).subscribe({
      next: (resp) => {
        if (Array.isArray(resp) && resp.length > 0) {
          // Accede al primer objeto del arreglo y luego a la propiedad 'count'
          this.badgeCount = Number(resp[0].count); // Convertir a número si es necesario
        }
      },
      error: (error) => {
        console.error('Error al obtener el conteo médico', error);
       }
    });
  }

  navigateToPage(): void {
    this.router.navigate(['/medical-fragment']);
  }

  navigateToPagePayments(): void {
    this.router.navigate(['/new-payments']);
  }

  navigateToReschedulingReservations(): void{
    console.log('reservaciones');
    
    this.router.navigate(['/rescheduling-reservations']);
  }
}
