export class ServiceOrderPaymentDetailNew{
    fecha:Date;
    idCoin:number;
    coin:string;
    idDoctor:number;
    doctor:string;
    idBussiness:number;
    bussiness?:string;
    idSpeciality:number;
    speciality?:string;
    idTariff:number;
    tariff?:string;
    valueTariff?:number;
    idMethod?:number;
    methodPaymennt?:string;
    idDiscount?:number;
    discount?:string;
    reasonDiscount?:string;
    quantity?:number;
    value:number;
    amountDiscount?:number;
    total:number;
    idClinicHistory:number;
    serviceorderId?:number;
    typeDiscount?:boolean;
    id?:number;
    idContrato?:number;
    idReservation?:number;
    idServiceOrder?:number;
    description?:string;
    importeSubtotal?:number;
    idAccountbank?:number;
    AccountBank?:string;
    idMethodCard?:number;
    methodCard?:string;
    operationNumber?:string;
    paymentDate?:Date;
    idExchanfueRate?:number;
    exchangueRate?: number;
    idCoinTariff?:number;
}

export class ServiceOrderNew{
    id?:number;
    clinichistory?:number;
    doctor?:number;
    reservation?:number;
    dateserviceorder?:Date;
    idstatusborrado?:number;


    constructor(data?: Partial<ServiceOrderNew>) {
        // Use optional chaining to handle potentially undefined properties
        this.clinichistory = data?.clinichistory;
        this.doctor = data?.doctor;
        this.reservation = data?.reservation;
        this.dateserviceorder = data?.dateserviceorder;
        this.idstatusborrado = data?.idstatusborrado;
    }
}

export interface ServiceOrderNewResponse{
    id?:number;
    clinichistory?:number;
    doctor?:number;
    reservation?:number;
    dateserviceorder?:Date;
    idstatusborrado?:number;
}

export class ServiceOrderPaymentDetailFiles{
    id: number; 
    idserviceorder: number;
    idmedicalactfiles: number;
    idstatusborrado: number;

    constructor(data?: Partial<ServiceOrderPaymentDetailFiles>) {
        // Use optional chaining to handle potentially undefined properties
        this.idserviceorder = data?.idserviceorder;
        this.idmedicalactfiles = data?.idmedicalactfiles;
        this.idstatusborrado = data?.idstatusborrado;
    }
}