import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ServiceOrderNew, ServiceOrderPaymentDetailFiles, ServiceOrderPaymentDetailNew } from 'src/app/models/finance/service-order-new.model';
import { ModalDiscountSpecificationComponent } from '../modal-add-attention-new-version/modal-discount-specification/modal-discount-specification.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalSelectQuotaComponent } from '../modal-add-attention-new-version/modal-select-quota/modal-select-quota.component';
import { ModalAutorizationComponent } from '../../security/modal-autorization/modal-autorization.component';
import * as moment from 'moment';
import { ClinicHistoryVouchersComponent } from '../../clinic-history/clinic-history-vouchers/clinic-history-vouchers.component';
import { DiscountSpecificationsService } from 'src/app/service/discount-specifications.service';
import { ServiceOrderV2Service } from 'src/app/service/service-order-v2.service';
import { DiscountReasonService } from 'src/app/service/discount-reason.service';
import { DiscountService } from 'src/app/service/discount.service';
import { PaymentsAdvanceService } from 'src/app/service/main/payments-advance';
import { ContractService } from 'src/app/service/finance/contract.service';
import { DiscountTypeService } from 'src/app/modules/finance/discount-type/services/public/discount-type.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MedicalActAttentionService } from 'src/app/service/main/medical-act-attention.service';
import { TariffService } from 'src/app/service/tariff.service';
import { BusinessLineService } from 'src/app/service/business-line.service';
import { SpecialtyService } from 'src/app/service/specialty.service';
import { PaymentMethodService } from 'src/app/service/payment-method.service';
import { CoinService } from 'src/app/service/coin.service';
import { DoctorService } from 'src/app/modules/operations/doctor/services/public/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentsAdvanceDto } from 'src/app/models/main/payments-advance.model';
import { MedicalActAttentionContractPaays } from 'src/app/models/main/medical-act-attention-contract-pays';
import { CoinModel } from 'src/app/models/coin.model';
import { DiscountType } from 'src/app/modules/finance/discount-type/model/discount-type.model';
import { TariffModel } from 'src/app/models/tariff.model';
import { SpecialtyModel } from 'src/app/models/specialty.model';
import { BusinessLineModel } from 'src/app/models/business-line.model';
import { PaymentMethodModel } from 'src/app/models/payment-method.model';
import { EnumeracionSpecificationDto } from 'src/app/models/discount-specifications';
import { DiscountReasonDto } from 'src/app/models/discount.model';
import { Doctor } from 'src/app/modules/operations/doctor/models/doctor.model';
import { MedicalActAttention } from 'src/app/models/main/medical-act.model';
import { BehaviorSubject } from 'rxjs';


class FormFields {
  get Date(): string { return 'AttentionDate'; }
  get Coin(): string { return 'AttentionCoin'; }
  get PaymentMethod(): string { return 'AttentionPaymentMethod'; }
  get LaboratoryCost(): string { return 'AttentionLaboratoryCost'; }
  get Doctor(): string { return 'AttentionDoctor'; }
  get BusisnessLine(): string { return 'AttentionBusisnessLine'; }
  get Specialty(): string { return 'AttentionSpecialty'; }
  get Tariff(): string { return 'AttentionTariff'; }
  get Quantity(): string { return 'AttentionQuantity'; }
  get Amount(): string { return 'AttentionAmount'; }
  get Total(): string { return 'AttentionTotal'; }
  get IdDiscountType(): string { return 'IdDiscountType'; }
  get TypeDiscount(): string { return 'TypeDiscount'; }
  get DiscountType(): string { return 'DiscountType'; }
  get DiscountAmount(): string { return 'DiscountAmount'; }
}

@Component({
  selector: 'app-modal-update-attention',
  templateUrl: './modal-update-attention.component.html',
  styleUrls: ['./modal-update-attention.component.scss']
})
export class ModalUpdateAttentionComponent implements OnInit,AfterViewInit {

  idclinchistory: number;
  iddoctor: number;
  idattention: number;
  attention: any;
  idmedical: number;
  private idmedicalSubject = new BehaviorSubject<number | undefined>(undefined);
  idmedical$ = this.idmedicalSubject.asObservable();
  item:any;
  title = 'Actualizar Orden de Servicio';

  // FORM
  formData: FormGroup;
  formFields: FormFields = new FormFields();
  formInput: MedicalActAttention;

  doctorList: Doctor[] = [];
  coinList: CoinModel[] = [];
  discountList: DiscountReasonDto[] = [];
  discountSpecificationList: EnumeracionSpecificationDto [] = []; 
  pmList: PaymentMethodModel[] = [];
  blList: BusinessLineModel[] = [];
  loadingBl = false;
  specialtyList: SpecialtyModel[] = [];
  loadingSpecialty = false;
  tariffList: TariffModel[] = [];
  discountTypeList: DiscountType[] = [];
  loadingTariff = false;
  showDiscountType = false;
  archivosCargadosData: any[] = [];
  medicalcontract: MedicalActAttentionContractPaays [] = [];
  coin = 'S/';
  commission = 0;
  rol : number;
  monedaTarifa:CoinModel;
  monedaTarifaArray:any;
  amountPer:number;
  listOS: ServiceOrderPaymentDetailNew ;
  listFielOs:ServiceOrderPaymentDetailNew[]=[];
  listFielOsDelete:ServiceOrderPaymentDetailNew[]=[];
  session: any = {};

  minimalUnitValue: number = 0;
  selectedValue:any;

  pago = '';

  itemSelected : any;

  contractAmount : any;
  seleccion : boolean = false;

  advance: PaymentsAdvanceDto = {
    id: 0,
    id_inicial: 0,
    id_final: 0,
    userid_created: 0,
    userid_update: 0,
    created_at: null,
    updated_at: null
  };

  serviceorder:number;
  servideOrderNew:any = {};

  actualizar:boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private doctorService: DoctorService,
    private coinService: CoinService,
    private pmService: PaymentMethodService,
    private blService: BusinessLineService,
    private specialtyService: SpecialtyService,
    private tariffService: TariffService,
    private store: Store<{ session: any }>,
    private modalSerive: NgbModal,
    private discountTypeService: DiscountTypeService,
    private serviceDiscount:DiscountService,
    private serviceDiscountReason: DiscountReasonService,
    private serviceOrder: ServiceOrderV2Service,
    private serviceDiscountSpecificaiton: DiscountSpecificationsService,
 
  ) { 
    this.medicalcontract = []
  }


  ngOnInit(): void {

    this.generateInformacion();
    this.getSession();
    this.getDoctors();
    this.getCoins();
    this.getPaymentMethod();
    this.getBl();
    this.getDiscountType();
    this.getDiscountAll();
    this.initForm();
    this.listado();
    
  }

  @ViewChild(ClinicHistoryVouchersComponent) vouchers!: ClinicHistoryVouchersComponent;
  ngAfterViewInit() {
    //console.log(this.vouchers.whoAmI()); // I am a pup component!
  }

  async generateInformacion():Promise<void>{
    await this.serviceOrder.getServiceOrderByPatient(this.item.id).subscribe(
      resp=>{
        //console.log('respuesta',resp);
        this.servideOrderNew = resp;

      }
    )
  }

  onMultiSelectChange() {
    this.selectedValue = this.formData.get(this.formFields.TypeDiscount).value;
    // Verifica si la opción seleccionada es "otros" con id 3
    if (this.selectedValue !== null) {
      // Abre el modal correspondiente aquí
      this.openModalDiscountSpecification();
    }
  }

  getAllDiscountSpecifications(): void {
    this.discountSpecificationList =  [];
    this.serviceDiscountSpecificaiton.findAllDiscountSpecifications().subscribe({
      next: (resp) =>{
      this.discountSpecificationList = resp;
      },
      error: (error) => {
        console.log({ error });
      }
    });
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.rol = this.session.roles.idrole; 
        }
      });
  }


  getDoctors(): void {
    this.doctorList = [];
    this.doctorService.getAll().subscribe({
      next: (resp) => {
        this.doctorList = resp;
        this.formData.get(this.formFields.Doctor).setValue(this.iddoctor);
      },
      error: (error) => {
        console.log({ error });
      }
    });
  }

  async getCoins():Promise<void> {
    this.coinList = [];
    await this.coinService.getAll()
      .subscribe(
        res => {
          this.coinList = res;
          
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getPaymentMethod(): void {
    this.pmList = [];
    this.pmService.getAll()
      .subscribe(
        res => {
          this.pmList = res;
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getDiscountType() {
    this.discountTypeList = [];
    this.discountTypeService.get().subscribe({
      next: (resp) => {
        this.discountTypeList = resp;
      },
      error: () => { }
    });
  }

  getDiscountAll() {
    this.discountList = [];
    this.serviceDiscountReason.findAllDiscountReason().subscribe({
      next: (resp) => {
        this.discountList = resp;
      },
      error: () => { }
    });
  }

  async manejarArchivosCargados(datos: any[]) {
    this.archivosCargadosData = datos;
    let serviceFileList :ServiceOrderPaymentDetailFiles[] = [];
    for(let i = 0 ; i < this.archivosCargadosData.length; i++){

      let serviceFile :ServiceOrderPaymentDetailFiles = new ServiceOrderPaymentDetailFiles();

      serviceFile.idmedicalactfiles = this.archivosCargadosData[i].id;
      serviceFile.idstatusborrado =1;
      serviceFile.idserviceorder = this.serviceorder;

      serviceFileList.push(serviceFile)
    }

    await this.serviceOrder.createServiceOrderPaymentDetailFiles(serviceFileList).subscribe(
      res =>{
        this.spinner.hide();
        this.toastr.success('carga de acrhivos exitosa!!', 'Ok!', {
          timeOut: 3000,
        });
      },
      error => {
        this.spinner.hide();
        this.toastr.error('carga de acrhivos errada!!', 'Error!', {
          timeOut: 3000,
        });
      }
    );

  };

  initForm() {

    //console.log(this.item);
    

    this.formData = this.fb.group({});

    const today = this.attention ? this.attention.date : moment().tz('America/Lima').format('YYYY-MM-DD');
    this.formData.addControl(this.formFields.Date, new FormControl(today, [Validators.required]));
    this.formData.addControl(this.formFields.Coin, new FormControl());
    this.formData.addControl(this.formFields.PaymentMethod, new FormControl(this.attention ? this.attention.idpaymentmethod : 1));
    this.formData.addControl(this.formFields.LaboratoryCost, new FormControl(this.attention ? this.attention.lab_cost : 0));
    this.formData.addControl(this.formFields.Doctor, new FormControl(13, [Validators.required]));
    const discount_amount = this.attention ? this.attention.discount_amount : null;
    this.showDiscountType = discount_amount > 0 ? true : false;
    const iddiscounttype = this.attention ? this.attention.iddiscounttype ? this.attention.iddiscounttype.id : null : null;
    this.formData.addControl(this.formFields.IdDiscountType, new FormControl(iddiscounttype));
    const discountType = this.attention ? this.attention.discount_type === 'P' ? true : false : false;
    this.formData.addControl(this.formFields.TypeDiscount, new FormControl(null))
    this.formData.addControl(this.formFields.DiscountType, new FormControl(discountType));
    this.formData.addControl(this.formFields.DiscountAmount, new FormControl(discount_amount));

    let businessLine = null;
    let idSpecialty = null;
    let idTariff = null;
    if (this.attention) {
      const { tariff } = this.attention;
      const { specialty } = tariff;
      const { businessLines } = specialty;
      idSpecialty = specialty.id;
      businessLine = businessLines.id;
      idTariff = tariff.id;
    }

    this.formData.addControl(this.formFields.BusisnessLine, new FormControl(businessLine, [Validators.required]));
    this.formData.addControl(this.formFields.Specialty, new FormControl(idSpecialty, [Validators.required]));
    this.formData.addControl(this.formFields.Tariff, new FormControl(idTariff));
    this.formData.addControl(this.formFields.Quantity, new FormControl(this.attention ? this.attention.quantity : 1));
    this.formData.addControl(this.formFields.Amount, new FormControl(this.attention ? this.attention.value : 0));
    this.formData.addControl(this.formFields.Total, new FormControl(0));

    if (this.attention) {
      this.getSpecialtys();
    }
  }



  setComision(): void {
    const comi = this.pmList.filter(el => el.id === this.formData.get(this.formFields.PaymentMethod).value);
    this.commission = comi[0].commission;
  }

  getBl(): void {
    this.blList = [];
    this.loadingBl = true;
    this.blService.getAll()
      .subscribe(
        res => {
          this.loadingBl = false;
          this.blList = res;
        },
        err => {
          this.loadingBl = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of Specialtys <Especialidades>
   */
  getSpecialtys(): void {
    this.formData.get(this.formFields.Specialty).setValue(null);
    this.specialtyList = [];
    this.loadingSpecialty = true;
    this.specialtyService.getByBusinessLine(this.formData.get(this.formFields.BusisnessLine).value)
      .subscribe(
        res => {
          this.specialtyList = res;
          this.loadingSpecialty = false;
          if (this.attention) {
            this.formData.get(this.formFields.Specialty).setValue(this.attention.tariff.specialty.id);
            this.getTariff();
          }
        },
        err => {
          this.loadingSpecialty = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of tariff <Tarifario o tratamiento>
   */
  getTariff(): void {
    this.loadingTariff = true;
    this.formData.get(this.formFields.Tariff).setValue(null);
    this.tariffList = [];
    this.tariffService.getBySpecialty(this.formData.get(this.formFields.Specialty).value)
      .subscribe(
        res => {
          this.tariffList = res;
          this.loadingTariff = false;
          if (this.attention) {
            this.formData.get(this.formFields.Tariff).setValue(this.attention.tariff.id);
            this.setMonto(2);
          }
        },
        err => {
          this.loadingTariff = false;
          console.log(err.error);
        }
      );
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required')) {
      return true;
    }
    return false;
  }


  openModalAutorization(): void {
    const modal = this.modalSerive.open(ModalAutorizationComponent, { size: 'sm', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        // this.get(iddoctor, doctor);
      } else {
        this.activeModal.close('Close click');
      }
    });
    modal.componentInstance.message = 'Para editar la atención es necesario que sea autorizado por un usuario con perfil ADMIN!';
    modal.componentInstance.data = this.attention;
    modal.componentInstance.module = 'medical-act-attention';
  }

  onDiscountChange(event : any){
    this.amountPer = event.discountrate;
    this.formData.get(this.formFields.DiscountType).setValue(true);
    this.formData.get(this.formFields.DiscountAmount).setValue(this.amountPer);
    this.setMonto(2);
  }

  clean():void{
    this.selectedValue = null;
        this.formData.get(this.formFields.TypeDiscount).reset();
        this.formData.get(this.formFields.DiscountType).reset();
        this.formData.get(this.formFields.DiscountAmount).reset();
        this.formData.get(this.formFields.Total).reset();
        this.formData.get(this.formFields.Amount).reset();
        this.formData.get(this.formFields.Tariff).reset();
        this.seleccion = false;
        this.setMonto(2)
  }

  async setMonto(opc: number):Promise<void> {
    const type = this.formData.get(this.formFields.TypeDiscount).value;
    const discount = this.formData.get(this.formFields.DiscountAmount).value;
    this.showDiscountType = false;
    if(type == null && discount > 0){
      this.showDiscountType = false;
      /*this.toastr.error('Debe seleccionar un tipo de descuento', 'Ok!', {
        timeOut: 3000,
      });*/
      return;
    }

    this.formData.get(this.formFields.IdDiscountType).clearValidators();
    const tariff = this.formData.get(this.formFields.Tariff).value;

    if((tariff == 123 || tariff == 121 || tariff == 202 || tariff == 204 || tariff ==203
      || tariff == 122) && this.seleccion == false){
      const modal = this.modalSerive.open(ModalSelectQuotaComponent, { size: 'lg', centered: true});
      modal.result.then((result:any) => {

        if (result === 'Close click') {
          this.clean();
          
        } else {
          //console.log('Resultado del modal cerrado:', result);
          this.itemSelected = result;
          this.formData.get(this.formFields.Amount).setValue(this.itemSelected.amount);
          const quantity = this.formData.get(this.formFields.Quantity).value;
          const amount = this.formData.get(this.formFields.Amount).value
          const total = this.formData.get(this.formFields.Total).setValue(quantity * amount);
          this.seleccion = true;

        }

      })
      modal.componentInstance.idclinchistory = this.idclinchistory;
      modal.componentInstance.listFielOs = this.listFielOs;
      modal.componentInstance.idTarifa = tariff;
    }

    const valor = this.tariffList.find(it => it.id === tariff);
    const discount_type = this.formData.get(this.formFields.DiscountType).value;
    const discount_amount = this.formData.get(this.formFields.DiscountAmount).value;

    if (this.formData.get(this.formFields.Amount).value <= 0 || opc === 1) {
      this.minimalUnitValue = valor.price_sol > 0 ? valor.price_sol : valor.price_usd;
      this.formData.get(this.formFields.Amount).setValue(this.minimalUnitValue);
    }
    this.coin = valor.price_sol > 0 ? 'S/' : '$';
    let total = 0;
    total = Number((this.formData.get(this.formFields.Quantity).value * this.formData.get(this.formFields.Amount).value));
    if (discount_type && discount_amount > 0) {
      this.showDiscountType = true;
      this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
      total = total - Number(((total * discount_amount) / 100));
    } else if (discount_amount > 0) {
      this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
      this.showDiscountType = true;

      if (discount_type == false){
        const prom = (this.amountPer/100) * total;

        if(discount_amount < prom){
          total -= discount_amount;
        }
        else{
          /*this.toastr.error('El importe de descuento es mayor al calculado!!', 'Ok!', {
            timeOut: 3000,
          });*/
        }
      }
      
    }
    this.formData.get(this.formFields.Total).setValue(total.toFixed(2));

    this.monedaTarifa = this.coinList.find(it => it.code === this.coin);
    
    this.formData.get(this.formFields.Coin).setValue(this.monedaTarifa.id);
    

  }

  openModalDiscountSpecification():void{
    const modalSpecific = this.modalSerive.open(ModalDiscountSpecificationComponent)
    modalSpecific.result.then((result: any) => {

      if (result === 'Save click') {
        // this.get(iddoctor, doctor);
        const data = modalSpecific.componentInstance.selectedCategories;

        let info='';
        for(let i = 0;i <data.length; i++){  
          if (i > 0) {
            info += ', '; // Agregar coma después del primer elemento
          }

          if(data[i].id == 3){
            info += `${data[i].name} - ${data[i].otherdescription} `;
          }else{
            info += `${data[i].name} `;
          }
        }
        
        this.formData.get(this.formFields.IdDiscountType).setValue(info);
      } else {

        this.selectedValue = null;
        this.formData.get(this.formFields.TypeDiscount).reset();
        this.formData.get(this.formFields.DiscountType).reset();
        this.formData.get(this.formFields.DiscountAmount).reset();
        this.setMonto(2)

      }
    });
  }


  async listado():Promise<void>{
   
    const detalles = await this.serviceOrder.getServiceOrderByPatient(this.item.id).toPromise();
    const coins = await this.coinService.getAll().toPromise();
    const tarifas = await this.tariffService.getAll().toPromise();
    const bussinesslist = await this.blService.getAll().toPromise();
    const specialities = await this.specialtyService.getAll().toPromise();
    const descuentoslistado = await this.serviceDiscount.getAllDiscoutn().toPromise();
    const mdoctores = await this.doctorService.getAll().toPromise();

    const detalle = this.servideOrderNew.detail;

    for (let index = 0; index < detalle.length; index++) {
      
      const coin = coins.find(co => co.id == detalle[index].coin);
      const doctor = mdoctores.find(item => item.id == this.servideOrderNew.iddoctor)
      const bussiness = bussinesslist.find(item => item.id == detalle[index].businessline )
      const tarifa = tarifas.find(item => item.id == detalle[index].tariff )
      const especialidad = specialities.find(item => item.id == detalle[index].specialty )
      const descuento = descuentoslistado.find(item => item.id == detalle[index].discount)

      let descuentoTipo;
      if(descuento == undefined){
        descuentoTipo = null;
      }

      this.listOS = {
        fecha:this.servideOrderNew.dateserviceorder,
        idCoin:coin.id,
        coin:coin.code,
        idDoctor:doctor.id,
        doctor:doctor.nameQuote,
        idBussiness:bussiness.id,
        bussiness:bussiness.name,
        idSpeciality:especialidad.id,
        speciality:especialidad.name,
        idTariff:tarifa.id,
        tariff:tarifa.name,
        idDiscount: descuento ? descuento.id : descuentoTipo,
        discount: descuento && descuento.idstatusapproval && descuento.idstatusapproval.name ? descuento.idstatusapproval.name : '-',
        quantity:detalle[index].quantity,
        value:detalle[index].value,
        amountDiscount:descuento ? descuento.numericalvalue : descuentoTipo,
        total:detalle[index].subtotal,
        idClinicHistory:this.servideOrderNew.idclinchistory,
        idServiceOrder:detalle[index].idDetail

      }
      this.listFielOs.push (this.listOS);
    }

    
  }

  agregarFila():void{

    const idAttention = this.idclinchistory;
    const idCoin = this.formData.get(this.formFields.Coin).value;
    const date = this.formData.get(this.formFields.Date).value;
    const paymentMethod = this.formData.get(this.formFields.PaymentMethod).value;
    const laboratory = this.formData.get(this.formFields.LaboratoryCost).value;
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const busisnessLine = this.formData.get(this.formFields.BusisnessLine).value;
    const specialty = this.formData.get(this.formFields.Specialty).value;
    const tariff = this.formData.get(this.formFields.Tariff).value;
    const amount = this.formData.get(this.formFields.Amount).value;
    const total = this.formData.get(this.formFields.Total).value;
    const idDiscountType = this.formData.get(this.formFields.IdDiscountType).value;
    const type = this.formData.get(this.formFields.TypeDiscount).value;
    const discount = this.formData.get(this.formFields.DiscountType).value;
    const discoutamount = this.formData.get(this.formFields.DiscountAmount).value;
    const cantidad = this.formData.get(this.formFields.Quantity).value;

    let idcontract;
      if (this.itemSelected !== undefined) {
          idcontract = Number(this.itemSelected['Id del contrato']);
      } else {
          idcontract = null;
      }

    if(type == null && discoutamount > 0){
      this.showDiscountType = false;
      this.toastr.error('Debe seleccionar un tipo de descuento', 'Ok!', {
        timeOut: 3000,
      });
      this.clean();
      return;
    }

    if (discount == false){
      const prom = (this.amountPer/100) * total;

      if(discoutamount > prom){
        this.toastr.error('El importe de descuento es mayor al calculado!!', 'Ok!', {
          timeOut: 3000,
        });
        return;
      }
    }

    if (!idCoin || idCoin <= 0) {
      this.toastr.warning("Debe agregar un coin", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!doctor || doctor <= 0) {
      this.toastr.warning("Debe seleccionar un Doctor", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!paymentMethod || paymentMethod <= 0) {
      this.toastr.warning("Debe seleccionar una forma de pago", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!busisnessLine || busisnessLine <= 0) {
      this.toastr.warning("Debe seleccionar una linea de trabajo", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!specialty || specialty <= 0) {
      this.toastr.warning("Debe seleccionar una especialidad", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!tariff || tariff <= 0) {
      this.toastr.warning("Debe seleccionar una tarifa", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (this.listFielOs.length > 0){
      
      for(let i = 0 ; i < this.listFielOs.length ; i++){
        if(this.listFielOs[i].idDoctor !== doctor){
          this.toastr.warning("Se está seleccionando a un doctor diferente", "Atención", { timeOut: 3000, progressBar: true });
          return;
        }
      }

      /*for(let i = 0 ; i < this.listFielOs.length ; i++){
        if(this.listFielOs[i].idCoin !== idCoin){
          this.toastr.warning("Las tarifas seleccionas son de monedas diferentes", "Atención", { timeOut: 3000, progressBar: true });
          return;
        }
      }*/
    }

    const coin = this.coinList.find(co => co.id == idCoin);
    const doc = this.doctorList.find(d => d.id == doctor);
    const business = this.blList.find(bl => bl.id = busisnessLine);
    const spe = this.specialtyList.find(sp => sp.id == specialty);
    const tarifa = this.tariffList.find(tr => tr.id == tariff);
    
    const method = this.pmList.find(pl => pl.id == paymentMethod);

    let descuentoTipo;
    if(type == null){
      descuentoTipo = null;
    }else{
      descuentoTipo = this.discountList.find(dt => dt.id == type);
    }

    this.listOS = {
      fecha:date,
      idCoin:coin.id,
      coin:coin.code,
      idDoctor:doc.id,
      doctor:doc.nameQuote,
      idBussiness:business.id,
      bussiness:business.name,
      idSpeciality:spe.id,
      speciality:spe.name,
      idTariff:tarifa.id,
      tariff:tarifa.name,
      idMethod:method.id,
      methodPaymennt:method.name,
      idDiscount: descuentoTipo ? descuentoTipo.id : null,
      discount: descuentoTipo ? descuentoTipo.name : '-',
      reasonDiscount:idDiscountType,
      quantity:cantidad,
      value:amount,
      amountDiscount:discoutamount,
      total:total,
      idClinicHistory:idAttention,
      typeDiscount:discount,
      idContrato : idcontract?? null
    }

    this.listFielOs.push (this.listOS);

    this.clean();
  }

  seleccionarFila(pago: any, index: number) {
    this.formData.get(this.formFields.Doctor).setValue(pago.idDoctor);
    this.actualizar = true;
}

  eliminarFila(index: number,pago:any): void {
    // Verificar si el índice está dentro de los límites del array
    if (index >= 0 && index < this.listFielOs.length) {
      // Eliminar la fila en la posición del índice
      this.listFielOs.splice(index, 1);
      this.listFielOsDelete.push(pago)
      
    }
  }

  actualizarFila():void{

    //console.log(this.listFielOs);
    const doctor = this.formData.get(this.formFields.Doctor).value;
    //console.log(doctor);
    const newDoctor = this.doctorList.find(item => item.id == doctor);
    //console.log(newDoctor);
    
    for(let i = 0; i < this.listFielOs.length ; i++){
      this.listFielOs[i].idDoctor = newDoctor.id;
      this.listFielOs[i].doctor = newDoctor.nameQuote;
    }
    
    this.actualizar = false;
  }
  save(): void {


    this.spinner.show();
    
    //console.log(this.servideOrderNew.iddoctor);
    //console.log(this.listFielOs);
    if(this.listFielOs.length == 0){
      this.spinner.hide();
        this.toastr.error('No se han registrado ordenes de servicio detalle', 'Error!', {
        timeOut: 3000,
      });
      return;
    }
    
    if(this.servideOrderNew.iddoctor !== this.listFielOs[0].idDoctor){
      this.serviceOrder.updateServiceOrderByDoctor(this.servideOrderNew.id,this.listFielOs[0].idDoctor).subscribe(
        resp=>{
          this.spinner.hide();
          this.toastr.success('se actualizo el doctor en la Orden de Servicio!!', 'Ok!', {
            timeOut: 3000,
          });
          if(this.listFielOs.length > this.servideOrderNew.detail.length){
            this.serviceOrder.updateOrdenServiceDetail(this.listFielOs,this.servideOrderNew.id).subscribe(
              resp=>{
                this.spinner.hide();
                this.toastr.success('Se añadio los nuevo detalles a la Orden de Servicio!', 'Ok!', {
                  timeOut: 3000,
                });

                if(this.listFielOsDelete.length >0){
                  this.serviceOrder.updateStateOrdenService(this.listFielOsDelete).subscribe(
                    resp=>{
                      this.spinner.hide();
                      this.toastr.success('Se eliminaron detalles!', 'Ok!', {
                        timeOut: 3000,
                      });
                      this.activeModal.close('Save click');
                    }
                  )
                }  
              },error=>{
                console.log(error);
                this.spinner.hide();
                this.toastr.error('No se actualizo detalle','error!',{
                  timeOut: 3000,
                })
              }
            )
          }else{
            this.activeModal.close('Save click');
          }
        },error=>{
          console.log(error);
          this.spinner.hide();
          this.toastr.error('No se logro la actualización del doctor','error!',{
            timeOut: 3000,
          })
        }
      )
    }else if(this.listFielOsDelete.length >0){
      this.serviceOrder.updateStateOrdenService(this.listFielOsDelete).subscribe(
        resp=>{
          this.spinner.hide();
          this.toastr.success('Se eliminaron detalles!', 'Ok!', {
            timeOut: 3000,
          });
          if(this.listFielOsDelete.length >0){
            this.serviceOrder.updateStateOrdenService(this.listFielOsDelete).subscribe(
              resp=>{
                this.spinner.hide();
                this.toastr.success('Se eliminaron detalles!', 'Ok!', {
                  timeOut: 3000,
                });
                this.activeModal.close('Save click');
              }
            )
          }
        }
      ),error=>{
        console.log(error);
        this.spinner.hide();
        this.toastr.error('No se eliminaros detalle','error!',{
          timeOut: 3000,
        })
      }
    }else{
         console.log('aqui');
          
          if(this.listFielOs.length > this.servideOrderNew.detail.length){
            
            this.serviceOrder.updateOrdenServiceDetail(this.listFielOs,this.servideOrderNew.id).subscribe(
              resp=>{
                this.spinner.hide();
                this.toastr.success('Se añadio los nuevo detalles a la Orden de Servicio!', 'Ok!', {
                  timeOut: 3000,
                });

                this.activeModal.close('Save click');
              },error=>{
                console.log(error);
                this.spinner.hide();
                this.toastr.error('No se actualizo detalle','error!',{
                  timeOut: 3000,
                })
              }
            )
          }
    }
    
  }

  formatDate(dateString: string): string {
    return moment(dateString).format('DD/MM/YYYY');
  }
}
