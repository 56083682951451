<form class="was-validated" (ngSubmit)="onSubmit()" ngNativeValidate>
  <div class="modal-header modal_header">
      <h5 class="modal-title titulo-modal">Estados aparatos aboratorio</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss(this.closeModal)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label" for="state">Estado</label>
            <input name="state" class="form-control form-control-sm" placeholder="Estado aparato"
            [(ngModel)]="formInput.name" required>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group mt-3">
            <div class="switch switch-success d-inline m-r-10">
              <input type="checkbox" id="switch-chip" name="chip" [(ngModel)]="formInput.chip">
              <label for="switch-chip" class="cr"></label>
            </div>
            <label>Chip</label>
          </div>
        </div>
        <div class="col-sm-12">
          <hr>
          <app-card cardTitle="Lista de estados" cardClass="card-datatable" [options]="false">
            <div class="d-flex align-items-center" *ngIf="loading">
              <strong>Loading...</strong>
              <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
            </div>
            <div class="table-responsive" *ngIf="!loading">
              <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered nowrap table-hover table-sm">
                <thead>
                  <tr>
                    <th style="width: 50px;">#</th>
                    <th>Nombre</th>
                    <th style="width: 100px;">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataList">
                    <td>{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td>
                      <button type="button" class="btn btn-success btn-sm" (click)="setItem(item);"><i class="fas fa-edit"></i></button>
                      <button type="button" class="btn btn-danger btn-sm ml-1" (click)="delete(item)"><i class="fas fa-ban"></i></button>
                    </td>
                  </tr>
                </tbody>
                  <tfoot>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Acciones</th>
                    </tr>
                  </tfoot>
              </table>
            </div>
          </app-card>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-outline-dark"
    (click)="activeModal.close(this.closeModal)">
      Salir
    </button>
    <button type="submit" class="btn btn-outline-primary">
      <i class="fas fa fa-save"></i> Registrar
    </button>
  </div>
</form>
