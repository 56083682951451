import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Programming } from '../doctor-diary/models/programming.model';
import { DoctorDiaryService } from '../doctor-diary/services/public/doctor-diary.service';
import { DoctorDiaryAttentionPatientComponent } from '../doctor-diary/components/doctor-diary-attention-patient/doctor-diary-attention-patient.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { DiaryListComponent } from 'src/app/components/diary-list/diary-list.component';
import { DiaryLockComponent } from 'src/app/components/diary-lock/diary-lock.component';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { DoctorDiaryModel, ItemHourDoctor } from './model/doctor-diary-model-hour';
import { FormDoctorProgrammingService } from '../../../service/form-doctor-programming.service'
import { AppointmentDetailComponent } from 'src/app/components/appointment-detail/appointment-detail.component';
import { Router } from '@angular/router';
import { ClinicHistoryFormComponent } from 'src/app/pages/clinic-history/form/clinic-history-form.component';


@Component({
  selector: 'app-doctor-diary-new',
  templateUrl: './doctor-diary-new.component.html',
  styleUrls: ['./doctor-diary-new.component.scss']
})
export class DoctorDiaryNewComponent implements OnInit {

  public model: any;
  public date: { year: number, month: number };

  // Autocomplet
  searching = false;
  searchFailed = false;
  patient = '';
  doc = '';
  filterPatient: any;

  // Estado
  filterState: any;
  loadingSelectStateFilter: boolean;
  listState: any[] = [];
  listHours: any[] = [];
  listHoursGeneral:any[] = [];

  date_selected:string;
  minDate: NgbDate; 
  list_docs:any[] = [];
  selectedDoctor: any = null;

  @Output() setSave = new EventEmitter<string>();

  constructor(
    private calendar: NgbCalendar,
    public parserFormatter: NgbDateParserFormatter,
    private servicePatient: ClinicHistoryService,
    private modalSerive: NgbModal,
    private doctorProgramming :DoctorDiaryService,
    private router: Router,
    private spinner: NgxSpinnerService,
    
  ) { 
    this.minDate = new NgbDate(2024, 7, 1);
  }

  ngOnInit(): void {
    this.getStatesFilter();
    this.filterState = 0;
    this.selectToday();
    
    
  }



 getCalendary():any{
    this.spinner.show();
  
    this.doctorProgramming.get_programing_reserv_doctor_with_patient('1',this.date_selected).subscribe(
      resp=>{
        //console.log('respuesta',resp);
        
        if(resp.length > 0){
          //console.log("Entró al if: ", (resp.length > 0));
          
          this.listHours = [];
          this.list_docs = [];
          for (let index = 0; index < resp.length; index++) {
            //console.log("Entro al for inicial: ", index);
            
            const data = resp[index];

            //console.log("Doctor id: ",data);

            if(data && data.length > 0){
              let doctor: DoctorDiaryModel = {
                idDoctor: data[0].idDoctor,
                nameQuote: data[0].nameQuote,
                date: data[0].date,
                items: []
              }
            
              this.list_docs.push(doctor);
              
              for (let x = 0; x < data.length; x++) {
                 const item : ItemHourDoctor = {
                   id_appointment: data[x].idAppointment,
                   id_clinic_history: data[x].idClinicHistory,
                   id_consultorio: data[x].idConsultorio,
                   consultorio: data[x].consultorio,
                   history_clinic: data[x].historyClinic,
                   interval: data[x].interval,
                   availability_status: data[x].availabilityStatus,
                   since: data[x].since,
                   until: data[x].until,
                   ap_materno_paciente:data[x].apMaternoPaciente ,
                   ap_paterno_paciente: data[x].apPaternoPaciente,
                   nombre_paciente: data[x].nombrePaciente,
                   name_quote: data[x].nameQuote
                 }  
  
                 doctor.items.push(item)
              }
              
              this.listHours.push(doctor);
            }
              
            }
            this.listHoursGeneral = this.listHours;
            this.spinner.hide();
        }else{

          this.listHours = resp;
          this.spinner.hide();
        }
        
      },error=>{
        console.log(error);
        this.spinner.hide();
      }
    );


    
  }

  formatTime(time: string): string {
    const parsedTime = new Date(`1970-01-01T${time}`);
  
    const hours = parsedTime.getHours().toString().padStart(2, '0');
    const minutes = parsedTime.getMinutes().toString().padStart(2, '0');
  
    return `${hours}:${minutes}`;
  }

  //ng select que lista los estados de las reservas
  getStatesFilter(): void {
    this.loadingSelectStateFilter = true;
    this.listState = [
      {
        id: 0,
        state: 'Todos'
      },
      {
        id: 1,
        state: 'Por confirmar'
      },
      {
        id: 2,
        state: 'Confirmado'
      },
      {
        id: 3,
        state: 'Atendido'
      },
    ];
    this.filterState = this.listState[0].id;
    this.loadingSelectStateFilter = false;
  }

  //aperturaba un modal para la busqueda de citas por doctor
  onSearch(): void {
    const modal = this.modalSerive.open(DiaryListComponent, { size: 'xl' });
    modal.result.then((res: any) => {
      if (res === 'Save click') {
        //
      }
    });
    modal.componentInstance.patient = this.filterPatient;
    //modal.componentInstance.doctor = this.filterDoctor;
    modal.componentInstance.state = this.filterState;
    //modal.componentInstance.date = this.dateSelect;
  }

  //seleccionaba el dia hoy
  selectToday(): void {
    /*const today = new Date();
    this.model = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());*/
    const today = new Date();
    const year = today.getFullYear();
    const month = 7;  // Julio es el mes 7
    const day = 1;    // Primer día del mes

    this.model = new NgbDate(year, month, day);
    this.setDate();
  }

  //seleccionaba un dia elegido en el calendario
  setDate(): void {
    const month = this.model.month < 10 ? `0${this.model.month}` : this.model.month;
    const day = this.model.day < 10 ? `0${this.model.day}` : this.model.day;
    const dateSelect = `${this.model.year}-${month}-${day}`;
    this.date_selected = dateSelect;
    this.getCalendary();
  }

 //selecciona un paciente no realiza mas
  selectedItem(it: any): void {
    const { item } = it;
    this.filterPatient = item.id;

    const filteredItems: any[] = [];

    // Suponiendo que listHours es el arreglo que contiene los datos
    this.listHoursGeneral.forEach(doctor => {
      const filteredItemsForDoctor = doctor.items.filter(hour => hour.id_clinic_history === this.filterPatient);
      if (filteredItemsForDoctor.length > 0) {
        // Si hay elementos filtrados para este doctor, los agregamos a filteredItems
        filteredItems.push({ ...doctor, items: filteredItemsForDoctor });
      }
    })

    this.listHours = filteredItems;
    
  }

  /* Autocompletado de pacientes **/
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.servicePatient.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            //console.log('aqui');
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

    searchDoctor = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
        map(term => term.trim()), // Elimina espacios en blanco del inicio y final del término de búsqueda
        map(term => term.toLowerCase()), // Convierte el término de búsqueda a minúsculas
        tap(() => this.searchFailed = false),
        map(term => this.list_docs.filter(doctor => doctor.name_quote.toLowerCase().indexOf(term) > -1)), // Filtra los doctores según el término de búsqueda
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        }),
        tap(() => this.searching = false)
      );

      formatter = (doctor: any) => doctor.name_quote; // Función para formatear los resultados en la lista desplegable

      onDoctorSelected(doctor: any) {
        //console.log('Doctor seleccionado:', doctor);

        const id_doctor = doctor.item.idDoctor;

        const filteredItems: any[] = [];
        this.listHours = this.listHoursGeneral.filter(item => item.idDooctor === id_doctor);    
        
    
  }

  resultFormatBandListValue(value: any): string {
    return value.name;
  }

  inputFormatBandListValue(value: any): any {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  newAttention():any {

    const month = this.model.month < 10 ? `0${this.model.month}` : this.model.month;
    const day = this.model.day < 10 ? `0${this.model.day}` : this.model.day;
    const dateSelect = `${this.model.year}-${month}-${day}`;
 
    const modal = this.modalSerive.open(DoctorDiaryAttentionPatientComponent, { size: 'xl' });
    modal.result.then((res: any) => {
      if (res === 'Save click') {
        this.setDate();
      }
    });
    modal.componentInstance.dateToday = dateSelect;
  }

  clean(){
    this.listHours = this.listHoursGeneral;
    this.patient = '';
    this.selectedDoctor='';
  }


  setAppointmentDetail(item:any){
    
    const modal = this.modalSerive.open(AppointmentDetailComponent, { size: 'lg' });
    modal.result.then((result: any) => {
      //console.log('resultado',result);
      
      if (result === 'Refresh') {
        this.getCalendary();
      } else if (result === 'Close') {
        this.getCalendary();
      }
    });
    modal.componentInstance.id = item.id_appointment;
    modal.componentInstance.identificar = 2;
  }

  goToClinicHistory(idclinichistory: any): void {
    //console.log('aqui',idclinichistory);
    
    sessionStorage.setItem('idpatient', idclinichistory.id_clinic_history.toString());
    
    const url = this.router.serializeUrl(this.router.createUrlTree(['clinic-history']));
    window.open(url, '_blank');

    /*const modal = this.modalSerive.open(ClinicHistoryFormComponent, { size: 'xl' });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        this.getCalendary();
      }
      if (result === 'Close click') {
        sessionStorage.removeItem('idpatient');
      }
    });
    modal.componentInstance.id = idclinichistory.id_clinic_history;*/
  }

}
