import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-progress-bar-animated',
  templateUrl: './progress-bar-animated.component.html',
  styleUrls: ['./progress-bar-animated.component.scss']
})
export class ProgressBarAnimatedComponent implements OnInit {
  progress = 0;
  showCustomerInfo = false;
  @Input() progressValue: number = 0;
  @Input() cliente?: string;
  @Input() historia_clinica?: string;
  @Input() numero_op_banks?:string;
  @Input() url_soles:string;
  @Input() url_dolares:string;

  soles:string
  dolares:string;
  constructor(public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.startProgress();

  }

  startProgress() {
    const interval = setInterval(() => {
      if (!this.url_soles && !this.url_dolares) {
        this.progress += this.progressValue;
        if (this.progress > 100) {
          this.progress = 100; // Asegúrate de que el progreso no exceda el 100%
        }
      } else {
        clearInterval(interval);
        this.showCustomerInfo = true;

        if(this.url_soles){
          const pattern = /[A-Z]{2}[A-Z0-9]\d-\d{8}/;
          const match = this.url_soles.match(pattern);
          this.soles = match[0];   
        }

        if(this.url_dolares){
          const pattern = /[A-Z]{2}[A-Z0-9]\d-\d{8}/;
          const match = this.url_dolares.match(pattern);
          this.dolares = match[0];
          console.log('soles',this.dolares);
        }
      }
    }, 1000); // Actualiza cada 100 ms
  }

  closeModal() {
    this.activeModal.close(); // Método para cerrar el modal manualmente
  }
  
  openInvoiceSoles(){
    const newWindow = window.open(this.url_soles, '_blank');
      newWindow.focus();
  }

  openInvoiceDolares(){
    const newWindow = window.open(this.url_dolares, '_blank');
      newWindow.focus();
  }
  
  copySoles(){

    const texto_copiado = this.soles+' '+this.cliente;
    navigator.clipboard.writeText(texto_copiado).then(() => {
      this.toastr.success('Texto copiado al portapapeles');
    }).catch(err => {
      this.toastr.error('Error al copiar al portapapeles');
      console.error('Error al copiar: ', err);
    });
  }

  copyDolares(){
    const texto_copiado = this.dolares+' '+this.cliente;
    navigator.clipboard.writeText(texto_copiado).then(() => {
      this.toastr.success('Texto copiado al portapapeles');
    }).catch(err => {
      this.toastr.error('Error al copiar al portapapeles');
      console.error('Error al copiar: ', err);
    });
  }
}
