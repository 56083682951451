import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProgramationAppointment } from '../../../doctor-diary/models/programming-appointment.model';
import { ClinicHistoryModel } from 'src/app/models/clinic-history.model';
import { TariffModel } from 'src/app/models/tariff.model';
import { ListSelectDto } from 'src/app/shared/dtos/list-select-dto';
import { Doctor } from '../../../doctor/models/doctor.model';
import { CoinModel } from 'src/app/models/coin.model';
import { EnvironmentDoctorModel } from 'src/app/models/environment-doctor.model';
import { MedicalActAttention } from 'src/app/models/main/medical-act.model';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from '../../../doctor/services/public/doctor.service';
import { EnvironmentDoctorService } from 'src/app/service/environment-doctor.service';
import { TariffService } from 'src/app/service/tariff.service';
import { CoinService } from 'src/app/service/coin.service';
import { ReservationService } from 'src/app/service/main/reservation.service';
import { ProgramationService } from 'src/app/service/programation.service';
import { UnionDoctorPatientAttentionService } from 'src/app/service/union-doctor-patient-attention.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { UnionDoctorPaymentAttentionModel } from 'src/app/models/union-doctor-patient-attention.model';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

class FormFields {
  get Patient(): string { return 'Patient'; }
  get Tariff(): string { return 'Tariff'; }
  get Doctor(): string { return 'Doctor'; }
  get Doctor2(): string { return 'Doctor2'; }
  get EnvironmentDoctor(): string { return 'EnvironmentDoctor'; }
  get Date(): string { return 'Date'; }
  get Appointment(): string { return 'Appointment'; }
  get PendingPayment(): string { return 'PendingPayment'; }
  get Amount(): string { return 'Amount'; }
  get Coin(): string { return 'Coin'; }
  get Reason(): string { return 'Reason'; }
  get EnvironmentDoctorOld(): string { return 'EnvironmentDoctorOld'; }
  get DateOld(): string { return 'DateOld'; }
  get AppointmentOld(): string { return 'AppointmentOld'; }
  get Type(): string { return 'type'; }
  get Time(): string { return 'time'; }
}

@Component({
  selector: 'app-modal-doctor-diary-appointment-three',
  templateUrl: './modal-doctor-diary-appointment-three.component.html',
  styleUrls: ['./modal-doctor-diary-appointment-three.component.scss']
})
export class ModalDoctorDiaryAppointmentThreeComponent implements OnInit {
 
  @Input() calendario:any; 
  @Input() data: any;
  @Input() usuario:any;
  @Input() total_calendario:any;
  @Input() res?:any;

  // FORM
  formData: FormGroup;
  formFields: FormFields = new FormFields();

  idpatient: number;

  formInput: ProgramationAppointment = new ProgramationAppointment();
  listPatient: ClinicHistoryModel[] = [];
  loadingSelectPatient = false;
  loadingSelectDoctor2 = false;
  listTariff: TariffModel[] = [];
  listDoctors: ListSelectDto[] = [];
  listDoctors2: Doctor[] = [];
  listCoin: CoinModel[] = [];
  environmentList: EnvironmentDoctorModel[] = [];
  listTarriffType : any [] = [];
  title = 'Nueva Cita';
  reprograming = false;
  reservacion : any[] = [];
  listado_reservaciones : any[] = [];
  reprogramcion = true;

  // Autocomplet
  searching = false;
  searchFailed = false;
  patient = '';
  showAlert = false;

  showPendingPayment = false;
  time_until: string;

  // Si el usuario viene del modulo de atención
  attention: MedicalActAttention;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    config: NgbModalConfig,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private servicePatient: ClinicHistoryService,
    private serviceTariff: TariffService,
    private serviceDoctor: DoctorService,
    private edService: EnvironmentDoctorService,
    private reservationService: ReservationService,
    private coinService: CoinService,
    private serviceReservation: ProgramationService,
    private serviceUnionDoctorPatientAttention: UnionDoctorPatientAttentionService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.time_until = moment(`${this.data.date} ${this.data.since}`).add(this.data.data.interval, 'minutes').format('HH:mm');
    this.getDoctors();
    this.getTariffs();
    //console.log({ data: this.data });
    this.initForm();
    this.attention = this.reservationService.attention;
    //console.log(this.data);
    
    if (this.data.acction) {
      this.get();
      this.title = 'Editar cita';
    }

    this.getEnvironment();
    this.onGetDoctors2();

    if (sessionStorage.getItem('oldreservation')) {
      this.title = 'Reprogramar cita';
      this.reprograming = true;
      this.get();
    }
    if (this.attention) {
      this.patient = `${this.attention.patient.documentNumber} ${this.attention.patient.name} ${this.attention.patient.lastNamefather} ${this.attention.patient.lastNameMother}`;
      this.formInput.patient = this.attention.patient.id
    }

    this.getCoin();

  }

  initForm() {
    this.formData = this.fb.group({});
    this.formData.addControl(this.formFields.Patient, new FormControl(null, [Validators.required]));
    this.formData.addControl(this.formFields.Tariff, new FormControl(null, [Validators.required]));
    this.formData.addControl(this.formFields.Type, new FormControl(null));
    this.formData.addControl(this.formFields.Doctor, new FormControl(this.data.mainData.iddoctor, [Validators.required]));
    this.formData.addControl(this.formFields.Doctor2, new FormControl(null));
    this.formData.addControl(this.formFields.EnvironmentDoctor, new FormControl(this.data.environtment, [Validators.required]));
    this.formData.addControl(this.formFields.EnvironmentDoctorOld, new FormControl(null));
    this.formData.addControl(this.formFields.Date, new FormControl(this.data.date, [Validators.required]));
    this.formData.addControl(this.formFields.DateOld, new FormControl(null));
    this.formData.addControl(this.formFields.Appointment, new FormControl(`${this.data.since}:00-${this.time_until}:00`, [Validators.required]));
    this.formData.addControl(this.formFields.AppointmentOld, new FormControl(null));
    this.formData.addControl(this.formFields.Time, new FormControl(null));
    this.formData.addControl(this.formFields.PendingPayment, new FormControl(false));
    this.formData.addControl(this.formFields.Amount, new FormControl(null));
    this.formData.addControl(this.formFields.Coin, new FormControl(null));
    this.formData.addControl(this.formFields.Reason, new FormControl(null));

  }

  getTariffs(): void { 
    this.listTariff = [];
    this.spinner.show();
    //const environ = _.find(this.environmentList, {id: this.formInput.environment});
    this.serviceTariff.getToDiary()
      .subscribe(
        res => {
        
          res.forEach((tariff: TariffModel) => {
            this.listTariff.push(tariff);
          }); 

          switch (this.usuario.cargo.nombre) {
            case 'EJECUTIVO DE CONTROLES':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 192 || item.id === 198

              );
              break;
            case 'ASISTENTE DENTAL':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 121 || item.id === 192 || item.id === 198
              );
              break;
            case 'CERRADORAS':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 121 || item.id === 192
              );
              break;
            case 'SUPERVISOR DE ASISTENTE DENTALES':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 121 || item.id === 192 || item.id === 198
              );
              break;
            default:
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 121 || item.id === 192 || item.id === 198
              );
          }
          this.spinner.hide();
        },
        err => { 
          console.log(err.error);
        }
      );
  }

  async calularHorarios():Promise<number>{
    //console.log('data',this.data);
    //console.log(this.calendario);

    let index = this.calendario.hours2.findIndex(
      horario => horario.since === this.data.since && horario.until === this.data.until
    );
    //console.log(index);
    
    let cantidad_intervalos_libres = 1;
    index ++;
    //console.log(index);
    
    for (index ; index < this.calendario.hours2.length; index++) {
      if(this.calendario.hours2[index].type == 1){
        cantidad_intervalos_libres++
      }/*else if(this.calendario.hours2[index].type == 4){
        console.log('2');

        let since_hour_seleccionado = this.calendario.hours2[index].since+ ":00";
        let until_hour_seleccionado = this.calendario.hours2[index].until+ ":00";

        for (let a = 0; a < this.listado_reservaciones.length; a++) {
          console.log('2a');
          console.log(this.listado_reservaciones);
          console.log(this.data);

          console.log(this.data.idpatient);
          console.log(this.data.idtariff);
          console.log(since_hour_seleccionado);
          console.log(until_hour_seleccionado);
          
          
          if(this.listado_reservaciones[a].id === this.data.idpatient 
            && this.listado_reservaciones[a].tariff_id === this.data.idtariff
            && this.listado_reservaciones[a].since === since_hour_seleccionado
            && this.listado_reservaciones[a].until === until_hour_seleccionado){
              console.log('2b');
              
              cantidad_intervalos_libres++
              //console.log('2a');
            }
          }
      }*/else{
        break;
      }
    }

    //console.log('rango hhorarios',cantidad_intervalos_libres);
    return cantidad_intervalos_libres;
  }


  async validacionReservaciones(reservaciones_comparativas: any, data: ProgramationAppointment, reservaciones_originales: any):Promise<boolean> {
    //throw new Error('Method not implemented.');
    //console.log('reservaciones_comparativas',reservaciones_comparativas);
    //console.log('data',data);
    //console.log('reservaciones_originales',reservaciones_originales);
//
    //console.log('this.calendario',this.calendario);
    
    const cantidad_intervalos_reservado:number = reservaciones_originales.length;

    let since = data.since.split(":").slice(0, 2).join(":");
    let until = data.until.split(":").slice(0, 2).join(":");

    let index = this.calendario.hours2.findIndex(
      horario => horario.since === since&& horario.until === until
    );
    
    let cantidad_intervalos_libres = 0;

    for (index ; index < this.calendario.hours2.length; index++) {
      if(this.calendario.hours2[index].type == 1){
        
        cantidad_intervalos_libres++
        //console.log(cantidad_intervalos_libres);
        
      }else if(this.calendario.hours2[index].type == 4){
        break
      }
    }

    let condicional = cantidad_intervalos_reservado >cantidad_intervalos_libres
    if (condicional) {
      Swal.fire({
        title: 'Atención!!!!',
        text: 'No hay horarios disponibles para este servicio',
        type: 'error',
        showCloseButton: false,
        showCancelButton: false
      }).then((willInsert) => {
        if (willInsert.value){
          this.activeModal.close('Save click');
          return false;
        }
      })
      return false;
    }else{
      return true;
    }
    
  }
  

  async calularHorariosDetail(horario_libre:any):Promise<number>{

    let listado_restante : any [] = this.listado_reservaciones.filter(reservacion => {
      let reservacion_since = reservacion.since.split(":").slice(0, 2).join(":");
      return reservacion_since >= this.data.since;
    });

    let index = this.calendario.hours2.findIndex(
      horario => horario.since === this.data.since && horario.until === this.data.until
    );
    
    let cantidad_intervalos_libres = 0;
    //console.log(index);
    
    for (index ; index < this.calendario.hours2.length; index++) {
      if(this.calendario.hours2[index].type == 1){
        //console.log(1);
        
        cantidad_intervalos_libres++
        //console.log(cantidad_intervalos_libres);
        
      }else if(this.calendario.hours2[index].type == 4){
        //console.log('2');

       // let since_hour_seleccionado = this.calendario.hours2[index].since+ ":00";
        // until_hour_seleccionado = this.calendario.hours2[index].until+ ":00";

        let encontrado = false;
        for (let a = 0; a < listado_restante.length; a++) {
          //console.log('2a');
          //console.log(since_hour_seleccionado);
          //console.log(listado_restante);
          //console.log(until_hour_seleccionado);
          
          
          if(listado_restante[a].id === horario_libre.id 
            && listado_restante[a].tariff_id === horario_libre.tariff_id){
              
              cantidad_intervalos_libres++
              //console.log(cantidad_intervalos_libres);
              encontrado = true;
              break;
            }
          }
          if (!encontrado) {
            break;
          }
      }else{
        break;
      }
      
    }

    //console.log('rango hhorarios',cantidad_intervalos_libres);
    return cantidad_intervalos_libres;
  }

  async getDetail(reservaciones:any[]):Promise<void>{
    //console.log('reservaciones',reservaciones);
    
    const cantidad_intervalos_reservado:number = this.reservacion.length;
    try {
  
    //console.log('reservaciones',reservaciones);
    let cantidad_intervalos;
      if(reservaciones.length> 0){
        cantidad_intervalos = await this.calularHorariosDetail(reservaciones[0]); // Esta función parece ser síncrona
      }else{
        cantidad_intervalos =cantidad_intervalos_reservado
      }
  
      //console.log('cantidad_intervalos',cantidad_intervalos);
      //console.log('cantidad_intervalos_reservado',cantidad_intervalos_reservado);
      
      const limite_intervalo = cantidad_intervalos_reservado >cantidad_intervalos;

      //console.log('limite_intervalo',limite_intervalo);
      
      if (limite_intervalo) {
        Swal.fire({
          title: 'Atención!!!!',
          text: 'No hay horarios disponibles para este servicio',
          type: 'error',
          showCloseButton: false,
          showCancelButton: false
        }).then((willInsert) => {
          if (willInsert.value){
            this.activeModal.close('Save click');
          }
        })
        return;
      }
      //console.log('listado',this.listTarriffType);
      
  
      //this.listTarriffType = resp;
    } catch (error) {
      console.error('Error al obtener la tarifa:', error);
    } finally {
      // Puedes realizar alguna acción final si es necesario
    }
  }

  getTimeTarifa():void{
    const tarifa_type = this.formData.get(this.formFields.Type).value;
    const doctor = this.formData.get(this.formFields.Doctor).value;
    //console.log(tarifa_type,doctor);
    
    this.serviceUnionDoctorPatientAttention.tariffInfoHors(tarifa_type,doctor).subscribe(
      resp=>{
        //console.log('tiempo',resp);
        
        const tiempo = resp[0].intervalo_minimo * resp[0].tiempo

        this.formData.get(this.formFields.Time).setValue(tiempo)
        
      }
    )
  }

  async filtrarHorarioTarifa(id:number):Promise<any>{
    const doctor = this.formData.get(this.formFields.Doctor).value;

    const horario = await this.serviceUnionDoctorPatientAttention.tariffInfoHors(id,doctor).toPromise();
    return horario;
  }
  
  getDoctors() {
    
    this.listDoctors = [];
    this.serviceDoctor.getAll()
      .subscribe(
        (res: Doctor[]) => {
          this.listDoctors = <any>res; 
        },
        (err: HttpErrorResponse) => { 
          console.log(err.error);
        }
      );
  }


  /**
   * Obtenemos solo los doctores
   * OI del día
   */
  onGetDoctors2(): void {
    this.loadingSelectDoctor2 = true;
    this.listDoctors2 = [];
    this.serviceDoctor.getByBl([2], moment(this.data.date).day())
      .subscribe(
        res => {
          // Obtenemos solo los doc
          this.loadingSelectDoctor2 = false;
          this.listDoctors2 = res;
        },
        (err: HttpErrorResponse) => {
          this.loadingSelectDoctor2 = false;
          this.toastr.error(
            'Ocurrio un error al obtener los dotores de OI',
            'Ateción',
            { timeOut: 4000, progressBar: true, closeButton: true }
          );
        }
      );
  }

  getEnvironment(): void {
    this.environmentList = [];
    this.edService.getAll().subscribe(
      res => {
        this.environmentList = res;

      },
      err => {
        console.error('Error al obtener los consultorios');
      }
    );
  }

  /*addAppointment(): void {
    if (this.formData.invalid) {
      return;
    }
    this.spinner.show();
    const data = this.setData();
    ///console.log(data);
    
    if (this.data.acction) {
      data.id = this.formInput.id;
      this.serviceReservation.update(data, this.formInput.id).subscribe(
        res => {
          this.spinner.hide();
          this.toastr.success(
            'La reprogramación fue registrada correctamente',
            'Ok!',
            { timeOut: 3000, progressBar: true }
          );
          sessionStorage.removeItem('oldreservation');
          this.activeModal.close('Save click');
          this.reservationService.attention = null;
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'Ocurrio un error al registrar la cita',
            'Atención',
            { timeOut: 3000, progressBar: true }
          );
        }
      );
    } else {
      console.log(data);
      const ciclos = this.formData.get(this.formFields.Time).value / 15;
      console.log(ciclos);

      let validador = true;
      let contador = 0;
      while (validador) {
        if(contador < ciclos){
        this.serviceReservation.insert(data).subscribe(
          res => {
            console.log('respuesta',res);
            let union : UnionDoctorPaymentAttentionModel =  {
              id_clinic_history: data.patient,
              id_reservation: res.id,
              id_doctor_attention: data.doctor
            }
  
            this.serviceUnionDoctorPatientAttention.search_patient(data.patient).subscribe(
              res =>{
                console.log('respuesta2',res);
                if(res !== null){
                  union.id_doctor_charge = res.id_doctor
                }
                
                this.serviceUnionDoctorPatientAttention.insert_pattient_for_attention(union).subscribe(
                  resp =>{
                    console.log('respuesta3',resp);
                    
                  }
                )
              }
            )
            this.spinner.hide();
            this.toastr.success(
              'La reserva fue registrada correctamente',
              'Ok!',
              { timeOut: 3000, progressBar: true }
            );

          },
          (err: HttpErrorResponse) => {
            this.spinner.hide();
            if (err.status === 400) {
              this.toastr.warning(
                err.error.message,
                'Atención',
                { timeOut: 3500, progressBar: true }
              );
            } else {
              this.toastr.error(
                'Ocurrio un error al registrar la cita',
                'Atención',
                { timeOut: 3500, progressBar: true }
              );
            }
          }
        );

          let new_since = new Date(`1970-01-01T${data.since}Z`);
          let new_until = new Date(`1970-01-01T${data.until}Z`);

          new_since.setMinutes(new_since.getMinutes() + 15);
          new_until.setMinutes(new_until.getMinutes() + 15);

          data.since = new_since.toISOString().substr(11, 8);
          data.until = new_until.toISOString().substr(11, 8);

          const [startTime, endTime] = data.appointment.split('-');

          const addMinutes = (time: string, minutes: number): string => {
            const date = new Date(`1970-01-01T${time}Z`);
            date.setMinutes(date.getMinutes() + minutes);
            return date.toISOString().substr(11, 8);
          };

          const newStartTime = addMinutes(startTime, 15);
          const newEndTime = addMinutes(endTime, 15);

          data.appointment= `${newStartTime}-${newEndTime}`;
          contador++
          validador=true;
        }else{
          validador=false;
        }
      }
      this.activeModal.close('Save click');
    }


  }*/
    addMinutes(time: string, minutes: number): string {
      let [hours, mins, secs] = time.split(":").map(Number);
      let date = new Date();
      date.setHours(hours);
      date.setMinutes(mins + minutes);
      date.setSeconds(secs);
  
      // Formatear la hora a "HH:mm:ss"
      let formattedHours = date.getHours().toString().padStart(2, '0');
      let formattedMinutes = date.getMinutes().toString().padStart(2, '0');
      let formattedSeconds = date.getSeconds().toString().padStart(2, '0');
  
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    async addAppointment(): Promise<void> {
      if (this.formData.invalid) {
        return;
      }
      this.spinner.show();
      const data = this.setData();
  
      try {
          if (this.data.acction) {
            data.id = this.formInput.id;
            const reservaciones_comparativas = await this.reservationService.reservation_with_doctor_date(data.doctor,data.date).toPromise();
            const reservaciones_originales = await this.reservationService.reservation_with_doctor(this.formInput.id).toPromise();

            const validacion = await this.validacionReservaciones(reservaciones_comparativas,data,reservaciones_originales);
            if(validacion){
              await this.serviceReservation.actualizacion_masiva(data, this.formInput.id).toPromise();
            
              this.toastr.success(
                  'La reprogramación fue registrada correctamente',
                  'Ok!',
                  { timeOut: 3000, progressBar: true }
              );
            }

            sessionStorage.removeItem('oldreservation');
            this.activeModal.close('Save click');
            this.reservationService.attention = null;

             /* let deleteObservables = [];
              let updateObservables = [];
              let insertObservables = [];

              let data_inicial: any = { ...this.data };
              //console.log(data_inicial);
              let data_reservation_update: any[] = [];
              let data_reservation_delete: any[] = [];

              let total_intervalos =this.reservacion.length;

              if(this.reservacion.length > 0){
                for (let index = 0; index < this.reservacion.length; index++) {
                  if (data_inicial.appointment == this.reservacion[index].appointment) {
                    let data_inicial_copy = { ...data_inicial };
                    data_inicial_copy.id = this.reservacion[index].idreservation;
                    data_reservation_update.push(data_inicial_copy);
             
                    let [since, until] = data_inicial_copy.appointment.split("-");
                    let newSince = this.addMinutes(since, 15);
                    let newUntil = this.addMinutes(until, 15);
                    data_inicial.appointment = `${newSince}-${newUntil}`;
                  } else {
                    data_reservation_delete.push(this.reservacion[index].idreservation);
                  }
                }
  
                if (data_reservation_update.length > 0) {
                  total_intervalos = total_intervalos - data_reservation_update.length;
                }
  
                if (data_reservation_delete.length > 0) {
                  //console.log('delete',data_reservation_delete);
                  
                  for (let i = 0; i < data_reservation_delete.length; i++) {
                    deleteObservables.push(this.serviceReservation.delete(data_reservation_delete[i]));
                  }
                }
  
                if (data_reservation_update.length > 0) {
                  //console.log('update',data_reservation_update);
                  for (let e = 0; e < data_reservation_update.length; e++) {
                    delete data_reservation_update[e].time;
                    delete data_reservation_update[e].type;
                    updateObservables.push(this.serviceReservation.update(data_reservation_update[e], data_reservation_update[e].id));
                  }
                }

                if (total_intervalos > 0) {
                  //console.log('a');
                  
                  for (let index = 0; index < total_intervalos; index++) {
                    //console.log('b');
                    let data_inicial_copy = { ...data };
                    //console.log(data_inicial_copy);
                    data_inicial_copy.id = null;
                    let [since, until] = data_inicial_copy.appointment.split("-");
                    data_inicial_copy.since = since;
                    data_inicial_copy.until = until;
                    data_inicial_copy.interval = 15;
                    //console.log('insertar',data_inicial_copy);
                    
             
                    insertObservables.push(this.serviceReservation.insert(data_inicial_copy));
             
                    let newSince = this.addMinutes(since, 15);
                    let newUntil = this.addMinutes(until, 15);
                    data.appointment = `${newSince}-${newUntil}`;
                  }
                }
  
                forkJoin([
                  ...deleteObservables,
                  ...updateObservables,
                  ...insertObservables
                ]).subscribe(
                  res => {
                    this.spinner.hide();
                    this.toastr.success('Todas las operaciones se realizaron correctamente', 'Ok!', { timeOut: 3000, progressBar: true });
                    sessionStorage.removeItem('oldreservation');
                    this.activeModal.close('Save click');
                    this.reservationService.attention = null;
                  },
                  err => {
                    this.spinner.hide();
                    this.toastr.error('Ocurrió un error durante la operación', 'Atención', { timeOut: 3500, progressBar: true });
                  }
                );
  
              }else{
                data.id = this.formInput.id;
                await this.serviceReservation.update(data, this.formInput.id).toPromise();
                
                this.toastr.success(
                    'La reprogramación fue registrada correctamente',
                    'Ok!',
                    { timeOut: 3000, progressBar: true }
                );
                sessionStorage.removeItem('oldreservation');
                this.activeModal.close('Save click');
                this.reservationService.attention = null;
              }*/

              
              //console.log(total_intervalos);

              /*let new_reservation:any = {
                environment: data.environment,
                date: data.date,
                appointment: data.appointment,
                doctor_id2: data.doctor_id2,
                pending_payment:data.pending_payment,
                amount: data.amount,
                coin: data.coin,
                interval:data.interval,
                doctor:data.doctor,
                interval:data.interval,
              };*/
              
              
              
              /*this.spinner.hide();
              return;
              data.id = this.formInput.id;
              await this.serviceReservation.update(data, this.formInput.id).toPromise();
              
              this.toastr.success(
                  'La reprogramación fue registrada correctamente',
                  'Ok!',
                  { timeOut: 3000, progressBar: true }
              );
              sessionStorage.removeItem('oldreservation');
              this.activeModal.close('Save click');
              this.reservationService.attention = null;*/
          } else {
              //console.log(data);
              const ciclos = this.formData.get(this.formFields.Time).value / 15;
              //console.log(ciclos);
  
              let validador = true;
              let contador = 0;
  
              while (validador) {
                  if(contador < ciclos){
                      const res = await this.serviceReservation.insert(data).toPromise();
                      //console.log('respuesta', res);
                      
                      let union: UnionDoctorPaymentAttentionModel = {
                          id_clinic_history: data.patient,
                          id_reservation: res.id,
                          id_doctor_attention: data.doctor
                      };
    
                      const patientRes = await this.serviceUnionDoctorPatientAttention.search_patient(data.patient).toPromise();
                      //console.log('respuesta2', patientRes);
                      
                      if (patientRes !== null) {
                          union.id_doctor_charge = patientRes.id_doctor;
                      }
                      
                      await this.serviceUnionDoctorPatientAttention.insert_pattient_for_attention(union).toPromise();
                      //console.log('respuesta3', res);
                      
                      this.toastr.success(
                          'La reserva fue registrada correctamente',
                          'Ok!',
                          { timeOut: 3000, progressBar: true }
                      );
  
                      // Incrementar tiempo en 15 minutos
                      let new_since = new Date(`1970-01-01T${data.since}Z`);
                      let new_until = new Date(`1970-01-01T${data.until}Z`);
  
                      new_since.setMinutes(new_since.getMinutes() + 15);
                      new_until.setMinutes(new_until.getMinutes() + 15);
  
                      data.since = new_since.toISOString().substr(11, 8);
                      data.until = new_until.toISOString().substr(11, 8);
  
                      const [startTime, endTime] = data.appointment.split('-');
  
                      const addMinutes = (time: string, minutes: number): string => {
                          const date = new Date(`1970-01-01T${time}Z`);
                          date.setMinutes(date.getMinutes() + minutes);
                          return date.toISOString().substr(11, 8);
                      };
  
                      const newStartTime = addMinutes(startTime, 15);
                      const newEndTime = addMinutes(endTime, 15);
  
                      data.appointment = `${newStartTime}-${newEndTime}`;
  
                      contador++;
                      validador = true;
                  } else {
                      validador = false;
                  }
              }
              this.activeModal.close('Save click');
          }
      } catch (err) {
          this.spinner.hide();
          this.toastr.error(
              'Ocurrió un error al registrar la cita',
              'Atención',
              { timeOut: 3000, progressBar: true }
          );
      } finally {
          this.spinner.hide();
      }
  }


  setData(): ProgramationAppointment {
    const data: ProgramationAppointment = new ProgramationAppointment();
    data.environment = this.formData.get(this.formFields.EnvironmentDoctor).value;
    data.date = this.formData.get(this.formFields.Date).value;
    data.appointment = this.formData.get(this.formFields.Appointment).value;
    data.pending_payment = this.formData.get(this.formFields.PendingPayment).value;
    data.amount = this.formData.get(this.formFields.Amount).value;
    data.coin = this.formData.get(this.formFields.Coin).value;
    data.tariff = this.formData.get(this.formFields.Tariff).value;
    data.reason = this.formData.get(this.formFields.Reason).value;
    data.doctor = this.formData.get(this.formFields.Doctor).value;
    data.doctor_id2 = this.formData.get(this.formFields.Doctor2).value;
    data.id = null;
    data.qdetail = 0;
    data.state = 1;
    data.patient = this.idpatient;
    data.since = `${this.data.since}:00`;
    data.until = `${this.time_until}:00`;
    data.interval = this.data.data.interval;
    return data;
  }

  cancelRepro(): void {
    this.toastr.info(
      'Ok!', 'Reprogramación Cancelada', { timeOut: 3000, progressBar: true }
    );
    sessionStorage.removeItem('oldreservation');
    this.activeModal.close('Save click');
  }

  adjustTime(time: string, minutes: number): string {

    const date = new Date(`1970-01-01T${time}Z`);
    date.setMinutes(date.getMinutes() + minutes);
    
    return date.toISOString().substr(11, 8);
  }

  get(): void {
    let id: number;
    //console.log('horario de doctores',this.calendario);

    if (this.data.acction && this.data.acction === 'edit') {
      id = this.data.id;
      
    } else {
      id = Number(sessionStorage.getItem('oldreservation'));
      this.data.acction = 're-programming';
      this.reprogramcion = false;
    }

    if(this.reprogramcion == false){
      const horario_doctor = this.calendario.hours2[0].mainData.schedule;
      //let reservaciones :any[] = [];
        //Del listado principal ,obtengo un listado de todos las reservaciones en general
        horario_doctor.forEach(item =>{
          if(item.data){
            this.listado_reservaciones.push(item.data);
          }
        })

        for (let index = 0; index < this.total_calendario.length; index++) {
          const element = this.total_calendario[index];
          
        }  
        
        //buscas las reservacion seleccionada
        const reservacion_selected = this.listado_reservaciones.filter(reser => reser.idreservation === id);

        if(reservacion_selected.length > 0){
          const reservaciones_disponibles = this.listado_reservaciones.filter(item => reservacion_selected[0].id == item.id);
  
          let until = reservacion_selected[0].until;
          let since = reservacion_selected[0].since;
    
            let x = 0;
            let y = reservaciones_disponibles.length -1;
            let validador = true
            while (validador) {
              const disponible = reservaciones_disponibles[x];
              const disponible_alrevez = reservaciones_disponibles[y];     
              
              if(x <= reservaciones_disponibles.length){
                if((disponible.until === until) &&
                disponible.tariff_id === reservacion_selected[0].tariff_id){
                  if (!this.reservacion.some(res => res.idreservation === disponible.idreservation)) {
                    this.reservacion.push(disponible);
                  }
                  x++
                  until = this.adjustTime(until, 15);
                }else{
                  x++
                }
              }
              
              if(y >= 0){        
                if(disponible_alrevez.since === since  &&
                  disponible_alrevez.tariff_id === reservacion_selected[0].tariff_id){
    
                    if (!this.reservacion.some(res => res.idreservation === disponible_alrevez.idreservation)) {
                      this.reservacion.push(disponible_alrevez);
                    }
                    y--;
                    
                since = this.adjustTime(since, -15);
                }else{
                  y--;
                }
              }
    
              if(x == reservaciones_disponibles.length && y < 0 ){
                validador =false;
              }
             
            }
        }
        //Se filtra el listado por todas las reservaciones que hay por cliente, en este caso id = idclinichistory(se debe cambiar el dto)
        
  
          this.getDetail(reservacion_selected);
     
      /*this.serviceReservation.getOne(id)
        .subscribe(
          (res: any) => {
            this.formData.get(this.formFields.Patient).setValue(res.patient);
            this.formData.get(this.formFields.Tariff).setValue(res.idtariff);
            this.formData.get(this.formFields.Doctor2).setValue(res.iddoctor2);
            this.formData.get(this.formFields.EnvironmentDoctorOld).setValue(res.idenvironment);
            this.formData.get(this.formFields.DateOld).setValue(moment(res.date).format('YYYY-MM-DD'));
            this.formData.get(this.formFields.AppointmentOld).setValue(res.appointment);
            this.formInput.id = res.id;
            this.formInput.patient = res.idpatient;
            this.idpatient = res.idpatient;
            this.patient = res.patient;
            this.formInput.doctor = res.iddoctor;
            this.formInput.doctor_id2 = res.iddoctor2;
            if (res.idtariff) {
              this.formInput.tariff = res.idtariff;
            }
            this.formInput.pending_payment = res.pending_payment;
            this.formInput.amount = res.amount;
            this.formInput.coin = res.idcoin;
            this.formInput.reason = res.reason;
          },
          err => {
            console.log('Error al buscar la reserva');
          }
        );*/
        if(reservacion_selected.length > 0){
          let listado_agenda: any[] = [];
          const requests = this.reservacion.map(res => 
            this.serviceReservation.getOne(res.idreservation)
          );
        
          forkJoin(requests).subscribe(
            responses => {
              responses.forEach(res => {
                //console.log('respuesta', res);
                listado_agenda.push(res);
              });
              //console.log('listado_agenda', listado_agenda);
              // Aquí puedes seguir procesando listado_agenda si es necesario
              const position = listado_agenda.findIndex(item => item.id === id);
    
              this.formData.get(this.formFields.Patient).setValue(listado_agenda[position].patient);
              this.formData.get(this.formFields.Tariff).setValue(listado_agenda[position].idtariff);
              this.formData.get(this.formFields.Doctor2).setValue(listado_agenda[position].iddoctor2);
              this.formData.get(this.formFields.EnvironmentDoctorOld).setValue(listado_agenda[position].idenvironment);
              this.formData.get(this.formFields.DateOld).setValue(moment(listado_agenda[position].date).format('YYYY-MM-DD'));
              this.formData.get(this.formFields.AppointmentOld).setValue(listado_agenda[position].appointment);
              this.formInput.id = listado_agenda[position].id;
              this.formInput.patient = listado_agenda[position].idpatient;
              this.idpatient = listado_agenda[position].idpatient;
              this.patient = listado_agenda[position].patient;
              this.formInput.doctor = listado_agenda[position].iddoctor;
              this.formInput.doctor_id2 = listado_agenda[position].iddoctor2;
              if (listado_agenda[position].idtariff) {
                this.formInput.tariff = listado_agenda[position].idtariff;
              }
              this.formInput.pending_payment = listado_agenda[position].pending_payment;
              this.formInput.amount = listado_agenda[position].amount;
              this.formInput.coin = listado_agenda[position].idcoin;
              this.formInput.reason = listado_agenda[position].reason;
            },
            err => {
              console.log('Error al buscar las reservas', err);
            }
          );



        }else{
          this.serviceReservation.getOne(id)
          .subscribe(
            (res: any) => {
              this.formData.get(this.formFields.Patient).setValue(res.patient);
              this.formData.get(this.formFields.Tariff).setValue(res.idtariff);
              this.formData.get(this.formFields.Doctor2).setValue(res.iddoctor2);
              this.formData.get(this.formFields.EnvironmentDoctorOld).setValue(res.idenvironment);
              this.formData.get(this.formFields.DateOld).setValue(moment(res.date).format('YYYY-MM-DD'));
              this.formData.get(this.formFields.AppointmentOld).setValue(res.appointment);
              this.formInput.id = res.id;
              this.formInput.patient = res.idpatient;
              this.idpatient = res.idpatient;
              this.patient = res.patient;
              this.formInput.doctor = res.iddoctor;
              this.formInput.doctor_id2 = res.iddoctor2;
              if (res.idtariff) {
                this.formInput.tariff = res.idtariff;
              }
              this.formInput.pending_payment = res.pending_payment;
              this.formInput.amount = res.amount;
              this.formInput.coin = res.idcoin;
              this.formInput.reason = res.reason;
            },
            err => {
              console.log('Error al buscar la reserva');
            }
          );
        }
        
    }else{
      this.serviceReservation.getOne(id)
        .subscribe(
          (res: any) => {
            this.formData.get(this.formFields.Patient).setValue(res.patient);
            this.formData.get(this.formFields.Tariff).setValue(res.idtariff);
            this.formData.get(this.formFields.Doctor2).setValue(res.iddoctor2);
            this.formData.get(this.formFields.EnvironmentDoctorOld).setValue(res.idenvironment);
            this.formData.get(this.formFields.DateOld).setValue(moment(res.date).format('YYYY-MM-DD'));
            this.formData.get(this.formFields.AppointmentOld).setValue(res.appointment);
            this.formInput.id = res.id;
            this.formInput.patient = res.idpatient;
            this.idpatient = res.idpatient;
            this.patient = res.patient;
            this.formInput.doctor = res.iddoctor;
            this.formInput.doctor_id2 = res.iddoctor2;
            if (res.idtariff) {
              this.formInput.tariff = res.idtariff;
            }
            this.formInput.pending_payment = res.pending_payment;
            this.formInput.amount = res.amount;
            this.formInput.coin = res.idcoin;
            this.formInput.reason = res.reason;
          },
          err => {
            console.log('Error al buscar la reserva');
          }
        );
    }
    
  }

  async getTypeTarifa():Promise<void>{
    //console.log('ingreso al metodo tipo');
    this.spinner.show();
    
    const tarifa = this.formData.get(this.formFields.Tariff).value;
    //console.log(tarifa);
     
    try {
      const resp = await this.serviceUnionDoctorPatientAttention.tariffInfo(tarifa).toPromise();
      //console.log('respuesta',resp);
  
      const cantidad_intervalos = await this.calularHorarios(); // Esta función parece ser síncrona
      //console.log('cantidad',cantidad_intervalos);
      
      const horariosPromises = resp.map(async (tarifa) => {
        // Asegúrate de que `filtrarHorarioTarifa` retorne una promesa
        const horarioArray = await this.filtrarHorarioTarifa(tarifa.id_info);
        // Retornar el primer elemento del array
        return horarioArray[0];
      });
      //console.log(horariosPromises);
      
      // Esperar a que todas las promesas se resuelvan
      const horarios :any[] = await Promise.all(horariosPromises);
      //console.log('horario', horarios);
  
      //console.log(cantidad_intervalos);

      const limite_tiempo = cantidad_intervalos * 15;

      for (let i = 0; i < horarios.length; i++) {
        let total = horarios[i].intervalo_minimo * horarios[i].tiempo;

        if(total <=limite_tiempo){
          this.listTarriffType.push(horarios[i])
        }
        
      }

      if (this.listTarriffType.length==0) {
        Swal.fire({
          title: 'Atención!!!!',
          text: 'No hay horarios disponibles para este servicio',
          type: 'error',
          showCloseButton: false,
          showCancelButton: false
        }).then((willInsert) => {
          if (willInsert.value){
            this.spinner.hide();
            this.activeModal.close('Save click');
          }
        })
        return;
      }
      //console.log('listado',this.listTarriffType);
      this.spinner.hide();
  
      //this.listTarriffType = resp;
    } catch (error) {
      console.error('Error al obtener la tarifa:', error);
    } finally {
      // Puedes realizar alguna acción final si es necesario
    }
  }

  validateDoctor(): void {
    this.spinner.show();
    /* this.serviceReservation.validateDoctor(this.formInput.doctor, this.formInput.date, this.formInput.appointment)
      .subscribe(
        res => {
          this.spinner.hide();
          if (res === 1) {
            this.formInput.doctor = null;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene una reserva a la misma hora en otro consultorio',
              type: 'warning',
            });
          } else if (res === 2) {
            this.formInput.doctor = 0;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene bloqueado ese horario',
              type: 'warning',
            });
          }
        },
        err => {
          this.spinner.hide();
          console.log('Errro Validación ');
        }
      ); */
  }

  /* SELECT DEL AUTO COMPLETADO */
  selectedItem(it: any): void {
    this.showAlert = false;
    const { item } = it;
    this.idpatient = item.id;
    if (Number(item.quotasPending) > 0) {
      this.showAlert = true;
    }
  }

  /* AUTOCOMPLETADO DE LOS MEDICAMENTOS **/
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.servicePatient.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  /**
   * Used to format the result data from the lookup into the
   * display and list values. Maps `{name: 'band', id:'id' }` into a string
   */
  resultFormatBandListValue(value: any): string {
    return value.name;
  }
  /**
   * Initially binds the string value and then after selecting
   * an item by checking either for string or key/value object.
   */
  inputFormatBandListValue(value: any): any {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  getCoin(): void {
    this.listCoin = [];
    this.coinService.coinList$.pipe(
      takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.listCoin = data;
      });
  }

  setPendingPayment(): void {
    this.showPendingPayment = this.formData.get(this.formFields.PendingPayment).value;
    if (this.showPendingPayment) {
      this.formData.get(this.formFields.Amount).setValidators([Validators.required]);
      this.formData.get(this.formFields.Coin).setValidators([Validators.required]);
    } else {
      this.formData.get(this.formFields.Amount).clearValidators();
      this.formData.get(this.formFields.Coin).clearValidators();
      this.formData.get(this.formFields.Amount).setValue(null);
      this.formData.get(this.formFields.Coin).setValue(null);
    }
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required') && this.formData.controls[control].touched) {
      return true;
    }
    return false;
  }
}
