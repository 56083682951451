import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {UnionPaymenAttentionComponent} from './union-paymen-attention.component';

@Injectable({
  providedIn: 'root'
})
export class UnionpaymentdetailService {
  private baseUrl: string = `${environment.apiUrl}`;
  constructor(private http: HttpClient) { }
  getByPayments(idreservation: number): Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/union_payment_attention/for_reservation/${idreservation}`);
  }

  insertconfirmPayments(id_pago: number[], id_user: number,  id_reservation: number): Observable<any[]> {

    return this.http.post<any[]>(`${this.baseUrl}/union_payment_attention/payment_for_reservation/${id_user}/${id_reservation}`, id_pago);
  }

  getOneReservation(id:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/union_payment_attention/for_reservation/${id}`);
  }

  getValidationpayments(id_reservation:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/union_payment_attention/validation_reservation_payments/${id_reservation}`);
  }

  getValidationpaymentsForUnionReservation(id_reservation:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/union_payment_attention/validation_reservation_payments_for_union/${id_reservation}`);
  }
}
