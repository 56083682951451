<div class="row" *ngIf="loaded">
    <div class="col-md-12">
        
      <h5 class="font-weight-bold">Consultorio {{ environment.name }}</h5>
      <hr>

      <div class="row">
        <div class="col-xl-4 col-md-4">
          <app-card [hidHeader]="true" cardClass="comp-card">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-b-20 font-weight-bold">Cant. de citas en 1 año</h6>
                <h3 class="text-c-blue font-weight-bold">{{ tYear }}</h3>
                <p class="m-b-0">{{ rangeToYear }}</p>
              </div>
              <div class="col-auto">
                <i class="fas fa-address-book badge-light-primary"></i>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-xl-4 col-md-4">
          <app-card [hidHeader]="true" cardClass="comp-card">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-b-20 font-weight-bold">Cant. de citas en 1 mes</h6>
                <h3 class="text-c-green  font-weight-bold">{{ tMonth }}</h3>
                <p class="m-b-0">{{ rangeToMonth }}</p>
              </div>
              <div class="col-auto">
                <i class="fas fa-address-book badge-light-success"></i>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-xl-4 col-md-4">
          <app-card [hidHeader]="true" cardClass="comp-card">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-b-20 font-weight-bold">Cant. de citas hoy</h6>
                <h3 class="text-c-yellow  font-weight-bold">{{ tToday }}</h3>
                <p class="m-b-0">{{ rangeToDay }}</p>
              </div>
              <div class="col-auto">
                <i class="fas fa-address-book badge-light-warning"></i>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>
</div>

<div class="row" *ngIf="loaded">
    <div class="col-lg-4 col-md-6">
        <app-card cardTitle="Cant. de citas en 1 año" [options]="false">
          <app-apex-chart chartID="chart-apex-user-1{{environment.id}}" [chartConfig]="chartYear"></app-apex-chart>
        </app-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <app-card cardTitle="Cant. de citas en 1 mes" [options]="false">
          <app-apex-chart chartID="chart-apex-user-2{{environment.id}}" [chartConfig]="chartMonth"></app-apex-chart>
        </app-card>
      </div>
      <div class="col-lg-4 col-md-12">
        <app-card cardTitle="Cant. de citas hoy" [options]="false">
          <app-apex-chart chartID="chart-apex-user-3{{environment.id}}" [chartConfig]="chartDays"></app-apex-chart>
        </app-card>
      </div>
</div>


