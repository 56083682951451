<form class="was-validated" (ngSubmit)="onSubmit()" ngNativeValidate>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="form-control-label" for="name">Examén de ingreso <small class="text-danger">(*)</small></label>
                <textarea name="examine_income" cols="30" rows="3" placeholder="Digite el examen de ingreso"
                class="form-control" required="" [(ngModel)]="formInput.examine_income"></textarea>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label class="form-control-label" for="name">Motivo de la consulta <small class="text-danger">(*)</small></label>
                <textarea name="reason" cols="30" rows="3" placeholder="Digite el motivo de la consulta"
                class="form-control" required="" [(ngModel)]="formInput.reason"></textarea>
            </div>
        </div>
        <div class="col-12">
            <h5>Exámenes Auxiliares</h5>
            <hr>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                    <input type="checkbox" id="switch-p-1" name="radiografia" [(ngModel)]="formInput.bone_scan">
                    <label for="switch-p-1" class="cr"></label>
                </div>
                <label>Radiografía</label>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                    <input type="checkbox" id="switch-p-2" name="periodotograma" [(ngModel)]="formInput.periodontogram">
                    <label for="switch-p-2" class="cr"></label>
                </div>
                <label>Periodotograma</label>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                    <input type="checkbox" id="switch-p-3" name="fotografia" [(ngModel)]="formInput.clinical_photography">
                    <label for="switch-p-3" class="cr"></label>
                </div>
                <label>Fotografía Clinica</label>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                    <input type="checkbox" id="switch-p-4" name="examen" [(ngModel)]="formInput.laboratory_exams">
                    <label for="switch-p-4" class="cr"></label>
                </div>
                <label>Exámenes de laboratorio</label>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                    <input type="checkbox" id="switch-p-5" name="modelo" [(ngModel)]="formInput.study_models">
                    <label for="switch-p-5" class="cr"></label>
                </div>
                <label>Modelos de Estudio</label>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label class="form-control-label" for="name">Informe Radiográfico <small class="text-danger">(*)</small></label>
                <textarea name="report" cols="30" rows="3" placeholder="Digite el informe..."
                class="form-control" required="" [(ngModel)]="formInput.radiographic_report"></textarea>
            </div>
        </div>
    </div>
    <button type="submit" class="btn btn-primary btn-lg btn-block" *ngIf="!loaderInsert">
        <i class="fas fa fa-save"></i> Registrar
    </button>
    <button type="submit" class="btn btn-primary btn-lg btn-block" *ngIf="loaderInsert" disabled>
        <span class="spinner-border spinner-border-sm" role="status"></span>
        <span class="load-text"> Registrando...</span>
    </button>
</form>
