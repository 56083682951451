import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateService } from 'src/app/service/helpers/date.service';

@Component({
  selector: 'app-dashboard-basic',
  templateUrl: './dashboard-basic.component.html',
  styleUrls: ['./dashboard-basic.component.scss']
})
export class DashboardBasicComponent implements OnInit {

  greetings: string;
  session: any = {};

  constructor(
    private _helperDateService: DateService,
    private store: Store<{ session: any }>
  ) { }

  ngOnInit(): void {
    this.getSession();
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.setGreetings();
        }
      });
  }

  setGreetings(): void {
    this.greetings = `<p>${this._helperDateService.turn} <b>${this.session.username}</b>, bienvenido al sistema de Maxillaris</p>`;
  }

}
