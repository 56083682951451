import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LanguageApp } from 'src/app/config/data-table.language';
import { ServiceOrderPaymentDetailNew } from 'src/app/models/finance/service-order-new.model';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';

@Component({
  selector: 'app-modal-select-quota',
  templateUrl: './modal-select-quota.component.html',
  styleUrls: ['./modal-select-quota.component.scss']
})
export class ModalSelectQuotaComponent implements OnInit {

  idclinchistory: number;
  idTarifa: number;
  title = 'Seleccione Cuota';
  dtOptions: DataTables.Settings = {};
  listFielOs:ServiceOrderPaymentDetailNew[]=[];


  pendientQuotas: any [] = [];
  selectedItem:any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private serviceClinicHistory:ClinicHistoryService
  ) { }

  ngOnInit(): void {
    this.getData(this.idclinchistory);
  }

  getData(id:number):void{
    this.pendientQuotas = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: LanguageApp.spanish_datatables,
      search: true,
      responsive: true,
      order: [0],
      orderClasses: true
    };
    this.serviceClinicHistory.getAllContractsPendient(id).subscribe(
      resp =>{
        if (this.idTarifa === 202 || this.idTarifa == 121) {
          // Filtrar los datos de la columna 'descripcion' que contengan 'Moldes'
          this.pendientQuotas = resp.filter(item => item.description.includes('Moldes'));
        } else if (this.idTarifa === 203 || this.idTarifa == 122) {
          this.pendientQuotas = resp.filter(item => item.description.includes('Inicial'));
        }else {
            // Si la idTarifa no es 202, mostrar todos los datos sin filtrar
            this.pendientQuotas =resp.filter(item => item.description.includes('Cuota'));
        }
      }
    )
  }

  toggleSelection(item: any) {
    this.selectedItem = item;
    
}

  Seleccionar() {
    // Usa los IDs seleccionados como desees
    this.activeModal.close(this.selectedItem);
  }

  isDisabled(item: any): boolean {
    // Retorna true si el item ya está en la lista pendientQuotas
    return this.listFielOs.some(p => p.idContrato === Number(item['Id del contrato']));
}


}
