<div class="modal-header modal_header">
    <h5 class="modal-title titulo-modal">Odontograma</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close({ok: true})"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <!-- <li [ngbNavItem]="1">
            <a ngbNavLink>
                <i class="fas fa-teeth"></i>  Odontograma Inicial
            </a>
            <ng-template ngbNavContent>
                <app-odontograma [data]="this.data" [first]="true" [origin]="this.origin"
                (saved)="this.close($event)" (active)="active=$event" [odontograma]="this.odontograma"></app-odontograma>
            </ng-template>
        </li> -->
        <li [ngbNavItem]="1">
            <a ngbNavLink>
                <i class="fas fa-plus-circle"></i> Nuevo Odontograma
            </a>
            <ng-template ngbNavContent>
                <app-odontograma [data]="this.data" [first]="false" [origin]="this.origin"
                (saved)="this.close($event)" [odontograma]="this.odontograma"></app-odontograma>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div class="modal-footer">
    <button
      type="button" ngbAutofocus
      class="btn btn-outline-dark"
      (click)="activeModal.close({ok: false})"
    >
      Salir
    </button>
</div>