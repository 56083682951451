import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ci10Model } from '../../app/models/ci10.model'

@Injectable({
  providedIn: 'root'
})
export class Ci10Service {

  
  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http:HttpClient) { }

  getAll(): Observable<Ci10Model[]>{
    return this.http.get<Ci10Model[]>(`${this.baseUrl}/ci10`);
  }
}
