import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LanguageApp } from 'src/app/config/data-table.language';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { FileGroupModel } from 'src/app/models/mat/files-group.model';

import { FilesModel } from 'src/app/models/mat/files-medical-act.model';
import { MedialAttentionService } from 'src/app/service/main/medial-attention.service';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewPdfComponent } from '../../view-pdf/view-pdf.component';

@Component({
  selector: 'app-clinic-history-vouchers',
  templateUrl: './clinic-history-vouchers.component.html',
  styleUrls: ['./clinic-history-vouchers.component.scss']
})
export class ClinicHistoryVouchersComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  @Input() id: number;
  active: number = 1;
  urlDownload = environment.apiUrlDownload;
  public uploadedFiles: File[] = [];
  quantityFiles: any[] = [];
  listFiles: FilesModel[] = [];
  listGroup: FileGroupModel[] = [];
  group: any;
  description: string = '';
  @Output() archivosCargados = new EventEmitter<any[]>();
  listFilesData: any[] = [];

  constructor(
    private _maService: MedialAttentionService,
    private spinner: NgxSpinnerService,
    private _modalSerive: NgbModal,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.group = '5';
    this.getListFileGroup();
  }

  getListFileGroup(){
    this.listGroup = [];
    this._maService.getFileGroup().subscribe(
      res => {
        res.forEach((it:FileGroupModel) => {
          this.listGroup.push(it);
        });
      },
      err => {
        console.error("Error al obtener los datos de los grupos");
      }
    );
  }

  getFilesQuantity(){
    this.spinner.show();
    this.quantityFiles = [];
    this._maService.getQuantityFiles(this.id).subscribe(
      res => {
        this.quantityFiles = res;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.toastr.error("Ocurrio un error al obtener los archivos","Error",{timeOut: 3000, progressBar: true});
      }
    );
  }

  getFiles(idgroup: number = 0){
    this.spinner.show();
    this.listFiles = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: LanguageApp.spanish_datatables,
      search: true,
      responsive: true,
      order: [0],
      orderClasses: true
    };
    this._maService.getFilesByClinicHistory(this.id,idgroup).subscribe(
      res => {
        this.listFiles = res;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.toastr.error("Ocurrio un error al obtener los archivos","Error",{timeOut: 3000, progressBar: true});
      }
    );
  }

  validate(): void {
    this.spinner.show();
    if(this.uploadedFiles.length === 0){
      this.spinner.hide();
      this.toastr.warning("Debe seleccionar un archivo","Atención",{timeOut: 3000,progressBar: true});
      return;
    }/*
    if(this.uploadedFiles.length > 1){
      this.spinner.hide();
      this.toastr.warning("Recuerde que solo puede survir un archivo a la vez","Atención",{timeOut: 3000,progressBar: true});
      return;
    }*/
  }

  async onSubmit(idMedical?:number): Promise<void>{
    this.validate();
    //console.log('aqui2');

    try {
  
      const med = idMedical;
      const id = this.id;
      const datosCargados: any[] = [];
      const uploadPromises = this.uploadedFiles.map(file => {
        if(med>0){
          return this._maService.uploadFileFromClinicHistory(this.group, id,med,file, this.description)
          .toPromise()
          .then(resultado => {
            // Almacena cualquier resultado que desees enviar al padre
            const extractedData = {
              fila_name: resultado.data[0].fila_name,
              file_ext: resultado.data[0].file_ext,
              clinichistory: resultado.data[0].clinichistory,
              createdAt:resultado.data[0].createdAt,
              desciption:resultado.data[0].desciption,
              id:resultado.data[0].id,
              medicalactattention:resultado.data[0].medicalactattention,
              medicalact:resultado.data[0].medicalact,
              updatedAt:resultado.data[0].updateAt,
              state:resultado.data[0].state
            };
            datosCargados.push(extractedData);
            this.listFilesData=datosCargados;
           //console.log('info1');
           
            this.archivosCargados.emit(datosCargados);
          });
        }else{
          return this._maService.uploadFileFromClinicHistoryOS(this.group, id,file, this.description)
          .toPromise()
          .then(resultado => {
            // Almacena cualquier resultado que desees enviar al padre
            //console.log('resultado',resultado);
            
            const extractedData = {
              fila_name: resultado.data[0].fila_name,
              file_ext: resultado.data[0].file_ext,
              clinichistory: resultado.data[0].clinichistory,
              createdAt:resultado.data[0].createdAt,
              desciption:resultado.data[0].desciption,
              id:resultado.data[0].id,
              medicalactattention:resultado.data[0].medicalactattention,
              medicalact:resultado.data[0].medicalact,
              updatedAt:resultado.data[0].updateAt,
              state:resultado.data[0].state
            };

            //('extractedData',extractedData);
            
            datosCargados.push(extractedData);
            this.listFilesData=datosCargados;

            //console.log('info2',datosCargados);
            this.archivosCargados.emit(datosCargados);
          });
        }
      
      
      });
 
    // Esperar que todas las promesas se completen
    const results = await Promise.all(uploadPromises);
    // Procesar los resultados si es necesario

    this.spinner.hide();
    this.toastr.success("Archivos cargados correctamente", "OK!", { timeOut: 3000, progressBar: true });
    this.group = '';
    this.uploadedFiles = [];
    this.description = '';
    this.getFiles();

  } catch (err) {
    this.spinner.hide();
    this.toastr.error("Ocurrió un error al cargar los archivos", "Error!!", { timeOut: 3000, progressBar: true });
      }
    
  }


  onDelete(id: number): void{
    Swal.fire({
      title: 'Atención!!!!',
      text: 'Estś seguro que desea borrar el archivo?',
      type: 'warning',
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Si, borrar!',
      cancelButtonText: "No, cancelar!",
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete: any) => {
      if (willDelete.value) {
        this.spinner.show();
        this._maService.deleteFile(id)
        .subscribe(
          res => {
            this.getFiles();
            this.spinner.hide();
            this.toastr.success('ok!','Archivo borrado!',{timeOut: 4000, progressBar: true, closeButton: true});
          },
          err => {
            this.spinner.hide();
            this.toastr.error('Atención!','Ocurrio un error al borrar  el Archivo!!',{timeOut: 4000, progressBar: true, closeButton: true});
          }
        );
      }
    });
  }

  onPrint(link: string,title: string,type: string){
    const modal = this._modalSerive.open(ViewPdfComponent,{size: 'xl'});
    modal.componentInstance.title = 'Archivo - '+title;
    modal.componentInstance.url = link;
    modal.componentInstance.origin = '';
    modal.componentInstance.id = null;
    modal.componentInstance.type = type;
  }

}
