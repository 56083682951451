import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnionDoctorPatientAttentionService {

  private baseUrl: string = `${environment.apiUrl}/union_doctor_patient_attention/`;

  constructor(private http:HttpClient) { }

 search_patient(id_clinic_history:number):Observable<any>{
  return this.http.get<any>(`${this.baseUrl}search_patient/${id_clinic_history}`)
 }

 insert_pattient_for_attention(data:any):Observable<any>{
  return this.http.post<any>(`${this.baseUrl}insert_pattient_for_attention`,data)
 }

 search_patient_with_users_controlls():Observable<any>{
  return this.http.get<any>(`${this.baseUrl}search_patient_with_users_controlls`)
 }
 
 find_a_doctor(id_clinic_history:number,date:string):Observable<any>{
  return this.http.get<any>(`${this.baseUrl}find_a_doctor/${id_clinic_history}/${date}`)
 }

 find_family():Observable<any>{
  return this.http.get<any>(`${this.baseUrl}find_family`)
 }

 tariffInfo(id_fam:number):Observable<any>{
  return this.http.get<any>(`${this.baseUrl}tariffInfo/${id_fam}`)
 }

 tariffInfoHors(id_fam:number,id_doctor:number):Observable<any>{
  return this.http.get<any>(`${this.baseUrl}tariffInfoHors/${id_fam}/${id_doctor}`)
 }

 programing_doctor(id_doctor:number,date_appointemn:string):Observable<any>{
  return this.http.get<any>(`${this.baseUrl}programing_doctor/${id_doctor}/${date_appointemn}`)
 }


 doctor_programing_seg(id_programing:number,dif:number):Observable<any>{
  return this.http.patch<any>(`${this.baseUrl}doctor_programing_seg/${id_programing}/${dif}`,null)
 }
}
