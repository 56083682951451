<div class="modal-header modal-header-default modal_header">
    <h5 class="modal-title titulo-modal">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div >
        <div *ngFor="let item of discountSpecification" class="p-field-checkbox">
                <p-checkbox name="group2" value="item" [value]="item"  
                [(ngModel)]="selectedCategories" [inputId]="item.id" (ngModelChange)="onCheckboxChange()" ></p-checkbox>
                <label [for]="item.key">{{item.name}}</label>
            
        </div>
        <div *ngIf="showInput"> 
            <span class="p-float-label">
                <input id="float-input" type="text" pInputText [(ngModel)]="descripcion"> 
                <label for="float-input">descripcion</label>
            </span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" uiSref="work" class="btn btn-outline-primary"
        (click)="save()" type="button">
        Guardar
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Salir
    </button>
</div>