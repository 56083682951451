import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CoinModel } from '../models/coin.model';

@Injectable({
  providedIn: 'root'
})
export class CoinService {

  private _coinList: BehaviorSubject<CoinModel[]> = new BehaviorSubject(null);
  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  /**
     * Getter for coin list
     */
  get coinList$(): Observable<any> {
    return this._coinList.asObservable();
  }

  getAll(): Observable<CoinModel[]> {
    return this.http.get<CoinModel[]>(`${this.baseUrl}/coin`).pipe(
      tap((response: CoinModel[]) => {
        this._coinList.next(response);
      })
    );
  }

  insert(coin: CoinModel): Observable<CoinModel> {
    return this.http.post<CoinModel>(`${this.baseUrl}/coin`, coin);
  }

  update(coin: CoinModel, id: number): Observable<CoinModel> {
    return this.http.put<CoinModel>(`${this.baseUrl}/coin/${id}`, coin);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/coin/${id}`)
  }

}
