import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ClinicHistoryModel } from 'src/app/models/clinic-history.model';
import { ProgramationModel } from 'src/app/models/programation.model';
import { TariffModel } from 'src/app/models/tariff.model';
import { Doctor } from '../../../doctor/models/doctor.model';
import { CoinModel } from 'src/app/models/coin.model';
import { EnvironmentDoctorModel } from 'src/app/models/environment-doctor.model';
import { MedicalActAttention } from 'src/app/models/main/medical-act.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { TariffService } from 'src/app/service/tariff.service';
import { DoctorService } from '../../../doctor/services/public/doctor.service';
import { EnvironmentDoctorService } from 'src/app/service/environment-doctor.service';
import { ReservationService } from 'src/app/service/main/reservation.service';
import { ProgramationService } from 'src/app/service/programation.service';
import { CoinService } from 'src/app/service/coin.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { UnionDoctorPatientAttentionService } from 'src/app/service/union-doctor-patient-attention.service';

@Component({
  selector: 'app-medical-appointment-three',
  templateUrl: './medical-appointment-three.component.html',
  styleUrls: ['./medical-appointment-three.component.scss']
})
export class MedicalAppointmentThreeComponent implements OnInit {

  @Input() data: any;
  @Input() horario:any;
  @Input() reservacion:any[];
  @Input() usuario:any;
  @Input() listado_reservaciones:any[];

  formInput: ProgramationModel;
  formInputOld: ProgramationModel;
  listPatient: ClinicHistoryModel[] = [];
  loadingSelectPatient = false;
  loadingSelectTariff = false;
  loadingSelectDoctor = false;
  loadingSelectDoctor2 = false;
  listTariff: TariffModel[] = [];
  listDoctors: Doctor[] = [];
  listDoctors2: Doctor[] = [];
  listCoin: CoinModel[] = [];
  environmentList: EnvironmentDoctorModel[] = [];
  title = 'Nueva Cita Tercera';
  reprograming = false;
  isDisabled: boolean = true;
  // Autocomplet
  searching = false;
  searchFailed = false;
  patient = '';
  showAlert = false;
  
  // Si el usuario viene del modulo de atención
  attention: MedicalActAttention;

  listTarriffType:any[]=[];

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    private servicePatient: ClinicHistoryService,
    private serviceTariff: TariffService,
    private serviceDoctor: DoctorService,
    private edService: EnvironmentDoctorService,
    private serviceReservation: ProgramationService,
    private reservationService: ReservationService,
    private coinService: CoinService,
    private serviceUnionDoctorPatientAttention: UnionDoctorPatientAttentionService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    //console.log('reservaciones en el modal',this.reservacion);
    //console.log('horarios del doctor',this.horario);
    //console.log('usuario',this.usuario);
    
    
    this.attention = this.reservationService.attention;
    if (this.data.acction) {
      this.formInput = {
        environment: this.data.idenvironment,
        date: moment(this.data.date).format('YYYY-MM-DD'),
        appointment: `${this.data.appointment}`,
        reason: this.data.reason,
        pending_payment: this.data.pending_payment,
        amount: this.data.amount,
        coin: this.data.idcoin
      };


      this.get();
      this.title = 'Editar cita';
    } else {
      this.formInput = {
        environment: this.data.id,
        date: this.data.date,
        appointment: `${this.data.since}:00-${this.data.until}:00`,
        doctor_id2: null,
        pending_payment: this.data.pending_payment,
        amount: this.data.amount,
        coin: this.data.coin
      };
    }
    this.formInputOld = {
      environment: 0,
      date: '',
      appointment: '',
      pending_payment: false,
      amount: null,
      coin: null
    };
    this.getEnvironment();
    this.onGetDoctors2();
    if (sessionStorage.getItem('oldreservation')) {
      this.title = 'Reprogramar cita';
      this.reprograming = true;
      this.get();
    }
    if (this.attention) {
      this.patient = `${this.attention.patient.documentNumber} ${this.attention.patient.name} ${this.attention.patient.lastNamefather} ${this.attention.patient.lastNameMother}`;
      this.formInput.patient = this.attention.patient.id
    }

    this.getCoin();
  }

  getPatient(): void {
    this.loadingSelectPatient = true;
    this.listPatient = [];
    this.servicePatient.getAll()
      .subscribe(
        res => {
          res.forEach((patient: ClinicHistoryModel) => {
            this.listPatient.push(patient);
          });
          this.loadingSelectPatient = false;
        },
        err => {
          this.loadingSelectPatient = false;
          console.log(err.error);
        }
      );
  }

  getTariffs(): void {
    this.loadingSelectTariff = true;
    //*this.listTariff = [];*/
    //const environ = _.find(this.environmentList, {id: this.formInput.environment});
    /*this.serviceTariff.getToDiary()
      .subscribe(
        res => {
          res.forEach((tariff: TariffModel) => {
            this.listTariff.push(tariff);
          });
          this.loadingSelectTariff = false;
        },
        err => {
          this.loadingSelectTariff = false;
          console.log(err.error);
        }
      );*/
      this.listTariff = [];
    //const environ = _.find(this.environmentList, {id: this.formInput.environment});
    this.serviceTariff.getToDiary()
      .subscribe(
        res => {
          console.log('respuesta de tarifa',res);
          res.forEach((tariff: TariffModel) => {
            this.listTariff.push(tariff);
          }); 
          
          switch (this.usuario.cargo.nombre) {
            case 'EJECUTIVO DE CONTROLES':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 192 || item.id === 198
              );
              break;
            case 'ASISTENTE DENTAL':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 121 || item.id === 192 || item.id === 198
              );
              break;
            case 'CERRADORAS':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 121 || item.id === 192
              );
              break;
            case 'SUPERVISOR DE ASISTENTE DENTALES':
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 121 || item.id === 192 || item.id === 198
              );
              break;
            default:
              this.listTariff = this.listTariff.filter(item => 
                item.id === 58 || item.id === 121 || item.id === 192 || item.id === 198
              );
          }
          //console.log('listado',this.listTariff);
          this.loadingSelectTariff = false;
        },
        err => { 
          console.log(err.error);
          this.loadingSelectTariff = false;
        }
      );

 
      
  }

  async filtrarHorarioTarifa(id:number):Promise<any>{
    const doctor = this.formInput.doctor;

    const horario = await this.serviceUnionDoctorPatientAttention.tariffInfoHors(id,doctor).toPromise();
    return horario;
  }

  async calularHorarios():Promise<number>{
    console.log(this.data);
    console.log(this.horario);
    console.log(this.reservacion);
    console.log(this.listado_reservaciones);
    

    let posicion_rango_horarios = [];

    /*this.horario.hours2.forEach((horario, i) => {
      this.reservacion.forEach((reserva, j) => {
        if (horario.since === reserva.since && horario.until === reserva.until) {
          posicion_rango_horarios.push(i); // Guarda el índice de hours2
        }
      });
    });*/

    for (let i = 0; i < this.horario.hours2.length; i++) {
      for (let z = 0; z < this.reservacion.length; z++) {
        let time_since = this.reservacion[z].since.split(":").slice(0, 2).join(":");
        let time_until = this.reservacion[z].until.split(":").slice(0, 2).join(":");
        if (this.horario.hours2[i].since === time_since && this.horario.hours2[i].until === time_until) {
          posicion_rango_horarios.push(i); // Guarda el índice de hours2
        }
      }
    }

    console.log(posicion_rango_horarios);
    
    let cantidad_intervalos_cita = posicion_rango_horarios.length;

    let [since, until] = this.data.appointment.split("-");
    let since_seleccionado = since.split(":").slice(0, 2).join(":");
    let until_seleccionado = until.split(":").slice(0, 2).join(":");
    console.log(since_seleccionado,until_seleccionado);
    

    this.listado_reservaciones = this.listado_reservaciones.filter(reservacion => {
      let reservacion_since = reservacion.since.split(":").slice(0, 2).join(":");
      return reservacion_since >= since_seleccionado;
    });

    let index = this.horario.hours2.findIndex(
      horario => horario.since === since_seleccionado && horario.until === until_seleccionado
    );
    console.log(index);
    
    let cantidad_intervalos_libres = 0;

    for (index ; index < this.horario.hours2.length; index++) {
      if(this.horario.hours2[index].type == 1){
        cantidad_intervalos_libres++
        //console.log('1',cantidad_intervalos_libres);
        
      }else if(this.horario.hours2[index].type == 4){
        //console.log('2');

        let since_hour_seleccionado = this.horario.hours2[index].since+ ":00";
        let until_hour_seleccionado = this.horario.hours2[index].until+ ":00";

        let encontrado = false;
        for (let a = 0; a < this.listado_reservaciones.length; a++) {
          
          if(this.listado_reservaciones[a].id === this.data.idpatient 
            && this.listado_reservaciones[a].tariff_id === this.data.idtariff
            && this.listado_reservaciones[a].since === since_hour_seleccionado
            && this.listado_reservaciones[a].until === until_hour_seleccionado){
              //console.log('2b',cantidad_intervalos_libres);
              
              cantidad_intervalos_libres++
              //console.log('2a');
              encontrado = true;
              break;
            }
            //console.log(encontrado);

          }

          if (!encontrado) {
            break;
          }
      }else{
        break;
      }
    }

    return cantidad_intervalos_libres;
  }

  async getTypeTarifa():Promise<void>{
    //console.log('ingreso al metodo tipo');
    
    const tarifa = this.formInput.tariff;
    //console.log(tarifa);
     
    try {
      const resp = await this.serviceUnionDoctorPatientAttention.tariffInfo(tarifa).toPromise();
      console.log('respuesta',resp);
  
      const cantidad_intervalos = await this.calularHorarios(); // Esta función parece ser síncrona
      console.log('cantidad',cantidad_intervalos);
      
      const horariosPromises = resp.map(async (tarifa) => {
        // Asegúrate de que `filtrarHorarioTarifa` retorne una promesa
        const horarioArray = await this.filtrarHorarioTarifa(tarifa.id_info);
        // Retornar el primer elemento del array
        return horarioArray[0];
      });
      //console.log(horariosPromises);
      
      // Esperar a que todas las promesas se resuelvan
      const horarios :any[] = await Promise.all(horariosPromises);
      console.log('horario', horarios);
  
      //console.log(cantidad_intervalos);

      const limite_tiempo = cantidad_intervalos * 15;

      for (let i = 0; i < horarios.length; i++) {
        let total = horarios[i].intervalo_minimo * horarios[i].tiempo;

        if(total <=limite_tiempo){
          this.listTarriffType.push(horarios[i])
        }
        
      }

      console.log(this.listTarriffType);
      

      if (this.listTarriffType.length==0) {
        Swal.fire({
          title: 'Atención!!!!',
          text: 'No hay horarios disponibles para este servicio',
          type: 'error',
          showCloseButton: false,
          showCancelButton: false
        }).then((willInsert) => {
          if (willInsert.value){
            this.activeModal.close('Save click');
          }
        })
        return;
      }
      //console.log('listado',this.listTarriffType);
      
  
      //this.listTarriffType = resp;
    } catch (error) {
      console.error('Error al obtener la tarifa:', error);
    } finally {
      // Puedes realizar alguna acción final si es necesario
    }
  }

  getTimeTarifa():void{
    const tarifa_type = this.formInput.type;
    const doctor = this.formInput.doctor;
    //console.log(tarifa_type,doctor);
    
    this.serviceUnionDoctorPatientAttention.tariffInfoHors(tarifa_type,doctor).subscribe(
      resp=>{
        //console.log('tiempo',resp);
        
        const tiempo = resp[0].intervalo_minimo * resp[0].tiempo

        this.formInput.time=tiempo;
        
      }
    )
  }

  getDoctors(): void {
    this.loadingSelectDoctor = true;
    this.listDoctors = [];
    const environ = _.find(this.environmentList, { id: this.formInput.environment });
    const day = moment(this.data.date).tz('America/Lima').day();
    this.serviceDoctor.getByBl(environ.businessline, day)
      .subscribe(
        res => {
          // Busco si está disponible en el horario
          res.forEach(dr => {
            let validate = true;
            // Validamos si el doctor esta en el horario de la mañana
            if (dr.morning_schedule) {
              let morning_since: any;
              let morning_until: any;
              let time: any;
              if (this.data.acction && this.data.acction === 'edit') {
                morning_since = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
                morning_until = moment(`${this.formInput.date} ${this.data.appointment.split('-')[1]}`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
              } else {
                morning_since = moment(`${this.formInput.date} ${dr.morning_schedule.split('-')[0]}:00`).tz('America/Lima');
                morning_until = moment(`${this.formInput.date} ${dr.morning_schedule.split('-')[1]}:00`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.since}:00`).tz('America/Lima');
              }
              if (time >= morning_since && time < morning_until) {
                validate = false;
                this.listDoctors.push(dr);
              }
            }
            // Validamos si el doctor esta en el horario de la tarde
            if (dr.afternoon_schedule && validate) {
              let afternoon_since: any;
              let afternoon_until: any;
              let time: any;
              if (this.data.acction && this.data.acction === 'edit') {
                afternoon_since = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
                afternoon_until = moment(`${this.formInput.date} ${this.data.appointment.split('-')[1]}`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
              } else {
                afternoon_since = moment(`${this.formInput.date} ${dr.afternoon_schedule.split('-')[0]}:00`).tz('America/Lima');
                afternoon_until = moment(`${this.formInput.date} ${dr.afternoon_schedule.split('-')[1]}:00`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.since}:00`).tz('America/Lima');
              }
              if (time >= afternoon_since && time < afternoon_until) {
                this.listDoctors.push(dr);
              }
            }
          });
          if (this.attention) {
            this.formInput.doctor = this.attention.doctor.id;
          }
          this.loadingSelectDoctor = false;
        },
        err => {
          this.loadingSelectDoctor = false;
          console.log(err.error);
        }
      );
  }

  /**
   * Obtenemos solo los doctores
   * OI del día
   */
  onGetDoctors2(): void {
    this.loadingSelectDoctor2 = true;
    this.listDoctors2 = [];
    this.serviceDoctor.getByBl([2], moment(this.data.date).day())
      .subscribe(
        res => {
          // Obtenemos solo los doc
          this.loadingSelectDoctor2 = false;
          this.listDoctors2 = res;
        },
        (err: HttpErrorResponse) => {
          this.loadingSelectDoctor2 = false;
          this.toastr.error(
            'Ocurrio un error al obtener los dotores de OI',
            'Ateción',
            { timeOut: 4000, progressBar: true, closeButton: true }
          );
        }
      );
  }

  getEnvironment(): void {
    this.environmentList = [];
    this.edService.getAll().subscribe(
      res => {
        this.environmentList = res;
        this.getDoctors();
        this.getTariffs();

      },
      err => {
        console.error('Error al obtener los consultorios');
      }
    );
  }

  addMinutes(time: string, minutes: number): string {
    let [hours, mins, secs] = time.split(":").map(Number);
    let date = new Date();
    date.setHours(hours);
    date.setMinutes(mins + minutes);
    date.setSeconds(secs);

    // Formatear la hora a "HH:mm:ss"
    let formattedHours = date.getHours().toString().padStart(2, '0');
    let formattedMinutes = date.getMinutes().toString().padStart(2, '0');
    let formattedSeconds = date.getSeconds().toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  /*addappointmentByPatient(): void {
    this.spinner.show();
    if (this.formInput.id > 0) {

      let total_intervalos = this.formInput.time / 15;
      
      let data_inicial: any = { ...this.formInput };
      let data_reservation_update : any [] = [];
      let data_reservation_delete : any [] = [];

        for (let index = 0; index < this.reservacion.length; index++) {
 
          if(data_inicial.appointment == this.reservacion[index].appointment ){

            let data_inicial_copy = { ...data_inicial };
            data_inicial_copy.id = this.reservacion[index].idreservation;
            data_reservation_update.push(data_inicial_copy);
    
            let [since, until] = data_inicial_copy.appointment.split("-");
            //console.log(since, until);
    
            let newSince = this.addMinutes(since, 15);
            let newUntil = this.addMinutes(until, 15);
            //console.log('new', newSince, newUntil);
    
            // Actualizar la copia de data_inicial para no afectar el objeto original
            data_inicial.appointment = `${newSince}-${newUntil}`;
          }else{
            data_reservation_delete.push(this.reservacion[index].idreservation);
          }
          //console.log('data',data_inicial);
        }

      if(data_reservation_update.length> 0){
        total_intervalos = total_intervalos - data_reservation_update.length;
      }
      console.log(total_intervalos);
      
        
      console.log('data_reservation',data_reservation_update);
      console.log('data_delete',data_reservation_delete);
      
      if(data_reservation_delete){
        console.log('delete');
        
        for (let i = 0; i < data_reservation_delete.length; i++) {
          this.serviceReservation.delete(data_reservation_delete[i]).subscribe(
            resp=>{
              this.spinner.hide();
              this.toastr.success(
                'Horarios que ya no tienen reserva actualizado',
                'Ok!',
                { timeOut: 3000, progressBar: true }
              );
            },error=>{
              this.spinner.hide();
              this.toastr.error(
                'Ocurrio un error al actualizar horarios que ya no tendran cita',
                'Atención',
                { timeOut: 3000, progressBar: true }
              );
            }
          )
          
        }
      }

      if(data_reservation_update.length > 0){
        console.log('update');
        for (let e = 0; e < data_reservation_update.length; e++) {
          delete data_reservation_update[e].time;
          delete data_reservation_update[e].type;
          this.serviceReservation.update(data_reservation_update[e], data_reservation_update[e].id).subscribe(
            res => {
              this.spinner.hide();
              this.toastr.success(
                'La reprogramación fue actualizada correctamente',
                'Ok!',
                { timeOut: 3000, progressBar: true }
              );

            },
            err => {
              this.spinner.hide();
              this.toastr.error(
                'Ocurrio un error al registrar la cita',
                'Atención',
                { timeOut: 3000, progressBar: true }
              );
            }
          );
          
        }
      }
      
      console.log(total_intervalos);
      
      if(total_intervalos>0){
        console.log('insert');
        for (let index = 0; index < total_intervalos; index++) {
          console.log(data_inicial);

          let data_inicial_copy = { ...data_inicial };
          data_inicial_copy.id = null;
          let [since, until] = data_inicial_copy.appointment.split("-");
          data_inicial_copy.since = since;
          data_inicial_copy.until = until;
          data_inicial_copy.interval = 15;
            //console.log(since, until);
            delete data_inicial_copy.time;
            delete data_inicial_copy.type;
            console.log(data_inicial_copy);
            
          this.serviceReservation.insert(data_inicial_copy).subscribe(
            res => {
              this.spinner.hide();
              this.toastr.success(
                'La reserva fue registrada correctamente',
                'Ok!',
                { timeOut: 3000, progressBar: true }
              );
              this.activeModal.close('Save click');
            },
            (err: HttpErrorResponse) => {
              this.spinner.hide();
              if (err.status === 400) {
                this.toastr.warning(
                  err.error.message,
                  'Atención',
                  { timeOut: 3500, progressBar: true }
                );
              } else {
                this.toastr.error(
                  'Ocurrio un error al registrar la cita',
                  'Atención',
                  { timeOut: 3500, progressBar: true }
                );
              }
            }
          );

          let newSince = this.addMinutes(since, 15);
          let newUntil = this.addMinutes(until, 15);
          data_inicial.appointment = `${newSince}-${newUntil}`;
        }
        sessionStorage.removeItem('oldreservation');
        this.activeModal.close('Save click');
        this.reservationService.attention = null;
      }*/
      /*this.serviceReservation.update(this.formInput, this.formInput.id).subscribe(
        res => {
          this.spinner.hide();
          this.toastr.success(
            'La reprogramación fue registrada correctamente',
            'Ok!',
            { timeOut: 3000, progressBar: true }
          );
          sessionStorage.removeItem('oldreservation');
          this.activeModal.close('Save click');
          this.reservationService.attention = null;
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'Ocurrio un error al registrar la cita',
            'Atención',
            { timeOut: 3000, progressBar: true }
          );
        }
      );*/
    /*} else {
      this.serviceReservation.insert(this.formInput).subscribe(
        res => {
          this.spinner.hide();
          this.toastr.success(
            'La reserva fue registrada correctamente',
            'Ok!',
            { timeOut: 3000, progressBar: true }
          );
          this.activeModal.close('Save click');
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status === 400) {
            this.toastr.warning(
              err.error.message,
              'Atención',
              { timeOut: 3500, progressBar: true }
            );
          } else {
            this.toastr.error(
              'Ocurrio un error al registrar la cita',
              'Atención',
              { timeOut: 3500, progressBar: true }
            );
          }
        }
      );
    }
  }*/


    addappointmentByPatient(): void {
      this.spinner.show();
     
      let deleteObservables = [];
      let updateObservables = [];
      let insertObservables = [];
     
      if (this.formInput.id > 0) {
        let total_intervalos = this.formInput.time / 15;
        let data_inicial: any = { ...this.formInput };
        let data_reservation_update: any[] = [];
        let data_reservation_delete: any[] = [];
     
        for (let index = 0; index < this.reservacion.length; index++) {
          if (data_inicial.appointment == this.reservacion[index].appointment) {
            let data_inicial_copy = { ...data_inicial };
            data_inicial_copy.id = this.reservacion[index].idreservation;
            data_reservation_update.push(data_inicial_copy);
     
            let [since, until] = data_inicial_copy.appointment.split("-");
            let newSince = this.addMinutes(since, 15);
            let newUntil = this.addMinutes(until, 15);
            data_inicial.appointment = `${newSince}-${newUntil}`;
          } else {
            data_reservation_delete.push(this.reservacion[index].idreservation);
          }
        }
     
        if (data_reservation_update.length > 0) {
          total_intervalos = total_intervalos - data_reservation_update.length;
        }
     
        // Eliminar reservas
        if (data_reservation_delete.length > 0) {
          for (let i = 0; i < data_reservation_delete.length; i++) {
            deleteObservables.push(this.serviceReservation.delete(data_reservation_delete[i]));
          }
        }
     
        // Actualizar reservas
        if (data_reservation_update.length > 0) {
          for (let e = 0; e < data_reservation_update.length; e++) {
            delete data_reservation_update[e].time;
            delete data_reservation_update[e].type;
            updateObservables.push(this.serviceReservation.update(data_reservation_update[e], data_reservation_update[e].id));
          }
        }
     
        // Insertar nuevas reservas
        if (total_intervalos > 0) {
          for (let index = 0; index < total_intervalos; index++) {
            let data_inicial_copy = { ...data_inicial };
            data_inicial_copy.id = null;
            let [since, until] = data_inicial_copy.appointment.split("-");
            data_inicial_copy.since = since;
            data_inicial_copy.until = until;
            data_inicial_copy.interval = 15;
            delete data_inicial_copy.time;
            delete data_inicial_copy.type;
     
            insertObservables.push(this.serviceReservation.insert(data_inicial_copy));
     
            let newSince = this.addMinutes(since, 15);
            let newUntil = this.addMinutes(until, 15);
            data_inicial.appointment = `${newSince}-${newUntil}`;
          }
        }
     
        // Ejecutar las solicitudes concurrentes y esperar hasta que todas se completen
        forkJoin([
          ...deleteObservables,
          ...updateObservables,
          ...insertObservables
        ]).subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success('Todas las operaciones se realizaron correctamente', 'Ok!', { timeOut: 3000, progressBar: true });
            sessionStorage.removeItem('oldreservation');
            this.activeModal.close('Save click');
            this.reservationService.attention = null;
          },
          err => {
            this.spinner.hide();
            this.toastr.error('Ocurrió un error durante la operación', 'Atención', { timeOut: 3500, progressBar: true });
          }
        );
      } else {
        this.serviceReservation.insert(this.formInput).subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success('La reserva fue registrada correctamente', 'Ok!', { timeOut: 3000, progressBar: true });
            this.activeModal.close('Save click');
          },
          (err: HttpErrorResponse) => {
            this.spinner.hide();
            if (err.status === 400) {
              this.toastr.warning(err.error.message, 'Atención', { timeOut: 3500, progressBar: true });
            } else {
              this.toastr.error('Ocurrió un error al registrar la cita', 'Atención', { timeOut: 3500, progressBar: true });
            }
          }
        );
      }
    }     

  cancelRepro(): void {
    this.toastr.info(
      'Ok!', 'Reprogramación Cancelada', { timeOut: 3000, progressBar: true }
    );
    sessionStorage.removeItem('oldreservation');
    this.activeModal.close('Save click');
  }

  get(): void {
    let id: number;
    if (this.data.acction && this.data.acction === 'edit') {
      id = this.data.id;
    } else {
      id = Number(sessionStorage.getItem('oldreservation'));
    }
    this.serviceReservation.getOne(id)
      .subscribe(
        (res: any) => {
          this.formInput.id = res.id;
          this.formInput.patient = res.idpatient;
          this.patient = res.patient;
          this.formInput.doctor = res.iddoctor;
          this.formInput.doctor_id2 = res.iddoctor2;
          /*if (res.idtariff) {
            this.formInput.tariff = res.idtariff;
          }*/
          this.formInput.pending_payment = res.pending_payment;
          this.formInput.amount = res.amount;
          this.formInput.coin = res.idcoin;
          this.formInput.reason = res.reason;
          if (!this.data.acction) {
            this.formInputOld = res;
            this.formInputOld = {
              environment: res.idenvironment,
              date: moment(res.date).format('YYYY-MM-DD'),
              appointment: res.appointment,
              pending_payment: res.pending_payment,
              amount: res.amount,
              coin: res.coin
            };
          }
        },
        err => {
          console.log('Error al buscar la reserva');
        }
      );
  }

  validateDoctor(): void {
    this.spinner.show();
    this.serviceReservation.validateDoctor(this.formInput.doctor, this.formInput.date, this.formInput.appointment)
      .subscribe(
        res => {
          this.spinner.hide();
          if (res === 1) {
            this.formInput.doctor = null;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene una reserva a la misma hora en otro consultorio',
              type: 'warning',
            });
          } else if (res === 2) {
            this.formInput.doctor = 0;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene bloqueado ese horario',
              type: 'warning',
            });
          }
        },
        err => {
          this.spinner.hide();
          console.log('Errro Validación ');
        }
      );
  }

  /* SELECT DEL AUTO COMPLETADO */
  selectedItem(it: any): void {
    this.showAlert = false;
    const { item } = it;
    this.formInput.patient = item.id;
    if (Number(item.quotasPending) > 0) {
      this.showAlert = true;
    }
  }

  /* AUTOCOMPLETADO DE LOS MEDICAMENTOS **/
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.servicePatient.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  /**
   * Used to format the result data from the lookup into the
   * display and list values. Maps `{name: 'band', id:'id' }` into a string
   */
  resultFormatBandListValue(value: any): string {
    return value.name;
  }
  /**
   * Initially binds the string value and then after selecting
   * an item by checking either for string or key/value object.
   */
  inputFormatBandListValue(value: any): any {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  getCoin(): void {
    this.listCoin = [];
    this.coinService.getAll().subscribe(resp => {
      this.listCoin = resp;
    });
  }

}
