import { Component, ViewChild, AfterViewInit, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

// Models
import { BusinessLineModel } from '../../../models/business-line.model';
import { CoinModel } from '../../../models/coin.model';
import { Doctor } from '../../../modules/operations/doctor/models/doctor.model';
import { MedicalActAttention, MedicalActPAys, medicalActMultiplePays } from '../../../models/main/medical-act.model';
import { PaymentMethodModel } from '../../../models/payment-method.model';
import { SpecialtyModel } from '../../../models/specialty.model';
import { TariffModel } from '../../../models/tariff.model';
import { DiscountType } from '../../../modules/finance/discount-type/model/discount-type.model';

// Services
import { BusinessLineService } from '../../../service/business-line.service';
import { CoinService } from '../../../service/coin.service';
import { DoctorService } from '../../../modules/operations/doctor/services/public/doctor.service';
import { MedicalActAttentionService } from '../../../service/main/medical-act-attention.service';
import { PaymentMethodService } from '../../../service/payment-method.service';
import { SpecialtyService } from '../../../service/specialty.service';
import { TariffService } from '../../../service/tariff.service';
import { DiscountTypeService } from '../../../modules/finance/discount-type/services/public/discount-type.service'

import { ModalAutorizationComponent } from '../../security/modal-autorization/modal-autorization.component';

import { ClinicHistoryVouchersComponent } from '../../clinic-history/clinic-history-vouchers/clinic-history-vouchers.component';
import { Customer } from 'src/app/modules/finance/service-loan/customer';
import { Contract } from 'src/app/models/finance/contract.model';
import { concatMap } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PaymentDetailCh } from 'src/app/models/finance/payment.detail.ch.model';
import { ExchangeRateModel } from 'src/app/models/exchange-rate.model';
import { ExchangeRateService } from 'src/app/service/exchange-rates.service';
import { ServiceOrder } from 'src/app/models/finance/service-order.model';
import Swal from 'sweetalert2';
import { PaymentsAdvanceService } from 'src/app/service/main/payments-advance';
import { NewPaymentsAdvanceDto, PaymentsAdvanceDto, UpdatePaymetnDto } from 'src/app/models/main/payments-advance.model';
import { MedicalActAttentionContractPaays } from 'src/app/models/main/medical-act-attention-contract-pays';
import { ContractService } from 'src/app/service/finance/contract.service';
import { Subject } from 'rxjs';
import { ServiceOrderV2Service } from 'src/app/service/service-order-v2.service';
import { ModalDiscountSpecificationComponent } from '../modal-add-attention-new-version/modal-discount-specification/modal-discount-specification.component';
import { DiscountReasonService } from 'src/app/service/discount-reason.service';
import { DiscountReasonDto } from 'src/app/models/discount.model';

class FormFields {
  get Date(): string { return 'AttentionDate'; }
  get Coin(): string { return 'AttentionCoin'; }
  get PaymentMethod(): string { return 'AttentionPaymentMethod'; }
  get LaboratoryCost(): string { return 'AttentionLaboratoryCost'; }
  get Doctor(): string { return 'AttentionDoctor'; }
  get BusisnessLine(): string { return 'AttentionBusisnessLine'; }
  get Specialty(): string { return 'AttentionSpecialty'; }
  get Tariff(): string { return 'AttentionTariff'; }
  get Quantity(): string { return 'AttentionQuantity'; }
  get Amount(): string { return 'AttentionAmount'; }
  get Total(): string { return 'AttentionTotal'; }
  get IdDiscountType(): string { return 'IdDiscountType'; }
  get DiscountType(): string { return 'DiscountType'; }
  get DiscountAmount(): string { return 'DiscountAmount'; }
  get AmountPay(): string {return 'AmountPay'}
  get ExchangeRate(): string { return 'ExchangeRate'; }
  get TypeDiscount():string { return 'TypeDiscount';}
}

@Component({
  selector: 'app-card-add-attention',
  templateUrl: './card-add-attention.component.html',
  styleUrls: ['./card-add-attention.component.scss']
})
export class CardAddAttentionComponent implements OnInit, AfterViewInit {

  minimalUnitValue: number = 0;

  showExchangeRate = true;
  labelPosition = "before";
  color: ThemePalette = 'accent';
  coinListDetail: CoinModel[] = [];
  pmListDetail: PaymentMethodModel[] = [];
  listExchangeRate: ExchangeRateModel[] = [];
  archivosCargadosData: any[] = [];
  exchangerate : number = 0;
  dataTemp: ServiceOrder;
  idmedical: number;
  getExchangeRate(): void {
    this.exchangeRateService.getLast().subscribe({
      next: (resp) => {
        this.listExchangeRate = resp;
      }
    });
  }

  discountList: DiscountReasonDto[] = [];

  paymentForm = this.fb.group({
    PaymentBreakdown: new FormControl(),
  });

  get f(): { [key: string]: AbstractControl } {
    return this.paymentForm.controls;
  } 
  amountPer:number;
  
  fieldpays: PaymentDetailCh;
  listpays:PaymentDetailCh[] = [];
  
  public toggle(event: MatSlideToggleChange) {
    let quotas = 0;
    
    if (event.checked) {
      quotas++;
    }

  }

  // historial clinico.
  idclinchistory: number;
  iddoctor: number;
  idattention: number;
  attention: any;

  title = 'Resumen de Pago';

  // FORM
  formData: FormGroup;
  formFields: FormFields = new FormFields();
  formInput: MedicalActAttention;

  doctorList: Doctor[] = [];
  coinList: CoinModel[] = [];
  pmList: PaymentMethodModel[] = [];
  blList: BusinessLineModel[] = [];
  loadingBl = false;
  specialtyList: SpecialtyModel[] = [];
  loadingSpecialty = false;
  tariffList: TariffModel[] = [];
  discountTypeList: DiscountType[] = [];
  loadingTariff = false;
  showDiscountType = false;

  // tipo de moneda, mision
  coin = 'S/';
  commission = 0;
  session: any = {};

  selectedContracts: Contract[];
  listContract: Contract[] = [];
  listContractPay: Contract[] = [];
  loading: boolean = false;
  totalQuota : number = 0;

  // ESTA VARIABLE SIRVE PARA ASIGNARLE UN VALOR Y SETEAR AL INCIIAR LA PANTALLA
  public inputBusisnessLine: number = 0;
  public inputSpecialty: number = 0;

  advance: PaymentsAdvanceDto = {
    id: null,
    id_inicial: null,
    id_final: null,
    userid_created: null,
    userid_update: null,
    created_at: null,
    updated_at: null
  };

  
  newpay: NewPaymentsAdvanceDto={
    id: 0,
    id_inicial:0,
    userid_created:0,
    created_at: new Date(),
  }

  updatepay: UpdatePaymetnDto ={}
  
  listContractSeleccionados: Contract[] = [];

  contractMedical : MedicalActAttentionContractPaays [] = [];

  private modalClosedSubject = new Subject<void>();

  selectedValue:any;
  seleccion : boolean = false;
  
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private doctorService: DoctorService,
    private coinService: CoinService,
    private pmService: PaymentMethodService,
    private blService: BusinessLineService,
    private specialtyService: SpecialtyService,
    private tariffService: TariffService,
    private maaService: MedicalActAttentionService,
    private store: Store<{ session: any }>,
    private modalSerive: NgbModal,
    private discountTypeService: DiscountTypeService,
    private servicePaymentsAdvance: PaymentsAdvanceService,
    private exchangeRateService: ExchangeRateService,
    private contractService : ContractService,
    private serviceOrderNew : ServiceOrderV2Service,
    private serviceDiscountReason: DiscountReasonService,
  ) { }

  ngOnInit(): void {

    // Obtiene datos de session
    this.getSession();
    // Obtiene los datos de los doctores
    this.getDoctors();
    // Obtiene las monedas
    this.getCoins();
    // Obtiene los metodos de pagos
    this.getPaymentMethod();
    this.getBl();
    // Obtiene los tipos de descuento
    this.getDiscountType();

    //ob tener tipo de cambio
    this.getExchangeRate()
    // Inicializa el formulario
    this.initForm();
    
    // luego de inciar el formulario cargamos el init
    this.lastInitForm();
    this.getTotalQuota();
    this.getDiscountAll();
  }

  @ViewChild(ClinicHistoryVouchersComponent) vouchers!: ClinicHistoryVouchersComponent;
  ngAfterViewInit() {
    
  }
  
  
  setNewAmount(): void {
    const idexchangerate = Number(this.formData.get(this.formFields.ExchangeRate).value);
    
    this.exchangerate = this.listExchangeRate.find(el => el.id === idexchangerate).value;
    this.listExchangeRate.find(el => el.id === idexchangerate);
  }
  
  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
        }
      });
  }

  getDoctors(): void {
    this.doctorList = [];
    this.doctorService.getAll().subscribe({
      next: (resp) => {
        this.doctorList = resp;
        this.formData.get(this.formFields.Doctor).setValue(this.iddoctor);
      },
      error: (error) => {
        console.log({ error });
      }
    });
  }

  getCoins(): void {
    this.coinList = [];
    this.coinService.getAll()
      .subscribe(
        res => {
          this.coinList = res;
          this.coinListDetail = this.coinList.slice(0,2);
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getPaymentMethod(): void {
    this.pmList = [];
    this.pmService.getAll()
      .subscribe(
        res => {
          this.pmList = res;
          this.pmListDetail = this.pmList.slice(0,7);
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getDiscountType() {
    this.discountTypeList = [];
    this.discountTypeService.get().subscribe({
      next: (resp) => {
        this.discountTypeList = resp;
      },
      error: () => { }
    });
  }

  clean():void{
    this.selectedValue = null;
        this.formData.get(this.formFields.TypeDiscount).reset();
        this.formData.get(this.formFields.DiscountType).reset();
        this.formData.get(this.formFields.DiscountAmount).reset();
        this.formData.get(this.formFields.Total).reset();
        this.formData.get(this.formFields.Amount).reset();
        this.formData.get(this.formFields.Tariff).reset();
        this.seleccion = false;
        this.setMonto(2)
  }

  onMultiSelectChange() {
    this.selectedValue = this.formData.get(this.formFields.TypeDiscount).value;
    // Verifica si la opción seleccionada es "otros" con id 3
    if (this.selectedValue !== null) {
      // Abre el modal correspondiente aquí
      this.openModalDiscountSpecification();
    }
  }

  getDiscountAll() {
    this.discountList = [];
    this.serviceDiscountReason.findAllDiscountReason().subscribe({
      next: (resp) => {
        this.discountList = resp;
      },
      error: () => { }
    });
  }

  openModalDiscountSpecification():void{
    const modalSpecific = this.modalSerive.open(ModalDiscountSpecificationComponent)
    modalSpecific.result.then((result: any) => {

      if (result === 'Save click') {
        // this.get(iddoctor, doctor);
        const data = modalSpecific.componentInstance.selectedCategories;

        let info='';
        for(let i = 0;i <data.length; i++){  
          if (i > 0) {
            info += ', '; // Agregar coma después del primer elemento
          }

          if(data[i].id == 3){
            info += `${data[i].name} - ${data[i].otherdescription} `;
          }else{
            info += `${data[i].name} `;
          }
        }
        
        this.formData.get(this.formFields.IdDiscountType).setValue(info);
      } else {

        this.selectedValue = null;
        this.formData.get(this.formFields.TypeDiscount).reset();
        this.formData.get(this.formFields.DiscountType).reset();
        this.formData.get(this.formFields.DiscountAmount).reset();
        this.setMonto(2)

      }
    });
  }

  onDiscountChange(event : any){
    this.amountPer = event.discountrate;
    this.formData.get(this.formFields.DiscountType).setValue(true);
    this.formData.get(this.formFields.DiscountAmount).setValue(this.amountPer);
    this.setMonto(2);
  }


  initForm() {
    
    this.formData = this.fb.group({});
    const today = this.attention ? this.attention.date : moment().tz('America/Lima').format('YYYY-MM-DD');
    
    this.formData.addControl(this.formFields.Date, new FormControl(today, [Validators.required]));
    const coin = this.attention ? this.attention.co.id : 2;
    this.formData.addControl(this.formFields.Coin, new FormControl(coin));
    this.formData.addControl(this.formFields.PaymentMethod, new FormControl(this.attention ? this.attention.idpaymentmethod : 1));
    this.formData.addControl(this.formFields.LaboratoryCost, new FormControl(this.attention ? this.attention.lab_cost : 0));
    this.formData.addControl(this.formFields.Doctor, new FormControl(null, [Validators.required]));
    const discount_amount = this.attention ? this.attention.discount_amount : null;
    this.showDiscountType = discount_amount > 0 ? true : false;
    const iddiscounttype = this.attention ? this.attention.iddiscounttype ? this.attention.iddiscounttype.id : null : null;
    this.formData.addControl(this.formFields.IdDiscountType, new FormControl(iddiscounttype));
    const discountType = this.attention ? this.attention.discount_type === 'P' ? true : false : false;
    this.formData.addControl(this.formFields.DiscountType, new FormControl(discountType));
    this.formData.addControl(this.formFields.DiscountAmount, new FormControl(discount_amount));
    this.formData.addControl(this.formFields.TypeDiscount, new FormControl(null))

    let businessLine = null;
    let idSpecialty = null;
    let idTariff = null;
    if (this.attention) {
      const { tariff } = this.attention;
      const { specialty } = tariff;
      const { businessLines } = specialty;
      idSpecialty = specialty.id;
      businessLine = businessLines.id;
      idTariff = tariff.id;
    }

    this.formData.addControl(this.formFields.BusisnessLine, new FormControl(businessLine, [Validators.required]));
    this.formData.addControl(this.formFields.Specialty, new FormControl(idSpecialty, [Validators.required]));
    this.formData.addControl(this.formFields.Tariff, new FormControl(idTariff, [Validators.required]));
    this.formData.addControl(this.formFields.Quantity, new FormControl(this.attention ? this.attention.quantity : 1, [Validators.required, Validators.min(1)]));
    this.formData.addControl(this.formFields.AmountPay,new FormControl(this.attention ? this.attention.AmountPay : 1, [Validators.required, Validators.min(1)]));
    this.formData.addControl(this.formFields.ExchangeRate, new FormControl(null));
    this.formData.addControl(this.formFields.Amount, new FormControl(this.attention ? this.attention.value : 0, [Validators.required, Validators.min(0.01)]));
    this.formData.addControl(this.formFields.Total, new FormControl(0, [Validators.required, Validators.min(0.01)]));

    if (this.attention) {
      this.getSpecialtys();
    }
  }

  async lastInitForm() {

    this.blList = [];
    this.specialtyList = [];
    this.tariffList = [];

    this.loadingBl = true;

    this.blService.getAll().pipe(
      // Una vez que se ha completado el llamado a getAll, proseguimos con el siguiente paso.
      concatMap((blList) => {

          this.blList = blList;
          // Establecer el valor para BusisnessLine
          this.formData.get(this.formFields.BusisnessLine).setValue(3);

          // Segundo paso: obtener la especialidad por línea de negocio
          return this.specialtyService.getByBusinessLine(this.formData.get(this.formFields.BusisnessLine).value);
      }),
      
      // Después de obtener las especialidades, seleccionamos la especialidad deseada y pasamos al siguiente paso
      concatMap((specialties) => {
          // seteamos la lista de especialidades
          this.specialtyList = specialties;

          this.formData.get(this.formFields.Specialty).setValue(15);
          // Tercer paso: obtener tarifas por especialidad
          return this.tariffService.getBySpecialty(this.formData.get(this.formFields.Specialty).value);
      })
  ).subscribe({
      next: (tariffs) => {
          // seteamos la lista de tarifa
          this.tariffList = tariffs;
          // Después de obtener las tarifas, seleccionamos la tarifa deseada
          let tarifa = 0;
          if(this.listContractPay[0].description == 'Inicial'){
            tarifa = 122;
          }else if(this.listContractPay[0].description == 'Moldes') {
            tarifa = 121;
          }else{
            tarifa = 123;
          }

          this.formData.get(this.formFields.Tariff).setValue(tarifa);
          this.formData.get(this.formFields.Quantity).setValue(this.listContractPay.length);

          let total = this.listContractPay[0].amount - this.listContractPay[0].total.value;
          this.formData.get(this.formFields.Amount).setValue(total);

          // Llama a la función adicional que deseas ejecutar
            this.setMonto(1)
          // Si hay más lógica para agregar después de este punto, puedes hacerlo aquí
      },
      error: (error) => {
          console.error("Error durante el proceso:", error);
      }
  }); 
  }

  manejarArchivosCargados(datos: any[]) {
    this.archivosCargadosData = datos;

    const data = JSON.stringify(this.archivosCargadosData);
    this.maaService.updateMedicalActFiles(this.idmedical,data)
    .subscribe(
      res => {
        this.spinner.hide();
        this.toastr.success('carga de acrhivos exitosa!!', 'Ok!', {
          timeOut: 3000,
        });
      },
      error => {
        this.spinner.hide();
        this.toastr.error('carga de acrhivos errada!!', 'Error!', {
          timeOut: 3000,
        });
      }
    );
  }

  getTotalQuota() : void{

      this.totalQuota = 0;

      for(let i =0; i < this.listContractPay.length ; i++ ){
        this.totalQuota += this.listContractPay[i].amount;
      }
  }

  async save(): Promise<void> {

    let hayAlgunError = false;

    if (this.vouchers.uploadedFiles.length === 0) {
      this.vouchers.uploadedFiles.length;
      this.toastr.warning("Debe seleccionar un archivo", "Atención", { timeOut: 3000, progressBar: true });
      hayAlgunError = true;
    }

    if (this.formData.invalid) {
      return;
    }


    if(hayAlgunError){
      return
    }

    this.spinner.show();

    this.formInput = {
      medicalact: this.idattention,
      businessline: this.formData.get(this.formFields.BusisnessLine).value,
      specialty: this.formData.get(this.formFields.Specialty).value,
      tariff: this.formData.get(this.formFields.Tariff).value,
      quantity: this.formData.get(this.formFields.Quantity).value,
      doctor: this.formData.get(this.formFields.Doctor).value,
      user: this.session.id,
      date: this.formData.get(this.formFields.Date).value,
      patient: this.idclinchistory,
      co: this.formData.get(this.formFields.Coin).value,
      value: this.formData.get(this.formFields.Amount).value,
      lab_cost: this.formData.get(this.formFields.LaboratoryCost).value,
      idpaymentmethod: this.formData.get(this.formFields.PaymentMethod).value,
      commission: this.commission,
      iddiscounttype: this.formData.get(this.formFields.IdDiscountType).value,
      discount_type: this.formData.get(this.formFields.DiscountAmount).value > 0 ?
        (this.formData.get(this.formFields.DiscountType).value ? 'P' : 'A') : null,
      discount_amount: this.formData.get(this.formFields.DiscountAmount).value,
      TypeDiscount: this.formData.get(this.formFields.TypeDiscount).value
    };

    let saveMedicaQuotas:MedicalActPAys = <any>{};
    let medicalMultiplePaysModel : medicalActMultiplePays = <any>{}; ;

    saveMedicaQuotas.ate=[{
      "co":this.formData.get(this.formFields.Coin).value,
      "user":68,
      "lab_cost":this.formData.get(this.formFields.LaboratoryCost).value,
      "idpaymentmethod": this.formData.get(this.formFields.PaymentMethod).value,
    
    }];

    saveMedicaQuotas.data=[];


    for await (const contractPay of this.listContractPay) {
        saveMedicaQuotas.data.push({
          "id":contractPay.idcontract,
          "description": contractPay.description,
          "date":contractPay.date,
          "amount":contractPay.amount
      })
    }

    medicalMultiplePaysModel.ate = saveMedicaQuotas;
    medicalMultiplePaysModel.medical = this.formInput;
    medicalMultiplePaysModel.medical.fraction_of_payment = 1;
    

    let valorIngresado = this.formData.get(this.formFields.Total).value ;

    //console.log( this.formInput );
    //return;
    
      /*this.maaService.saveMedicaQuotas(medicalMultiplePaysModel)
        .subscribe(
          res => {
            
            
            this.spinner.hide();
            this.toastr.success('Cuotas canceladas correctamente!!', 'Ok!', {
              timeOut: 3000,
            });
            this.activeModal.close('Save click');
            //Enviar adjuntos... recuperar ID...
            const nuevoRegistro = res; // Asumiendo que res contiene la información necesaria
            
            this.idmedical = nuevoRegistro.medical.id;

            this.vouchers.onSubmit(this.idmedical);
          },
          error => {
            this.spinner.hide();
            this.toastr.error('Ha courrido un error al registrar la atención!!', 'Error!', {
              timeOut: 3000,
            });
          }
        );*/

        if( valorIngresado < this.totalQuota){
          this.spinner.hide()
          Swal.fire({
            title: 'Atención!!!!',
            text: 'El valor unitario ingresado es menor al mínimo requerido.',
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true
          }).then((willInsert) => {
            if (willInsert.value){  
              
              this.serviceOrderNew.createServiceOrderByContract(medicalMultiplePaysModel).subscribe(
                resp=>{
                  this.spinner.hide();
                  this.toastr.success('Cuotas canceladas correctamente!!', 'Ok!', {
                    timeOut: 3000,
                  });
                  this.activeModal.close('Save click');
                  //Enviar adjuntos... recuperar ID...
                  const nuevoRegistro = resp; // Asumiendo que res contiene la información necesaria
                  
                  this.idmedical = nuevoRegistro.medical.id;
    
                  this.vouchers.onSubmit(this.idmedical);
                },
                error => {
                  this.spinner.hide();
                  this.toastr.error('Ha courrido un error al registrar la atención!!', 'Error!', {
                    timeOut: 3000,
                  });
                }
              )
                /*this.formInput.fraction_of_payment = 0;
                this.maaService.insert(this.formInput)
                  .subscribe(
                    res => {
                      this.spinner.hide();
                      Swal.fire('ok!', 'Atención por adelanto agregada correctamente!!', 'success');
                      
                      const nuevoRegistro = res; // Asumiendo que res contiene la información necesaria
                  
                      this.idmedical = nuevoRegistro.id;

                      this.vouchers.onSubmit(this.idmedical)
                      
                      if(this.listContractPay[0].total.fragment == null){

                        this.advance.id_inicial = nuevoRegistro.id;
                        this.advance.userid_created = this.session.id;
                        this.advance.created_at = new Date(Date.now());

                        this.servicePaymentsAdvance.savePaymentsContract(this.advance)
                        .subscribe(
                            res =>{
                              this.spinner.hide();
                              this.toastr.success('okk', 'Ok!', {
                                timeOut: 3000,
                              });

                                this.contractMedical = [];
                                for(let i =0; i < this.listContractPay.length ; i++ ){
                                  const contract = {
                                    idcontractdetail: this.listContractPay[i].idcontract,
                                    idmedicalactattention: this.idmedical,
                                    userid_created: this.session.id,
                                    update_at: null,
                                    created_at: new Date()
                                  };
                              
                                  // Agrega el contrato a this.contractMedical
                                  this.contractMedical.push(contract);
                                }

                                this.maaService.saveMedicalContract(this.contractMedical).subscribe(
                                  res => {
                                    this.spinner.hide();
                                    this.toastr.success('Cuota Cancelada!!', 'Ok!', {
                                      timeOut: 3000,
                                    });
                                    this.activeModal.close('Save click');                                   
                                    this.contractService.updateTableEvent.emit();
                                    this.modalClosedSubject.next();

                                  },error => {
                                  this.spinner.hide();
                                  this.toastr.error('Ha courrido un error al cancelar cuota!!', 'Error!', {
                                    timeOut: 3000,
                                    });
                                  }
                                );
                              },
                        error => {
                          this.spinner.hide();
                          this.toastr.error('Ha courrido un error lklklkkl la atención!!', 'Error!', {
                            timeOut: 3000,
                          });
                        }
                      ) 
                      }
                      else{
                        this.updatepay.id = this.listContractPay[0].total.fragment;
                        this.updatepay.id_final = this.idmedical;
                        this.updatepay.userid_update = this.session.id;
                        this.updatepay.updated_at = new Date(Date.now());

                        this.servicePaymentsAdvance.updatePay(this.updatepay,this.listContractPay[0].total.fragment)
                        .subscribe(
                          res =>{
                            this.spinner.hide();
                            this.toastr.success('Actualizacion de Datos correcta', 'Ok!', {
                              timeOut: 3000,
                            });
                            let inicial = res.id_inicial

                            this.maaService.updateStateFragment(inicial)
                            .subscribe(
                              res =>{
                                this.spinner.hide();
                                this.toastr.success('Actualizacion exitosa!!', 'Ok!', {
                                  timeOut: 3000,
                                });
                              }
                            
                            )

                            let result = parseFloat((this.listContractPay[0].amount - this.listContractPay[0].total.value ?? 0).toFixed(2));
                            let importe = parseFloat((this.formInput.value ?? 0).toFixed(2));
                            let difference = result - importe;

                            if(difference > 0 ){

                              this.newpay.id_inicial = this.idmedical;
                              this.newpay.userid_created = this.session.id;
                              this.newpay.created_at = new Date(Date.now());

                              this.servicePaymentsAdvance.savePaymentsContract(this.newpay).subscribe(
                                res => {
                                  this.spinner.hide();
                                  this.toastr.success('okk', 'Ok!', {
                                  timeOut: 3000,
                                  });
                                }
                              );
                            };

                            if ( difference == 0){
                              this.maaService.updateStateFragment(this.idmedical)
                              .subscribe(
                                res =>{
                                  this.spinner.hide();
                                  this.toastr.success('Actualizacion exitosa!!', 'Ok!', {
                                    timeOut: 3000,
                                  });
                                }
                              )
                            }
                            
                            this.contractMedical = [];
                                for(let i =0; i < this.listContractPay.length ; i++ ){
                                  const contract = {
                                    idcontractdetail: this.listContractPay[i].idcontract,
                                    idmedicalactattention: this.idmedical,
                                    userid_created: this.session.id,
                                    update_at: null,
                                    created_at: new Date()
                                  };
                              
                                  // Agrega el contrato a this.contractMedical
                                  this.contractMedical.push(contract);
                                }
                                

                                this.maaService.saveMedicalContract(this.contractMedical).subscribe(
                                  res => {
                                    this.spinner.hide();
                                    this.toastr.success('Cuota Cancelada!!', 'Ok!', {
                                      timeOut: 3000,
                                    });
                                    this.activeModal.close('Save click');
                                    
                                    this.contractService.updateTableEvent.emit();

                                    this.modalClosedSubject.next();

                                    this.vouchers.onSubmit(this.idmedical)
                                  },error => {
                                  this.spinner.hide();
                                  this.toastr.error('Ha courrido un error al cancelar cuota!!', 'Error!', {
                                    timeOut: 3000,
                                  });
                                }
                                )
                          }
                        )
                      }
                      
                    },
                    err => {
                      Swal.fire('Error!', 'No se pudo atención Medica por Adelanto', 'error');
                    }
                  ) */    
              }
          });
        }
        else{
          
          this.serviceOrderNew.createServiceOrderByContract(medicalMultiplePaysModel).subscribe(
            resp=>{
              this.spinner.hide();
              this.toastr.success('Cuotas canceladas correctamente!!', 'Ok!', {
                timeOut: 3000,
              });
              this.activeModal.close('Save click');
              //Enviar adjuntos... recuperar ID...
              const nuevoRegistro = resp; // Asumiendo que res contiene la información necesaria
              
              this.idmedical = nuevoRegistro.medical.id;

              this.vouchers.onSubmit(this.idmedical);
            },
            error => {
              this.spinner.hide();
              this.toastr.error('Ha courrido un error al registrar la atención!!', 'Error!', {
                timeOut: 3000,
              });
            }
          )
          /*this.maaService.saveMedicaQuotas(medicalMultiplePaysModel)
          .subscribe(
          res => {
            
            
            this.spinner.hide();
            this.toastr.success('Cuotas canceladas correctamente!!', 'Ok!', {
              timeOut: 3000,
            });
            this.activeModal.close('Save click');
            //Enviar adjuntos... recuperar ID...
            const nuevoRegistro = res; // Asumiendo que res contiene la información necesaria
            
            this.idmedical = nuevoRegistro.medical.id;

            this.vouchers.onSubmit(this.idmedical);
          },
          error => {
            this.spinner.hide();
            this.toastr.error('Ha courrido un error al registrar la atención!!', 'Error!', {
              timeOut: 3000,
            });
          }
        )*/
      }
  }

  setComision(): void {
    const comi = this.pmList.filter(el => el.id === this.formData.get(this.formFields.PaymentMethod).value);
    this.commission = comi[0].commission;
  }

  setMonto(opc: number): void {
    this.showDiscountType = false;
    this.formData.get(this.formFields.IdDiscountType).clearValidators();
    const tariff = this.formData.get(this.formFields.Tariff).value;
    const valor = this.tariffList.find(it => it.id === tariff);
    const discount_type = this.formData.get(this.formFields.DiscountType).value;
    const discount_amount = this.formData.get(this.formFields.DiscountAmount).value;
    if (this.formData.get(this.formFields.Amount).value <= 0 || opc === 1) {
      this.minimalUnitValue = valor.price_sol > 0 ? valor.price_sol : valor.price_usd;
      // this.formData.get(this.formFields.Amount).setValue(this.minimalUnitValue);
    }
    this.coin = valor.price_sol > 0 ? 'S/' : '$';
    let total = 0;
    total = Number((this.formData.get(this.formFields.Quantity).value * this.formData.get(this.formFields.Amount).value));
    if (discount_type && discount_amount > 0) {
      this.showDiscountType = true;
      this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
      total = total - Number(((total * discount_amount) / 100));
    } else if (discount_amount > 0) {
      this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
      this.showDiscountType = true;
      total -= discount_amount;
    }
    this.formData.get(this.formFields.Total).setValue(total.toFixed(2));
  }

  /**
   * List of Business linea <Linea de negocio>
   */
  getBl(): void {
    this.blList = [];
    this.loadingBl = true;
    this.blService.getAll()
      .subscribe(
        res => {
          this.loadingBl = false;
          this.blList = res;
        },
        err => {
          this.loadingBl = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of Specialtys <Especialidades>
   */
  getSpecialtys(): void {

    let valor = this.formData.get(this.formFields.BusisnessLine).value;

    this.formData.get(this.formFields.Specialty).setValue(null);
    this.specialtyList = [];
    this.loadingSpecialty = true;
    this.specialtyService.getByBusinessLine(this.formData.get(this.formFields.BusisnessLine).value)
      .subscribe(
        res => {
          this.specialtyList = res;
          this.loadingSpecialty = false;
          if (this.attention) {
            this.formData.get(this.formFields.Specialty).setValue(this.attention.tariff.specialty.id);
            this.getTariff();
          }
        },
        err => {
          this.loadingSpecialty = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of tariff <Tarifario o tratamiento>
   */
  getTariff(): void {
    this.loadingTariff = true;
    this.formData.get(this.formFields.Tariff).setValue(null);
    this.tariffList = [];
    this.tariffService.getBySpecialty(this.formData.get(this.formFields.Specialty).value)
      .subscribe(
        res => {
          this.tariffList = res;
          this.loadingTariff = false;
          if (this.attention) {
            this.formData.get(this.formFields.Tariff).setValue(this.attention.tariff.id);
            this.setMonto(2);
          }
        },
        err => {
          this.loadingTariff = false;
          console.log(err.error);
        }
      );
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required')) {
      return true;
    }
    return false;
  }

  openModalAutorization(): void {
    const modal = this.modalSerive.open(ModalAutorizationComponent, { size: 'sm', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        // this.get(iddoctor, doctor);
      } else {
        this.activeModal.close('Close click');
      }
    });
    modal.componentInstance.message = 'Para editar la atención es necesario que sea autorizado por un usuario con perfil ADMIN!';
    modal.componentInstance.data = this.attention;
    modal.componentInstance.module = 'medical-act-attention';
  }



  public ObtenerSoloFecha(date) {

    // Suponiendo que 'fechaEntrada' es tu fecha en ese formato
    let fechaEntrada = date;

    const fechaMoment = moment.utc(fechaEntrada);

    // Formatea la fecha para que sólo muestre la parte de la fecha (YYYY-MM-DD)
    return fechaMoment.format('DD-MM-YYYY');
  }


  
  eliminarFila(index: number): void {
    // Verificar si el índice está dentro de los límites del array
    if (index >= 0 && index < this.listpays.length) {
      // Eliminar la fila en la posición del índice
      this.listpays.splice(index, 1);
    }
  }
  


  calcularSumaUs(listpays: any[]): number {
    return listpays.reduce((suma, pago) => suma + pago.amountUs, 0);
  }

  calcularSuma(listpays: any[]): number {
    return listpays.reduce((suma, pago) => suma + pago.amountPen, 0);
  }

}
