<div [innerHTML]="this.greetings"></div>
<div class="row btn-page">
    <div class="col-xl-3 col-lg-12">
      <app-card cardTitle="Buscar" cardClass="task-board-left" [options]="false">
        <div class="input-group mb-3">
          <input type="text" class="form-control add_task_todo" placeholder="Buscar..." required="">
          <div class="input-group-append">
            <button class="btn btn-primary btn-icon btn-msg-send" type="button"><i class="fas fa-search"></i></button>
          </div>
        </div>
      </app-card>
      <app-card cardTitle="Reservas por estado" blockClass="task-details" [options]="false">
        <table class="table">
          <tbody>
            <tr>
              <td><i class="fas fa-user-clock m-r-5"></i> Por Confirmar:</td>
              <td class="text-right">{{toBeConfirmed}}</td>
            </tr>
            <tr>
              <td><i class="fas fa-check m-r-5"></i> Confirmados:</td>
              <td class="text-right">{{confirmed}}</td>
            </tr>
            <tr>
              <td><i class="fas fa-check-double m-r-5"></i> Atendidos:</td>
              <td class="text-right">{{attended}}</td>
            </tr>
          </tbody>
        </table>
      </app-card>
    </div>
    <div class="col-xl-9 col-lg-12 filter-bar invoice-list">
      <nav class="navbar m-b-30 p-10">
        <ul class="nav">
          <li class="nav-item active">
            <a class="nav-link text-secondary" href="javascript:">Filtros: <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item dropdown" ngbDropdown placement="auto">
            <a class="nav-link text-secondary" ngbDropdownToggle href="javascript:" id="bydate"><i class="far fa-clock"></i> Fecha</a>
            <div ngbDropdownMenu aria-labelledby="bydate">
              <label for="hasta" class="dropdown-item">Desde</label>
              <input type="date" name="desde" class="form-control form-control-sm" [(ngModel)]="this.filter.since">
              <label for="hasta" class="dropdown-item">Hasta</label>
              <input type="date" name="hasta" class="form-control form-control-sm" [(ngModel)]="this.filter.until">
              <hr>
              <button class="btn btn-primary btn-lg btn-block dropdown-item" (click)="this.getReservation()">
                <i class="fas fa fa-search"></i> Buscar
              </button>
            </div>
          </li>
          <li class="nav-item dropdown" ngbDropdown placement="auto">
            <a class="nav-link text-secondary" ngbDropdownToggle href="javascript:" id="bystatus"><i class="fas fa-chart-line"></i> Estado</a>
            <div ngbDropdownMenu aria-labelledby="bystatus">
              <a class="dropdown-item" href="javascript:">Todos</a>
              <select name="state" class="custom-select">
                <option *ngFor="let ls of listState" [value]="ls.id">{{ls.name}}</option>
              </select>
            </div>
          </li>
        </ul>
      </nav>
      <div class="row">
        <div class="col-12" *ngIf="this.reservations.length === 0">
          <app-alert type="info">
            <h4 class="alert-heading">Atención!</h4>
            <p>No hay información para mostrar.</p>
            <hr>
            <p class="mb-0">Utilice los filtros para ampliar los parametros de busqueda.</p>
          </app-alert>
        </div>
      </div>
      <div class="row" *ngIf="this.reservations.length > 0">
        <div class="col-md-4 col-sm-12" *ngFor="let item of reservations">
          <app-card cardClass="card-border-c-blue" blockClass="card-task" [customHeader]="true">
            <div class="app-card-header">
              <a href="javascript:" class="text-secondary">
                {{item.patient.name}} {{item.patient.lastNameFather}}
              </a>
              <span class="label label-primary float-right"> {{item.patient.history}} </span>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <p class="task-detail">{{item.environment.name}}</p>
                <p class="task-due"><strong> Fecha y hora </strong><br>
                  {{item.date | date:'dd-MM-yyyy'}} <strong>{{item.appointment}}</strong>
                </p>
              </div>
            </div>
            <hr>
            <div class="task-list-table">
              <a href="javascript:"><img class="img-fluid img-radius m-r-5" src="assets/images/user/user-profile.png" [alt]="item.patient.name" /></a>
            </div>
            <div class="task-board">
              <span class="badge bg-success mr-1 text-white" *ngIf="item.state === 3">
                <i class="fas fa-check-double"></i> Atendido
              </span>
              <span class="badge bg-primary mr-1 text-white" *ngIf="item.state === 2">
                <i class="fas fa-check"></i> Confirmado
              </span>
              <span class="badge bg-secondary mr-1 text-white" *ngIf="item.state === 1">
                <i class="fas fa-user-clock"></i> Por confirmar
              </span>
              <div class="dropdown-secondary m-r-5 dropdown" ngbDropdown placement="auto">
                <button class="btn btn-primary btn-sm" ngbDropdownToggle type="button" id="dropdown1">Acciones</button>
                <div ngbDropdownMenu aria-labelledby="dropdown1">
                  <a class="dropdown-item" href="javascript:" (click)="getClinicHistory(item.patient)">
                    <span class="point-marker bg-danger"></span>Historia Clinica
                  </a>
                  <a class="dropdown-item" href="javascript:" (click)="setAtention(item)">
                    <span class="point-marker bg-warning"></span>Atender
                  </a>
                </div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>
</div>  
