import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DiscountReasonDto } from './../models/discount.model'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountReasonService {

  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http:HttpClient) { }

  findAllDiscountReason():Observable<DiscountReasonDto[]>{
    return this.http.get<DiscountReasonDto[]>(`${this.baseUrl}/discount-reason`)
    }
}
