<p *ngIf="this.listQuotation.length === 0">
    <ngb-alert [dismissible]="false" [type]="'primary'">
      <strong><i class="fas fa-info-circle"></i></strong>
      No hay cotizaciones registradas
    </ngb-alert>
</p>

<div class="table-responsive">
  <p-table [value]="listQuotation" 
    dataKey="id" 
    styleClass="p-datatable-customers" 
    [rowHover]="true"
    [rows]="rows" 
    [lazy]="true" 
    (onLazyLoad)="getQuotations($event)" 
    [paginator]="true" 
    [totalRecords]="totalRecords" 
    [loading]="loading"
    [paginator]="true"
    [showCurrentPageReport]="true" 
    [(first)]="first"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
    [rowsPerPageOptions]="[10,25,50]">
      <ng-template pTemplate="caption">
          <div class="table-header">
            <span style="line-height: 44px;">Lista de Cotizaciones</span>
       
          <div class="p-toolbar-group-right">
            <p-button label="Nuevo" icon="pi pi-plus" (click)="addForm(1)"></p-button>
          </div>
          </div>
      </ng-template>
        <ng-template pTemplate="header">
            <tr>
              <th>Fecha</th>
              <th>Paciente</th>
              <th>Linea de negocio</th>
              <th>Total S/</th>
              <th>Total $</th>
              <th>Acción</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{item.date | date:"dd-MM-yyyy"}}</td>
              <td>{{item.clinicHistory.name}} {{item.clinicHistory.lastNameFather}} {{item.clinicHistory.lastNameMother}}</td>
              <td>{{item.businessline}}</td>
              <td>{{item.total_sol | number}}</td>
              <td>{{item.total_usd | number}}</td>
              <td>
                <button type="button" class="btn btn-icon btn-success btn-sm" (click)="addForm(item.id)"
                ngbPopover="Editar" triggers="mouseenter:mouseleave">
                    <i class="feather icon-check-circle"></i>
                </button>
              </td>
            </tr>
        </ng-template>
      
    </p-table>
  </div>