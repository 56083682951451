import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { ListSelectDto } from '../../../../../shared/dtos/list-select-dto';
import { Doctor } from '../../models/doctor.model';
import { DoctorDataService } from '../private/doctor-data.service';
import { DoctorProgramming } from '../../models/doctor-programming.model';

import { EnvirontmentDto } from '../../../doctor-diary/dtos/environtment-dto';

@Injectable({
    providedIn: 'root'
})
export class DoctorService {

    private _doctorList: BehaviorSubject<ListSelectDto[]> = new BehaviorSubject(null);
    private baseUrl: string = `${environment.apiUrl}`;
    public dataDoctor: Doctor;

    constructor(
        private dataRequestService: DoctorDataService,
        private http: HttpClient
    ) { }

    /**
     * Getter for doctor list
     */
    get doctorList$(): Observable<any> {
        return this._doctorList.asObservable();
    }

    get(id: number): Observable<Doctor> {
        return this.http.get<Doctor>(`${this.baseUrl}/doctor/${id}`);
    }

    getAll(): Observable<Doctor[]> {
        return this.http.get<Doctor[]>(`${this.baseUrl}/doctor`);
    }

    getEnvirontment(): Observable<EnvirontmentDto[]> {
        return this.http.get<EnvirontmentDto[]>(`${this.baseUrl}/doctor-programming/environtment`);
    }

    getList(): Observable<ListSelectDto[]> {
        return this.http.get<ListSelectDto[]>(`${this.baseUrl}/doctor/get/list`).pipe(
            tap((response: ListSelectDto[]) => {
                this._doctorList.next(response);
            })
        );
    }

    insert(doctor: Doctor): Observable<Doctor> {
        return this.http.post<Doctor>(`${this.baseUrl}/doctor`, doctor);
    }

    update(doctor: Doctor, id: number): Observable<Doctor> {
        return this.http.put<Doctor>(`${this.baseUrl}/doctor/${id}`, doctor);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.baseUrl}/doctor/${id}`)
    }

     /**
     * Lista de doctores por nombre
     * @param name
     * @returns
     */
     search(name: string): Observable<ListSelectDto[]> {
        return this.http.get<ListSelectDto[]>(`${this.baseUrl}/doctor/search/${name}`).pipe(
            tap((response: ListSelectDto[]) => {
                this._doctorList.next(response);
            })
        );
    }

    /**
     * Lista de doctores por linea de negocio
     * @param id
     * @param day
     * @returns
     */
    getByBl(id: any, day: number): Observable<Doctor[]> {
        return this.http.post<Doctor[]>(`${this.baseUrl}/doctor/get-in-bl/${day}`, id);
    }

    /* Programación de doctores */
    // Obtenemos la lista de programación de un doctor
    getDoctorProgrammingByDoctor(iddoctor: number): Observable<DoctorProgramming[]> {
        return this.dataRequestService.getDoctorProgrammingByDoctor(iddoctor).pipe(
            map(ele => ele.map(el => new DoctorProgramming().FromDTO(el)))
        );
    }

    // Obtenemos los datos de una programación por id
    getDoctorProgrammingById(id: number): Observable<DoctorProgramming> {
        return this.dataRequestService.getDoctorProgrammingBy(id).pipe(
            map(el => new DoctorProgramming().FromDTO(el))
        );
    }

    // Agregamos una programación
    addDoctorProgramming(body: DoctorProgramming): Observable<Response> {
        return this.dataRequestService.addDoctorProgramming(body.ToDTO());
    }

    // Editamos una programación
    updateDoctorProgramming(id: number, body: DoctorProgramming): Observable<Response> {
        return this.dataRequestService.updateDoctorProgramming(id, body.ToDTO());
    }

    // Agregamos una programación
    deleteDoctorProgramming(id: number): Observable<Response> {
        return this.dataRequestService.deleteDoctorProgramming(id);
    }

    // Agregamos una programación
    NewaddDoctorProgramming(body: DoctorProgramming): Observable<Response> {
        return this.dataRequestService.NewaddDoctorProgramming(body.ToDTO());
    }
}
