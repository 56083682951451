import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';


import { environment } from '../../../environments/environment';

// Models
import { Contract } from '../../models/finance/contract.model';
import { ContractDetail } from '../../models/finance/contract-detail.model';
import { ContractQuotaPayment } from '../../models/finance/contract-quota-payment.model';
import { Payment } from '../../models/finance/payment.model';
import { KpiQuota } from '../../models/finance/kpi-quota.model';
import { catchError, map } from 'rxjs/operators';
import { State_contract } from '../../models/finance/state_contract.model';
import { ExecutiveContract } from '../../models/finance/executive-contract.model';
import {ContractPaysDetail, ContractPendientLetter} from '../../models/finance/contract-pay-detail.model';
import { ContractValidation } from '../../models/finance/contract-validation.model'
import { ServiceOrder } from 'src/app/models/finance/service-order.model';
import { ServiceOrderPaymentDetailNew } from 'src/app/models/finance/service-order-new.model';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private baseUrl: string = `${environment.apiUrl}/contract`;
  private baseUrlReport = `${environment.apiUrlReport}`;

  public updateTableEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient
  ) { }

  openWsp(id:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/informatioByPatient-wsp/${id}`)
  }

  contractUpdate(data:Contract,id:number,inicial:number,unico:number,cuotas:number):Observable<any>{
    
    return this. http.put<any>(`${this.baseUrl}/contractUpdate/${id}/${inicial}/${unico}/${cuotas}`,data)
  }

  getcountContractNumbering():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/countContractNumbering`);
  }

  getcountContractNumberingContract(id: number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/countContractNumberingValidate/${id}`);
  }

  getXlsReportProjected(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${this.baseUrl}/report-projected-xls/${filters.since}/${filters.until}`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  updateContractDetailByOrderService(data:ServiceOrderPaymentDetailNew[]):Observable<ServiceOrderPaymentDetailNew[]>{
    return this.http.put<any>(`${this.baseUrl}/contractDetail/update`,data);
  }

  getXlsReportContractPayment(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${this.baseUrl}/report-contracts-payment-xls/${filters.since}/${filters.until}`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getXlsReportCollectionAbandoned(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${this.baseUrl}/report-canceled-xls-contracts/${filters.since}/${filters.until}`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getXlsReportCuentasPorCobrar(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${environment.apiUrl}/collection-report/report-xls-collection-report-pendients/`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getXlsReportCollectionCancel(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${environment.apiUrl}/collection-report/report-xls-collection-report-cancels`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }


  getReportContractPays(filters:any,data:any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${this.baseUrl}/report-xls-contracts/${filters.since}/${filters.until}`,data,
    { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  putUpdateStateDetail(data : ContractDetail[]):Observable<ContractDetail>{
    return this.http.put<ContractDetail>(`${this.baseUrl}/updateStateContractDetail`,data)
  }

  saveContractDetail(data : ContractDetail [] , id:number):Observable<ContractDetail>{
    return this.http.post<ContractDetail>(`${this.baseUrl}/contractdetail/${id}`,data);
  }

  listMethodPaymentService(idcontract:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/listMethodPaymentService/${idcontract}`);
  }

  getMethodPayment(idcontract:number):Observable<Contract>{
    return this.http.get<Contract>(`${this.baseUrl}/getMethodPayment/${idcontract}`);
  }

  triggerTableUpdate(): void {
    this.updateTableEvent.emit();
  }

  getContractPendienteToPay(id:number):Observable<ContractPendientLetter[]>{
    return this.http.get<ContractPendientLetter[]>(`${this.baseUrl}/tContractPendienteToPay/${id}`);
  }

  getIdContractDetailByIdClinic(idClinic:number,descripcion:string):Observable<Number>{
    return this.http.get<Number>(`${this.baseUrl}/contractDetail/${descripcion}/${idClinic}`);
  }

  getById(id: number): Observable<Contract> {
    return this.http.get<Contract>(`${this.baseUrl}/${id}`);
  }

  getDataFilters(filters: any, action: string): Observable<Contract[]> {
    return this.http.post<Contract[]>(`${this.baseUrl}/${action}`, filters);
  }

  getDataByClinicHistory(idclinichistory: number): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.baseUrl}/clinic-history/${idclinichistory}`);
  }

  save(data: Contract): Observable<Contract> {
    return this.http.post<Contract>(`${this.baseUrl}`, data);
  }

  update(data:Contract, id:number): Observable<Contract>{
    return this.http.put<Contract>(`${this.baseUrl}/${id}`, data);
  }
  
  getLastContractNumber(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/get-last/contract/number`);
  }

  signature(data: any): Observable<Contract> {
    return this.http.post<Contract>(`${this.baseUrl}/signature`, data);
  }

  getDetail(id: number): Observable<ContractDetail[]> {
    return this.http.get<ContractDetail[]>(`${this.baseUrl}/detail/${id}`);
  }

  getDetailForPay(id: number): Observable<ContractDetail[]> {
    return this.http.get<ContractDetail[]>(`${this.baseUrl}/detail/for-payment/${id}`);
  }

  insertPayment(data: ContractQuotaPayment, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('body', JSON.stringify(data));
    return this.http.post<any>(`${this.baseUrl}/payment-quota/${data.id}/payment`, formData);
  }

  getPaymentList(filters: any): Observable<Payment[]> {
    return this.http.post<Payment[]>(`${this.baseUrl}/payments`, filters);
  }

  getPayment(idpayment: number): Observable<Payment> {
    return this.http.get<Payment>(`${this.baseUrl}/payment/detail/${idpayment}`);
  }

  changeStatusPayment(id: number, state: number): Observable<Response> {
    return this.http.put<Response>(`${this.baseUrl}/payment/change-state/${id}`, { state });
  }

  getKpiQuotas(filters: any): Observable<KpiQuota> {
    return this.http.post<KpiQuota>(`${this.baseUrl}/kpi/quotas`, filters);
  }

  getKpiQuotas2(): Observable<KpiQuota> {
    return this.http.get<KpiQuota>(`${environment.apiUrlAgenda}/finance/contract/view_contracts`);
  }


  getByIdContractPay(idClinicHistory:number,idContract:number,contract :ContractPaysDetail): Observable<ContractPaysDetail>{
    return this.http.put<ContractPaysDetail>(`${this.baseUrl}/contract/contract-pay-detail/${idClinicHistory}/${idContract}`,contract);
  }

  getKpiXls(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${this.baseUrl}/kpi/get-resume-xlsx`, filters, { headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getAccountsContracts(filters:any):Observable<any>{
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${this.baseUrl}/kpi/get-data-constracts`, filters, { headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getPdf(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrlReport}/report/pdf/contract/${id}`);
  }

  getPdfLetter(idcontract: number, type: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/pdf/letters/${type}/${idcontract}`);
  }
 
  getDataStateContract() {
    return this.http.get<State_contract[]>(`${this.baseUrl}/get/state_contract`);
  }

  getDataExecutivesContract() {
    return this.http.get<ExecutiveContract[]>(`${this.baseUrl}/get/settings`);
  }

  update_contract(data: any) {
    return this.http.put<any>(`${this.baseUrl}/get/update_state_contract`, data);
  }

  getQuotaGeneralPatient(idpatient:number):Observable<any>{
    return this.http.get<any>(`$-{this.baseUrl}/QuotaGeneralPatient/${idpatient}`)
  }


  getPatientContracts(idpatient:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/patient-contracts/${idpatient}`)
  }

  getContractValidation(filters: any):Observable<ContractValidation[]>{
    return this.http.post<ContractValidation[]>(`${this.baseUrl}/contract-validation`,filters)
  } 

  ContractConfirm(idcontract:number,idclinichistory:number, selectedExecutive: number):Observable<Contract>{
    return this.http.get<Contract>(`${this.baseUrl}/confirm-contract/${idcontract}/${idclinichistory}/${selectedExecutive}`)
  }

  getFindContractConfirm(idclinichistory:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/getFindContractConfirm/${idclinichistory}`)
  }

  getDataXlaContractsValidation(filters:any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${this.baseUrl}/kpi/get-constracts-validtaion`,filters ,
    { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getCountContractsValidate(idClinic:number):Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/count-contract-validates/${idClinic}`);
  }

  getQuotasPaysAll(idClinic:number):Observable<number>{
    return this.http.get<any>(`${this.baseUrl}/getQuotasPaysAll/${idClinic}`);
  }

  getpendientToPay(clinic:number,description:string):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/pendientToPay/${clinic}/${description}`);
  }

}
