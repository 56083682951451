import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { DiaryListComponent } from 'src/app/components/diary-list/diary-list.component';
import { DiaryLockComponent } from 'src/app/components/diary-lock/diary-lock.component';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { DoctorDiaryAttentionPatientComponent } from '../../../doctor-diary/components/doctor-diary-attention-patient/doctor-diary-attention-patient.component';
import { DoctorDiaryNewComponent } from '../../../doctor-diary-new/doctor-diary-new.component';

@Component({
  selector: 'app-doctor-diary-filters-two',
  templateUrl: './doctor-diary-filters-two.component.html',
  styleUrls: ['./doctor-diary-filters-two.component.scss']
})
export class DoctorDiaryFiltersTwoComponent implements OnInit {

  @Output() selectedDate = new EventEmitter<string>();
  public model: any;
  public date: { year: number, month: number };

  canInsert: boolean;
  canUpdate: boolean;
  canDelete: boolean;

  // Autocomplet
  searching = false;
  searchFailed = false;
  patient = '';
  filterPatient: any;

  // Estado
  filterState: any;
  loadingSelectStateFilter: boolean;
  listState: any[] = [];

  constructor(
    private calendar: NgbCalendar,
    public parserFormatter: NgbDateParserFormatter,
    private servicePatient: ClinicHistoryService,
    private modalSerive: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getStatesFilter();
    this.filterState = 0;
    this.selectToday();
  }


  getStatesFilter(): void {
    this.loadingSelectStateFilter = true;
    this.listState = [
      {
        id: 0,
        state: 'Todos'
      },
      {
        id: 1,
        state: 'Por confirmar'
      },
      {
        id: 2,
        state: 'Confirmado'
      },
      {
        id: 3,
        state: 'Atendido'
      },
    ];
    this.filterState = this.listState[0].id;
    this.loadingSelectStateFilter = false;
  }

  onDiaryLock(): void {
    const modal = this.modalSerive.open(DiaryLockComponent, { size: 'lg' });
    modal.result.then((res: any) => {
      if (res === 'Save click') {
        //
      }
    });
  }

  onSearch(): void {
    const modal = this.modalSerive.open(DiaryListComponent, { size: 'xl' });
    modal.result.then((res: any) => {
      if (res === 'Save click') {
        //
      }
    });
    modal.componentInstance.patient = this.filterPatient;
    //modal.componentInstance.doctor = this.filterDoctor;
    modal.componentInstance.state = this.filterState;
    //modal.componentInstance.date = this.dateSelect;
  }

  selectToday(): void {
    this.model = this.calendar.getToday();
    this.setDate();
  }

  setDate(): void {
    const month = this.model.month < 10 ? `0${this.model.month}` : this.model.month;
    const day = this.model.day < 10 ? `0${this.model.day}` : this.model.day;
    const dateSelect = `${this.model.year}-${month}-${day}`;
    this.selectedDate.emit(dateSelect);
  }

  /* SELECT DEL AUTO COMPLETADO */
  selectedItem(it: any): void {
    const { item } = it;
    this.filterPatient = item.id;
  }

  /* Autocompletado de pacientes **/
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.servicePatient.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  /**
   * Used to format the result data from the lookup into the
   * display and list values. Maps `{name: 'band', id:'id' }` into a string
   */
  resultFormatBandListValue(value: any): string {
    return value.name;
  }
  /**
   * Initially binds the string value and then after selecting
   * an item by checking either for string or key/value object.
   */
  inputFormatBandListValue(value: any): any {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  newAttention():any {

    const month = this.model.month < 10 ? `0${this.model.month}` : this.model.month;
    const day = this.model.day < 10 ? `0${this.model.day}` : this.model.day;
    const dateSelect = `${this.model.year}-${month}-${day}`;
 
    const modal = this.modalSerive.open(DoctorDiaryAttentionPatientComponent, { size: 'xl' });
    modal.result.then((res: any) => {
      if (res === 'Save click') {
        this.setDate();
      }
    });
    modal.componentInstance.dateToday = dateSelect;
  }

  modal():any{
    const modal = this.modalSerive.open(DoctorDiaryNewComponent, { size: 'xl' });
    modal.result.then((res: any) => {
      if (res === 'Save click') {
        this.setDate();
      }
    });
  }
}
