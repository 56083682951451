import { ActionReducerMap } from "@ngrx/store";
import { clinicHistoryReducer, ClinicHistoryState } from "./clinic-history.reducer";
import { sessionReducer } from './session.reducer';

interface AppState {
    clinicHistoryState: ClinicHistoryState;
    session: any
}

export const reducers: ActionReducerMap<AppState> = {
    clinicHistoryState: clinicHistoryReducer,
    session: sessionReducer
}