import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Programming } from '../../../doctor-diary/models/programming.model';
import { Store } from '@ngrx/store';
import { DoctorDiaryService } from '../../../doctor-diary/services/public/doctor-diary.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-doctor-diary-one',
  templateUrl: './doctor-diary-one.component.html',
  styleUrls: ['./doctor-diary-one.component.scss']
})
export class DoctorDiaryOneComponent implements OnInit {

  programming: Programming[] = [];
  session: any = {};
  showFilters = false;
  loading = false;
  sesiones : any;

  constructor(
    private store: Store<{ session: any }>,
    private service: DoctorDiaryService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private users: AuthService
  ) { }

  ngOnInit(): void {
    this.getSession();
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.showFilters = true;
          this.getUserDetail();
          if (sess.doctor) {
            // this.filterDoctor = sess.doctor.id;
          }

          /*if(this.session.roles.name == 'ADMIN'){
            Swal.fire({
              title: 'Atención!!!!',
              text: 'Su usuario no esta permitido el ingreso a esta agenda.',
              type: 'error',
              showCloseButton: false,
              showCancelButton: false
            }).then((willInsert) => {
              if (willInsert.value){
                this.router.navigate(['/inicio']);
              }
            })
          }*/
        }


      });
  }

  getUserDetail(): void {

    this.users.getUsersDetail(this.session.id).subscribe(
      user=>{
        this.sesiones=user;
        console.log(this.sesiones);
        
        //agenda 1 usuarios: ejecutivo ofm , ejecutivo apnea y ejecutivo oi
      },error=>{
        console.log({ error });
        this.loading = false;
      }
    )
  }

  setSchedule(event: any): void {
    this.loading = true;
    const idcampus = this.session.idcampus;
    this.programming = [];
    this.spinner.show();
    
    this.service.getProgramming(event, idcampus,1).subscribe({
      next: (resp) => {
        //console.log('respuesta',resp);
        
        this.loading = false;
        this.programming = resp;
        this.spinner.hide();
      },
      error: (error) => {
        console.log({ error });
        this.loading = false;
      }
    });
  }

}
