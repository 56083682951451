<div class="modal-header modal_header">
    <h5 class="modal-title titulo-modal">Cotización</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <form #validationForm="ngForm" class="editForm" novalidate>
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h6>Datos base</h6>
                        <hr class="hr">
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label" for="date">Fecha <small
                                    class="text-danger">(*)</small></label>
                            <input class="form-control form-control-sm" id="date" name="date" type="date"
                                placeholder="Fecha"
                                [ngClass]="{'is-invalid': !date.valid && (date.dirty || date.touched || isSubmit)}"
                                #date="ngModel" required [(ngModel)]="formInput.date" autofocus readonly>
                            <label *ngIf="!date.valid && (date.dirty || date.touched || isSubmit)"
                                id="validation-email-error"
                                class="error jquery-validation-error small form-text invalid-feedback">Debe colocar la
                                fecha.</label>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label class="form-control-label" for="doctor">Doctor <small
                                    class="text-danger">(*)</small></label>
                            <ng-select [(ngModel)]="formInput.doctor" [ngModelOptions]="{standalone: true}"
                                (change)="validateDoctor()" #doctor="ngModel" [virtualScroll]="true" name="doctor"
                                required>
                                <ng-option *ngFor="let do of doctorList" [value]="do.id">{{do.name}}</ng-option>
                            </ng-select>
                            <label *ngIf="!validDoctor && (doctor.dirty || doctor.touched || isSubmit)"
                                id="validation-cmbGear-error"
                                class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar
                                un doctor.</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h6>Datos del paciente</h6>
                        <hr class="hr">
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="form-group">
                            <i class="fas fa-info-circle pointer text-secondary" [placement]="'top'"
                                [popoverTitle]="'Buscar Paciente'" ngbPopover="Colcar el número de documento y Enter"
                                data-original-title="popup on top"></i>
                            <label class="form-control-label" for="document_number">Nro. Documento <small
                                    class="text-danger">(*)</small></label>
                            <input class="form-control form-control-sm" autofocus id="document_number"
                                name="document_number" type="text" placeholder="Nro. Documento"
                                [ngClass]="{'is-invalid': !document_number.valid && (document_number.dirty || document_number.touched || isSubmit)}"
                                #document_number="ngModel" required [(ngModel)]="formInput.document_number"
                                (keyup.enter)="onKey($event)" [disabled]="disabled">
                            <label
                                *ngIf="!document_number.valid && (document_number.dirty || document_number.touched || isSubmit)"
                                id="validation-email-error"
                                class="error jquery-validation-error small form-text invalid-feedback">Debe colocar el
                                numero de documenro</label>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label class="form-label">Paciente <small class="text-danger">(*)</small></label>
                            <ng-select [(ngModel)]="formInput.patient" [ngModelOptions]="{standalone: true}"
                                name="patient" [disabled]="disabled" (change)="selectPatient()" #patient="ngModel"
                                [virtualScroll]="true">
                                <ng-option *ngFor="let pt of patientList" [value]="pt.id">{{pt.name}}</ng-option>
                            </ng-select>
                            <label *ngIf="!patient.valid && (patient.dirty || patient.touched || isSubmit)"
                                id="validation-cmbGear-error"
                                class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar
                                una paciente.</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-control-label" for="history">Historia Clinica</label>
                            <input class="form-control form-control-sm" id="history" name="history" type="text"
                                placeholder="MX000-000000" readonly #age="ngModel" [(ngModel)]="formInput.history">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-control-label" for="age">Edad</label>
                            <input class="form-control form-control-sm" id="age" name="age" type="text"
                                placeholder="Edad" readonly #age="ngModel" [(ngModel)]="formInput.age">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-control-label" for="cellphone">Nro. Teléfono</label>
                            <input class="form-control form-control-sm" id="cellphone" name="cellphone" type="text"
                                placeholder="Nro. Teléfono" readonly #cellphone="ngModel"
                                [(ngModel)]="formInput.cellphone">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h6>Detalle</h6>
                <hr class="hr">
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="businessline" class="form-control-label">Linea de Negocio</label>
                    <ng-select [(ngModel)]="formInput.bl" [ngModelOptions]="{standalone: true}"
                        (change)="getSpecialtys(); validateBl()" [loading]="loadingBl"
                        [ngClass]="{'is-invalid': !validBl && (bl.dirty || bl.touched || isSubmit)}" #bl="ngModel">
                        <ng-option *ngFor="let bl of blList" [value]="bl">{{bl.name}}</ng-option>
                    </ng-select>
                    <label *ngIf="!validBl && (bl.dirty || bl.touched || isSubmit)" id="validation-cmbGear-error"
                        class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar una
                        Linea de negocio.</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="form-control-label" for="specialty">Especialidad</label>
                    <ng-select [(ngModel)]="formInput.specialty" [ngModelOptions]="{standalone: true}"
                        #specialty="ngModel" [loading]="loadingSpecialty" (change)="getTariff()">
                        <ng-option *ngFor="let sp of specialtyList" [value]="sp">{{sp.name}}</ng-option>
                    </ng-select>
                    <label *ngIf="!validSpecialty && (specialty.dirty || specialty.touched || isSubmit)"
                        id="validation-cmbGear-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Debe seleccionar una Especialidad.
                    </label>
                </div>
            </div>
            <div class="col-md-2" *ngIf="showCoin">
                <div class="form-group">
                    <label class="form-control-label" for="coin">Moneda</label>
                    <select class="form-control form-control-sm custom-select" name="coin"
                        [ngClass]="{'is-invalid': !validCoin && (coin.dirty || coin.touched || isSubmit)}"
                        #coin="ngModel" [(ngModel)]="formInputDetail.coin" (change)="validateCoin()">
                        <option *ngFor="let co of coinList" [value]="co.id">{{co.name}}</option>
                    </select>
                    <label *ngIf="!validCoin && (coin.dirty || coin.touched || isSubmit)" id="validation-cmbGear-error"
                        class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar una
                        moneda.</label>
                </div>
            </div>
            <div class="col-md-1">
                <button type="button" class="btn btn-outline-primary btn-sm mt-4"
                    *ngIf="this.formInput.specialty.odontograma" (click)="odontograma()" ngbPopover="Odontograma"
                    triggers="mouseenter:mouseleave">
                    <i class="fas fa-teeth"></i>
                </button>
            </div>
            <div class="col-md-3"></div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="producto" class="form-control-label">Tratamiento</label>
                    <ng-select [(ngModel)]="formInputDetail.tariff" [ngModelOptions]="{standalone: true}"
                        [loading]="loadingSelect" (change)="setTariff()">
                        <ng-option *ngFor="let tf of tariffList" [value]="tf">{{tf.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="precio" class="form-control-label">Precio <small
                            class="text-primary">{{coinDet}}</small></label>
                    <input class="form-control form-control-sm" id="price" name="price" type="number"
                        (change)="calculateDetail()" placeholder="Precio" [(ngModel)]="formInputDetail.price">
                </div>
            </div>
            <div class="col-sm-1">
                <div class="form-group">
                    <label for="quantity" class="form-control-label">Cantidad</label>
                    <input class="form-control form-control-sm" id="quantity" name="quantity" type="number" min="1"
                        placeholder="Cantidad" [(ngModel)]="formInputDetail.quantity" (change)="calculateDetail()">
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="discount" class="form-control-label">Descuento <small
                            class="text-primary">{{coinDet}}</small></label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">%</span>
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="checkbox" name="porce_discount"
                                    [(ngModel)]="formInputDetail.porce_discount" (change)="calculateDetail()">
                            </div>
                        </div>
                        <input class="form-control form-control-sm" id="discount" name="discount" type="number"
                            placeholder="Descuento" [(ngModel)]="formInputDetail.discount" (change)="calculateDetail()">
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="total" class="form-control-label">Total <small
                            class="text-primary">{{coinDet}}</small></label>
                    <input class="form-control form-control-sm" id="total" name="total" type="number"
                        placeholder="Total" readonly [(ngModel)]="formInputDetail.total">
                </div>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn btn-icon btn-outline-primary btn-sm mt-4" (click)="addItem()"
                    placement="top" ngbTooltip="Agregar">
                    <i class="feather icon-save"></i>
                </button>
            </div>
            <div class="col-12" *ngIf="details.length > 0">
                <h6>Lista de tratamientos</h6>
                <hr class="hr">
            </div>
            <div class="col-12" *ngIf="details.length > 0">
                <div class="row" *ngFor="let item of details; let i=index">
                    <div class="col-4"><label class="form-control-label">{{item.tariff.name}}</label></div>
                    <div class="col-2 text-right">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{item.coin === 1 ? 'S/' : '$'}}</span>
                            </div>
                            <input class="form-control form-control-sm" [name]="'price_det'+i" type="number"
                                [(ngModel)]="item.price" (change)="this.calculateItem(i)">
                        </div>
                    </div>
                    <div class="col-1 text-center">
                        <input class="form-control form-control-sm" [name]="'quantity_det'+i" type="number"
                            [(ngModel)]="item.quantity" (change)="this.calculateItem(i)">
                    </div>
                    <div class="col-2 text-right">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">%</span>
                                <div class="input-group-text">
                                    <input type="checkbox" aria-label="checkbox" name="porce_discount"
                                        [(ngModel)]="item.porce_discount" (change)="calculateItem(i)">
                                </div>
                            </div>
                            <input class="form-control form-control-sm" [name]="'discount_det'+i" type="number"
                                [(ngModel)]="item.discount" (change)="this.calculateItem(i)">
                        </div>
                    </div>
                    <div class="col-2 text-right">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{item.coin === 1 ? 'S/' : '$'}}</span>
                            </div>
                            <input class="form-control form-control-sm" [name]="'total_det'+i" type="number"
                                [(ngModel)]="item.total" readonly>
                        </div>
                    </div>
                    <div class="col-1">
                        <a href="javascript:" data-toggle="tooltip" data-original-title="Editar"
                            (click)="this.updateItem(item)">
                            <i class="feather icon-edit mr-1"></i>
                        </a>
                        <a href="javascript:" class="text-muted"><i class="feather icon-trash-2"
                                (click)="removeItem(i,item.id)"></i></a>
                        <!-- <button type="button" class="btn btn-icon btn-outline-danger btn-sm"
                        placement="top" ngbTooltip="Eliminar" (click)="removeItem(i,item.id)" *ngIf="item.state < 2">
                            <i class="feather icon-x-circle"></i>
                        </button> -->
                    </div>
                    <div class="col-12">
                        <hr class="hr">
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <table class="table table-responsive invoice-table invoice-total">
                    <tbody>
                        <tr class="text-info">
                            <td>
                                <h5 class="text-primary m-r-10">Total $: </h5>
                            </td>
                            <td>
                                <h5 class="text-primary"> {{totalUsd | number : '1.0-2'}}</h5>
                            </td>
                        </tr>
                        <tr class="text-info">
                            <td>
                                <h5 class="text-primary m-r-10">Total S/: </h5>
                            </td>
                            <td>
                                <h5 class="text-primary"> {{totalSol | number : '1.0-2'}}</h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" uiSref="work" class="btn btn-primary btn-raised" (click)="save(validationForm)"
        *ngIf="!processing">
        Guardar
        <span style="margin-left:10px;">
            <i class="feather icon-save"></i>
        </span>
    </button>
    <button class="btn btn-raised btn-primary" *ngIf="processing" disabled>
        <span class="spinner-border spinner-border-sm" role="status"></span>
        <span class="load-text"> Procesando...</span>
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')"
        *ngIf="!processing">
        Salir
    </button>
</div>