import { PaymentsAdvanceDto } from './../../../models/main/payments-advance.model';
import { PaymentsAdvanceService } from './../../../service/main/payments-advance';
import { Component, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

// Models
import { BusinessLineModel } from '../../../models/business-line.model';
import { CoinModel } from '../../../models/coin.model';
import { Doctor } from '../../../modules/operations/doctor/models/doctor.model';
import { MedicalActAttention } from '../../../models/main/medical-act.model';
import { PaymentMethodModel } from '../../../models/payment-method.model';
import { SpecialtyModel } from '../../../models/specialty.model';
import { TariffModel } from '../../../models/tariff.model';
import { DiscountType } from '../../../modules/finance/discount-type/model/discount-type.model'
// Services'src/app/service/business-line.service';
import { BusinessLineService } from '../../../service/business-line.service';
import { CoinService } from '../../../service/coin.service';
import { DoctorService } from '../../../modules/operations/doctor/services/public/doctor.service';
import { MedicalActAttentionService } from '../../../service/main/medical-act-attention.service';
import { PaymentMethodService } from '../../../service/payment-method.service';
import { SpecialtyService } from '../../../service/specialty.service';
import { TariffService } from '../../../service/tariff.service';
import { DiscountTypeService } from '../../../modules/finance/discount-type/services/public/discount-type.service'

import { ModalAutorizationComponent } from '../../security/modal-autorization/modal-autorization.component';

import { ClinicHistoryVouchersComponent } from '../../clinic-history/clinic-history-vouchers/clinic-history-vouchers.component';
import { BehaviorSubject } from 'rxjs';
import { data } from 'jquery';
import { ContractService } from 'src/app/service/finance/contract.service';
import { MedicalActAttentionContractPaays } from 'src/app/models/main/medical-act-attention-contract-pays';
import Swal from 'sweetalert2';


class FormFields {
  get Date(): string { return 'AttentionDate'; }
  get Coin(): string { return 'AttentionCoin'; }
  get PaymentMethod(): string { return 'AttentionPaymentMethod'; }
  get LaboratoryCost(): string { return 'AttentionLaboratoryCost'; }
  get Doctor(): string { return 'AttentionDoctor'; }
  get BusisnessLine(): string { return 'AttentionBusisnessLine'; }
  get Specialty(): string { return 'AttentionSpecialty'; }
  get Tariff(): string { return 'AttentionTariff'; }
  get Quantity(): string { return 'AttentionQuantity'; }
  get Amount(): string { return 'AttentionAmount'; }
  get Total(): string { return 'AttentionTotal'; }
  get IdDiscountType(): string { return 'IdDiscountType'; }
  get DiscountType(): string { return 'DiscountType'; }
  get DiscountAmount(): string { return 'DiscountAmount'; }
}

@Component({
  selector: 'app-modal-add-attention',
  templateUrl: './modal-add-attention.component.html',
  styleUrls: ['./modal-add-attention.component.scss']
})

  
export class ModalAddAttentionComponent implements OnInit, AfterViewInit {

  idclinchistory: number;
  iddoctor: number;
  idattention: number;
  attention: any;
  idmedical: number;
  private idmedicalSubject = new BehaviorSubject<number | undefined>(undefined);
  idmedical$ = this.idmedicalSubject.asObservable();

  title = 'Agregar atención medica';

  // FORM
  formData: FormGroup;
  formFields: FormFields = new FormFields();
  formInput: MedicalActAttention;

  doctorList: Doctor[] = [];
  coinList: CoinModel[] = [];
  pmList: PaymentMethodModel[] = [];
  blList: BusinessLineModel[] = [];
  loadingBl = false;
  specialtyList: SpecialtyModel[] = [];
  loadingSpecialty = false;
  tariffList: TariffModel[] = [];
  discountTypeList: DiscountType[] = [];
  loadingTariff = false;
  showDiscountType = false;
  archivosCargadosData: any[] = [];
  medicalcontract: MedicalActAttentionContractPaays [] = [];
  coin = 'S/';
  commission = 0;
  rol : number;

  session: any = {};

  minimalUnitValue: number = 0;

  pago = '';

  contractAmount : any;

  advance: PaymentsAdvanceDto = {
    id: 0,
    id_inicial: 0,
    id_final: 0,
    userid_created: 0,
    userid_update: 0,
    created_at: null,
    updated_at: null
  };

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private doctorService: DoctorService,
    private coinService: CoinService,
    private pmService: PaymentMethodService,
    private blService: BusinessLineService,
    private specialtyService: SpecialtyService,
    private tariffService: TariffService,
    private maaService: MedicalActAttentionService,
    private store: Store<{ session: any }>,
    private modalSerive: NgbModal,
    private discountTypeService: DiscountTypeService,
    private cdRef: ChangeDetectorRef,
    private serviceContract:ContractService,
    private servicePaymentsAdvance: PaymentsAdvanceService
  ) { 
    this.medicalcontract = []
  }

  ngOnInit(): void {
    Swal.fire({
      title: 'Atención!!!!',
      text: 'Servicio No Disponible, Comunicarse con TI o Borrar Memoria Caché',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#FF5370',
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.close) {
        this.activeModal.dismiss('Cross click');
      }
    });
    return;
    /*this.getSession();
    this.getDoctors();
    this.getCoins();
    this.getPaymentMethod();
    this.getBl();
    this.getDiscountType();
    this.initForm();*/
  }

  @ViewChild(ClinicHistoryVouchersComponent) vouchers!: ClinicHistoryVouchersComponent;
  ngAfterViewInit() {
    //console.log(this.vouchers.whoAmI()); // I am a pup component!
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.rol = this.session.roles.idrole; 
        }
      });
  }

  getDoctors(): void {
    this.doctorList = [];
    this.doctorService.getAll().subscribe({
      next: (resp) => {
        this.doctorList = resp;
        this.formData.get(this.formFields.Doctor).setValue(this.iddoctor);
      },
      error: (error) => {
        console.log({ error });
      }
    });
  }

  getCoins(): void {
    this.coinList = [];
    this.coinService.getAll()
      .subscribe(
        res => {
          this.coinList = res;
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getPaymentMethod(): void {
    this.pmList = [];
    this.pmService.getAll()
      .subscribe(
        res => {
          this.pmList = res;
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getDiscountType() {
    this.discountTypeList = [];
    this.discountTypeService.get().subscribe({
      next: (resp) => {
        this.discountTypeList = resp;
      },
      error: () => { }
    });
  }

  initForm() {
    this.formData = this.fb.group({});
    const today = this.attention ? this.attention.date : moment().tz('America/Lima').format('YYYY-MM-DD');
    this.formData.addControl(this.formFields.Date, new FormControl(today, [Validators.required]));
    const coin = this.attention ? this.attention.co.id : 1;
    this.formData.addControl(this.formFields.Coin, new FormControl(coin));
    this.formData.addControl(this.formFields.PaymentMethod, new FormControl(this.attention ? this.attention.idpaymentmethod : 1));
    this.formData.addControl(this.formFields.LaboratoryCost, new FormControl(this.attention ? this.attention.lab_cost : 0));
    this.formData.addControl(this.formFields.Doctor, new FormControl(null, [Validators.required]));
    const discount_amount = this.attention ? this.attention.discount_amount : null;
    this.showDiscountType = discount_amount > 0 ? true : false;
    const iddiscounttype = this.attention ? this.attention.iddiscounttype ? this.attention.iddiscounttype.id : null : null;
    this.formData.addControl(this.formFields.IdDiscountType, new FormControl(iddiscounttype));
    const discountType = this.attention ? this.attention.discount_type === 'P' ? true : false : false;
    this.formData.addControl(this.formFields.DiscountType, new FormControl(discountType));
    this.formData.addControl(this.formFields.DiscountAmount, new FormControl(discount_amount));

    let businessLine = null;
    let idSpecialty = null;
    let idTariff = null;
    if (this.attention) {
      const { tariff } = this.attention;
      const { specialty } = tariff;
      const { businessLines } = specialty;
      idSpecialty = specialty.id;
      businessLine = businessLines.id;
      idTariff = tariff.id;
    }

    this.formData.addControl(this.formFields.BusisnessLine, new FormControl(businessLine, [Validators.required]));
    this.formData.addControl(this.formFields.Specialty, new FormControl(idSpecialty, [Validators.required]));
    this.formData.addControl(this.formFields.Tariff, new FormControl(idTariff, [Validators.required]));
    this.formData.addControl(this.formFields.Quantity, new FormControl(this.attention ? this.attention.quantity : 1, [Validators.required, Validators.min(1)]));
    this.formData.addControl(this.formFields.Amount, new FormControl(this.attention ? this.attention.value : 0, [Validators.required, Validators.min(0.01)]));
    this.formData.addControl(this.formFields.Total, new FormControl(0, [Validators.required, Validators.min(0.01)]));

    if (this.attention) {
      this.getSpecialtys();
    }
  }

  manejarArchivosCargados(datos: any[]) {
    this.archivosCargadosData = datos;

    const data = JSON.stringify(this.archivosCargadosData);
    this.maaService.updateMedicalActFiles(this.idmedical,data)
    .subscribe(
      res => {
        this.spinner.hide();
        this.toastr.success('carga de acrhivos exitosa!!', 'Ok!', {
          timeOut: 3000,
        });
      },
      error => {
        this.spinner.hide();
        this.toastr.error('carga de acrhivos errada!!', 'Error!', {
          timeOut: 3000,
        });
      }
    );
  }


  async save(): Promise<void> {
      

      if(this.vouchers.uploadedFiles.length === 0){
        this.vouchers.uploadedFiles.length;
        //this.vouchers.actives
        //pinner.hide();
        this.toastr.warning("Debe seleccionar un archivo","Atención",{timeOut: 3000,progressBar: true});
        return;
      }
      /*
      if(this.vouchers.uploadedFiles.length > 1){
        this.vo.vouchersuchers.spinner.hide();
        this.toastr.warning("Recuerde que solo puede survir un archivo a la vez","Atención",{timeOut: 3000,progressBar: true});
        return;
      }
      */
      /*let tariff = this.formData.get(this.formFields.Tariff).value;
      let idClinic = this.idclinchistory;
      let desTariff = '';

      if(tariff == 202 || tariff == 121){
        desTariff = 'Moldes';
      }else if (tariff == 203 || tariff == 122){
        desTariff = 'Inicial';
      }else if (tariff == 123 || tariff == 204){
        desTariff = 'Cuota';
      }else{
        desTariff = null;
      }

      if (desTariff !=null){
        this.serviceContract.getCountContractsValidate(idClinic).subscribe(
          cantContratos => {
            let contratos = cantContratos[0].contract;
            if (contratos === '0') {
              this.toastr.warning("No hay Contratos validados", "Atención", { timeOut: 3000, progressBar: true });
              this.spinner.hide();
            } else {
              // Continuar con la siguiente verificación solo si contratos no es '0'
              this.checkPaymentsAndProceed(desTariff, idClinic);
            }
          }, error => {
            this.spinner.hide();
            this.toastr.error('Ha ocurrido un error al validar contrato!!', 'Error!', {
              timeOut: 3000,
            });
          }
        );
      }else{*/
      /*
      this.maaService.getQuantityMedical(desTariff, idClinic).subscribe(cant => {
        this.pago = cant[0].quantitymedical;
        console.log('pago', this.pago);
      
        if (this.pago == '1') {
          this.toastr.warning("Ya realizó el pago de " + desTariff, "Atención", { timeOut: 3000, progressBar: true });
          this.spinner.hide();
          this.state = false;
          return;
        }
      
      }, error => {
        this.spinner.hide();
        this.toastr.error('Ha ocurrido un error al identificar pago!!', 'Error!', {
          timeOut: 3000,
        });
      });


  
        console.log('estado',this.state);
    if (this.state == false ){
      return;
    }*/
        
    if (this.formData.invalid) {
      return;
    }
    if (this.formData.get(this.formFields.DiscountAmount).value > 0 && !this.showDiscountType) {
      this.formData.get(this.formFields.IdDiscountType).touched;
      return;
    }
    
    this.spinner.show();
    this.formInput = {
      medicalact: this.idattention,
      businessline: this.formData.get(this.formFields.BusisnessLine).value,
      specialty: this.formData.get(this.formFields.Specialty).value,
      tariff: this.formData.get(this.formFields.Tariff).value,
      quantity: this.formData.get(this.formFields.Quantity).value,
      doctor: this.formData.get(this.formFields.Doctor).value,
      user: this.session.id,
      date: this.formData.get(this.formFields.Date).value,
      patient: this.idclinchistory,
      co: this.formData.get(this.formFields.Coin).value,
      value: this.formData.get(this.formFields.Amount).value,
      lab_cost: this.formData.get(this.formFields.LaboratoryCost).value,
      idpaymentmethod: this.formData.get(this.formFields.PaymentMethod).value,
      commission: this.commission,
      iddiscounttype: this.formData.get(this.formFields.IdDiscountType).value,
      discount_type: this.formData.get(this.formFields.DiscountAmount).value > 0 ?
        (this.formData.get(this.formFields.DiscountType).value ? 'P' : 'A') : null,
      discount_amount: this.formData.get(this.formFields.DiscountAmount).value,
      medicalactfiles: JSON.stringify(this.archivosCargadosData),
      fraction_of_payment: 1
    };

    if(this.formInput.medicalactfiles == null){
      this.toastr.warning("data vacia","Atención",{timeOut: 3000,progressBar: true});
      this.spinner.hide();
      return;
    }
    // validamos que el valor no sea menor al monto seleccionado
    let valorMinimoIngresado = this.formInput.value ;

    /*if( valorMinimoIngresado < this.minimalUnitValue){
      this.toastr.warning("El valor unitario ingresado no puede ser menor al mínimo requerido.","Atención",{timeOut: 3000,progressBar: true});
      this.spinner.hide();
      return;
    }*/
    if(this.minimalUnitValue == 0){
      const tariff = this.formData.get(this.formFields.Tariff).value;
      const valor  = this.tariffList.find(it => it.id === tariff);
      this.minimalUnitValue = valor.price_sol > 0 ? valor.price_sol : valor.price_usd;

    }

    if( valorMinimoIngresado < this.minimalUnitValue){
      this.spinner.hide()
      Swal.fire({
        title: 'Atención!!!!',
        text: 'El valor unitario ingresado es menor al mínimo requerido.',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true
      }).then((willInsert) => {
        if (willInsert.value){
          if (this.attention){
            this.formInput.fraction_of_payment = 0;
            this.maaService.update(this.formInput, this.attention.id)
            .subscribe(
              res => {
   
                this.spinner.hide();
                this.toastr.success('Atención editada correctamente!!', 'Ok!', {
                  timeOut: 3000,
                });
                this.activeModal.close('Save click');
                this.idmedical = this.attention.id;
                this.vouchers.onSubmit(this.idmedical);
              },
              error => {
                this.spinner.hide();
                this.toastr.error('Ha courrido un error al editar la atención!!', 'Error!', {
                  timeOut: 3000,
                });
              }
            );
          }else{
            /*if(this.formInput.tariff == 123 || this.formInput.tariff == 122 || this.formInput.tariff == 121){
              this.formInput.fraction_of_payment = 1;
            }
            else{
              this.formInput.fraction_of_payment = 0;
            }*/
           
            this.maaService.insert(this.formInput)
              .subscribe(
                res => {
           
                  this.spinner.hide();
                  Swal.fire('ok!', 'Atención por adelanto agregada correctamente!!', 'success');
                  this.activeModal.close('Save click');
                  const nuevoRegistro = res; // Asumiendo que res contiene la información necesaria
                  this.idmedical = nuevoRegistro.id;
                  
                  /*if(this.formInput.tariff == 123 || this.formInput.tariff == 122 || this.formInput.tariff == 121){
                    
                    this.medicalcontract = []
                    const contract = {
                      idcontractdetail: this.contractAmount.id,
                      idmedicalactattention: this.idmedical,
                      userid_created: this.session.id,
                      update_at: null,
                      created_at: new Date()
                    };

                    this.medicalcontract.push(contract);*/

                    /*this.maaService.saveMedicalContract(this.medicalcontract).subscribe(
                      res =>{
                        this.spinner.hide();
                        this.toastr.success('Cuota Cancelada!!', 'Ok!', {
                          timeOut: 3000,
                        });
                      }
                    )
                  }else{*/
                    
  
                    this.advance.id_inicial = nuevoRegistro.id;
                    this.advance.userid_created = this.session.id;
                    this.advance.created_at = new Date(Date.now());
    
                    this.servicePaymentsAdvance.savePaymentsContract(this.advance)
                    .subscribe(
                      res =>{
                        this.spinner.hide();
                        this.toastr.success('okk', 'Ok!', {
                          timeOut: 3000,
                        });
                      },
                      error => {
                        this.spinner.hide();
                        this.toastr.error('Ha courrido un error lklklkkl la atención!!', 'Error!', {
                          timeOut: 3000,
                        });
                      }
                    )
                  //}
                  this.vouchers.onSubmit(this.idmedical)
                },
                err => {
                  Swal.fire('Error!', 'No se pudo atención Medica por Adelanto', 'error');
                }
              )
          
          }
                  
          }
      });
    }else if(valorMinimoIngresado > this.minimalUnitValue){
      this.spinner.hide()
      Swal.fire({
        title: 'Atención!!!!',
        text: 'El valor unitario ingresado es mayor al requerido.',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true
      }).then((willInsert) => {
        if (willInsert.value){
          if (this.attention){
            this.formInput.fraction_of_payment = 2;
            this.maaService.update(this.formInput, this.attention.id)
            .subscribe(
              res => {

                this.spinner.hide();
                this.toastr.success('Atención editada correctamente!!', 'Ok!', {
                  timeOut: 3000,
                });
                this.activeModal.close('Save click');
                this.idmedical = this.attention.id;
                this.vouchers.onSubmit(this.idmedical);
              },
              error => {
                this.spinner.hide();
                this.toastr.error('Ha courrido un error al editar la atención!!', 'Error!', {
                  timeOut: 3000,
                });
              }
            );
          }else{
            this.formInput.fraction_of_payment = 2;
            this.maaService.insert(this.formInput)
              .subscribe(
                res => {
                  this.spinner.hide();
                  Swal.fire('ok!', 'Atención por adelanto agregada correctamente!!', 'success');
                  this.activeModal.close('Save click');
                  const nuevoRegistro = res; // Asumiendo que res contiene la información necesaria
              
                  this.idmedical = nuevoRegistro.id;
  
                  this.vouchers.onSubmit(this.idmedical)
                },
                err => {
                  Swal.fire('Error!', 'No se pudo atención Medica por Adelanto', 'error');
                }
              )
          }
          }
      });
    }
    else{
    
    //console.log( this.formInput );
    //return;

    if (this.attention) {
      this.maaService.update(this.formInput, this.attention.id)
        .subscribe(
          res => {

            this.spinner.hide();
            this.toastr.success('Atención editada correctamente!!', 'Ok!', {
              timeOut: 3000,
            });
            this.activeModal.close('Save click');
            this.idmedical = this.attention.id;
            this.vouchers.onSubmit(this.idmedical);
          },
          error => {
            this.spinner.hide();
            this.toastr.error('Ha courrido un error al editar la atención!!', 'Error!', {
              timeOut: 3000,
            });
          }
        );
    } else {
      this.maaService.insert(this.formInput)
        .subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success('Atención agregada correctamente!!', 'Ok!', {
              timeOut: 3000,
            });
            this.activeModal.close('Save click');
            //Enviar adjuntos... recuperar ID...
            // Aquí manejas la respuesta con el ID o el registro completo
            const nuevoRegistro = res; // Asumiendo que res contiene la información necesaria
            this.idmedical = nuevoRegistro.id;

            // Aquí podrías llamar a una función para manejar los adjuntos, por ejemplo
            // this.handleAttachments(nuevoRegistro.id);
            /*if(this.formInput.tariff == 121){
                    
              this.medicalcontract = []
              const contract = {
                idcontractdetail: this.contractAmount.id,
                idmedicalactattention: this.idmedical,
                userid_created: this.session.id,
                update_at: null,
                created_at: new Date()
              };

              this.medicalcontract.push(contract);

              this.maaService.saveMedicalContract(this.medicalcontract).subscribe(
                res =>{
                  this.spinner.hide();
                  this.toastr.success('Moldes Cancelada!!', 'Ok!', {
                    timeOut: 3000,
                  });
                }
              )
            }*/
            
            this.vouchers.onSubmit(this.idmedical);

            
          },
          error => {
            this.spinner.hide();
            this.toastr.error('Ha courrido un error al registrar la atención!!', 'Error!', {
              timeOut: 3000,
            });
          }
        );
    }
  }
  }

  checkPaymentsAndProceed(desTariff, idClinic) {
    if(desTariff == 'Moldes' || desTariff == 'Inicial'){
    this.maaService.getQuantityMedical(desTariff, idClinic).subscribe(cant => {
      this.pago = cant[0].quantitymedical;
      if (this.pago === '1') {
        this.toastr.warning("Ya realizó el pago de " + desTariff, "Atención", { timeOut: 3000, progressBar: true });
        this.spinner.hide();
      } else {
          this.insert(desTariff, idClinic);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error('Ha ocurrido un error al identificar pago!!', 'Error!', {
        timeOut: 3000,
      });
    });
    }else{
      this.serviceContract.getQuotasPaysAll(idClinic).subscribe(cant =>{
        if (cant >0 ){
          this.insert(desTariff, idClinic);
        }else{
          this.toastr.warning("No hay más cuotas pendientes por pagar ", "Atención", { timeOut: 3000, progressBar: true });
          this.spinner.hide();
        }
      })
    }
  }


  async insert (desTariff,idClinic):Promise<void>{
    if (this.formData.invalid) {
      return;
    }
    if (this.formData.get(this.formFields.DiscountAmount).value > 0 && !this.showDiscountType) {
      this.formData.get(this.formFields.IdDiscountType).touched;
      return;
    }
    
    this.spinner.show();
    this.formInput = {
      medicalact: this.idattention,
      businessline: this.formData.get(this.formFields.BusisnessLine).value,
      specialty: this.formData.get(this.formFields.Specialty).value,
      tariff: this.formData.get(this.formFields.Tariff).value,
      quantity: this.formData.get(this.formFields.Quantity).value,
      doctor: this.formData.get(this.formFields.Doctor).value,
      user: this.session.id,
      date: this.formData.get(this.formFields.Date).value,
      patient: this.idclinchistory,
      co: this.formData.get(this.formFields.Coin).value,
      value: this.formData.get(this.formFields.Amount).value,
      lab_cost: this.formData.get(this.formFields.LaboratoryCost).value,
      idpaymentmethod: this.formData.get(this.formFields.PaymentMethod).value,
      commission: this.commission,
      iddiscounttype: this.formData.get(this.formFields.IdDiscountType).value,
      discount_type: this.formData.get(this.formFields.DiscountAmount).value > 0 ?
        (this.formData.get(this.formFields.DiscountType).value ? 'P' : 'A') : null,
      discount_amount: this.formData.get(this.formFields.DiscountAmount).value,
      medicalactfiles: JSON.stringify(this.archivosCargadosData)
    };


    if(this.formInput.medicalactfiles == null){
      this.toastr.warning("data vacia","Atención",{timeOut: 3000,progressBar: true});
      this.spinner.hide();
      return;
    }
    // validamos que el valor no sea menor al monto seleccionado
    let valorMinimoIngresado = this.formInput.value ;

    if( valorMinimoIngresado < this.minimalUnitValue){
      this.toastr.warning("El valor unitario ingresado no puede ser menor al mínimo requerido.","Atención",{timeOut: 3000,progressBar: true});
      this.spinner.hide();
      return;
    }
    
    //console.log( this.formInput );
    //return;

    if (this.attention) {

      this.maaService.update(this.formInput, this.attention.id)
        .subscribe(
          res => {

            this.spinner.hide();
            this.toastr.success('Atención editada correctamente!!', 'Ok!', {
              timeOut: 3000,
            });
            this.activeModal.close('Save click');
            this.idmedical = this.attention.id;
            this.vouchers.onSubmit(this.idmedical);
          },
          error => {
            this.spinner.hide();
            this.toastr.error('Ha courrido un error al editar la atención!!', 'Error!', {
              timeOut: 3000,
            });
          }
        );
    } else {
      this.maaService.insert(this.formInput)
        .subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success('Atención agregada correctamente!!', 'Ok!', {
              timeOut: 3000,
            });
            this.activeModal.close('Save click');
            this.saveMedicalContract(desTariff,idClinic,this.idmedical)
            //Enviar adjuntos... recuperar ID...
            // Aquí manejas la respuesta con el ID o el registro completo
            const nuevoRegistro = res; // Asumiendo que res contiene la información necesaria
            
            this.idmedical = nuevoRegistro.id;

            // Aquí podrías llamar a una función para manejar los adjuntos, por ejemplo
            // this.handleAttachments(nuevoRegistro.id);

            this.vouchers.onSubmit(this.idmedical);
       

            
          },
          error => {
            this.spinner.hide();
            this.toastr.error('Ha courrido un error al registrar la atención!!', 'Error!', {
              timeOut: 3000,
            });
          }
        );
    }
  }

  saveMedicalContract(idClinic:number,descripccion:string,idMedical:number):void {
    try{
      this.serviceContract.getIdContractDetailByIdClinic(idClinic,descripccion,).subscribe(
        res =>{
          const idCuotaDetalle = res[0].min;
          var day = new Date();
          this.medicalcontract[0].idcontractdetail = parseFloat(idCuotaDetalle);
          this.medicalcontract[0].idmedicalactattention= this.idmedical;
          this.medicalcontract[0].userid_created = this.session.id;
          this.medicalcontract[0].created_at = day;


          this.maaService.saveMedicalContract(this.medicalcontract).subscribe(
            rs=>{
              this.spinner.hide();
              this.toastr.success('Registro de pago del contrato  correctamente!!', 'Ok!', {
              timeOut: 3000,
            });
            },
            error => {
              this.spinner.hide();
              this.toastr.error('Ha courrido un error al registrar la atención!!', 'Error!', {
                timeOut: 3000,
              });
            }
          )
      })
    }catch (err) {
      this.spinner.hide();
      this.toastr.error("Ocurrió un error al cargar los archivos", "Error!!", { timeOut: 3000, progressBar: true });
        }
  }
  
  setComision(): void {
    const comi = this.pmList.filter(el => el.id === this.formData.get(this.formFields.PaymentMethod).value);
    this.commission = comi[0].commission;
  }

  setMonto(opc: number):void {
   
    /*let desTariff = '';
    let tariffselected = this.formData.get(this.formFields.Tariff).value;

    if(tariffselected == 202 || tariffselected == 121){
      desTariff = 'Moldes';
    }else if (tariffselected == 203 || tariffselected == 122){
      desTariff = 'Inicial';
    }else if (tariffselected == 123 || tariffselected == 204){
      desTariff = 'Cuota';
    }else{
      desTariff = null;
    }
    
    if (desTariff !== null){
      let q = this.serviceContract.getpendientToPay(this.idattention,desTariff).subscribe(
        res =>{
;
          this.contractAmount = res;

          this.showDiscountType = false;
          this.formData.get(this.formFields.IdDiscountType).clearValidators();
          const discount_type = this.formData.get(this.formFields.DiscountType).value;
          const discount_amount = this.formData.get(this.formFields.DiscountAmount).value;
          this.formData.get(this.formFields.Amount).setValue(this.contractAmount.amount);
          
          this.coin = this.contractAmount.amount > 0 ? 'S/' : '$';
          let total = 0;
          total = Number((this.formData.get(this.formFields.Quantity).value * this.formData.get(this.formFields.Amount).value));

          if (discount_type && discount_amount > 0) {
            this.showDiscountType = true;
            this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
            total = total - Number(((total * discount_amount) / 100));
          } else if (discount_amount > 0) {
            this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
            this.showDiscountType = true;
            total -= discount_amount;
          }
          this.formData.get(this.formFields.Total).setValue(total.toFixed(2));
        }
      );
    }else{
      this.showDiscountType = false;
      this.formData.get(this.formFields.IdDiscountType).clearValidators();
      const tariff = this.formData.get(this.formFields.Tariff).value;
      const valor = this.tariffList.find(it => it.id === tariff);
      const discount_type = this.formData.get(this.formFields.DiscountType).value;
      const discount_amount = this.formData.get(this.formFields.DiscountAmount).value;
      if (this.formData.get(this.formFields.Amount).value <= 0 || opc === 1) {
        this.minimalUnitValue = valor.price_sol > 0 ? valor.price_sol : valor.price_usd;
        this.formData.get(this.formFields.Amount).setValue(this.minimalUnitValue);
      }
      this.coin = valor.price_sol > 0 ? 'S/' : '$';
      let total = 0;
      total = Number((this.formData.get(this.formFields.Quantity).value * this.formData.get(this.formFields.Amount).value));
      if (discount_type && discount_amount > 0) {
        this.showDiscountType = true;
        this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
        total = total - Number(((total * discount_amount) / 100));
      } else if (discount_amount > 0) {
        this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
        this.showDiscountType = true;
        total -= discount_amount;
      }
      this.formData.get(this.formFields.Total).setValue(total.toFixed(2));
    }*/

    this.showDiscountType = false;
    this.formData.get(this.formFields.IdDiscountType).clearValidators();
    const tariff = this.formData.get(this.formFields.Tariff).value;
    const valor = this.tariffList.find(it => it.id === tariff);
    const discount_type = this.formData.get(this.formFields.DiscountType).value;
    const discount_amount = this.formData.get(this.formFields.DiscountAmount).value;
    if (this.formData.get(this.formFields.Amount).value <= 0 || opc === 1) {
      this.minimalUnitValue = valor.price_sol > 0 ? valor.price_sol : valor.price_usd;
      this.formData.get(this.formFields.Amount).setValue(this.minimalUnitValue);
    }
    this.coin = valor.price_sol > 0 ? 'S/' : '$';
    let total = 0;
    total = Number((this.formData.get(this.formFields.Quantity).value * this.formData.get(this.formFields.Amount).value));
    if (discount_type && discount_amount > 0) {
      this.showDiscountType = true;
      this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
      total = total - Number(((total * discount_amount) / 100));
    } else if (discount_amount > 0) {
      this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
      this.showDiscountType = true;
      total -= discount_amount;
    }
    this.formData.get(this.formFields.Total).setValue(total.toFixed(2));

  }

  /**
   * List of Business linea <Linea de negocio>
   */
  getBl(): void {
    this.blList = [];
    this.loadingBl = true;
    this.blService.getAll()
      .subscribe(
        res => {
          this.loadingBl = false;
          this.blList = res;
        },
        err => {
          this.loadingBl = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of Specialtys <Especialidades>
   */
  getSpecialtys(): void {
    this.formData.get(this.formFields.Specialty).setValue(null);
    this.specialtyList = [];
    this.loadingSpecialty = true;
    this.specialtyService.getByBusinessLine(this.formData.get(this.formFields.BusisnessLine).value)
      .subscribe(
        res => {
          this.specialtyList = res;
          this.loadingSpecialty = false;
          if (this.attention) {
            this.formData.get(this.formFields.Specialty).setValue(this.attention.tariff.specialty.id);
            this.getTariff();
          }
        },
        err => {
          this.loadingSpecialty = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of tariff <Tarifario o tratamiento>
   */
  getTariff(): void {
    this.loadingTariff = true;
    this.formData.get(this.formFields.Tariff).setValue(null);
    this.tariffList = [];
    this.tariffService.getBySpecialty(this.formData.get(this.formFields.Specialty).value)
      .subscribe(
        res => {
          this.tariffList = res;
          this.loadingTariff = false;
          if (this.attention) {
            this.formData.get(this.formFields.Tariff).setValue(this.attention.tariff.id);
            this.setMonto(2);
          }
        },
        err => {
          this.loadingTariff = false;
          console.log(err.error);
        }
      );
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required')) {
      return true;
    }
    return false;
  }

  openModalAutorization(): void {
    const modal = this.modalSerive.open(ModalAutorizationComponent, { size: 'sm', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        // this.get(iddoctor, doctor);
      } else {
        this.activeModal.close('Close click');
      }
    });
    modal.componentInstance.message = 'Para editar la atención es necesario que sea autorizado por un usuario con perfil ADMIN!';
    modal.componentInstance.data = this.attention;
    modal.componentInstance.module = 'medical-act-attention';
  }

}
