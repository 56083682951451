<div class="modal-header modal_header">
    <h5 class="modal-title titulo-modal">Seleccione el pago de la cita</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="close()"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body"> 
        <div class="col-sm-12" >
            <button class="btn btn-primary btn-lg btn-block" (click)="confirmPayments()">Asociar pago</button>
        </div>     
            <div class="col-sm-12" >
                    <div class="dt-responsive table-responsive">
                        <table 
                            class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th class="w50">Nro.</th>
                                    <th>Fecha de O.S</th>
                                    <th class="w50">Concepto de pago</th>
                                    <th class="w50">Cantidad</th>
                                    <th>SubTotal</th>
                                    <th>Total</th>
                                    <th>Boleta</th>
                                    <th>Fecha de Boleta</th>
                                    <th class="w50">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reservations of reservation">
                                    <td>{{reservations.maat_id}}</td>
                                    <td>{{ reservations.maat_date | date:'dd/MM/yyyy' }}</td>
                                    <td>{{reservations.nombre_tariff}}</td>
                                    <td>{{reservations.maat_quantity}}</td>
                                    <td>{{ reservations.coId === 1 ? 'S/. ' : 'dollar '}} {{reservations.maat_value}}</td>
                                    <td>{{ reservations.coId === 1 ? 'S/. ' : 'dollar '}} {{ reservations.maat_quantity * reservations.maat_value }}</td>
                                    <td>{{reservations.maat_document_number }}</td>
                                    <td>{{reservations.maat_document_date  | date:'dd/MM/yyyy' }}</td>
                                    <td>
                                        <input type="checkbox" name="estado" [value]="reservations.maat_id" id="checkbox" (change)="onCheckboxChange($event,reservations.coId)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="close()">
        Salir
    </button>
</div>