import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServiceOrderNew, ServiceOrderNewResponse, ServiceOrderPaymentDetailFiles, ServiceOrderPaymentDetailNew } from '../models/finance/service-order-new.model';
import { Observable } from 'rxjs';
import { ServiceOrderDetailInvoice, ServiceOrderOneDetail } from '../models/finance/service-order-one-detail.model';
import { ServiceOrder } from '../models/finance/service-order.model';
import { MedicalActAttention, MedicalActAttentionOrder } from '../models/main/medical-act.model';
import { NumberFormatStyle } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderV2Service {

  private baseUrl: string = `${environment.apiUrl}`;

  constructor(
    private http:HttpClient
  ) { }

  update_invoice(id_document:number, cantidad: number, idserviceOrder: number):Observable<any>{
    console.log("Este es el documento desde el servicio: ",id_document);
    return this.http.get<any>(`${environment.apiphp}/withApdateInvoice.php?id_invoice=${id_document}&cantidad=${cantidad}&idServiceOrder=${idserviceOrder}`)
  }

  consulta_datos_numeracion(typeincoice:number):Observable<any>{
    return this.http.get<any>(`${environment.apiphp}/withNewInvoice.php?typeinvoice=${typeincoice}`)
  }

  consulta_datos_cliente(id_service_order:number):Observable<any>{
    return this.http.get<any>(`${environment.apiphp}/withpatient.php?idServiceOrder=${id_service_order}`)
  }
  createServiceOrderByContract(data:any):Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/service-order-v2/createServiceOrderByContract`,data)
  }

  deleteServiceOrder(id:number):Observable<any>{
    return this.http.delete<any>(`${this.baseUrl}/service-order-v2/deleteServiceOrder/${id}`)
  }
  
  updateStateOrdenService(item:any[]):Observable<any>{
    return this.http.put<any>(`${this.baseUrl}/service-order-v2/updateStateOrdenService`,item)
  }

  updateOrdenServiceDetail(item:any[],id:NumberFormatStyle):Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/service-order-v2/updateOrdenServiceDetail/${id}`,item)
  }

  updateServiceOrderByDoctor(id:number,doctor:number):Observable<any>{
    const body = { id: id, doctor: doctor };

    return this.http.put<any>(`${this.baseUrl}/service-order-v2/updateServiceOrderByDoctor/${id}`,body);
  }

  getServiceOrderByPatient(id:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/service-order-v2/getServiceOrderByPatient/${id}`);
  }
  createNewServiceOrder(data:MedicalActAttention[]):Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/service-order-v2/NewServiceOrder`,data);
  }

  serviceOrderBillePaymentFiles(data:ServiceOrderDetailInvoice[]):Observable<ServiceOrderDetailInvoice[]>{
    return this.http.post<ServiceOrderDetailInvoice[]>(`${this.baseUrl}/service-order-v2/serviceOrderBillePaymentFiles`,data);
  }

  createServiceOrderPaymentDetail(serviceDetail: ServiceOrderPaymentDetailNew[]): Observable<ServiceOrderPaymentDetailNew[]>{
    return this.http.post<ServiceOrderPaymentDetailNew[]>(`${this.baseUrl}/service-order-v2/createServiceOrderPaymentDetail`,serviceDetail);
  }

  createServiceOrder(service_order_create:any): Observable<ServiceOrderNew>{
    return this.http.post<ServiceOrderNewResponse>(`${this.baseUrl}/service-order-v2/createServiceOrder`,service_order_create)
  }

  createServiceOrderPaymentDetailFiles(service:ServiceOrderPaymentDetailFiles[]):Observable<ServiceOrderPaymentDetailFiles>{
    return this.http.post<ServiceOrderPaymentDetailFiles>(`${this.baseUrl}/service-order-v2/createServiceOrderPaymentDetailFiles`,service);
  }

  getFindAll(idpatient:number,filters : any):Observable<any[]>{
    return this.http.post<any[]>(`${this.baseUrl}/service-order-v2/byFilters/${idpatient}`,filters)
  }

  getOneOrdenService(id:number):Observable <ServiceOrderOneDetail[]>{
    return this.http.get<ServiceOrderOneDetail[]>(`${this.baseUrl}/service-order-v2/getOneOrdenService/${id}`);
  }

  updateServiceOrder(id:number, data: ServiceOrderNew):Observable<ServiceOrderNew>{
    return this.http.put<ServiceOrderNew>(`${this.baseUrl}/service-order-v2/updateServiceOrder/${id}`,data);
  }

  createServiceOrderBilledPayment(data:ServiceOrderDetailInvoice[]):Observable<ServiceOrderDetailInvoice[]>{
    return this.http.post<ServiceOrderDetailInvoice[]>(`${this.baseUrl}/service-order-v2/serviceorderbilledpayment`,data);
  }
  obtenerTextoAnotaciones(): string {
    return "Renderizado 1";
  }

  obtenerTextoCotizaciones(): string {
    return "Renderizado 2";
  }

  obtenerTextoReservaciones(): string {
    return "Renderizado 3";
  }

  getAllAnnotations(idpatient:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/clinic-history-notes/get-patient-notes/${idpatient}`)
  }

  getAllQuotations(idpatient:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/quotation/get-by-clinic-history/${idpatient}`)
  }
  getAllQuotationsDetail(idquote:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/quotation/number/${idquote}`)
  }

  getAllReservation(idpatient:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/reservation/get-by-clinic-history/${idpatient}`)
  }

  getAllReservationValidator(idreservation:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/union_payment_service_order/view_detail_by_controls/${idreservation}`)
  }
}
