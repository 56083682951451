<app-card cardTitle="Subir Archivos" [options]="false">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
        <li [ngbNavItem]="1">
            <a ngbNavLink (click)="this.getFiles()">
                <i class="fas fa-file-medical"></i> Nuevo Archivo
            </a>
            <ng-template ngbNavContent>    
                <hr>
                <div class="row">
                    <div class="col-12">
                        <form class="was-validated" (ngSubmit)="onSubmit()" ngNativeValidate>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="form-control-label" for="name">Grupo <small class="text-danger">(*)</small></label>
                                        <select name="group" [(ngModel)]="group" class="form-control custom-select" required>
                                            <option value="">Seleccione</option>
                                            <option *ngFor="let item of listGroup" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="form-group">
                                        <label class="form-control-label" for="name">Descripción <small class="text-danger">(*)</small></label>
                                        <input name="description" [(ngModel)]="description" class="form-control form-control-sm"
                                        placeholder="Descripción del archivo" maxlength="250" required>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <file-upload [(ngModel)]="uploadedFiles" name="files" fileslimit="1"
                                    ></file-upload>
                                    <small class="text-secondary">Nota: solo subir un archivo a la vez</small>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary btn-lg btn-block">
                                        <i class="fas fa fa-save"></i> Subir
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12" *ngIf="this.listFiles.length > 0">
                        <app-card cardTitle="Archivos Actuales" [options]="false">
                            <table class="table table-sm">
                                <thead>
                                    <th class="w100">Fecha</th>
                                    <th>Grupo</th>
                                    <th>Descripción</th>
                                    <th class="w50">Tipo</th>
                                    <th class="w50">Acción</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of listFiles">
                                        <td>{{item.createdAt | date:"dd-MM-yyyy hh:mm"}}</td>
                                        <td>{{item.filegroup.name}}</td>
                                        <td>{{item.desciption}}</td>
                                        <td>{{item.file_ext}}</td>
                                        <td>
                                            <a [href]="urlDownload+'/'+item.fila_name" target="blank" class="btn btn-outline-primary btn-icon btn-sm"
                                            ngbPopover="Descargar" triggers="mouseenter:mouseleave">
                                                <i class="fas fa-download"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </app-card>
                    </div>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink (click)="this.getFilesClinicHistory()">
              <i class="fas fa-file-archive"></i> Historial de Archivos
            </a>
            <ng-template ngbNavContent>
                <hr>
                <p *ngIf="this.listFileClinicHistory.length === 0">
                    <ngb-alert [dismissible]="false" [type]="'primary'">
                      <strong><i class="fas fa-info-circle"></i></strong>
                      No hay archivos registrados
                    </ngb-alert>
                </p>
                <div *ngIf="this.listFileClinicHistory.length > 0">
                    <table class="table table-sm">
                        <thead>
                            <th class="w100">Fecha</th>
                            <th>Grupo</th>
                            <th>Descripción</th>
                            <th class="w50">Tipo</th>
                            <th class="w50">Acción</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of listFileClinicHistory">
                                <td>{{item.createdAt | date:"dd-MM-yyyy hh:mm"}}</td>
                                <td>{{item.filegroup.name}}</td>
                                <td>{{item.desciption}}</td>
                                <td>{{item.file_ext}}</td>
                                <td>
                                    <a [href]="urlDownload+'/'+item.fila_name" target="blank" class="btn btn-outline-primary btn-icon btn-sm"
                                    ngbPopover="Descargar" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</app-card>