<app-card [customHeader]="true" [options]="false">
    <div class="app-card-header">
        <h5 class="mb-1">Datos de la Anamnesis</h5>
        <hr>
    </div>
    <div class="row">
        <div class="col-md-12">
            <form class="was-validated" (ngSubmit)="onSubmit()" ngNativeValidate>
                <div class="row">
                    <div class="col-md-12">
                        <h5>En caso de emergencia dar los siguientes datos</h5>
                        <hr>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label class="form-control-label" for="emergency_contact_name">Contactar a</label>
                            <input name="emergency_contact_name" class="form-control form-control-sm"
                            placeholder="Nombre persona contacto" [(ngModel)]="this.formInputs.emergency_contact_name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-control-label" for="emergency_contact_cellphone">Nro. teléfono</label>
                            <input name="emergency_contact_cellphone" class="form-control form-control-sm"
                            placeholder="Número de teléfono contacto" [(ngModel)]="this.formInputs.emergency_contact_cellphone">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h5>Antecedentes Médicos</h5>
                        <hr>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="checkbox checkbox-fill d-inline">
                                <input type="checkbox" name="medicine" id="medicine" [(ngModel)]="this.formInputs.medicine">
                                <label for="medicine" class="cr">Está tomando algún tipo de medicamento</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="medicine_name">Nombre del medicamento</label>
                            <input type="text" name="medicine_name" class="form-control form-control-sm"
                            [(ngModel)]="this.formInputs.medicine_name">
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label class="form-control-label" for="medic_name">Nombre de su médico</label>
                            <input name="medic_name" class="form-control form-control-sm"
                            placeholder="Nombre médico" [(ngModel)]="this.formInputs.medic_name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-control-label" for="medic_cellphone">Nro. teléfono</label>
                            <input name="medic_cellphone" class="form-control form-control-sm"
                            placeholder="Número de teléfono médico" [(ngModel)]="this.formInputs.medic_cellphone">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mt-4">
                            <div class="checkbox checkbox-fill d-inline">
                                <input type="checkbox" name="hepatitis" id="hepatitis" [(ngModel)]="this.formInputs.hepatitis">
                                <label for="hepatitis" class="cr">Hepatitis</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="form-control-label" for="assistant">Tipo de hepatitis</label>
                            <select name="hepatitis_type" class="custom-select" [(ngModel)]="this.formInputs.hepatitis_type">
                                <option value="">Seleccione</option>
                                <option value="Hepatitis A">Hepatitis A</option>
                                <option value="Hepatitis B">Hepatitis B</option>
                                <option value="Hepatitis C">Hepatitis C</option>
                                <option value="Hepatitis D">Hepatitis D</option>
                                <option value="Hepatitis E">Hepatitis E</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mt-4">
                            <div class="checkbox checkbox-fill d-inline">
                                <input type="checkbox" name="diabetes" id="diabetes" [(ngModel)]="this.formInputs.diabetes">
                                <label for="diabetes" class="cr">Diabetes</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mt-4">
                            <div class="checkbox checkbox-fill d-inline">
                                <input type="checkbox" name="compensated" id="compensated" [(ngModel)]="this.formInputs.compensated">
                                <label for="compensated" class="cr">Está compensando</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mt-4">
                            <div class="checkbox checkbox-fill d-inline">
                                <input type="checkbox" name="high_pressure" id="high_pressure" [(ngModel)]="this.formInputs.high_pressure">
                                <label for="high_pressure" class="cr">Sufre de presión alta</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label" for="color">
                                Sufre de alguna enfermedad que ponga en riesgo su vida o sea de vital importancia para nuestro conocimiento
                            </label>
                            <textarea name="suffers_illness" class="form-control" cols="2" placeholder="Digite la enfermedad"
                            [(ngModel)]="this.formInputs.suffers_illness"></textarea>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                                <label class="form-control-label" for="ci10">CI 10</label>
                                    <ng-select [items]="listCi10" bindLabel="code" bindValue="id" placeholder="Seleccione"
                                    name="ci10" [(ngModel)]="this.formInputs.ci10" >
                                </ng-select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"> 
                                <label class="form-control-label" for="diagrama">Diagrama</label>
                                    <ng-select [items]="listDiagrama" bindLabel="value" bindValue="id" placeholder="Seleccione"
                                    name="diagrama" [(ngModel)]="this.formInputs.diagrama" >
                                    </ng-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h5>Antecedentes Odontológicos</h5>
                        <hr>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="visit_frequency">Frecuencia con que visita al dentista</label>
                            <input type="text" name="visit_frequency" class="form-control form-control-sm" [(ngModel)]="this.formInputs.visit_frequency">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="extracted_molars">Le han extraido muelas</label>
                            <input type="text" name="extracted_molars" class="form-control form-control-sm" [(ngModel)]="this.formInputs.extracted_molars">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="complication_anesthesia">Presentó alguna complicación a la anestesia</label>
                            <input type="text" name="complication_anesthesia" class="form-control form-control-sm" [(ngModel)]="this.formInputs.complication_anesthesia">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group mt-4">
                            <div class="checkbox checkbox-fill d-inline">
                                <input type="checkbox" name="gums_bleed" id="gums_bleed" [(ngModel)]="this.formInputs.gums_bleed">
                                <label for="gums_bleed" class="cr">Le sangran las encías</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label class="form-control-label" for="last_prophylaxis">Última profilaxia</label>
                            <input type="date" name="last_prophylaxis" class="form-control form-control-sm" [(ngModel)]="this.formInputs.last_prophylaxis">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label" for="color">
                                Experiencias dentales traumáticas, describa
                            </label>
                            <textarea name="traumatic_experiences" class="form-control" cols="2" placeholder="Digite la experiencia"
                            [(ngModel)]="this.formInputs.traumatic_experiences"></textarea>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="popping">Siente chasquidos y ruidos al masticar o abrir la boca</label>
                            <input type="text" name="popping" class="form-control form-control-sm" [(ngModel)]="this.formInputs.popping">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-control-label" for="satisfied_aesthetic">Está satisfecho con su estética dental</label>
                            <input type="text" name="satisfied_aesthetic" class="form-control form-control-sm" [(ngModel)]="this.formInputs.satisfied_aesthetic">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label class="form-control-label" for="last_date">Última atención</label>
                            <input type="date" name="last_date" class="form-control form-control-sm" [(ngModel)]="this.formInputs.last_date">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label" for="observation">
                                Observaciones / Comentarios
                            </label>
                            <textarea name="observation" class="form-control" cols="3" placeholder="Digite las observaciones y/o los comentarios"
                            [(ngModel)]="this.formInputs.observation"></textarea>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary btn-lg btn-block">
                    <i class="fas fa fa-save"></i> Registrar
                </button>
            </form>
        </div>
    </div>
</app-card>