import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DiscountTypeEndpoints } from '../../config/discount-type-endpoint.config';
import { DiscountTypeDto } from '../../dto/discount-type.dto';

@Injectable({
    providedIn: 'root'
})
export class DiscountTypeDataService {

    constructor(
        private http: HttpClient,
        private endpoints: DiscountTypeEndpoints
    ) { }

    get(): Observable<DiscountTypeDto[]> {
        return this.http.get<DiscountTypeDto[]>(this.endpoints.get());
    }

    getById(id: number): Observable<DiscountTypeDto> {
        return this.http.get<DiscountTypeDto>(this.endpoints.getById(id));
    }


}