<div class="modal-header modal-header-default modal_header">
    <h5 class="modal-title titulo-modal">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Close click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div class="alert alert-warning" role="alert">
        {{message}}
    </div>
    <form class="editForm" *ngIf="formData" [formGroup]="formData">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="form-control-label" for="password">Password <small
                            class="text-danger">(*)</small></label>
                    <input class="form-control input-md" [id]="'password'" [name]="'password'" type="password"
                        [ngClass]="{'is-invalid': getErrorMessage('password')}" [formControlName]="'password'"
                        (keydown.enter)="save()">
                    <label *ngIf="getErrorMessage('password')" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Debe colocar el password
                    </label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" uiSref="work" class="btn btn-outline-primary" [disabled]="formData.pristine" (click)="save()"
        type="button">
        Autorizar
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Salir
    </button>
</div>