import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FileGroupModel } from 'src/app/models/mat/files-group.model';
import { MedialAttentionService } from 'src/app/service/main/medial-attention.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  title = '';
  data: any;

  public uploadedFiles: File[] = [];
  listGroup: FileGroupModel[] = [];
  group: number = 5;
  description: string = '';

  public processing = false;

  constructor(
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private _medicalService: MedialAttentionService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.getListFileGroup();
  }

  getListFileGroup() {
    this.listGroup = [];
    this._medicalService.getFileGroup().subscribe(
      res => {
        res.forEach((it: FileGroupModel) => {
          this.listGroup.push(it);
        });
      },
      err => {
        console.error("Error al obtener los datos de los grupos");
      }
    );
  }

  onSubmit() {
    this.spinner.show();
    if (this.uploadedFiles.length === 0) {
      this.spinner.hide();
      this.toastr.warning("Debe seleccionar un archivo", "Atención", { timeOut: 3000, progressBar: true });
      return;
    }
    if (this.uploadedFiles.length > 1) {
      this.spinner.hide();
      this.toastr.warning("Recuerde que solo puede survir un archivo a la vez", "Atención", { timeOut: 3000, progressBar: true });
      return;
    }
    const idmedicalact = this.data.idclinichistory;
    this._medicalService.uploadFile(this.group, idmedicalact, this.uploadedFiles[0], this.description,
      this.data.origin, this.data.id).subscribe(
        res => {
          this.spinner.hide();
          this.toastr.success("Archivo cargado correctamente", "OK!", { timeOut: 3000, progressBar: true });
          this.group = null;
          this.uploadedFiles = [];
          this.description = '';
          this.activeModal.close('Save click');
        },
        err => {
          this.spinner.hide();
          this.toastr.error("Ocurrio un error al cargar la imagen", "Error!!", { timeOut: 3000, progressBar: true });
        }
      );
  }

}
