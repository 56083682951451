import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DoctorEndpoints } from '../../config/doctor-endpoints.config';
import { DoctorProgrammingDto } from '../../dtos/doctor-programming-dto';

@Injectable({
    providedIn: 'root'
})
export class DoctorDataService {

    constructor(
        private http: HttpClient,
        private endpoints: DoctorEndpoints
    ) { }

    getDoctorProgrammingByDoctor(iddoctor: number): Observable<DoctorProgrammingDto[]> {
        return this.http.get<DoctorProgrammingDto[]>(this.endpoints.getDoctorProgrammingByDoctor(iddoctor));
    }

    getDoctorProgrammingBy(id: number): Observable<DoctorProgrammingDto> {
        return this.http.get<DoctorProgrammingDto>(this.endpoints.getDoctorProgrammingById(id));
    }

    // Inserta una programación
    addDoctorProgramming(body: DoctorProgrammingDto): Observable<Response> {
        return this.http.post<Response>(this.endpoints.getDoctorProgramming(), body);
    }

    // Edita una programacion
    updateDoctorProgramming(id: number, body: DoctorProgrammingDto): Observable<Response> {
        return this.http.put<Response>(this.endpoints.getDoctorProgrammingById(id), body);
    }

    // Borra una programación
    deleteDoctorProgramming(id: number): Observable<Response> {
        return this.http.delete<Response>(this.endpoints.getDoctorProgrammingById(id));
    }

    // Inserta una programación
    NewaddDoctorProgramming(body: DoctorProgrammingDto): Observable<Response> {
        //console.log('body',body);
        
        return this.http.post<Response>(this.endpoints.NewgetDoctorProgramming(), body);
    }

}