<form class="was-validated" *ngIf="formData" [formGroup]="formData" ngNativeValidate>
    <div class="modal-header modal_header">
        <h5 class="modal-title titulo-modal">{{this.title}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.close('Save click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <div class="row" id="search-container">
            <div class="col-md-5">
                <div class="form-group">
                    <label class="form-label">Paciente <small class="text-danger"> (*)</small></label>
                    <input type="text" class="form-control form-control-sm" [class.is-invalid]="searchFailed"
                        [id]="formFields.Patient" [name]="formFields.Patient"
                        [formControlName]="this.formFields.Patient" (selectItem)="selectedItem($event)"
                        [ngbTypeahead]="search" placeholder="Buscar paciente"
                        [resultFormatter]="resultFormatBandListValue" [inputFormatter]="inputFormatBandListValue"
                        required />
                    <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                    <div class="invalid-feedback" *ngIf="searchFailed">
                        Error, Las sugerencias no pueden ser cargadas
                    </div>
                    <label *ngIf="getErrorMessage(formFields.Patient)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención campo requerido
                    </label>
                </div>
            </div>
            <div class="col-md-4" >
                <div class="form-group">
                    <label class="form-label">Tratamiento <small class="text-danger"> (*)</small></label>
                    <select class="custom-select custom-select-sm" [formControlName]="this.formFields.Tariff" required
                        [id]="formFields.Tariff" [name]="formFields.Tariff"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.Tariff)}" (change)="getTypeTarifa()">
                        <option *ngFor="let item of listTariff" [value]="item.id">{{item.name}}</option>
                    </select>
                    <label *ngIf="getErrorMessage(formFields.Tariff)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención campo requerido
                    </label>
                </div>
            </div>
            <div class="col-md-3" *ngIf="reprogramcion">
                <div class="form-group">
                    <label class="form-label">Tipo <small class="text-danger"> (*)</small></label>
                    <select class="custom-select custom-select-sm" [formControlName]="this.formFields.Type" required
                        [id]="formFields.Type" [name]="formFields.Type"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.Type)}" (change)="getTimeTarifa()">
                        <option *ngFor="let item of listTarriffType" [value]="item.id_info">{{item.type_family}}</option>
                    </select>
                    <label *ngIf="getErrorMessage(formFields.Type)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención campo requerido
                    </label>
                </div>
            </div>
            <div class="col-md-5" >
                <div class="form-group">
                    <label class="form-label">Doctor<small class="text-danger"> (*)</small></label>
                    <select class="custom-select custom-select-sm" [formControlName]="this.formFields.Doctor" required
                        [id]="formFields.Doctor" [name]="formFields.Doctor" disabled
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.Doctor)}">
                        <option *ngFor="let item of listDoctors" [value]="item.id">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                    <label class="form-label">Doctor OI</label>
                    <select class="custom-select custom-select-sm" [formControlName]="this.formFields.Doctor2"
                        [id]="formFields.Doctor2" [name]="formFields.Doctor2" (change)="getTimeTarifa()" >
                        <option *ngFor="let item of listDoctors2" [value]="item.id">{{item.namequote}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2" *ngIf="reprogramcion">
                <div class="form-group">
                    <label class="form-label">Tiempo <small class="text-danger">(*)</small></label>
                    <input class="form-control form-control-sm" readonly type="number"
                            [formControlName]="this.formFields.Time" [id]="formFields.Time"
                            [name]="formFields.Time" required>
                </div>
            </div>
        </div>
        <!-- Datos de la cita anterior -->
        <ng-container *ngIf="reprograming">
            <div class="row">
                <div class="col-md-12">
                    <h5>Datos de la cita anterior</h5>
                    <hr>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="form-label">Consultorio <small class="text-danger">(*)</small></label>
                        <select class="custom-select custom-select-sm"
                            [formControlName]="this.formFields.EnvironmentDoctorOld"
                            [id]="formFields.EnvironmentDoctorOld" [name]="formFields.EnvironmentDoctorOld" disabled>
                            <option value="">Seleccione</option>
                            <option *ngFor="let ed of this.environmentList" [value]="ed.id">{{ed.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="form-control-label" for="date">Fecha de Cita <span
                                class="text-danger">(*)</span></label>
                        <input class="form-control form-control-sm" readonly type="date"
                            [formControlName]="this.formFields.DateOld" [id]="formFields.DateOld"
                            [name]="formFields.DateOld" required>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="form-label">Horario <small class="text-danger">(*)</small></label>
                        <input class="form-control form-control-sm" readonly type="text"
                            [formControlName]="this.formFields.AppointmentOld" [id]="formFields.AppointmentOld"
                            [name]="formFields.AppointmentOld" required>
                    </div>
                </div>

            </div>

        </ng-container>
        <div class="row">
            <!-- Datos de la nueva cita -->
            <div class="col-md-12" *ngIf="reprograming">
                <h5>Datos de la nueva cita</h5>
                <hr>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-label">Consultorio <small class="text-danger">(*)</small></label>
                    <select class="custom-select custom-select-sm" [formControlName]="this.formFields.EnvironmentDoctor"
                        [id]="formFields.EnvironmentDoctor" [name]="formFields.EnvironmentDoctor" required disabled>
                        <option *ngFor="let ed of this.environmentList" [value]="ed.id">{{ed.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.Date">
                        Fecha de Cita <span class="text-danger">(*)</span>
                    </label>
                    <input class="form-control form-control-sm" readonly type="date"
                        [formControlName]="this.formFields.Date" [id]="formFields.Date" [name]="formFields.Date"
                        required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-label">Horario <small class="text-danger">(*)</small></label>
                    <input class="form-control form-control-sm" readonly type="text"
                        [formControlName]="this.formFields.Appointment" [id]="formFields.Appointment"
                        [name]="formFields.Appointment" required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="checkbox checkbox-fill d-inline">
                    <input type="checkbox" [formControlName]="this.formFields.PendingPayment"
                        [id]="formFields.PendingPayment" [name]="formFields.PendingPayment"
                        (change)="setPendingPayment()">
                    <label [for]="formFields.PendingPayment" class="cr">Pendiente pago</label>
                </div>
            </div>
            <div class="col-md-4" *ngIf="showPendingPayment">
                <div class="form-group">
                    <label class="form-label">Monto <small class="text-danger">(*)</small></label>
                    <input class="form-control form-control-sm" type="text" [formControlName]="this.formFields.Amount"
                        [id]="formFields.Amount" [name]="formFields.Amount" required min="1">
                </div>
            </div>
            <div class="col-md-4" *ngIf="showPendingPayment">
                <div class="form-group">
                    <label class="form-label">Moneda <small class="text-danger">(*)</small></label>
                    <select class="custom-select custom-select-sm" required [formControlName]="this.formFields.Coin"
                        [id]="formFields.Coin" [name]="formFields.Coin">
                        <option *ngFor="let co of this.listCoin" [value]="co.id">{{co.code}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="form-control-label" for="reason">Motivo</label>
                    <textarea class="form-control form-control" rows="2" [formControlName]="this.formFields.Reason"
                        [id]="formFields.Reason" [name]="formFields.Reason" placeholder="Motivo de la cita"
                        maxlength="500" minlength="10"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="alert alert-danger" role="alert" *ngIf="this.showAlert">
            <b>Atención!</b> el cliente tiene cuota(s) vencida(s)
        </div>
        <button type="submit" class="btn btn-warning ml-1" ngbTooltip="Cancelar" *ngIf="this.reprograming"
            (click)="cancelRepro()">Cancelar</button>
        <button type="submit" class="btn btn-primary ml-1" ngbTooltip="Programar" [disabled]="formData.invalid"
            (click)="addAppointment(validationForm)">Programar</button>
        <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Save click')">
            Salir
        </button>
    </div>
</form>