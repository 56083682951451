import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DiscountType } from '../../model/discount-type.model';

import { DiscountTypeDataService } from '../private/discount-type-data.service';


@Injectable({
    providedIn: 'root'
})
export class DiscountTypeService {

    constructor(private dataRequestService: DiscountTypeDataService) { }

    // Obtenemos la
    get(): Observable<DiscountType[]> {
        return this.dataRequestService.get().pipe(
            map(ele => ele.map(el => new DiscountType().FromDTO(el)))
        );
    }

    // Obtenemos los datos de una compañia por id
    getById(id: number): Observable<DiscountType> {
        return this.dataRequestService.getById(id).pipe(
            map(el => new DiscountType().FromDTO(el))
        );
    }

}