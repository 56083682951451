<div class="scheduleContainer">
        <!--TIMES -->
        <div class="time start-750">H/DOC</div>
        <ng-container *ngFor="let sd of this.scheduleCalendar">
            <ng-container *ngFor="let hrs of sd.hoursBeta">
                <div class="time start-{{ hrs.hourb }}">{{ hrs.houra }}</div>
            </ng-container>
        </ng-container>
        <!-- EVENTS -->
        <ng-container *ngFor="let ca of hoursCalendar">
            <div class="event stage-jupiter start-750 end-750 length-1">{{ ca.doctor }} </div>
            <ng-container *ngFor="let cale of ca.hours">
                <div *ngIf="cale.type == 0"
                    class="event stage-venus start-{{cale.sinceMod}} end-{{cale.untilMod}} length-1">No
                    Disponible </div>
                <div style="cursor:pointer;" *ngIf="cale.type == 1" (click)="addMedicalAppointment(cale)"
                    class="event stage-saturn start-{{cale.sinceMod}} end-{{cale.untilMod}} length-1">
                    {{cale.environtment_name}} Dispobible | {{cale.since}} | Intervalo {{cale.data.interval}}min.
                </div>
                <div *ngIf="cale.type == 2"
                    class="event stage-mars start-{{cale.sinceMod}} end-{{cale.untilMod}} length-1">Limpieza
                </div>
                <div *ngIf="cale.type == 3"
                    class="event stage-earth start-{{cale.sinceMod}} end-{{cale.untilMod}} length-1">Refrigerio
                </div>
                <!-- Cupo con reserva -->
                <div style="cursor:pointer;" (click)="setAppointmentDetail(cale.data.data.idreservation)"
                    *ngIf="cale.type == 4" [ngClass]="{
                        'stage-mercury': cale.data.data.status === 1,
                        'stage-jupiter': cale.data.data.status === 2,
                        'stage-mars': cale.data.data.status === 3
                    }" class="event start-{{cale.sinceMod}} end-{{cale.untilMod}} length-1">
                    {{cale.data.data.doctor}}
                    <span>{{cale.data.data.history}} - {{cale.data.data.lastNameFather}}
                        {{cale.data.data.lastNameMother}}
                        {{cale.data.data.name}}</span>
                    <span [class]="'badge badge-warning'" *ngIf="cale.data.data.status === 1">
                        Por confirmar
                    </span>
                    <span [class]="'badge badge-primary'" *ngIf="cale.data.data.status === 3">
                        Atendido
                    </span>
                    <span [class]="'badge badge-warning'" *ngIf="cale.data.data.status === 2">
                        Confirmado
                    </span>
                </div>
            </ng-container>
        </ng-container>
    </div>