<div *ngIf="this.role === ''">
  <app-alert type="primary"><i class="fas fa-spinner fa-spin"></i> Cargando perfil!</app-alert>
</div>
<div *ngIf="this.role === 'ODONTOLOGO'">
  <app-dashboard-doctor></app-dashboard-doctor>
</div>
<div *ngIf="this.role === 'SUPERUSER'">
  <app-dashboard></app-dashboard>
  <!-- <app-dashboard-reception></app-dashboard-reception> -->
</div>
<div *ngIf="this.role === 'BASICO'">
  <app-dashboard-basic></app-dashboard-basic>
</div>
<div *ngIf="this.role === 'RECEPCIÓN'">
  <app-dashboard-reception></app-dashboard-reception>
</div>
<div *ngIf="this.role === 'ASISTENTE'">
  <app-dashboard-assistant></app-dashboard-assistant>
</div>