<form class="was-validated" #register="ngForm" (ngSubmit)="onSubmit()" ngNativeValidate>
    <div class="modal-header modal_header">
        <h5 class="modal-title titulo-modal">Bloquear Agenda</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close('Close')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <div class="row" id="search-container">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Doctor <small class="text-danger"> (*)</small></label>
                    <ng-select [(ngModel)]="inputs.doctor"
                    placeholder="Seleccione" [loading]="loadingSelectDoctor" required name="doctor"
                    (change)=this.onGetList()>
                        <ng-option *ngFor="let do of listDoctors" [value]="do.id">
                            {{do.nameQuote}}
                        </ng-option>
                    </ng-select>
                </div>
                <div *ngIf="!inputs.doctor" class="invalid-feedback">
                    Seleccione un doctor
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="date" class="form-label">
                        Fecha <small class="text-danger">(*)</small>
                    </label>
                    <input type="date" name="date" class="form-control form-control-sm"
                    required [(ngModel)]="inputs.date">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="time_since" class="form-label">
                        Hora desde<small>(*)</small>
                    </label>
                    <input type="time" name="time_since" class="form-control form-control-sm"
                    required [(ngModel)]="inputs.time_since">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="time_until" class="form-label">
                        Hora hasta<small>(*)</small>
                    </label>
                    <input type="time" name="time_until" class="form-control form-control-sm"
                    required [(ngModel)]="inputs.time_until">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label for="description" class="form-label">Observación</label>
                    <input type="text" name="description" class="form-control form-control-sm"
                    [(ngModel)]="inputs.description">
                </div>
            </div>
            <div class="col-md-12">
                <hr>
                <button type="submit" class="btn btn-primary ml-1" ngbTooltip="Guardar"
                [disabled]="!register.valid">Guardar</button>
                <hr>
            </div>
            <div class="col-md-12" *ngIf="this.inputs.doctor !== '' && this.inputs.doctor !== null">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de horarios bloqueados</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="since" class="form-label">Desde</label>
                                    <input type="date" name="since" class="form-control form-control-sm"
                                    [(ngModel)]="this.filters.since">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="until" class="form-label">Hasta</label>
                                    <input type="date" name="until" class="form-control form-control-sm"
                                    [(ngModel)]="this.filters.until">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button type="button" class="btn btn-primary btn-sm mt-4"
                                (click)="this.onGetList()">
                                    Buscar
                                </button>
                            </div>
                            <div class="col-md-12">
                                <p *ngIf="list.length === 0">
                                    <ngb-alert [dismissible]="false" [type]="'info'">
                                      <strong><i class="fas fa-info-circle"></i></strong>
                                      No hay horarios bloqueados.
                                    </ngb-alert>
                                </p>
                                <table class="table table-sm" *ngIf="this.list.length > 0">
                                    <thead>
                                        <th>Fecha</th>
                                        <th>Hora desde</th>
                                        <th>Hora hasta</th>
                                        <th>Observación</th>
                                        <th>Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of list">
                                            <td>{{item.date | date: 'dd-MM-yyyy'}}</td>
                                            <td>{{item.time_since}}</td>
                                            <td>{{item.time_until}}</td>
                                            <td>{{item.description}}</td>
                                            <td>
                                                <button type="button" class="btn btn-warning btn-sm"
                                                ngbPopover="Editar" triggers="mouseenter:mouseleave" (click)="setItem(item)">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button type="button" class="btn btn-danger btn-sm ml-1" (click)="delete(item)"
                                                ngbPopover="Eliminar" triggers="mouseenter:mouseleave">
                                                    <i class="fas fa-ban"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-outline-dark"
        (click)="activeModal.close('Close')">
        Salir
        </button>
    </div>
</form>
