import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnumeracionSpecificationDto } from './../models/discount-specifications'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountSpecificationsService {

  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http:HttpClient) { }

  findAllDiscountSpecifications():Observable<EnumeracionSpecificationDto[]>{
    return this.http.get<EnumeracionSpecificationDto[]>(`${this.baseUrl}/discount-specifications`);
    }

  create(discount:EnumeracionSpecificationDto):Observable<EnumeracionSpecificationDto>{
    return this.http.post<EnumeracionSpecificationDto>(`${this.baseUrl}/discount-specifications`,discount);
  }
}
