import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PaymentsAdvanceDto } from 'src/app/models/main/payments-advance.model';


@Injectable({
    providedIn: 'root'
  })
  export class PaymentsAdvanceService{

    private baseUrl: string = `${environment.apiUrl}/payments-advance/`;

  constructor(private http: HttpClient) { }

  savePaymentsContract(data:PaymentsAdvanceDto):Observable<PaymentsAdvanceDto>{
    return this.http.post<PaymentsAdvanceDto>(`${this.baseUrl}`,data);
  }

  updatePay(data: PaymentsAdvanceDto, id: number): Observable<PaymentsAdvanceDto> {
    return this.http.put<PaymentsAdvanceDto>(`${this.baseUrl}${id}`, data);
  }


  }