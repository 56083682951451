<div class="row">
  <div class="contenedor-principal" >
    <div class="fecha">
      <div class="container-scroll">
          <app-card cardTitle="" hidHeader="true" cardClass="card-datatable" [options]="false">
              <pre>Fecha Seleccionada: {{ parserFormatter.format(model) }}</pre>
              <ngb-datepicker #dp [(ngModel)]="model" (ngModelChange)="setDate()" [minDate]="minDate"
                  (navigate)="date = $event.next"></ngb-datepicker><!-- (ngModelChange)="getDentalOffice();setSchedule()" -->
              <br />
              <button class="btn btn-sm btn-outline-primary mt-1" (click)="selectToday()">Hoy</button>
              <hr>
              <div class="filter mt-2">
                  <div class="title">
                      <h5>Busqueda de Citas</h5>
                  </div>
                  <div class="form-group">
                      <label for="patient">Paciente</label>
                      <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" name="patient"
                          (selectItem)="selectedItem($event)" [(ngModel)]="patient" [ngbTypeahead]="search" placeholder="Todos.."
                          [resultFormatter]="resultFormatBandListValue" [inputFormatter]="inputFormatBandListValue" required />
                      <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                      <div class="invalid-feedback" *ngIf="searchFailed">Error, Las sugerencias no pueden ser cargadas
                      </div>
                  </div>
                  <div class="form-group">
                      <label for="doc">Doctor</label>
                      <input type="text" class="form-control" [class.is-invalid]="searchFailed" name="selectedDoctor"
                      (selectItem)="onDoctorSelected($event)"[(ngModel)]="selectedDoctor" [ngbTypeahead]="searchDoctor" placeholder="Buscar doctor..." 
                          [resultFormatter]="formatter" [inputFormatter]="formatter" required />
                      <div class="invalid-feedback" *ngIf="searchFailed">Error, Las sugerencias no pueden ser cargadas
                      </div>
                  </div>
                  <!--<div class="form-group">
                      <label for="state">Estado</label>
                      <ng-select name="state" [(ngModel)]="filterState" [ngModelOptions]="{standalone: true}"
                          [loading]="loadingSelectStateFilter" placeholder="Todos">
                          <ng-option *ngFor="let st of listState" [value]="st.id">{{st.state}}</ng-option>
                      </ng-select>
                  </div>-->
                  <div class="form-group">
                      <button class="btn btn-primary mr-2" (click)="onSearch()">Buscar</button>
                      <button class="btn btn-info" (click)="clean()">🧹</button>
                  </div>
              </div>
              <div class="filter mt-3">
                  <button class="btn btn-warning" (click)="newAttention()">Agendar Cita</button>
              </div>
          </app-card>
      </div>
    </div>
  <ng-container *ngIf="listHours.length > 0">
      <ng-container *ngFor="let doctor of listHours">
          <div class="horario">
              <div class="event stage-jupiter start-750 end-750 length-1 fixed-header">{{ doctor.nameQuote }}</div>
            <ng-container class="horarios-container" *ngFor="let hour of doctor.items">
                  <div *ngIf="hour.availability_status == 0" class="horario-item-0">
                      <div (click)="newAttention()">
                          <div class="bg-white dark:bg-zinc-800 shadow-sm rounded-lg ">
                            <div class="container">
                              <div class="left-section">
                                  <span class="icon"><h5>⌚</h5></span>
                                <time>{{hour.interval}} min.</time>
                                <ul class="no-list-style">
                                  <li class="full-width">{{formatTime(hour.since)}} - {{formatTime(hour.until)}}</li>
                                </ul>
                              </div>
                              <div class="right-section">
                                <ul class="no-list-style-history">
                                  <li class="full-width-history"><strong>{{hour.name_quote}}</strong> </li>
                                  <li class="full-width-history"><strong>{{hour.consultorio}}</strong> </li>
                                  
                                </ul>
                              </div>
                            </div>
                            <div class="bg-zinc-100 dark:bg-zinc-700 p-4 rounded-b-lg">
                              <ul class="list-none mb-0">
                                  <li><strong>DISPONIBLE</strong></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                    
                  </div>
                  <div  *ngIf="hour.availability_status == 1" class="horario-item-1">
                      <div (click)="setAppointmentDetail(hour)">
                          <div class="bg-lime-300 dark:bg-zinc-800 shadow-sm rounded-lg ">
                            <div class="container">
                              <div class="left-section">
                                <span class="icon"><h5>⌚</h5></span>
                                <time>{{hour.interval}} min.</time>
                                <ul class="no-list-style">
                                  <li class="full-width">{{formatTime(hour.since)}} - {{formatTime(hour.until)}}</li>
                                </ul>
                              </div>
                              <div class="right-section">
                                <ul class="no-list-style">
                                  <li class="full-width"><strong>{{hour.name_quote}}</strong></li>
                                  <li class="full-width"><strong>{{hour.consultorio}}</strong></li>
                                  <li class="full-width"><strong><span class="label label-warning">Por Confirmar</span></strong></li>
                                </ul>
                              </div>
                            </div>
                            <div class="bg-lime-400 dark:bg-zinc-700 p-4 rounded-b-lg">
                              <ul class="no-list-style-history">                             
                                <li class="full-width-history"><strong>Historia Clínica:</strong><a href="javascript:void(0)" (click)="goToClinicHistory(hour); $event.stopPropagation();">{{hour.history_clinic}}</a></li>
                                <li class="full-width-history"><strong>Nombre:</strong> {{hour.nombre_paciente}}</li>
                                  <li class="full-width-history"><strong>Apellido Paterno:</strong> {{hour.ap_paterno_paciente}}</li>
                                  <li class="full-width-history"><strong>Apellido Materno:</strong> {{hour.ap_materno_paciente}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                  </div>
                  <!--<div *ngIf="hour.availability_status == 2"
                      class="horario-item-2 event stage-mars start-{{hour.since}} end-{{hour.until}} length-1">Limpieza
                  </div>-->
                  <div *ngIf="hour.availability_status == 4"
                      class="horario-item-4 event stage-earth start-{{hour.since}} end-{{hour.until}} length-1">Refrigerio <br>
                      {{formatTime(hour.since)}} - {{formatTime(hour.until)}}
                  </div>
                  <div *ngIf="hour.availability_status == 2" class="horario-item-2">
                    <div (click)="setAppointmentDetail(hour)">
                      <div class="bg-lime-300 dark:bg-zinc-800 shadow-sm rounded-lg ">
                        <div class="container">
                          <div class="left-section">
                            <span class="icon"><h5>⌚</h5></span>
                            <time>{{hour.interval}} min.</time>
                            <ul class="no-list-style">
                              <li class="full-width">{{formatTime(hour.since)}} - {{formatTime(hour.until)}}</li>
                            </ul>
                          </div>
                          <div class="right-section">
                            <ul class="no-list-style">
                              <li class="full-width"><strong>{{hour.name_quote}}</strong></li>
                              <li class="full-width"><strong>{{hour.consultorio}}</strong></li>
                              <li class="full-width"><strong><span class="label label-success">Confirmado</span></strong></li>
                            </ul>
                          </div>
                        </div>
                        <div class="bg-lime-400 dark:bg-zinc-700 p-4 rounded-b-lg">
                          <ul class="no-list-style-history">                             
                            <li class="full-width-history"><strong>Historia Clínica:</strong><a href="javascript:void(0)" (click)="goToClinicHistory(hour); $event.stopPropagation();">{{hour.history_clinic}}</a></li>
                            <li class="full-width-history"><strong>Nombre:</strong> {{hour.nombre_paciente}}</li>
                              <li class="full-width-history"><strong>Apellido Paterno:</strong> {{hour.ap_paterno_paciente}}</li>
                              <li class="full-width-history"><strong>Apellido Materno:</strong> {{hour.ap_materno_paciente}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="hour.availability_status == 3"class="horario-item-3">
                    <div (click)="setAppointmentDetail(hour)">
                      <div class="bg-lime-300 dark:bg-zinc-800 shadow-sm rounded-lg ">
                        <div class="container">
                          <div class="left-section">
                            <span class="icon"><h5>⌚</h5></span>
                            <time>{{hour.interval}} min.</time>
                            <ul class="no-list-style">
                              <li class="full-width">{{formatTime(hour.since)}} - {{formatTime(hour.until)}}</li>
                            </ul>
                          </div>
                          <div class="right-section">
                            <ul class="no-list-style">
                              <li class="full-width"><strong>{{hour.name_quote}}</strong></li>
                              <li class="full-width"><strong>{{hour.consultorio}}</strong></li>
                              <li class="full-width"><strong><span class="label label-primary">Atendido</span></strong></li>
                            </ul>
                          </div>
                        </div>
                        <div class="bg-lime-400 dark:bg-zinc-700 p-4 rounded-b-lg">
                          <ul class="no-list-style-history">                             
                            <li class="full-width-history"><strong>Historia Clínica:</strong><a href="javascript:void(0)" (click)="goToClinicHistory(hour); $event.stopPropagation();">{{hour.history_clinic}}</a></li>
                            <li class="full-width-history"><strong>Nombre:</strong> {{hour.nombre_paciente}}</li>
                              <li class="full-width-history"><strong>Apellido Paterno:</strong> {{hour.ap_paterno_paciente}}</li>
                              <li class="full-width-history"><strong>Apellido Materno:</strong> {{hour.ap_materno_paciente}}</li> 
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="hour.availability_status == 10"
                      class="horario-item-3 event stage-earth start-{{hour.since}} end-{{hour.until}} length-1">Refrigerio
                  </div>
            </ng-container>
          </div>
      </ng-container>
  </ng-container>
  <div>
      <ng-container *ngIf="listHours.length === 0">
          <div class="alert alert-primary" role="alert">
              No hay doctores programados para hoy.
          </div>
      </ng-container>
  </div>
  </div>
    

</div>
