import { SETDATAUSER } from '../actions/session.action';

export const initialState = {};

export function sessionReducer(state = initialState, action) {
    switch (action.type) {
        case SETDATAUSER:
            return state = action.payload;
        default:
            return state;
    }
}