import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DiscountTypeEndpoints {

    constructor() { }

    private apiUrl(): string {
        return `${environment.apiUrl}/discount-type`;
    }

    get() {
        return `${this.apiUrl()}`;
    }

    getById(id: number) {
        return `${this.apiUrl()}/${id}`;
    }

}