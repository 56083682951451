<div class="card">
  <h2 *ngIf="!showCustomerInfo">Espere porfavor...</h2>
  <div *ngIf="!showCustomerInfo">
    <section>
      <div class="loading-container" *ngIf="progress < 100">
        <span class="hourglass">⏳</span>
        <p class="loading-text">Cargando Comprobante de Pago<span class="dots">...</span></p>
      </div>
    </section>
    <div class="progress-container" *ngIf="progress < 100">
      <div class="progress-bar" [ngStyle]="{ 'width': progress + '%' }"></div>
    </div>
  </div>
  <div *ngIf="showCustomerInfo" class="customer-info">
      <h3>Información del Cliente</h3>
      <p><strong>Nombre: </strong> {{ cliente }}</p>
      <p><strong>Historial Clínico: </strong> {{ historia_clinica }}</p>
      <p><strong>Forma de Pago: </strong>{{ numero_op_banks }}</p>
      <ng-container *ngIf="soles">
        <p (click)="openInvoiceSoles()"><strong>Comprobante Soles: </strong><span [class]="'badge badge-light-primary'">{{ soles }}</span> </p>
      </ng-container>
      <ng-container *ngIf="dolares">
        <p (click)="openInvoiceDolares()"><strong>Commprobante Dólares: </strong> <span [class]="'badge badge-light-primary'">{{ dolares }}</span></p>
      </ng-container>

      <!-- Añade más detalles según sea necesario -->
       <div>
          <button class="btn btn-primary button-spacing" (click)="closeModal()">Cerrar</button>
          <ng-container *ngIf="soles">
            <button class="btn btn-warning button-spacing" (click)="copySoles()">Copiar Soles</button>
          </ng-container>
          <ng-container *ngIf="dolares">
            <button class="btn btn-warning button-spacing" (click)="copyDolares()">Copiar Dolares</button>
          </ng-container>
       </div>
  </div>
  