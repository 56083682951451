import { Transferable } from '../../../../shared/interfaces/iTransferable';
import { DiscountTypeDto } from '../dto/discount-type.dto';

export class DiscountType implements Transferable<DiscountTypeDto> {
    id: number;
    name: string;
    status: number;

    ToDTO(): DiscountTypeDto {
        const dto: DiscountTypeDto = ({
            id: this.id,
            name: this.name,
            status: this.status
        });
        return dto;
    }

    FromDTO(from: DiscountTypeDto): DiscountType {
        this.id = from.id;
        this.name = from.name;
        this.status = from.status;
        return this;
    }
}
