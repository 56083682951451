<div class="table-responsive" *ngIf="data.length > 0">
    <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered nowrap table-hover table-sm">
        <thead>
            <tr>
                <th class="w50">Fecha</th>
                <th class="w50">Horario</th>
                <th>Doctor</th>
                <th class="w100">Tratamiento</th>
                <th class="w100">Estado</th>
                <th class="w50">Acción</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td>{{item.date}}
                </td>
                <td>{{item.appointment}}</td>
                <td>
                    <div class="d-inline-block align-middle">
                        <div class="d-inline-block">
                            <h6 class="m-b-0">{{item.doctor.nameQuote}}</h6>
                            <p class="m-b-0">{{item.environment.name}}</p>
                        </div>
                    </div>
                </td>
                <td>{{item.tariff ? item.tariff.name : ''}}</td>
                <td>
                    <span class="badge bg-warning mr-1" *ngIf="item.state === 15">
                        <i class="fas fa-chalkboard"></i>  Eliminado por reprogramación (Automático)
                    </span>
                    <span class="badge bg-success mr-1 text-white" *ngIf="item.state === 3">
                        <i class="fas fa-check-double"></i> Atendido
                    </span>
                    <span class="badge bg-primary mr-1 text-white" *ngIf="item.state === 2">
                        <i class="fas fa-check"></i> Confirmado
                    </span>
                    <span class="badge bg-secondary mr-1 text-white" *ngIf="item.state === 1">
                        <i class="fas fa-user-clock"></i> Por confirmar
                    </span>
                    <span class="badge bg-error mr-1 text-white" *ngIf="item.state === 0">
                        <i class="fas fa-trash"></i> Eliminado
                    </span>
                </td>
                <td>
                    <button type="button" class="btn btn-icon btn-outline-success btn-sm" *ngIf="item.state === 1"
                        ngbPopover="Confirmar" triggers="mouseenter:mouseleave" (click)="this.confirmQuote(item)">
                        <i class="fas fa-check-double"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-outline-primary btn-sm" *ngIf="item.state > 1"
                        ngbPopover="Registrar atención" triggers="mouseenter:mouseleave"
                        (click)="this.setMedicalAct(item)">
                        <i class="fas fa-notes-medical"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>