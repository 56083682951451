import { Transferable } from '../../../../shared/interfaces/iTransferable';
import { ProgramationAppointmentDto } from '../dtos/programming-appointment-dto';

export class ProgramationAppointment implements Transferable<ProgramationAppointmentDto> {
    environment: any;
    date: string;
    appointment: string;
    pending_payment: boolean;
    amount: number;
    coin: number;
    tariff: number;
    reason: string;
    doctor: number;
    doctor_id2: number;
    id: number;
    qdetail: number;
    state: number;
    patient: number;
    since: string;
    until: string;
    interval: number;

    ToDTO(): ProgramationAppointmentDto {
        const dto: ProgramationAppointmentDto = ({
            id: this.id,
            environment: this.environment,
            date: this.date,
            appointment: this.appointment,
            pending_payment: this.pending_payment,
            amount: this.amount,
            coin: this.coin,
            tariff: this.tariff,
            reason: this.reason,
            doctor: this.doctor,
            doctor_id2: this.doctor_id2,
            qdetail: this.qdetail,
            state: this.state,
            patient: this.patient,
            since: this.since,
            until: this.until,
            interval: this.interval
        });
        return dto;
    }

    FromDTO(from: ProgramationAppointmentDto): ProgramationAppointment {
        this.id = from.id;
        this.environment = from.environment;
        this.date = from.date;
        this.appointment = from.appointment;
        this.pending_payment = from.pending_payment;
        this.amount = from.amount;
        this.coin = from.coin;
        this.tariff = from.tariff;
        this.reason = from.reason;
        this.doctor = from.doctor;
        this.doctor_id2 = from.doctor_id2;
        this.qdetail = from.qdetail;
        this.state = from.state;
        this.patient = from.patient;
        return this;
    }
}