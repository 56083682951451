
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
    <li [ngbNavItem]="1">
        
        <ng-template ngbNavContent>
          <div class="row">
            <!--div class="col-md-12">
              <p *ngIf="this.listFiles.length === 0">
                <ngb-alert [dismissible]="false" [type]="'primary'">
                  <strong><i class="fas fa-info-circle"></i></strong>
                  No hay archivos registrados
                </ngb-alert>
              </p>
            </div>
            <div class="col-md-12" *ngIf="this.listFiles.length > 0">
              <app-card [hidHeader]="true" cardClass="user-profile-list">
                <div class="dt-responsive table-responsive">
                  <table datatable [dtOptions]="dtOptions" class="table table-striped row-border table-hover">
                    <thead>
                        <th class="w100">Fecha</th>
                        <th>Grupo</th>
                        <th>Descripción</th>
                        <th class="w50">Tipo</th>
                        <th class="w50">Acción</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of this.listFiles">
                        <td>{{item.createdAt | date:"dd-MM-yyyy hh:mm"}}</td>
                        <td>{{item.filegroup.name}}</td>
                        <td>{{item.desciption}}</td>
                        <td>{{item.file_ext}}</td>
                        <td>
                          <a [href]="urlDownload+'/'+item.fila_name" target="blank" class="btn btn-outline-primary btn-icon btn-sm"
                          ngbPopover="Descargar" triggers="mouseenter:mouseleave">
                            <i class="fas fa-download"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </app-card>
            </div-->
            <div class="col-12">
              <form class="was-validated" (ngSubmit)="onSubmit()" ngNativeValidate>
                  <div class="row">
                      <div class="col-4">
                          <div class="form-group">
                              <label class="form-control-label" for="name">Grupo <small class="text-danger">(*)</small></label>
                              <select name="group" [(ngModel)]="group" class="form-control custom-select" disabled required>
                                  <option value="">Seleccione</option>
                                  <option *ngFor="let item of listGroup" [value]="item.id" >
                                      {{item.name}}
                                  </option>
                              </select>
                          </div>
                      </div>
                      <div class="col-8">
                          <div class="form-group">
                              <label class="form-control-label" for="name">Descripción <small class="text-danger">(*)</small></label>
                              <input name="description" [(ngModel)]="description" class="form-control form-control-sm"
                              placeholder="Descripción del archivo" maxlength="250" required>
                          </div>
                      </div>
                        <div class="col-12">
                          <file-upload [(ngModel)]="uploadedFiles" name="files" multiple="multiple" (onFileChange)="onFileChange($event)"></file-upload>
                          <small class="text-secondary">Nota: solo subir un archivo a la vez</small>
                        </div>
                      <div class="col-12">
                          <!--
                          <button type="submit" class="btn btn-primary btn-lg btn-block">
                              <i class="fas fa fa-save"></i> Subir
                          </button>
                          -->
                      </div>
                  </div>
              </form>
            </div>
          </div>
        </ng-template>
    </li>
    <li [ngbNavItem]="2">
    
      <ng-template ngbNavContent>
          <hr>
          <p *ngIf="this.quantityFiles.length === 0">
              <ngb-alert [dismissible]="false" [type]="'primary'">
                <strong><i class="fas fa-info-circle"></i></strong>
                No hay archivos registrados
              </ngb-alert>
          </p>
          <div class="row" *ngIf="this.quantityFiles.length > 0">
            <div class="col-md-3" *ngFor="let el of quantityFiles">
              <div class="card flat-card widget-purple-card" style="cursor: pointer;"
              (click)="this.getFiles(el.idgroup)">
                <div class="row-table">
                  <div class="col-sm-2 card-body" style="padding: 20px 5px !important;">
                    <i class="feather icon-folder"></i>
                  </div>
                  <div class="col-sm-10">
                    <h6>Archivo(s) {{el.quantity}}</h6>
                    <h6>{{el.file_group}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.listFiles.length > 0">
              <table class="table table-sm">
                  <thead>
                      <th class="w100">Fecha</th>
                      <th>Grupo</th>
                      <th>Descripción</th>
                      <th class="w50">Tipo</th>
                      <th class="w50">Acción</th>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of listFiles">
                          <td>{{item.createdAt | date:"dd-MM-yyyy hh:mm"}}</td>
                          <td>{{item.filegroup.name}}</td>
                          <td>{{item.desciption}}</td>
                          <td>{{item.file_ext}}</td>
                          <td>
                            <a [href]="urlDownload+item.fila_name" target="blank" class="btn btn-outline-primary btn-icon btn-sm"
                            ngbPopover="Descargar" triggers="mouseenter:mouseleave" *ngIf="item.file_ext !== '.pdf' && item.file_ext !== '.png' && item.file_ext !== '.jpg' && item.file_ext !== '.jpeg'">
                                <i class="fas fa-download"></i>
                            </a>
                            <a href="javascript:" class="btn btn-outline-primary btn-icon btn-sm" (click)="this.onPrint(item.fila_name,item.desciption,'pdf')"
                            ngbPopover="Ver" triggers="mouseenter:mouseleave" *ngIf="item.file_ext === '.pdf'">
                              <i class="fas fa-file-pdf"></i>
                            </a>
                            <a href="javascript:" class="btn btn-outline-primary btn-icon btn-sm" (click)="this.onPrint(item.fila_name,item.desciption,'img')"
                            ngbPopover="Ver" triggers="mouseenter:mouseleave" *ngIf="item.file_ext === '.png' || item.file_ext === '.jpg' || item.file_ext === '.jpeg'">
                              <i class="fas fa-file-image"></i>
                            </a>
                            <button class="btn btn-outline-danger btn-icon btn-sm ml-1"
                              (click)="onDelete(item.id)"
                              ngbPopover="Eliminar" triggers="mouseenter:mouseleave">
                              <i class="fas fa-trash"></i>
                            </button>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>



