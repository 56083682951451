<div class="row" *ngIf="showFilters">
    <div class="col-md-3 col-sm-12">
        <div class="container-scroll">
            <app-doctor-diary-filters-two (selectedDate)="setSchedule($event)"></app-doctor-diary-filters-two>
        </div>
    </div>


    <div class="col-md-9 col-sm-12">

        <ng-container *ngIf="programming.length > 0 && !loading">
            <app-doctor-diary-detail-two [programming]="programming" [usuario]="sesiones"
                (setSave)="setSchedule($event)"></app-doctor-diary-detail-two>
        </ng-container>
        <ng-container *ngIf="programming.length === 0 && !loading">
            <div class="alert alert-primary" role="alert">
                No hay doctores programados para hoy.
            </div>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="d-flex align-items-center">
                <strong>Cargando...</strong>
                <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
            </div>
        </ng-container>
    </div>
</div>