
<div class="row">
    <div class="col-8">
        <div [innerHTML]="this.greetings"></div>
    </div>
    <div class="col-4">
        <div class="float-right">
            <p class="txt-time">Hora {{time | date:'mediumTime'}}</p>
        </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-red p-0">
          <div class="row align-items-center m-b-0 p-0"> 
              <div class="col">
              <h6 class="m-b-5 text-white font-weight-bold h4">Historias Clinicas</h6>
              <h3 class="m-b-0 text-white font-weight-bold">{{cantPatient | number}}</h3>
            </div>
            <div class="col-auto">
              <i class="fas fa-file-medical text-white"></i>
            </div>
          </div>
        </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-blue p-0">
            <div class="row align-items-center m-b-0 p-0"> 
                <div class="col">
                    <h6 class="m-b-5 text-white font-weight-bold h4">Reservas del mes</h6>
                    <h3 class="m-b-0 text-white font-weight-bold">{{cantReservation | number}}</h3>
                </div>
                <div class="col-auto">
                    <i class="fas fa-calendar text-white"></i>
                </div>
            </div>
        </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-green p-0">
            <div class="row align-items-center m-b-0 p-0">
                <div class="col">
                    <h6 class="m-b-5 text-white font-weight-bold h4">Atenciones del mes</h6>
                    <h3 class="m-b-0 text-white font-weight-bold">{{cantAttention | number}}</h3>
                </div>
                <div class="col-auto">
                    <i class="fas fa-notes-medical text-white"></i>
                </div>
            </div>
        </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-yellow p-0">
            <div class="row align-items-center m-b-0 p-0">
                <div class="col">
                    <h6 class="m-b-5 text-white font-weight-bold h4">Ordenes de laboratorio</h6>
                    <h3 class="m-b-0 text-white  font-weight-bold">{{cantLabOrder | number}}</h3>
                </div>
                <div class="col-auto">
                    <i class="fas fa-flask text-white"></i>
                </div>
            </div>
        </app-card>
    </div>
    <div class="col-xl-4 col-md-12  p-0">
        <app-card [hidHeader]="true">
 
                      
            <div class="row">
              <div class="col-auto">
                <span class="font-weight-bold">Controles</span>
              </div>
              <div class="col text-right">
                <h2 class="mb-0 font-weight-bold">{{datachartControl.total}}</h2>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <p class="m-b-0   font-weight-bold">Mes/Año</p>
                  </div>
                  <div class="col-md-8">
                    <span class="float-right">
                      <div class="row">
                        <div class="col-md-5">
                          <input type="number" class="form-control form-control-sm" [(ngModel)]="filtersControls.month"
                          min="1" max="12">
                        </div>
                        <div class="col-md-5">
                          <input type="number" class="form-control form-control-sm" [(ngModel)]="filtersControls.year">
                        </div>
                        <div class="col-md-2">
                          <span class="text-c-green">
                            <i class="fas fa-sync icon-reload" [ngClass]="{'fa-spin': loadingChartControls}"
                            (click)="getCantControls()"></i>
                          </span>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="this.datachartControl.atendidos > 0 || this.datachartControl.confirmados > 0">
              <app-apex-chart chartID="analytics-customer1-chart" [chartConfig]="this.confChartControls"></app-apex-chart>
            </div> -->
            <div class="row mt-3 text-center">
              <div class="col">
                <h4 class="m-0"><i class="fas fa-circle f-10 m-r-5 text-warning font-weight-bold"></i>{{this.datachartControl.por_confirmar}}</h4>
                <span class="ml-3"><h6 class="font-weight-bold">Por confirmar</h6></span>
              </div>
              <div class="col">
                <h4 class="m-0"><i class="fas fa-circle text-success f-10 m-r-5 font-weight-bold"></i>{{this.datachartControl.confirmados}}</h4>
                <span class="ml-3"><h6 class="font-weight-bold">Confirmados</h6></span>
              </div>
              <div class="col">
                <h4 class="m-0"><i class="fas fa-circle text-primary f-10 m-r-5 font-weight-bold"></i>{{this.datachartControl.atendidos}}</h4>
                <span class="ml-3"><h6 class="font-weight-bold">Atendidos</h6></span>
              </div>
            </div>
          </app-card>
    </div>
</div>
