import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EnumeracionSpecificationDto } from 'src/app/models/discount-specifications';
import { DiscountSpecificationsService } from 'src/app/service/discount-specifications.service';


@Component({
  selector: 'app-modal-discount-specification',
  templateUrl: './modal-discount-specification.component.html',
  styleUrls: ['./modal-discount-specification.component.scss']
})
export class ModalDiscountSpecificationComponent implements OnInit {

  title = 'Especificar Descuento';

  formData: FormGroup;

  discountSpecification: EnumeracionSpecificationDto [] = []; 
  checked: boolean = false;
  descripcion:string;
  selectedCategories:any[]=[];
  showInput: boolean = false;
  selectSpecification:EnumeracionSpecificationDto;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private serviceDiscountSpecifications:DiscountSpecificationsService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllDiscountSpecifications()
  }

  initForm() {
  }

  getAllDiscountSpecifications(): void {
    this.discountSpecification =  [];
    this.serviceDiscountSpecifications.findAllDiscountSpecifications().subscribe({
      next: (resp) =>{
      this.discountSpecification = resp;
      },
      error: (error) => {
        console.log({ error });
      }
    });
  }
  
  onCheckboxChange() {
    this.showInput = this.selectedCategories.some(category => category.id === 3);
  }

  save():void{
    if(this.selectedCategories.length == 0 ){
      this.toastr.error('Debe seleecionar un tipo de descuento!!', 'Error!', {
        timeOut: 3000,
      });
      return;
    }

    for(let i = 0; i < this.selectedCategories.length ; i++){
      if(this.descripcion == null && this.selectedCategories[i].id === 3){

          this.toastr.error('Debe escribir una descripción!!', 'Error!', {
            timeOut: 3000,
          });
          return;
      } 
    };

    for(let i = 0; i < this.selectedCategories.length ; i++){
      if(this.selectedCategories[i].id == 3){
        this.selectedCategories[i].otherdescription = this.descripcion;
      }
    }
    
    this.activeModal.close('Save click');
  }

}
