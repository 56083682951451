import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TariffModel } from '../models/tariff.model';

@Injectable({
  providedIn: 'root'
})
export class TariffService {

  private _tariffToDiaryList: BehaviorSubject<TariffModel[]> = new BehaviorSubject(null);
  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  /**
     * Getter for tariff to diary
     */

  get tariffToDiaryList$(): Observable<any> {
    return this._tariffToDiaryList.asObservable();
  }

  getAll(): Observable<TariffModel[]> {
    return this.http.get<TariffModel[]>(`${this.baseUrl}/tariff`);
  }

  getOne(id: number): Observable<TariffModel> {
    return this.http.get<TariffModel>(`${this.baseUrl}/tariff/${id}`);
  }

  getDataToContract(): Observable<TariffModel[]> {
    return this.http.get<TariffModel[]>(`${this.baseUrl}/tariff/get-data/to-contract`);
  }

  insert(tariff: TariffModel): Observable<TariffModel> {
    return this.http.post<TariffModel>(`${this.baseUrl}/tariff`, tariff)
    .pipe(
      catchError(error => {
        if (error.status === 500 && error.error && error.error.message) {
          // Si es un error interno del servidor (500) y hay un mensaje personalizado
          return throwError({ error: error.error.message + ' nombre duplicado ' });
        } else {
          // Otros errores
          return throwError({ error: 'Error en la ejecución' });
        }
      })
    );
  }

  update(tariff: TariffModel, id: number): Observable<TariffModel> {
    return this.http.put<TariffModel>(`${this.baseUrl}/tariff/${id}`, tariff);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/tariff/${id}`)
  }

  getBySpecialty(id: number): Observable<TariffModel[]> {
    if (id === 0) {
      return this.http.get<TariffModel[]>(`${this.baseUrl}/tariff`);
    } else {
      return this.http.get<TariffModel[]>(`${this.baseUrl}/tariff/get-by-specialty/${id}`);
    }
  }

  getByDentalStatus(id: number): Observable<TariffModel[]> {
    return this.http.get<TariffModel[]>(`${this.baseUrl}/tariff/get-by-dental-status/${id}`);
  }

  getLabs(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/tariff/get-labs/all`);
  }

  getByBl(id: any): Observable<TariffModel[]> {
    return this.http.post<TariffModel[]>(`${this.baseUrl}/tariff/get-by-bl`, id);
  }

  getToDiary(): Observable<TariffModel[]> {
    return this.http.get<TariffModel[]>(`${this.baseUrl}/tariff/get/diary`).pipe(
      tap((response: TariffModel[]) => {
        this._tariffToDiaryList.next(response);
      })
    );
  }

  getQuotationTermsValues(): Observable<TariffModel[]> {
    return this.http.get<TariffModel[]>(`${this.baseUrl}/tariff/get-quotation/terms-values`);
  }
}
