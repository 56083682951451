<div class="modal-header modal-header-default modal_header">
    <h5 class="modal-title titulo-modal">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>

<div class="modal-body">

    <div class="table-responsive">

        <p-table #dt [value]="listContractPay" [(selection)]="selectedContracts" dataKey="id"
            styleClass="p-datatable-customers" [rowHover]="true" [rows]="5" [showCurrentPageReport]="true"
            [loading]="loading" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
            [globalFilterFields]="['name','contract','description','amount']">>

            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" pSortableColumn="id">Nº<p-sortIcon field="id"></p-sortIcon></th>
                    <th scope="col" pSortableColumn="client">Concepto<p-sortIcon field="client"></p-sortIcon></th>
                    <th scope="col" pSortableColumn="attorney">Fecha<p-sortIcon field="attorney"></p-sortIcon></th>
                    <th scope="col" pSortableColumn="amount">Monto<p-sortIcon field="amount"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contractPay>
                <tr class="p-selectable-row">
                    <td>
                        <span class="image-text"> </span>
                    </td>
                    <td>
                        <span class="image-text">{{contractPay.description}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{ObtenerSoloFecha(contractPay.date)}}</span>
                    </td>
                    <td>
                        <span class="image-text"> {{(contractPay.amount -contractPay.total.value).toFixed(2)}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>

    <form class="editForm" *ngIf="formData" [formGroup]="formData" class="pt-4">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label class="form-control-label" for="date">Fecha <small class="text-danger">(*)</small></label>
                    <input class="form-control input-md" [id]="formFields.Date" [name]="formFields.Date" type="date"
                        [ngClass]="{'is-invalid': getErrorMessage(formFields.Date)}"
                        [formControlName]="this.formFields.Date">
                    <label *ngIf="getErrorMessage(formFields.Date)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar la fecha
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-control-label" for="doctor">Doctor <small class="text-danger">(*)</small></label>
                    <ng-select [items]="doctorList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Doctor" [id]="this.formFields.Doctor"
                        [formControlName]="this.formFields.Doctor" [clearable]="false">
                    </ng-select>
                    <label *ngIf="getErrorMessage(formFields.Doctor)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar un doctor
                    </label>
                </div>
            </div>
            
        </div>
        <div class="row" >
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" for="coin">Moneda <small class="text-danger">(*)</small></label>
                    <ng-select [items]="coinList" bindLabel="code" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Coin" [id]="this.formFields.Coin" [clearable]="false"
                        [formControlName]="this.formFields.Coin">
                    </ng-select>
                    <label *ngIf="getErrorMessage(formFields.Coin)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Atención debe seleccionar una moneda
                    </label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" for="idpaymentmethod">Forma pago <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="pmList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.PaymentMethod" [id]="this.formFields.PaymentMethod" [clearable]="false"
                        [formControlName]="this.formFields.PaymentMethod" (change)="setComision()"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.PaymentMethod)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Seleccione una forma de pago
                    </label>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.TypeDiscount">Descuento<small
                        class="text-danger">(*)</small></label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <p-button icon="pi pi-eraser" 
                                (click)="clean()" styleClass="p-button-warning" styleClass="p-button-sm"></p-button>
                        </div>
                        <ng-select [items]="discountList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                            [name]="this.formFields.TypeDiscount" [id]="this.formFields.TypeDiscount" [clearable]="false"
                            [formControlName]="this.formFields.TypeDiscount" (change)="onDiscountChange($event)"
                            (ngModelChange)="onMultiSelectChange()" class="flex-grow-1"></ng-select>
                        <label *ngIf="getErrorMessage(formFields.TypeDiscount)" id="validation-date-error"
                            class="error jquery-validation-error small form-text invalid-feedback">
                            El tratamiento es requerido
                        </label>
                    </div>
                </div>
            </div>
        </div>     

        
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.BusisnessLine">Linea de Negocio <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="blList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.BusisnessLine" [id]="this.formFields.BusisnessLine" [clearable]="false"
                        [formControlName]="this.formFields.BusisnessLine" (change)="getSpecialtys()"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.BusisnessLine)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        La Linea de negocio es requerida
                    </label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.Specialty">Especialidad <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="specialtyList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Specialty" [id]="this.formFields.Specialty" [clearable]="false"
                        [formControlName]="this.formFields.Specialty" (change)="getTariff()"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.LaboratoryCost)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        La especialidad es requerida
                    </label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.Tariff">Tratamiento <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="tariffList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.Tariff" [id]="this.formFields.Tariff" [clearable]="false"
                        [formControlName]="this.formFields.Tariff" (change)="setMonto(1)"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.Tariff)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        El tratamiento es requerido
                    </label>
                </div>
            </div>
        </div>
        <!--<div class="row" *ngIf="showDiscountType">
            <div class="col-6">
                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.IdDiscountType">Motivo descuento <small
                            class="text-danger">(*)</small></label>
                    <ng-select [items]="discountTypeList" bindLabel="name" bindValue="id" placeholder="Seleccione"
                        [name]="this.formFields.IdDiscountType" [id]="this.formFields.IdDiscountType"
                        [clearable]="false" [formControlName]="this.formFields.IdDiscountType"></ng-select>
                    <label *ngIf="getErrorMessage(formFields.IdDiscountType)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        El campo requerido
                    </label>
                </div>
            </div>

        </div>-->
        <div class="row" *ngIf="showDiscountType">
            <div class="col-6">

                <div class="form-group">
                    <label class="form-control-label" [for]="this.formFields.IdDiscountType">Motivo descuento <small
                        class="text-danger">(*)</small></label>

                    <input [name]="this.formFields.IdDiscountType" [id]="this.formFields.IdDiscountType" type="number"
                        class="form-control" [type]="text"
                        [formControlName]="this.formFields.IdDiscountType">
                        
                    <label *ngIf="getErrorMessage(formFields.IdDiscountType)" id="validation-date-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        El campo requerido
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" for="quantity">Cantidad</label>
                    <input [name]="this.formFields.Quantity" [id]="this.formFields.Quantity" type="number"
                        class="form-control" min="1" step="1" pattern="[0-9]*"
                        [formControlName]="this.formFields.Quantity" (change)="setMonto(2)" >
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" [for]="formFields.Amount">Valor Unit...</label>
                    <input [name]="this.formFields.Amount" [id]="this.formFields.Amount" type="number"
                        class="form-control" [formControlName]="this.formFields.Amount" step=".01"
                        [min]="this.minimalUnitValue" (change)="setMonto(2)" >
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label for="discount" class="form-control-label">Descuento <small
                            class="text-primary">{{coinDet}}</small></label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">%</span>
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="checkbox" (change)="setMonto(2)"
                                    [name]="this.formFields.DiscountType" [id]="this.formFields.DiscountType"
                                    [formControlName]="this.formFields.DiscountType">
                            </div>
                        </div>
                        <input class="form-control form-control-sm" [name]="this.formFields.DiscountAmount"
                            [id]="this.formFields.DiscountAmount" type="number" (change)="setMonto(2)"
                            [formControlName]="this.formFields.DiscountAmount">
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label" for="total">Total</label>
                    <input [name]="this.formFields.Total" [id]="this.formFields.Total" type="number"
                        class="form-control" [formControlName]="this.formFields.Total" step=".01" min="0" readonly>
                </div>
            </div>
        </div>
    </form>

    <div class="row">
        <div class="col-12">
            <app-clinic-history-vouchers [id]="this.idclinchistory" (archivosCargados)="manejarArchivosCargados($event)"></app-clinic-history-vouchers>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" uiSref="work" class="btn btn-outline-primary" [disabled]="!formData.valid" (click)="save()"
        type="button">
        Guardar
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Salir
    </button>
</div>