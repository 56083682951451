import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { UnionpaymentdetailService } from 'src/app/components/appointment-detail/union-paymen-attention/unionpaymentdetail.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-union-paymen-attention-one',
  templateUrl: './union-paymen-attention-one.component.html',
  styleUrls: ['./union-paymen-attention-one.component.scss']
})
export class UnionPaymenAttentionOneComponent implements OnInit {

  @Input() idReservation!:number;
  @Input() iduser!:number;
  showModal: boolean = false;
  reservation: any[] = [];
  payments: any[] = [];
  selectedPayments: any[] = [];
  totalDollars: number = 0;
  totalSoles: number = 0;
  doctores: any[]= [];
  coin: any[]= [];
  selectedDoctor: number;
  selectedCoin: number;
  validacion_cerrado: boolean;

  selectedReservations: number[] = [];

  constructor(private paymentService: UnionpaymentdetailService,public activeModal: NgbActiveModal, private toastr: ToastrService,) { }

  ngOnInit(): void {
    //console.log('Este es el nuevo componente UnionPaymenAttentionComponent');
    
    this.loadPayments();
  }

  open(): void {
    this.activeModal.close('Open');
  }

  close(): void {
    if (this.validacion_cerrado) {
      this.activeModal.close('Close');
    }else{
      Swal.fire({
        title: '¿Estás seguro de continuar?',
        text: "No seleccionaste un pago asociado",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FFC80C',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sí, salir!',
        cancelButtonText: 'No, cancelar!'
      }).then((result) => {
        if (result.value) {
          this.activeModal.close(false);
          return false
        }else{
          this.toastr.info('Continua entonces ...', 'info!', {
            timeOut: 3000,
          });
        }
      });
    }
    

  }

  loadPayments() {
    //console.log("Listando datos");
    
    this.paymentService.getOneReservation(this.idReservation).subscribe(
      (data: any[]) => {
        this.reservation = data;
        const uniqueCoinIds = new Set<number>();
        data.forEach(item => uniqueCoinIds.add(item.coId));
        this.coin = Array.from(uniqueCoinIds).map(id => ({
          id: id,
          nombre: id === 1 ? "S/. (SOLES)" : "$ (DÓLARES)"
        }));

        const uniqueDoctors = {};

        data.forEach(item => {
          uniqueDoctors[item.doctorId] = item.nameQuote;
        });

        this.doctores = Object.keys(uniqueDoctors).map(id => ({
          id: parseInt(id),
          nombre: uniqueDoctors[id]
        }));

        //console.log("estos son los doctores: ",this.doctores);
        //console.log("Datos de la moneda: ",this.coin);
        //console.log("Datos de la reserva: ",this.reservation);
        //console.log("Datos de traidos: ",JSON.stringify(data));
        
      }),
      catchError((error) => {
        console.error('Error al cargar los pagos', error);
        close()
        throw error;
      });
  }

  confirmPayments() {
    //console.log('----------------- Estos son los campos: -----------------');
    //console.log("\t- Los pagos son: ",this.selectedPayments);
    //console.log("\t- El usuario es: ",this.iduser);
    if (this.selectedPayments.length > 0) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: "Este paso no es reversible",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, confirmar!',
        cancelButtonText: 'No, cancelar!'
      }).then((result) => {
        if (result.value) {
          this.paymentService.insertconfirmPayments(this.selectedPayments, this.iduser,this.idReservation).subscribe(
            (res) => {
              Swal.fire('¡Ok!', 'Pagos confirmados correctamente', 'success');
              this.selectedPayments = [];
              this.totalDollars = 0;
              this.totalSoles = 0;
              this.loadPayments();
              this.activeModal.close(true);
              this.validacion_cerrado = true
              return true
            },
            (err) => {
              Swal.fire('¡Error!', 'Confirmar los pagos', 'error');
              this.activeModal.close(false);
              this.validacion_cerrado = false
              return false
            }
          );
        }
      });
    }else{
      this.toastr.error('Debe seleccionar almenos un pago!!', 'Error!', {
        timeOut: 3000,
      });
      this.activeModal.close(false);
      this.validacion_cerrado = false
      return false;
    }
   
  }

  cancelPayment() {
    this.selectedPayments = [];
    this.totalDollars = 0;
    this.totalSoles = 0;
    this.close();
  }

  findForDate(): void {
    const coin = this.selectedCoin;
    const doctor = this.selectedDoctor;
    let until = (document.getElementById('until') as HTMLInputElement).value;
    let since = (document.getElementById('since') as HTMLInputElement).value;

    let filteredReservations = this.reservation;

    if (coin !== null) {
      filteredReservations = filteredReservations.filter(reservation => reservation.coId.toString() === coin);
      //console.log("Filtros por moneda: ",filteredReservations);
    }

    if (doctor !== null) {
      filteredReservations = filteredReservations.filter(reservation => reservation.doctorId.toString() === doctor);
      //console.log("Filtros por Doctor: ",filteredReservations);
    }

    if (until !== '') {
      const untilDate = new Date(until);
      filteredReservations = filteredReservations.filter(reservation => new Date(reservation.maat_date) <= untilDate);
      //console.log("Filtros por Desde: ",filteredReservations);
    }

    if (since !== '') {
      const sinceDate = new Date(since);
      filteredReservations = filteredReservations.filter(reservation => new Date(reservation.maat_date) >= sinceDate);
      //console.log("Filtros por Hasta: ",filteredReservations);
    }

    this.reservation = filteredReservations;
}




  onCheckboxChange(event: any, moneda : number) {
    const id = +event.target.value;
    const currency = moneda;

    if (event.target.checked) {
      this.selectedPayments.push(id);

      if (currency === 1) {
        this.totalSoles += this.getReservationById(id).maat_quantity * this.getReservationById(id).maat_value;
      } else {
        this.totalDollars += this.getReservationById(id).maat_quantity * this.getReservationById(id).maat_value;
      }
    } else {
      const index = this.selectedPayments.indexOf(id);
      if (index !== -1) {
        this.selectedPayments.splice(index, 1);
      }

      if (currency === 1) {
        this.totalSoles -= this.getReservationById(id).maat_quantity * this.getReservationById(id).maat_value;
      } else {
        this.totalDollars -= this.getReservationById(id).maat_quantity * this.getReservationById(id).maat_value;
      }
    }
  }

  // Método para obtener la reserva por su ID
  getReservationById(id: number) {
    return this.reservation.find(reservation => reservation.maat_id === id);
  }
}
