import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { BankAccount } from 'src/app/models/finance/bank-account.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  private baseUrl: string = `${environment.apiUrl}/bank-accounts`;

  constructor(private http: HttpClient) { }

  getById(id: number): Observable<BankAccount> {
    return this.http.get<BankAccount>(`${this.baseUrl}/${id}`);
  }

  getAll(): Observable<BankAccount[]> {
    return this.http.get<BankAccount[]>(`${this.baseUrl}`);
  }

  insert(bank: BankAccount): Observable<BankAccount> {
    return this.http.post<BankAccount>(`${this.baseUrl}`, bank);
  }

  update(id: number, bank: BankAccount): Observable<BankAccount> {
    return this.http.put<BankAccount>(`${this.baseUrl}/${id}`, bank);
  }

  delete(id: number): Observable<Response> {
    return this.http.delete<Response>(`${this.baseUrl}/${id}`);
  }
}
