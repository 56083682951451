import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { LanguageApp } from 'src/app/config/data-table.language';
import { NgxSpinnerService } from 'ngx-spinner';

// Models
import { LabStateModel } from 'src/app/models/mat/lab-state.model';

// Srevices
import { LabStateService } from '../../service/mat/lab-state.service';

@Component({
  selector: 'app-lab-state',
  templateUrl: './lab-state.component.html',
  styleUrls: ['./lab-state.component.scss']
})
export class LabStateComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  formInput: LabStateModel;
  closeModal = 'Close click';
  dataList: LabStateModel[] = [];
  public loading = false;

  constructor(
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    private labStateService: LabStateService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.getList();
    this.formInput = {
      id: 0,
      name: '',
      chip: false
    };
  }

  getList(): void{
    this.loading = true;
    this.dataList = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: LanguageApp.spanish_datatables,
      search: true,
      responsive: true,
      order: [0],
      orderClasses: true
    };
    this.labStateService.getAll().subscribe(
      res => {
        this.dataList = res;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error('Error al buscar la moneda ', err.error);
        this.toastr.success('Atención, ocurrio un error al obtener los estados!!', 'Error!', {
          timeOut: 3500,
        });
      }
    );
  }

  setItem(item: LabStateModel): void{
    this.formInput = {
      id: item.id,
      name: item.name,
      chip: item.chip,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      iduser: item.iduser,
    };
  }

  delete(data: LabStateModel): void{
    Swal.fire({
      title: 'Atención!!!!',
      text: `¿Está seguro que desea eliminar el estado ${data.name}?`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.value) {
        this.labStateService.delete(data.id)
        .subscribe(
          res => {
            Swal.fire('ok!', 'Registro eliminado satisfactoriamente', 'success');
            this.activeModal.close('Dalete');
          },
          err => {
            Swal.fire('Error!', 'No se puedo borrar la sede', 'error');
          }
        );
      }
    });
  }

  onSubmit(): void{
    this.spinner.show();
    if (this.formInput.id > 0){
      this.labStateService.update(this.formInput, this.formInput.id)
      .subscribe(
        res => {
          this.activeModal.close('Save');
          this.spinner.hide();
          this.toastr.success('Estado de aparato de laboratorio editado correctamente!!', 'Ok!', {
            timeOut: 3000,
          });
        },
        err => {
          this.spinner.hide();
          this.toastr.error('Ocurrio un error al editar los datos!!', 'Error!', {
            timeOut: 3000,
          });
          console.error('Error al insertar ', err.error);
        }
      );
    }else{
      this.labStateService.insert(this.formInput)
      .subscribe(
        res => {
          this.activeModal.close('Save');
          this.spinner.hide();
          this.toastr.success('Estado de aparato de laboratorio registrado correctamente!!', 'Ok!', {
            timeOut: 3000,
          });
        },
        err => {
          this.spinner.hide();
          this.toastr.error('Ocurrio un error al insertar los datos!!', 'Error!', {
            timeOut: 3000,
          });
          console.error('Error al insertar ', err.error);
        }
      );
    }
  }

}
