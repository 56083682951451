<div class="modal-header modal_header">
    <h5 class="modal-title titulo-modal">Cotización {{data.id}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div class="row btn-page">
        <!-- [ Invoice ] start -->
        <div class="container" id="printTable">            
            <div class="card">
                <div class="row invoice-contact">
                    <div class="col-md-8">
                        <div class="invoice-box row">
                            <div class="col-sm-12">
                                <table class="table table-responsive invoice-table table-borderless p-l-20">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <a href="index.html" class="b-brand">
                                                    <img class="img-fluid" src="assets/images/logo-dark.png" alt="Maxillaris">
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Maxillaris </td>
                                        </tr>
                                        <tr>
                                            <td>Miraflores - Lima - Perú</td>
                                        </tr>
                                        <tr>
                                            <td><a class="text-secondary" href="mailto:demo@gmail.com" target="_top">info@maxillaris.com</a></td>
                                        </tr>
                                        <tr>
                                            <td>+51 999.888.777</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                </div>
                <div class="card-body">
                    <div class="row invoive-info">
                        <div class="col-md-4 col-xs-12 invoice-client-info">
                            <h6>Datos del paciente :</h6>
                            <h6 class="m-0">{{data.clinicHistory.name}} {{data.clinicHistory.lastNameFather}} {{data.clinicHistory.lastNameMother}}</h6>
                            <p class="m-0 m-t-10">{{data.clinicHistory.address}}</p>
                            <p class="m-0">{{data.clinicHistory.cellphone}}</p>
                            <p><a class="text-secondary" [href]="'mailto:'+data.clinicHistory.email" target="_top">{{data.clinicHistory.email}}</a></p>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <h6>Datos de la cotización :</h6>
                            <table class="table table-responsive invoice-table invoice-order table-borderless">
                                <tbody>
                                    <tr>
                                        <th>Fecha:</th>
                                        <td>{{data.clinicHistory.date | date: 'dd-MM-yyyy'}}</td>
                                    </tr>
                                    <tr>
                                        <th>Estado:</th>
                                        <td><span class="label label-warning">Pendiente</span></td>
                                    </tr>
                                    <!--<tr>
                                        <th>Nro.:</th>
                                        <td>#146859</td>
                                    </tr>-->
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <h6 class="m-b-20">Nro. Cotización <span>#{{data.id}}</span></h6>
                            <table class="table table-responsive invoice-table invoice-order table-borderless">
                                <tbody>
                                    <!-- <tr>
                                        <th>Especialidad:</th>
                                        <td>{{data.specialty.name}}</td>
                                    </tr> -->
                                    <tr>
                                        <th>Doctor:</th>
                                        <td>{{data.doctor.name}}</td>
                                    </tr>
                                    <!-- <tr>
                                        <th>Moneda:</th>
                                        <td>{{data.coin.code}}</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table invoice-detail-table">
                                    <thead>
                                        <tr class="thead-default">
                                            <th>Tratatimiento</th>
                                            <th class="w100 text-right">Precio</th>
                                            <th class="w100 text-right">Cantidad</th>
                                            <th class="w100 text-right">Descuento</th>
                                            <th class="w100 text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let det of detail">
                                            <td><p class="m-0">{{det.tariff.name}}</p></td>
                                            <td class="text-right">
                                                {{det.coin.id === 1 ? 'S/' : '$'}} {{det.price | number : '1.0-2'}}
                                            </td>
                                            <td class="text-right">{{det.quantity}}</td>
                                            <td class="text-right">
                                                {{det.coin.id === 1 ? 'S/' : '$'}} {{det.discount | number : '1.0-2'}}
                                            </td>
                                            <td class="text-right">
                                                {{det.coin.id === 1 ? 'S/' : '$'}} {{det.total | number : '1.0-2'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table table-responsive invoice-table invoice-total">
                                <tbody>
                                    <!-- <tr>
                                        <th>Sub Total:</th>
                                        <td>{{data.subtotal | number : '1.0-2'}}</td>
                                    </tr>
                                    <tr>
                                        <th>IGV (18%) :</th>
                                        <td>{{data.tax | number : '1.0-2'}}</td>
                                    </tr>
                                    <tr>
                                        <th>Descuento:</th>
                                        <td>{{data.discount | number : '1.0-2'}}</td>
                                    </tr> -->
                                    <tr class="text-info">
                                        <td>
                                            <h5 class="text-primary m-r-10">Total $:</h5>
                                        </td>
                                        <td>
                                            <h5 class="text-primary">{{total_usd | number : '1.0-2'}}</h5>
                                        </td>
                                    </tr>
                                    <tr class="text-info">
                                        <td>
                                            <h5 class="text-primary m-r-10">Total S/:</h5>
                                        </td>
                                        <td>
                                            <h5 class="text-primary">{{total_sol | number : '1.0-2'}}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <h6>Terminos y condiciones :</h6>
                            <p>...
                            </p>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
        <!-- [ Invoice ] end -->
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn waves-effect waves-light btn-primary btn-print-invoice m-b-10">Imprimir</button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')">
      Salir
    </button>
</div>