import { Component, Input, OnInit } from '@angular/core';
import { ClinicHistoryModel } from 'src/app/models/clinic-history.model';
import { ProgramationModel } from 'src/app/models/programation.model';
import { TariffModel } from 'src/app/models/tariff.model';
import { Doctor } from '../../../doctor/models/doctor.model';
import { CoinModel } from 'src/app/models/coin.model';
import { EnvironmentDoctorModel } from 'src/app/models/environment-doctor.model';
import { MedicalActAttention } from 'src/app/models/main/medical-act.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TariffService } from 'src/app/service/tariff.service';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { DoctorService } from '../../../doctor/services/public/doctor.service';
import { EnvironmentDoctorService } from 'src/app/service/environment-doctor.service';
import { ProgramationService } from 'src/app/service/programation.service';
import { ReservationService } from 'src/app/service/main/reservation.service';
import { CoinService } from 'src/app/service/coin.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-medical-appointment-two',
  templateUrl: './medical-appointment-two.component.html',
  styleUrls: ['./medical-appointment-two.component.scss']
})
export class MedicalAppointmentTwoComponent implements OnInit {

  @Input() data: any;
  @Input() usuario:any;

  formInput: ProgramationModel;
  formInputOld: ProgramationModel;
  listPatient: ClinicHistoryModel[] = [];
  loadingSelectPatient = false;
  loadingSelectTariff = false;
  loadingSelectDoctor = false;
  loadingSelectDoctor2 = false;
  listTariff: TariffModel[] = [];
  listDoctors: Doctor[] = [];
  listDoctors2: Doctor[] = [];
  listCoin: CoinModel[] = [];
  environmentList: EnvironmentDoctorModel[] = [];
  title = 'Nueva Cita';
  reprograming = false;

  // Autocomplet
  searching = false;
  searchFailed = false;
  patient = '';
  showAlert = false;

  // Si el usuario viene del modulo de atención
  attention: MedicalActAttention;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    private servicePatient: ClinicHistoryService,
    private serviceTariff: TariffService,
    private serviceDoctor: DoctorService,
    private edService: EnvironmentDoctorService,
    private serviceReservation: ProgramationService,
    private reservationService: ReservationService,
    private coinService: CoinService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.attention = this.reservationService.attention;
    if (this.data.acction) {
      this.formInput = {
        environment: this.data.idenvironment,
        date: moment(this.data.date).format('YYYY-MM-DD'),
        appointment: `${this.data.appointment}`,
        reason: this.data.reason,
        pending_payment: this.data.pending_payment,
        amount: this.data.amount,
        coin: this.data.idcoin
      };


      this.get();
      this.title = 'Editar cita';
    } else {
      this.formInput = {
        environment: this.data.id,
        date: this.data.date,
        appointment: `${this.data.since}:00-${this.data.until}:00`,
        doctor_id2: null,
        pending_payment: this.data.pending_payment,
        amount: this.data.amount,
        coin: this.data.coin
      };
    }
    this.formInputOld = {
      environment: 0,
      date: '',
      appointment: '',
      pending_payment: false,
      amount: null,
      coin: null
    };
    this.getEnvironment();
    this.onGetDoctors2();
    if (sessionStorage.getItem('oldreservation')) {
      this.title = 'Reprogramar cita';
      this.reprograming = true;
      this.get();
    }
    if (this.attention) {
      this.patient = `${this.attention.patient.documentNumber} ${this.attention.patient.name} ${this.attention.patient.lastNamefather} ${this.attention.patient.lastNameMother}`;
      this.formInput.patient = this.attention.patient.id
    }
    //console.log('tercer modal',this.usuario);
    
    this.getCoin();
  }

  getPatient(): void {
    this.loadingSelectPatient = true;
    this.listPatient = [];
    this.servicePatient.getAll()
      .subscribe(
        res => {
          res.forEach((patient: ClinicHistoryModel) => {
            this.listPatient.push(patient);
          });
          this.loadingSelectPatient = false;
        },
        err => {
          this.loadingSelectPatient = false;
          console.log(err.error);
        }
      );
  }

  getTariffs(): void {
    this.loadingSelectTariff = true;
    this.listTariff = [];
    //const environ = _.find(this.environmentList, {id: this.formInput.environment});
    this.serviceTariff.getToDiary()
      .subscribe(
        res => {
          switch (this.usuario.cargo.nombre) {
            case 'EJECUTIVO DE APNEA':
              res.forEach((tariff: TariffModel) => {
                if(tariff.id == 240){
                  this.listTariff.push(tariff);
                }
              }); 
              break;
            case 'EJECUTIVO DE OI':
              res.forEach((tariff: TariffModel) => {
                if(tariff.id == 193){
                  this.listTariff.push(tariff);
                }
              }); 
              break;
            case 'ASISTENTE DENTAL':
              res.forEach((tariff: TariffModel) => {
                if(tariff.id == 193 || tariff.id == 240){
                  this.listTariff.push(tariff);
                }
              }); 
              break;
            case 'SUPERVISOR DE ASISTENTE DENTALES':
              res.forEach((tariff: TariffModel) => {
                if(tariff.id == 193 || tariff.id == 240){
                  this.listTariff.push(tariff);
                }
              }); 
            default:
              res.forEach((tariff: TariffModel) => {
                if(tariff.id == 193 || tariff.id == 240){
                  this.listTariff.push(tariff);
                }
              }); 
              break;
          }
          this.loadingSelectTariff = false;
        },
        err => {
          this.loadingSelectTariff = false;
          console.log(err.error);
        }
      );
  }

  getDoctors(): void {
    this.loadingSelectDoctor = true;
    this.listDoctors = [];
    const environ = _.find(this.environmentList, { id: this.formInput.environment });
    const day = moment(this.data.date).tz('America/Lima').day();
    this.serviceDoctor.getByBl(environ.businessline, day)
      .subscribe(
        res => {
          // Busco si está disponible en el horario
          res.forEach(dr => {
            let validate = true;
            // Validamos si el doctor esta en el horario de la mañana
            if (dr.morning_schedule) {
              let morning_since: any;
              let morning_until: any;
              let time: any;
              if (this.data.acction && this.data.acction === 'edit') {
                morning_since = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
                morning_until = moment(`${this.formInput.date} ${this.data.appointment.split('-')[1]}`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
              } else {
                morning_since = moment(`${this.formInput.date} ${dr.morning_schedule.split('-')[0]}:00`).tz('America/Lima');
                morning_until = moment(`${this.formInput.date} ${dr.morning_schedule.split('-')[1]}:00`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.since}:00`).tz('America/Lima');
              }
              if (time >= morning_since && time < morning_until) {
                validate = false;
                this.listDoctors.push(dr);
              }
            }
            // Validamos si el doctor esta en el horario de la tarde
            if (dr.afternoon_schedule && validate) {
              let afternoon_since: any;
              let afternoon_until: any;
              let time: any;
              if (this.data.acction && this.data.acction === 'edit') {
                afternoon_since = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
                afternoon_until = moment(`${this.formInput.date} ${this.data.appointment.split('-')[1]}`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.appointment.split('-')[0]}`).tz('America/Lima');
              } else {
                afternoon_since = moment(`${this.formInput.date} ${dr.afternoon_schedule.split('-')[0]}:00`).tz('America/Lima');
                afternoon_until = moment(`${this.formInput.date} ${dr.afternoon_schedule.split('-')[1]}:00`).tz('America/Lima');
                time = moment(`${this.formInput.date} ${this.data.since}:00`).tz('America/Lima');
              }
              if (time >= afternoon_since && time < afternoon_until) {
                this.listDoctors.push(dr);
              }
            }
          });
          if (this.attention) {
            this.formInput.doctor = this.attention.doctor.id;
          }
          this.loadingSelectDoctor = false;
        },
        err => {
          this.loadingSelectDoctor = false;
          console.log(err.error);
        }
      );
  }

  /**
   * Obtenemos solo los doctores
   * OI del día
   */
  onGetDoctors2(): void {
    this.loadingSelectDoctor2 = true;
    this.listDoctors2 = [];
    this.serviceDoctor.getByBl([2], moment(this.data.date).day())
      .subscribe(
        res => {
          // Obtenemos solo los doc
          this.loadingSelectDoctor2 = false;
          this.listDoctors2 = res;
        },
        (err: HttpErrorResponse) => {
          this.loadingSelectDoctor2 = false;
          this.toastr.error(
            'Ocurrio un error al obtener los dotores de OI',
            'Ateción',
            { timeOut: 4000, progressBar: true, closeButton: true }
          );
        }
      );
  }

  getEnvironment(): void {
    this.environmentList = [];
    this.edService.getAll().subscribe(
      res => {
        this.environmentList = res;
        this.getDoctors();
        this.getTariffs();
      },
      err => {
        console.error('Error al obtener los consultorios');
      }
    );
  }

  addappointmentByPatient(): void {
    this.spinner.show();
    if (this.formInput.id > 0) {
      this.serviceReservation.update(this.formInput, this.formInput.id).subscribe(
        res => {
          this.spinner.hide();
          this.toastr.success(
            'La reprogramación fue registrada correctamente',
            'Ok!',
            { timeOut: 3000, progressBar: true }
          );
          sessionStorage.removeItem('oldreservation');
          this.activeModal.close('Save click');
          this.reservationService.attention = null;
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'Ocurrio un error al registrar la cita',
            'Atención',
            { timeOut: 3000, progressBar: true }
          );
        }
      );
    } else {
      this.serviceReservation.insert(this.formInput).subscribe(
        res => {
          this.spinner.hide();
          this.toastr.success(
            'La reserva fue registrada correctamente',
            'Ok!',
            { timeOut: 3000, progressBar: true }
          );
          this.activeModal.close('Save click');
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status === 400) {
            this.toastr.warning(
              err.error.message,
              'Atención',
              { timeOut: 3500, progressBar: true }
            );
          } else {
            this.toastr.error(
              'Ocurrio un error al registrar la cita',
              'Atención',
              { timeOut: 3500, progressBar: true }
            );
          }
        }
      );
    }
  }

  cancelRepro(): void {
    this.toastr.info(
      'Ok!', 'Reprogramación Cancelada', { timeOut: 3000, progressBar: true }
    );
    sessionStorage.removeItem('oldreservation');
    this.activeModal.close('Save click');
  }

  get(): void {
    let id: number;
    if (this.data.acction && this.data.acction === 'edit') {
      id = this.data.id;
    } else {
      id = Number(sessionStorage.getItem('oldreservation'));
    }
    this.serviceReservation.getOne(id)
      .subscribe(
        (res: any) => {
          this.formInput.id = res.id;
          this.formInput.patient = res.idpatient;
          this.patient = res.patient;
          this.formInput.doctor = res.iddoctor;
          this.formInput.doctor_id2 = res.iddoctor2;
          if (res.idtariff) {
            this.formInput.tariff = res.idtariff;
          }
          this.formInput.pending_payment = res.pending_payment;
          this.formInput.amount = res.amount;
          this.formInput.coin = res.idcoin;
          this.formInput.reason = res.reason;
          if (!this.data.acction) {
            this.formInputOld = res;
            this.formInputOld = {
              environment: res.idenvironment,
              date: moment(res.date).format('YYYY-MM-DD'),
              appointment: res.appointment,
              pending_payment: res.pending_payment,
              amount: res.amount,
              coin: res.coin
            };
          }
        },
        err => {
          console.log('Error al buscar la reserva');
        }
      );
  }

  validateDoctor(): void {
    this.spinner.show();
    this.serviceReservation.validateDoctor(this.formInput.doctor, this.formInput.date, this.formInput.appointment)
      .subscribe(
        res => {
          this.spinner.hide();
          if (res === 1) {
            this.formInput.doctor = null;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene una reserva a la misma hora en otro consultorio',
              type: 'warning',
            });
          } else if (res === 2) {
            this.formInput.doctor = 0;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene bloqueado ese horario',
              type: 'warning',
            });
          }
        },
        err => {
          this.spinner.hide();
          console.log('Errro Validación ');
        }
      );
  }

  /* SELECT DEL AUTO COMPLETADO */
  selectedItem(it: any): void {
    this.showAlert = false;
    const { item } = it;
    this.formInput.patient = item.id;
    if (Number(item.quotasPending) > 0) {
      this.showAlert = true;
    }
  }

  /* AUTOCOMPLETADO DE LOS MEDICAMENTOS **/
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.servicePatient.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  /**
   * Used to format the result data from the lookup into the
   * display and list values. Maps `{name: 'band', id:'id' }` into a string
   */
  resultFormatBandListValue(value: any): string {
    return value.name;
  }
  /**
   * Initially binds the string value and then after selecting
   * an item by checking either for string or key/value object.
   */
  inputFormatBandListValue(value: any): any {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  getCoin(): void {
    this.listCoin = [];
    this.coinService.getAll().subscribe(resp => {
      this.listCoin = resp;
    });
  }

}
