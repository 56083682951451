import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';

import { DoctorDiaryService } from "../../services/public/doctor-diary.service";
import { Programming } from '../../models/programming.model';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-doctor-diary',
  templateUrl: './doctor-diary.component.html',
  styleUrls: ['./doctor-diary.component.scss']
})
export class DoctorDiaryComponent implements OnInit {

  programming: Programming[] = [];
  session: any = {};
  showFilters = false;
  loading = false;
  sesiones : any;
  validator_sesion = false;

  constructor(
    private store: Store<{ session: any }>,
    private service: DoctorDiaryService,
    private spinner: NgxSpinnerService,
    private users: AuthService
  ) { }

  ngOnInit(): void {
    this.getSession();
    console.log("Ni bien carga aparece aqui: modal principal");
    
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.showFilters = true;
          this.getUserDetail();
          if (sess.doctor) {
            // this.filterDoctor = sess.doctor.id;
            
          }
        }
      });
  }

  getUserDetail(): void {

    this.users.getUsersDetail(this.session.id).subscribe(
      user=>{
        this.sesiones=user;
        console.log(this.sesiones);
        console.log("Finalmenteee: ",this.sesiones[0].cargo.nombre);
        //agenda 1 usuarios: ejecutivo ofm , ejecutivo apnea y ejecutivo oi
      },error=>{
        console.log({ error });
        this.loading = false;
      }
    )
  }

  setSchedule(event: any): void {
    this.loading = true;
    const idcampus = this.session.idcampus;
    this.programming = [];
    this.spinner.show();
    
    this.service.getProgrammingsin(event,idcampus).subscribe({
      next: (resp) => {
        //console.log('respuesta',resp);
        
        this.loading = false;
        this.programming = resp;
        this.spinner.hide();
      },
      error: (error) => {
        console.log({ error });
        this.loading = false;
      }
    });
  }

}
