import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  session: any = {};
  role: string = '';
  constructor(private store: Store<{session: any}>) { }

  ngOnInit(): void {
    this.getSession();
  }

  getSession(): void{
    this.store.select('session')
    .subscribe(
      sess => {   
      if(sess.id){
          this.session = sess;
          //console.log("sess.roles", sess.roles);
          this.role = sess.roles.dashboard;
        }         
      },
      err => {
        console.error("Sessión error ", err.error);
        
      }
    );
  }

}
