<div class="row col-12 mb-1">
    <button type="button" class="btn btn-primary btn-sm" (click)="onForm(0)">
      <i class="fas fa-notes-medical"></i> Nueva Nota
    </button>
  </div>
  <div class="d-flex align-items-center" *ngIf="loadingNotes">
    <strong>Loading...</strong>
    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
  </div>
  <p *ngIf="notes.length === 0 && !loadingNotes">
    <ngb-alert [dismissible]="false" [type]="'info'">
      <strong><i class="fas fa-info-circle"></i></strong>
      No hay notas registradas
    </ngb-alert>
  </p>
  <div class="row" *ngIf="notes.length > 0">
    <div class="col-md-4 col-sm-12" *ngFor="let data of notes">
        <app-card cardClass="card-border-c-green" blockClass="card-task" [customHeader]="true">
            <div class="app-card-header">
                <a href="javascript:" class="text-secondary">{{data.doctor.nameQuote}}</a>
                <span class="label label-primary float-right">
                    {{data.createdAt | date:"dd-MM-yyyy"}}
                </span>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <p class="task-due"><strong> {{data.title}}</strong></p>
                    <p class="task-detail">{{data.note}}</p>
                </div>
                <div class="col-sm-12">
                    <button class="btn btn-sm btn-outline-warning float-right" (click)="this.onForm(data.id)">
                        <i class="fas fa-edit"></i>
                    </button>
                </div>
            </div>
        </app-card>
    </div>
  </div>


