import { PaymentsAdvanceDto } from './../../../models/main/payments-advance.model';
import { PaymentsAdvanceService } from './../../../service/main/payments-advance';
import { DiscountService } from './../../../service/discount.service'
import { DiscountReasonService } from './../../../service/discount-reason.service'
import { Component, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

// Models
import { BusinessLineModel } from '../../../models/business-line.model';
import { CoinModel } from '../../../models/coin.model';
import { Doctor } from '../../../modules/operations/doctor/models/doctor.model';
import { MedicalActAttention } from '../../../models/main/medical-act.model';
import { PaymentMethodModel } from '../../../models/payment-method.model';
import { SpecialtyModel } from '../../../models/specialty.model';
import { TariffModel } from '../../../models/tariff.model';
import { DiscountType } from '../../../modules/finance/discount-type/model/discount-type.model'
import { ServiceOrderNew, ServiceOrderPaymentDetailFiles, ServiceOrderPaymentDetailNew } from '../../../models/finance/service-order-new.model'
// Services'src/app/service/business-line.service';
import { BusinessLineService } from '../../../service/business-line.service';
import { CoinService } from '../../../service/coin.service';
import { DoctorService } from '../../../modules/operations/doctor/services/public/doctor.service';
import { MedicalActAttentionService } from '../../../service/main/medical-act-attention.service';
import { PaymentMethodService } from '../../../service/payment-method.service';
import { SpecialtyService } from '../../../service/specialty.service';
import { TariffService } from '../../../service/tariff.service';
import { DiscountTypeService } from '../../../modules/finance/discount-type/services/public/discount-type.service';
import { DiscountSpecificationsService } from '../../../service/discount-specifications.service';
import { ServiceOrderV2Service } from '../../../service/service-order-v2.service';

import { ModalAutorizationComponent } from '../../security/modal-autorization/modal-autorization.component';
import { ModalSelectQuotaComponent } from '../../../components/attentions/modal-add-attention-new-version/modal-select-quota/modal-select-quota.component';
import { ClinicHistoryVouchersComponent } from '../../clinic-history/clinic-history-vouchers/clinic-history-vouchers.component';
import { BehaviorSubject } from 'rxjs';
import { data } from 'jquery';
import { ContractService } from 'src/app/service/finance/contract.service';
import { MedicalActAttentionContractPaays } from 'src/app/models/main/medical-act-attention-contract-pays';
import Swal from 'sweetalert2';
import { DiscountTypeDto } from 'src/app/modules/finance/discount-type/dto/discount-type.dto';
import { DiscountDto, DiscountReasonDto } from 'src/app/models/discount.model';
import { EnumeracionSpecificationDto } from 'src/app/models/discount-specifications';
import { ModalDiscountSpecificationComponent } from './modal-discount-specification/modal-discount-specification.component';
import { PaymentMethodCard } from 'src/app/models/finance/payment-method-card.model';
import { PaymentMethodCardService } from 'src/app/service/finance/payment-method-card.service';
import { BankAccount } from 'src/app/models/finance/bank-account.model';
import { BankAccountService } from 'src/app/service/finance/bank-account.service';
import { ExchangeRateService } from 'src/app/service/exchange-rates.service';
import { ExchangeRateModel } from 'src/app/models/exchange-rate.model';
import { DatosAuditoria, DatosCliente, DatosFactura, DatosOrdenServicio, DatosPagoCliente, ServiceOrderBilling } from 'src/app/modules/finance/service-order-pending/modal-service-order-data-invoice/dto/model-service-order-data-invoice';
import { EnumeracionInvoiceService } from 'src/app/service/enumeracion-invoice.service';
import { ServiceOrderOneDetail } from 'src/app/models/finance/service-order-one-detail.model';
import { ServiceBillingPaymentsService } from 'src/app/service/service-billing-payments.service';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { ConsultasDocumentosService } from 'src/app/service/consultas-documentos.service';
import { ProgressBarAnimatedComponent } from './progress-bar-animated/progress-bar-animated.component';


class FormFields {
  get Date(): string { return 'AttentionDate'; }
  get Coin(): string { return 'AttentionCoin'; }
  get PaymentMethod(): string { return 'AttentionPaymentMethod'; }
  //get LaboratoryCost(): string { return 'AttentionLaboratoryCost'; }
  get Doctor(): string { return 'AttentionDoctor'; }
  get BusisnessLine(): string { return 'AttentionBusisnessLine'; }
  get Specialty(): string { return 'AttentionSpecialty'; }
  get Tariff(): string { return 'AttentionTariff'; }
  get Quantity(): string { return 'AttentionQuantity'; }
  get Amount(): string { return 'AttentionAmount'; }
  get Total(): string { return 'AttentionTotal'; }
  get IdDiscountType(): string { return 'IdDiscountType'; }
  get TypeDiscount(): string { return 'TypeDiscount'; }
  get DiscountType(): string { return 'DiscountType'; }
  get DiscountAmount(): string { return 'DiscountAmount'; }
  get NumberOperation(): string { return 'NumberOperation'; }
  get NumberBank(): string { return 'NumberBank' }
  get TypeCard(): string { return 'TypeCard' }
  get ExchanceRate(): string { return 'ExchanceRate' }
  get PaymentDate(): string { return 'PaymentDate' }
}

@Component({
  selector: 'app-modal-add-attention-new-version',
  templateUrl: './modal-add-attention-new-version.component.html',
  styleUrls: ['./modal-add-attention-new-version.component.scss']
})
export class ModalAddAttentionNewVersionComponent implements OnInit {

  idclinchistory: number;
  id_reservation: number;
  iddoctor: number;
  idattention: number;
  attention: any;
  idmedical: number;
  private idmedicalSubject = new BehaviorSubject<number | undefined>(undefined);
  idmedical$ = this.idmedicalSubject.asObservable();
  datos_del_fron_para_la_facturacion: ServiceOrderBilling = new ServiceOrderBilling();

  title = 'Generar Orden de Servicio';

  // FORM
  formData: FormGroup;
  formFields: FormFields = new FormFields();
  formInput: MedicalActAttention;

  doctorList: Doctor[] = [];
  coinList: CoinModel[] = [];
  discountList: DiscountReasonDto[] = [];
  discountSpecificationList: EnumeracionSpecificationDto [] = []; 
  pmList: PaymentMethodModel[] = [];
  blList: BusinessLineModel[] = [];
  loadingBl = false;
  specialtyList: SpecialtyModel[] = [];
  loadingSpecialty = false;
  tariffList: TariffModel[] = [];
  discountTypeList: DiscountType[] = [];
  loadingTariff = false;
  showDiscountType = false;
  archivosCargadosData: any[] = [];
  medicalcontract: MedicalActAttentionContractPaays [] = [];
  coin = 'S/';
  commission = 0;
  rol : number;
  monedaTarifa:CoinModel;
  monedaTarifaArray:any;
  amountPer:number;
  listOS: ServiceOrderPaymentDetailNew ;
  listFielOs:ServiceOrderPaymentDetailNew[]=[];
  session: any = {};
  listCards: PaymentMethodCard[] = [];
  listBankAccount: BankAccount[] = [];
  listBankAccountFilter: BankAccount[] = [];
  listado_de_datos_a_facturar : ServiceOrderBilling [] = [];
  paymentMethod: number = 1;

  listExchangeRateDay: ExchangeRateModel[] = [];
  
  minimalUnitValue: number = 0;
  selectedValue:any;

  pago = '';

  itemSelected : any;

  contractAmount : any;
  seleccion : boolean = false;

  advance: PaymentsAdvanceDto = {
    id: 0,
    id_inicial: 0,
    id_final: 0,
    userid_created: 0,
    userid_update: 0,
    created_at: null,
    updated_at: null
  };
  type_invoice_extract: number;
  type_service_detail_extra: number;
  serviceorder:number;
  documents : any [];
  series:any[] =[]
  historial_clinico_paciente:any;
  private progressModalRef: any;
  progressValue = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private doctorService: DoctorService,
    private coinService: CoinService,
    private pmService: PaymentMethodService,
    private blService: BusinessLineService,
    private specialtyService: SpecialtyService,
    private tariffService: TariffService,
    private maaService: MedicalActAttentionService,
    private store: Store<{ session: any }>,
    private modalSerive: NgbModal,
    private discountTypeService: DiscountTypeService,
    private cdRef: ChangeDetectorRef,
    private serviceContract:ContractService,
    private servicePaymentsAdvance: PaymentsAdvanceService,
    private serviceDiscount:DiscountService,
    private serviceDiscountReason: DiscountReasonService,
    private serviceOrder: ServiceOrderV2Service,
    private serviceDiscountSpecificaiton: DiscountSpecificationsService,
    private serviceCards: PaymentMethodCardService,
    private serviceBankAccount: BankAccountService,
    private exchangeRateService: ExchangeRateService,
    private serviceEnumeracion: EnumeracionInvoiceService,
    private serviceBillingPaymentsServic: ServiceBillingPaymentsService,
    private serviceClinicHistory: ClinicHistoryService,
    private serviceConsultarDocumento: ConsultasDocumentosService,
  ) { 
    this.medicalcontract = []

    
  }


  ngOnInit(): void {
    this.getSession();
    this.getDoctors();
    this.getCoins();
    this.getPaymentMethod();
    this.getBl();
    this.getDiscountType();
    this.initForm();
    this.getDiscountAll();
    this.getBankAccount();
    this.getCards();
    this.getInformationClinicHistory();
  }
  getInformationClinicHistory() {
    this.serviceClinicHistory.getOne(this.idclinchistory).subscribe(
      res=>{
        //console.log('id clinic history',res);
        this.historial_clinico_paciente = res;
        
      }
    )
  }

  async validacion_historial_clinico(): Promise<boolean>{
    let validacion_datos = true;
    
    if(this.historial_clinico_paciente.cellphone.length !==9 || this.historial_clinico_paciente.cellphone[0] !== '9'){
      this.toastr.warning('El número de celular no tiene 9 digitos y comenzar con 9','Error!!',{timeOut:3000})
      validacion_datos = false;
    }

    if (!/^\d{9}$/.test(this.historial_clinico_paciente.cellphone)) {
      this.toastr.warning('El número de celular es incorrecto', 'Error!!', { timeOut: 3000 });
      validacion_datos = false;
    }

    if(this.historial_clinico_paciente.invoise_type_document === 'DNI'){
      if(this.historial_clinico_paciente.invoise_num_document.length !==8){
        this.toastr.warning('El número de DNI del apoderado es incorrecto','Error!!',{timeOut:3000})
        validacion_datos = false;
      
      }
    }else if(this.historial_clinico_paciente.invoise_type_document === 'RUC'){
      if(this.historial_clinico_paciente.invoise_num_document.length !==11){
        this.toastr.warning('El número de RUC del apoderado es incorrecto','Error!!',{timeOut:3000})
        validacion_datos = false;
      }
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.historial_clinico_paciente.email)) {
      this.toastr.warning('El formato del correo electrónico es incorrecto', 'Error!!', { timeOut: 3000 });
      validacion_datos = false;
    }

    return validacion_datos;
  }

  async validacion_reniec(): Promise<any> {
    try {
      const resp = await this.serviceConsultarDocumento.getClientOne(
        this.historial_clinico_paciente.invoise_type_document.toLowerCase(),
        this.historial_clinico_paciente.invoise_num_document
      ).toPromise(); // Convierte el Observable en una Promesa
  
      return resp;
    } catch (error) {
      console.error(error);
      this.toastr.error('Error al consultar datos a la reniec', 'Error!!', { timeOut: 3000 });
      throw error; // Lanza el error para que sea manejado donde se llame a esta función
    }
  }
  

  @ViewChild(ClinicHistoryVouchersComponent) vouchers!: ClinicHistoryVouchersComponent;
  ngAfterViewInit() {
    //console.log(this.vouchers.whoAmI()); // I am a pup component!
  }

  onMultiSelectChange() {
    this.selectedValue = this.formData.get(this.formFields.TypeDiscount).value;
    // Verifica si la opción seleccionada es "otros" con id 3
    if (this.selectedValue !== null) {
      // Abre el modal correspondiente aquí
      this.openModalDiscountSpecification();
    }
  }

  getAllDiscountSpecifications(): void {
    this.discountSpecificationList =  [];
    this.serviceDiscountSpecificaiton.findAllDiscountSpecifications().subscribe({
      next: (resp) =>{
      this.discountSpecificationList = resp;
      },
      error: (error) => {
        console.log({ error });
      }
    });
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.rol = this.session.roles.idrole; 
        }
      });
  }

  getBankAccount(): void {
    this.listBankAccount = [];
    this.serviceBankAccount.getAll().subscribe(resp => {
      this.listBankAccount = resp;

    });
  }

  
  getCards(): void {
    this.listCards = [];
    this.serviceCards.getAll().subscribe(resp => { this.listCards = resp });
  }

  getDoctors(): void {
    this.doctorList = [];
    this.doctorService.getAll().subscribe({
      next: (resp) => {
        this.doctorList = resp;
        this.formData.get(this.formFields.Doctor).setValue(this.iddoctor);
      },
      error: (error) => {
        console.log({ error });
      }
    });
  }

  getCoins(): void {
    this.coinList = [];
    this.coinService.getAll()
      .subscribe(
        res => {
          this.coinList = res;
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getPaymentMethod(): void {
    this.pmList = [];
    this.pmService.getAll()
      .subscribe(
        res => {
          this.pmList = res;
        },
        err => {
          console.log(err.error);
        }
      );
  }

  getDiscountType() {
    this.discountTypeList = [];
    this.discountTypeService.get().subscribe({
      next: (resp) => {
        this.discountTypeList = resp;
      },
      error: () => { }
    });
  }

  getDiscountAll() {
    this.discountList = [];
    this.serviceDiscountReason.findAllDiscountReason().subscribe({
      next: (resp) => {
        this.discountList = resp;
      },
      error: () => { }
    });
  }

  async manejarArchivosCargados(datos: any[]) {
    //console.log('datos',datos);
    
    this.archivosCargadosData = datos;
    //console.log('archivos',this.archivosCargadosData);
    
    let serviceFileList :ServiceOrderPaymentDetailFiles[] = [];
    for(let i = 0 ; i < this.archivosCargadosData.length; i++){

      let serviceFile :ServiceOrderPaymentDetailFiles = new ServiceOrderPaymentDetailFiles();

      serviceFile.idmedicalactfiles = this.archivosCargadosData[i].id;
      serviceFile.idstatusborrado =1;
      serviceFile.idserviceorder = this.serviceorder;

      serviceFileList.push(serviceFile)
    }
    //console.log('preaprado');
    
    await this.serviceOrder.createServiceOrderPaymentDetailFiles(serviceFileList).subscribe(
      res =>{
        this.spinner.hide();
        this.toastr.success('carga de acrhivos exitosa!!', 'Ok!', {
          timeOut: 3000,
        });
      },
      error => {
        this.spinner.hide();
        this.toastr.error('carga de acrhivos errada!!', 'Error!', {
          timeOut: 3000,
        });
      }
    );

  };

  setPaymentMethod(): void {
    this.paymentMethod = Number(this.formData.get(this.formFields.PaymentMethod).value);

    console.log('id obtenido del metodo de pago',this.paymentMethod);
    
    if(this.paymentMethod == 0){
      this.paymentMethod = 1;
    };

    if(this.paymentMethod == 0){
      this.paymentMethod = 1;
    };
    this.listBankAccountFilter = this.listBankAccount;
    if (this.paymentMethod === 1 || this.paymentMethod === 7 ) {
      this.formData.get(this.formFields.NumberBank).clearValidators();
      this.formData.get(this.formFields.NumberOperation).clearValidators();
      this.formData.get(this.formFields.TypeCard).clearValidators();
      this.formData.get(this.formFields.NumberBank).setValue(null);
      this.formData.get(this.formFields.NumberOperation).setValue(null);
      this.formData.get(this.formFields.TypeCard).setValue(null);
    } else {
      this.listBankAccountFilter = [];
      const payment = this.pmList.find(el => el.id === this.paymentMethod);
      //console.log('payment',payment);
      
      this.formData.get(this.formFields.TypeCard).clearValidators();
      this.formData.get(this.formFields.TypeCard).setValue(null);
      if (payment.type === 'CA') {

        this.formData.get(this.formFields.TypeCard).setValidators(Validators.required);
        this.listBankAccountFilter = this.listBankAccount;
        this.listBankAccountFilter = this.listBankAccount.filter(el => el.bank_name === 'BBVA');
      }
      if (payment.id === 2) {
        this.listBankAccountFilter = this.listBankAccount.filter(el => el.bank_name === 'BBVA');
      }
      if (payment.id === 3 || payment.id === 6) {
        this.listBankAccountFilter = this.listBankAccount.filter(el => el.bank_name === 'BCP');
      }
      if (payment.id === 4) {
        this.listBankAccountFilter = this.listBankAccount.filter(el => el.bank_name === 'Interbank');
      }
      if(payment.id === 8 || payment.id === 9 || payment.id === 10 || payment.id === 11 || payment.id ===12 || payment.id === 13){
        this.listBankAccountFilter = this.listBankAccount;
      }
      if(this.paymentMethod === 14){
        this.paymentMethod = 14;
      }
      this.formData.get(this.formFields.NumberBank).setValidators(Validators.required);
      /*this.formData.get(this.formFields.OperationNumber).setValidators(Validators.required);*/
    }
  }
  initForm() {


  //console.log(this.attention);
    

    this.formData = this.fb.group({});
    const today = this.attention ? this.attention.date : moment().tz('America/Lima').format('YYYY-MM-DD');
    this.formData.addControl(this.formFields.Date, new FormControl(today, [Validators.required]));
    const coin = this.attention ? this.attention.co.id : 1;
    this.formData.addControl(this.formFields.Coin, new FormControl(coin));
    this.formData.addControl(this.formFields.PaymentMethod, new FormControl(this.attention ? this.attention.idpaymentmethod : 1));
    //this.formData.addControl(this.formFields.LaboratoryCost, new FormControl(this.attention ? this.attention.lab_cost : 0));
    this.formData.addControl(this.formFields.Doctor, new FormControl(null, [Validators.required]));
    const discount_amount = this.attention ? this.attention.discount_amount : null;
    this.showDiscountType = discount_amount > 0 ? true : false;
    const iddiscounttype = this.attention ? this.attention.iddiscounttype ? this.attention.iddiscounttype.id : null : null;
    this.formData.addControl(this.formFields.IdDiscountType, new FormControl(iddiscounttype));
    const discountType = this.attention ? this.attention.discount_type === 'P' ? true : false : false;
    this.formData.addControl(this.formFields.TypeDiscount, new FormControl(null))
    this.formData.addControl(this.formFields.DiscountType, new FormControl(discountType));
    this.formData.addControl(this.formFields.DiscountAmount, new FormControl(discount_amount));
    this.formData.addControl(this.formFields.NumberOperation, new FormControl(null,[Validators.required]));
    this.formData.addControl(this.formFields.NumberBank, new FormControl(null,[Validators.required]));
    this.formData.addControl(this.formFields.TypeCard, new FormControl(null,[Validators.required]));
    this.formData.addControl(this.formFields.ExchanceRate, new FormControl(null,[Validators.required]));
    this.formData.addControl(this.formFields.PaymentDate, new FormControl(null,[Validators.required]));

    let businessLine = null;
    let idSpecialty = null;
    let idTariff = null;
    if (this.attention) {
      const { tariff } = this.attention;
      const { specialty } = tariff;
      const { businessLines } = specialty;
      idSpecialty = specialty.id;
      businessLine = businessLines.id;
      idTariff = tariff.id;
    }

    this.formData.addControl(this.formFields.BusisnessLine, new FormControl(businessLine, [Validators.required]));
    this.formData.addControl(this.formFields.Specialty, new FormControl(idSpecialty, [Validators.required]));
    this.formData.addControl(this.formFields.Tariff, new FormControl(idTariff));
    this.formData.addControl(this.formFields.Quantity, new FormControl(this.attention ? this.attention.quantity : 1));
    this.formData.addControl(this.formFields.Amount, new FormControl(this.attention ? this.attention.value : 0));
    this.formData.addControl(this.formFields.Total, new FormControl(0));

    if (this.attention) {
      this.getSpecialtys();
    }

    //console.log("*************************** esta es el id de la reserva: ",this.id_reservation, " **************************");
    
  }



  setComision(): void {
    const comi = this.pmList.filter(el => el.id === this.formData.get(this.formFields.PaymentMethod).value);
    this.commission = comi[0].commission;
  }

  onPaymentDateChange(): void {
    const selectedDate = this.formData.get(this.formFields.PaymentDate).value;
    if (selectedDate) {
      this.getExchangeRateForDate(selectedDate);
    }
  }

  async getExchangeRateForDate(date: string): Promise<void> {
    await this.exchangeRateService.getFilter(date).subscribe({
      next: (exchangeRates) => {
        this.listExchangeRateDay = exchangeRates;
      },
      error: (error) => {
        console.error('Error al obtener la tasa de cambio:', error);
      }
    });
  }



  getBl(): void {
    this.blList = [];
    this.loadingBl = true;
    this.blService.getAll()
      .subscribe(
        res => {
          this.loadingBl = false;
          this.blList = res;
        },
        err => {
          this.loadingBl = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of Specialtys <Especialidades>
   */
  getSpecialtys(): void {
    this.formData.get(this.formFields.Specialty).setValue(null);
    this.specialtyList = [];
    this.loadingSpecialty = true;
    this.specialtyService.getByBusinessLine(this.formData.get(this.formFields.BusisnessLine).value)
      .subscribe(
        res => {
          this.specialtyList = res;
          this.loadingSpecialty = false;
          if (this.attention) {
            this.formData.get(this.formFields.Specialty).setValue(this.attention.tariff.specialty.id);
            this.getTariff();
          }
        },
        err => {
          this.loadingSpecialty = false;
          console.log(err.error);
        }
      );
  }

  /**
   * List of tariff <Tarifario o tratamiento>
   */
  getTariff(): void {
    this.loadingTariff = true;
    this.formData.get(this.formFields.Tariff).setValue(null);
    this.tariffList = [];
    this.tariffService.getBySpecialty(this.formData.get(this.formFields.Specialty).value)
      .subscribe(
        res => {
          this.tariffList = res;
          this.loadingTariff = false;
          if (this.attention) {
            this.formData.get(this.formFields.Tariff).setValue(this.attention.tariff.id);
            this.setMonto(2);
          }
        },
        err => {
          this.loadingTariff = false;
          console.log(err.error);
        }
      );
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required')) {
      return true;
    }
    return false;
  }


  openModalAutorization(): void {
    const modal = this.modalSerive.open(ModalAutorizationComponent, { size: 'sm', centered: true });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        // this.get(iddoctor, doctor);
      } else {
        this.activeModal.close('Close click');
      }
    });
    modal.componentInstance.message = 'Para editar la atención es necesario que sea autorizado por un usuario con perfil ADMIN!';
    modal.componentInstance.data = this.attention;
    modal.componentInstance.module = 'medical-act-attention';
  }

  onDiscountChange(event : any){
    this.amountPer = event.discountrate;
    this.formData.get(this.formFields.DiscountType).setValue(true);
    this.formData.get(this.formFields.DiscountAmount).setValue(this.amountPer);
    this.setMonto(2);
  }

  clean():void{
    this.selectedValue = null;
        this.formData.get(this.formFields.TypeDiscount).reset();
        this.formData.get(this.formFields.DiscountType).reset();
        this.formData.get(this.formFields.DiscountAmount).reset();
        this.formData.get(this.formFields.Total).reset();
        this.formData.get(this.formFields.Amount).reset();
        this.formData.get(this.formFields.Tariff).reset();
        this.seleccion = false;
        
  }

  async setMonto(opc: number):Promise<void> {
    const type = this.formData.get(this.formFields.TypeDiscount).value;
    const discount = this.formData.get(this.formFields.DiscountAmount).value;
    this.showDiscountType = false;
    if(type == null && discount > 0){
      this.showDiscountType = false;
      this.toastr.error('Debe seleccionar un tipo de descuento', 'Ok!', {
        timeOut: 3000,
      });
      return;
    }

    this.formData.get(this.formFields.IdDiscountType).clearValidators();
    const tariff = this.formData.get(this.formFields.Tariff).value;

    if((tariff == 123 || tariff == 121 || tariff == 202 || tariff == 204 || tariff ==203
      || tariff == 122) && this.seleccion == false){
      const modal = this.modalSerive.open(ModalSelectQuotaComponent, { size: 'lg', centered: true});
      modal.result.then((result:any) => {

        if (result === 'Close click') {
          this.clean();
          
        } else {
          //console.log('Resultado del modal cerrado:', result);
          this.itemSelected = result;
          this.formData.get(this.formFields.Amount).setValue(this.itemSelected.amount);
          const quantity = this.formData.get(this.formFields.Quantity).value;
          const amount = this.formData.get(this.formFields.Amount).value
          const total = this.formData.get(this.formFields.Total).setValue(quantity * amount);
          this.seleccion = true;

        }

      })
      modal.componentInstance.idclinchistory = this.idclinchistory;
      modal.componentInstance.listFielOs = this.listFielOs;
      modal.componentInstance.idTarifa = tariff;
    }
    //console.log(this.tariffList);

    const valor = this.tariffList.find(it => it.id === tariff);
    const valor_tarifa = valor.price_usd ? valor.price_usd : valor.price_sol
    //console.log('valor',valor);
    
    const discount_type = this.formData.get(this.formFields.DiscountType).value;
    const discount_amount = this.formData.get(this.formFields.DiscountAmount).value;

    if (this.formData.get(this.formFields.Amount).value <= 0 || opc === 1) {
      
      this.minimalUnitValue = valor.price_sol > 0 ? valor.price_sol : valor.price_usd;
      this.formData.get(this.formFields.Amount).setValue(this.minimalUnitValue);
    }
    
    this.coin = valor.price_sol > 0 ? 'S/' : '$';
    let total = 0;
    total = Number((this.formData.get(this.formFields.Quantity).value * this.formData.get(this.formFields.Amount).value));

    if (discount_type && discount_amount > 0) {
      const discount_percent_original = this.amountPer;
      const discount_new = this.formData.get(this.formFields.DiscountAmount).value;
      
      if(discount_new > discount_percent_original){
        this.toastr.error('El porcentaje de descuento es mayor al porcentaje base!', 'Error!', {
          timeOut: 3000,
        });
        return;
      }else{
        this.showDiscountType = true;
        this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
        total = total - Math.ceil(Number(((total * discount_amount) / 100)));
      }
      
      
      
    } else if (discount_amount > 0) {
      
      this.formData.get(this.formFields.IdDiscountType).setValidators([Validators.required]);
      this.showDiscountType = true;

      if (discount_type == false){
        const prom = (this.amountPer/100) * valor_tarifa;

        if(discount_amount < prom){
          total -= discount_amount;
        }
        else{
          this.toastr.error('El importe de descuento es mayor al calculado!!', 'Error!', {
            timeOut: 3000,
          });
        }
      }
      
    }
    this.formData.get(this.formFields.Total).setValue(total.toFixed(2));

    this.monedaTarifa = this.coinList.find(it => it.code === this.coin);
    
    this.formData.get(this.formFields.Coin).setValue(this.monedaTarifa.id);
    

  }

  openModalDiscountSpecification():void{
    const modalSpecific = this.modalSerive.open(ModalDiscountSpecificationComponent)
    modalSpecific.result.then((result: any) => {

      if (result === 'Save click') {
        // this.get(iddoctor, doctor);
        const data = modalSpecific.componentInstance.selectedCategories;

        let info='';
        for(let i = 0;i <data.length; i++){  
          if (i > 0) {
            info += ', '; // Agregar coma después del primer elemento
          }

          if(data[i].id == 3){
            info += `${data[i].name} - ${data[i].otherdescription} `;
          }else{
            info += `${data[i].name} `;
          }
        }
        
        this.formData.get(this.formFields.IdDiscountType).setValue(info);
      } else {

        this.selectedValue = null;
        this.formData.get(this.formFields.TypeDiscount).reset();
        this.formData.get(this.formFields.DiscountType).reset();
        this.formData.get(this.formFields.DiscountAmount).reset();
        this.setMonto(2)

      }
    });
  }



  agregarFila():void{

    const idAttention = this.idclinchistory;
    const idCoin = this.formData.get(this.formFields.Coin).value;
    const date = this.formData.get(this.formFields.Date).value;
    const paymentMethod = this.formData.get(this.formFields.PaymentMethod).value;
    //const laboratory = this.formData.get(this.formFields.LaboratoryCost).value;
    const doctor = this.formData.get(this.formFields.Doctor).value;

    const busisnessLine = this.formData.get(this.formFields.BusisnessLine).value;
    const specialty = this.formData.get(this.formFields.Specialty).value;
    const tariff = this.formData.get(this.formFields.Tariff).value;
    const amount = this.formData.get(this.formFields.Amount).value;
    const total = this.formData.get(this.formFields.Total).value;
    const idDiscountType = this.formData.get(this.formFields.IdDiscountType).value;
    const type = this.formData.get(this.formFields.TypeDiscount).value;
    const discount = this.formData.get(this.formFields.DiscountType).value;
    const discoutamount = this.formData.get(this.formFields.DiscountAmount).value;
    const cantidad = this.formData.get(this.formFields.Quantity).value;

    if (!doctor || doctor <= 0 || doctor == undefined) {
      this.toastr.warning("Debe seleccionar un Doctor", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!paymentMethod || paymentMethod <= 0) {
      this.toastr.warning("Debe seleccionar una forma de pago", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!busisnessLine || busisnessLine <= 0) {
      this.toastr.warning("Debe seleccionar una linea de trabajo", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!specialty || specialty <= 0) {
      this.toastr.warning("Debe seleccionar una especialidad", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if (!tariff || tariff <= 0) {
      this.toastr.warning("Debe seleccionar una tarifa", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };

    if(!amount || amount <=0){
      this.toastr.warning("Debe ingresar monto unitario por la tarifa", "Atención", { timeOut: 3000, progressBar: true });
      return; 
    }

    let idReservation = 0
    if (this.id_reservation !== undefined) {
      idReservation = this.id_reservation;  
    }else{
      idReservation  = null;
    }

    let idcontract;
      if (this.itemSelected !== undefined) {
          idcontract = Number(this.itemSelected['Id del contrato']);
      } else {
          idcontract = null;
      }

    if(type == null && discoutamount > 0){
      this.showDiscountType = false;
      this.toastr.error('Debe seleccionar un tipo de descuento', 'Ok!', {
        timeOut: 3000,
      });
      this.clean();
      return;
    }

    const tarifa = this.tariffList.find(tr => tr.id == tariff);

    const valor_tarifa = tarifa.price_usd ? tarifa.price_usd : tarifa.price_sol;
    const idCoinTarifa = tarifa.price_usd ? 2 : 1;


    if (discount == false){
      const prom = (this.amountPer/100) * valor_tarifa;


      if(discoutamount > prom){
        this.toastr.error('El importe de descuento es mayor al calculado!!', 'Ok!', {
          timeOut: 3000,
        });
        return;
      }
    }

    const discount_percent_original = this.amountPer;
   
    const discount_new = this.formData.get(this.formFields.DiscountAmount).value;
    
    if(discount === true){
      if(discount_new > discount_percent_original){
        this.toastr.error('El porcentaje de descuento es mayor al porcentaje base!', 'Error!', {
          timeOut: 3000,
        });
        return;
      }
    }


    if (!idCoin || idCoin <= 0) {
      this.toastr.warning("Debe agregar un coin", "Atención", { timeOut: 3000, progressBar: true });
      return;
    };


   




    if (this.listFielOs.length > 0){
      
      for(let i = 0 ; i < this.listFielOs.length ; i++){
        if(this.listFielOs[i].idDoctor !== doctor){
          this.toastr.warning("Se está seleccionando a un doctor diferente", "Atención", { timeOut: 3000, progressBar: true });
          return;
        }
      }

      /*for(let i = 0 ; i < this.listFielOs.length ; i++){
        if(this.listFielOs[i].idCoin !== idCoin){
          this.toastr.warning("Las tarifas seleccionas son de monedas diferentes", "Atención", { timeOut: 3000, progressBar: true });
          return;
        }
      }*/
    }

    const coin = this.coinList.find(co => co.id == idCoin);
    const doc = this.doctorList.find(d => d.id == doctor);
    const business = this.blList.find(bl => bl.id = busisnessLine);
    const spe = this.specialtyList.find(sp => sp.id == specialty);

    
    const method = this.pmList.find(pl => pl.id == paymentMethod);

    let bank = null;
    let op = null;
    let payment_date = null;
    let banck_selected = null;
    let id_tarjeta = null;
    let tarjeta = null;
    let exchange = null;
    let description_exchange = null;

    if(paymentMethod !== 1  && paymentMethod !==7 && paymentMethod !==14){

      bank = this.formData.get(this.formFields.NumberBank).value;
      op = this.formData.get(this.formFields.NumberOperation).value
      payment_date = this.formData.get(this.formFields.PaymentDate).value;
      exchange = this.formData.get(this.formFields.ExchanceRate).value;

      if(!bank || bank <= 0){
        this.toastr.warning("Debe seleccionar un banco", "Atención", { timeOut: 3000, progressBar: true });
        return;
      }
      
      if(!op || op <= 0){
        this.toastr.warning("Debe ingresar número de operación", "Atención", { timeOut: 3000, progressBar: true });
        return;
      }

      if(!payment_date || payment_date <= 0){
        this.toastr.warning("Debe seleccionar fecha de abono", "Atención", { timeOut: 3000, progressBar: true });
        return;
      }

      if(!exchange || exchange <= 0){
        this.toastr.warning("Debe seleccionar el tipo de cambio", "Atención", { timeOut: 3000, progressBar: true });
        return;
      }

      if(paymentMethod === 5 ){
        
        id_tarjeta = this.formData.get(this.formFields.TypeCard).value;

        if(!id_tarjeta || id_tarjeta <= 0){
          this.toastr.warning("Debe seleccionar una tarjeta de pago", "Atención", { timeOut: 3000, progressBar: true });
          return;
        }

        tarjeta = this.listCards.find(item => item.id === Number(id_tarjeta));
      }
      
      
      banck_selected = this.listBankAccountFilter.find(item => item.id === Number(bank));
      description_exchange = this.listExchangeRateDay.find(item => item.id === Number(exchange));
      
    }
//console.log(banck_selected);

let bankname = '';
    if (banck_selected) {
        bankname = `${banck_selected.bank_name} ${banck_selected.account_num}`;
    } else {
        bankname = '-';
    }

    
    let descuentoTipo = null;
    if(type == null){
      descuentoTipo = null;
    }else{
      descuentoTipo = this.discountList.find(dt => dt.id == type);
    }

    this.listOS = {
      fecha:date,
      idCoin:coin.id,
      coin:coin.code,
      idDoctor:doc.id,
      doctor:doc.nameQuote,
      idBussiness:business.id,
      bussiness:business.name,
      idSpeciality:spe.id,
      speciality:spe.name,
      idTariff:tarifa.id,
      tariff:tarifa.name,
      valueTariff:valor_tarifa,
      idMethod:method.id,
      methodPaymennt:method.name,
      idDiscount: descuentoTipo ? descuentoTipo.id : null,
      discount: descuentoTipo ? descuentoTipo.name : '-',
      reasonDiscount:idDiscountType,
      quantity:cantidad,
      value:amount,
      amountDiscount:discoutamount,
      total:total,
      idClinicHistory:idAttention,
      typeDiscount:discount,
      idContrato : idcontract?? null,
      idReservation : idReservation ?? null,
      idAccountbank: bank ?? null,
        AccountBank: bankname,
        operationNumber: op ?? null,
        paymentDate: payment_date ?? null,
        idMethodCard: id_tarjeta ?? null,
        methodCard: tarjeta ? tarjeta.name : '-',
        idExchanfueRate:exchange,
        exchangueRate: description_exchange ? description_exchange.value : null,
        idCoinTariff: idCoinTarifa
    }

    this.listFielOs.push (this.listOS);

    this.clean();
  }

  eliminarFila(index: number): void {
    // Verificar si el índice está dentro de los límites del array
    if (index >= 0 && index < this.listFielOs.length) {
      // Eliminar la fila en la posición del índice
      this.listFielOs.splice(index, 1);
    }
  }

  /*isDisabled():boolean{
    const tarifa = this.formData.get(this.formFields.Tariff).value;
    console.log(tarifa);

    if ( tarifa == 121 || tarifa == 122 || tarifa == 123 || tarifa == 202 || tarifa == 203 ||tarifa == 204){
      console.log('entro condicional')
      return true;
    }

    return false;
  }*/
 
    getCountDocuments(type:string):void{
      this.documents = [];
  
      this.serviceEnumeracion.getLastEnumeracion(type)
        .subscribe(        
          res => {
            this.series = res
  
          },
          err => {     
            this.toastr.error('Atención, ocurrio un error al obtener la cantidad de documentos!!', 'Error!', {
              timeOut: 3000,
            });
          }
        );
    }

  async agregarCampos(id_service_orden:number): Promise<void>{
    try {
      const data = await this.consulta(id_service_orden);
    } catch (error) {
      
    }
      }

  async consulta (item:number):Promise<any>{
    let data = null;
    try {
      data = await this.serviceOrder.getOneOrdenService(item).toPromise();
      
      this.type_service_detail_extra = data[0].serviceorderpaymentdetail
      return data;
    } catch (error) {
      console.error('Error en la consulta en la base de datos')
      this.toastr.error('Error en la consulta en la base de datos','Error',{timeOut: 3000,progressBar: true} )
    } finally{
      data = null;
    }
    
  }

  async invoiceStructure(data:any, orden:ServiceOrderOneDetail[],detalle:ServiceOrderPaymentDetailNew[],nombre_completo:string,nummero_documento:string,direccion:string):Promise<any>{
    //console.log('Datos recibidos para data: ', data);
    //console.log('Datos recibidos para orden: ', orden);
    //console.log('Datos recibidos para detalle: ', detalle);

    const listadoDeDatosAFacturar: ServiceOrderBilling[] = [];

    for (let i = 0; i < detalle.length; i++) {
        let datosDelFronParaLaFacturacion: ServiceOrderBilling = {
            datosOrdenServicio: new DatosOrdenServicio(),
            datosCliente: new DatosCliente(),
            datosFactura: new DatosFactura(),
            datosPagoCliente: new DatosPagoCliente(),
            datosAuditoria: new DatosAuditoria()
        };

        //console.log('a');
        
        datosDelFronParaLaFacturacion.datosCliente.id_cliente = Number(data.datosCliente.id_cliente);
        datosDelFronParaLaFacturacion.datosCliente.tipo_documento_cliente = data.datosCliente.tipo_documento_cliente;
        datosDelFronParaLaFacturacion.datosCliente.numero_documento_cliente =nummero_documento;
        datosDelFronParaLaFacturacion.datosCliente.nombre_cliente_a_facturar = nombre_completo;
        datosDelFronParaLaFacturacion.datosCliente.direccion_del_cliente = direccion;
        datosDelFronParaLaFacturacion.datosCliente.correo_electronico_cliente = data.datosCliente.correo_electronico_cliente;
        datosDelFronParaLaFacturacion.datosCliente.nombre_paciente = data.datosCliente.nombre_paciente;

        let typeInvoice: any = null;

        const paymentDate = new Date(detalle[i].paymentDate);
        const paymentMonth = paymentDate.getMonth() + 1;

        const fecha_hoy = new Date(detalle[i].fecha)
        const hoy_mes = fecha_hoy.getMonth()+1;

        let tipo = 0;
        console.log("Estos son los: this.historial_clinico_paciente", this.historial_clinico_paciente.history);
        const historia_clinica_pacinete = this.historial_clinico_paciente.history
        const validacion_paciente = historia_clinica_pacinete.startsWith('MD') ? true : false;
       // console.log("eL VALOR ES DE: ",historia_clinica_pacinete.startsWith('MD'));
        if(validacion_paciente == true){          
          if(data.datosCliente.tipo_documento_cliente === 'RUC'){
            tipo = 7
          }else{
            tipo = 5
          }

          try {
            typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
            //console.log("Datos de type invoice 1: ",typeInvoice);
            
          } catch (error) {
            throw new Error('Error al cpnsultar datos de numeracion');
          }

        }else{

            if(data.datosCliente.tipo_documento_cliente === 'RUC'){
              tipo = 1
            }else{
              tipo = 2
            }

            try {
              typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
              console.log("Datos de type invoice 2: ",typeInvoice);
            } catch (error) {
              throw new Error('Error al cpnsultar datos de numeracion');
            }
        }
        /*let typeInvoice: any = null;
        if(detalle[i].paymentDate < detalle[i].fecha){
          const paymentDate = new Date(detalle[i].paymentDate);
          const paymentMonth = paymentDate.getMonth() + 1;
          const fechaPago = new Date(detalle[i].paymentDate);
          const fechaFactura = new Date(detalle[i].fecha);
          const diferenciaEnMs = fechaPago.getTime() - fechaFactura.getTime();
          const diferenciaEnDias = diferenciaEnMs / (1000 * 60 * 60 * 24);
          //console.log('paymentMonth',paymentMonth);
          //console.log("Diferencia en días: ",diferenciaEnDias);
          
          const fecha_hoy = new Date(detalle[i].fecha)
          const hoy_mes = fecha_hoy.getMonth()+1;
          //console.log('hoy_mes',hoy_mes);
         // console.log("analisis: ",paymentMonth <hoy_mes);
          
          if(paymentMonth <hoy_mes ){

            let tipo = 0;
            if(data.datosCliente.tipo_documento_cliente === 'RUC'){
              tipo = 4
            }else{
              tipo = 3
            }
            //console.log('tipo',tipo);
            
            try {
              typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
              //console.log("Datos de type invoice 1: ",typeInvoice);
              
            } catch (error) {
              throw new Error('Error al cpnsultar datos de numeracion');
            }
          }else if(diferenciaEnDias>3 && data.datosCliente.tipo_documento_cliente === 'RUC') {
            let tipo = 1;
            typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
             //console.log("Datos de type invoice 1.1: ",typeInvoice);
          }else if(diferenciaEnDias>5 && data.datosCliente.tipo_documento_cliente === 'DNI') {
            let tipo = 2;
            typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
              //console.log("Datos de type invoice 1.2: ",typeInvoice);
          }else if(diferenciaEnDias>0 && data.datosCliente.tipo_documento_cliente === 'DNI') {
            let tipo = 3;
            typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
              //console.log("Datos de type invoice 1con3: ",typeInvoice);
          }else{
            let tipo = 4;
            typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
              //console.log("Datos de type invoice 1.4: ",typeInvoice);
          }
        }else{
          //console.log("Entro al else");
          
          let tipo = 0;
            if(data.datosCliente.tipo_documento_cliente === 'RUC'){
              tipo = 1
            }else{
              tipo = 2
            }

            try {
              typeInvoice = await this.serviceOrder.consulta_datos_numeracion(tipo).toPromise();
              //console.log("Datos de type invoice 2: ",typeInvoice);
            } catch (error) {
              throw new Error('Error al cpnsultar datos de numeracion');
            }
            
        }*/
        //console.log("Datos de type invoice 3: ",typeInvoice);

          this.type_invoice_extract = typeInvoice.Typeinvoice.id
          
        //console.log('c');
        datosDelFronParaLaFacturacion.datosPagoCliente.tipo_de_cambio = Number(detalle[i].exchangueRate);
        datosDelFronParaLaFacturacion.datosFactura.fecha_factura = detalle[i].fecha;
        datosDelFronParaLaFacturacion.datosFactura.fecha_abono = detalle[i].paymentDate;
        datosDelFronParaLaFacturacion.datosFactura.serie_recibo = typeInvoice.Typeinvoice.serie;
        datosDelFronParaLaFacturacion.datosFactura.numero_recibo = typeInvoice.Typeinvoice.correlative;
        datosDelFronParaLaFacturacion.datosFactura.id_tarifa = Number(detalle[i].idTariff);
        datosDelFronParaLaFacturacion.datosFactura.descripcion_tarifa = detalle[i].tariff;

        //console.log('1');
        
        if (detalle[i].idCoin === 1) {
            datosDelFronParaLaFacturacion.datosFactura.monto_detalle_pago_soles = Number(detalle[i].total);
        } else {
            datosDelFronParaLaFacturacion.datosFactura.monto_detalle_pago_dolares = Number(detalle[i].total);
        }
        //console.log('2');


        datosDelFronParaLaFacturacion.datosPagoCliente.id_moneda_del_como_pago = Number(detalle[i].idCoin);
        datosDelFronParaLaFacturacion.datosPagoCliente.id_tipo_cambio = Number(detalle[i].idExchanfueRate);
        datosDelFronParaLaFacturacion.datosPagoCliente.id_tipo_comprobante = data.datosCliente.tipo_documento_cliente === 'RUC' ? 'invoice': 'ticket';
        datosDelFronParaLaFacturacion.datosPagoCliente.metodo_pago = Number(detalle[i].idMethod);
        datosDelFronParaLaFacturacion.datosPagoCliente.tipo_de_tarjeta = Number(detalle[i].idMethodCard);
        datosDelFronParaLaFacturacion.datosPagoCliente.cuenta_bancaria = Number(detalle[i].idAccountbank);
        datosDelFronParaLaFacturacion.datosPagoCliente.numero_operacion = Number(detalle[i].operationNumber);

        //console.log('1¿3');
        const tarifa = Number(detalle[i].idTariff);
        //console.log(tarifa);
        
        for (let j = 0; j < orden.length; j++) {
            if (orden[j].tariffId === tarifa) {
              let validador_os = true;
              for (let a = 0; a < listadoDeDatosAFacturar.length; a++) {
                if(listadoDeDatosAFacturar[a].datosOrdenServicio.id_order_servicio_detalle === orden[j].serviceorderpaymentdetail ){
                  validador_os = false;
                } 
              }

              if(validador_os){
                datosDelFronParaLaFacturacion.datosOrdenServicio.id_orden_servicio = Number(orden[j].id);
                datosDelFronParaLaFacturacion.datosOrdenServicio.id_order_servicio_detalle = Number(orden[j].serviceorderpaymentdetail);
                datosDelFronParaLaFacturacion.datosOrdenServicio.cantidad_orden_servicio_detalle = Number(orden[j].quantity);
                datosDelFronParaLaFacturacion.datosOrdenServicio.moneda_orden_servicio_detalle = Number(orden[j].idcoin);
                datosDelFronParaLaFacturacion.datosOrdenServicio.monto_orden_servicio_detalle = Number(orden[j].subtotal);
                datosDelFronParaLaFacturacion.datosOrdenServicio.id_bussiness_line = Number(orden[j].busineslineId);
                datosDelFronParaLaFacturacion.datosOrdenServicio.id_specialty = Number(orden[j].specialtyId);
              }
            }
        }

        datosDelFronParaLaFacturacion.datosAuditoria.id_creador_orden_servicio = Number(orden[0].user_created);
        datosDelFronParaLaFacturacion.datosAuditoria.id_facturador_orden_servicio = Number(this.session.id);

        //console.log('Parte 5');
        listadoDeDatosAFacturar.push({ ...datosDelFronParaLaFacturacion });
    }

    //console.log(listadoDeDatosAFacturar);
    
    
    return listadoDeDatosAFacturar;
  }

  async save(): Promise<void> {

    if(this.vouchers.uploadedFiles.length === 0){
      this.vouchers.uploadedFiles.length;
      //this.vouchers.actives
      //pinner.hide();
      this.toastr.warning("Debe seleccionar un archivo","Atención",{timeOut: 3000,progressBar: true});
      return;
    }

    if(this.listFielOs.length === 0){
      this.toastr.warning("Debe agregar una Orden de Servicio","Atención",{timeOut: 3000,progressBar: true});
      return;
    }

    this.spinner.show();

    let serviceOrder: ServiceOrderNew = new ServiceOrderNew();

    serviceOrder.clinichistory = this.idclinchistory;
    serviceOrder.doctor = this.listFielOs[0].idDoctor;
    serviceOrder.reservation = null;
    serviceOrder.dateserviceorder = this.listFielOs[0].fecha;
    serviceOrder.idstatusborrado = 1;

    const service_order_create: any = {
      serviceOrder: serviceOrder,
      listado_service_order_detalle: this.listFielOs
    };
    //console.log('informacion enviada al front',service_order_create);
    
    await this.serviceOrder.createServiceOrder(service_order_create).subscribe(
      async res =>{
        //console.log('respuesta',res);
        this.serviceorder = res.id
        const detalle : any = res;
        this.serviceContract.updateContractDetailByOrderService(detalle).subscribe(
          res=>{

            
          
            
          },error=>{
            console.log(error);
            this.toastr.error('Ocurrio un error en la inserciòn de datos','Error !!',{timeOut: 3000})
          }

        )
        /*this.serviceorder = res.id;
        for( let i=0 ; i < this.listFielOs.length; i++){
          this.listFielOs[i].serviceorderId = this.serviceorder;
        }*/

          if (this.id_reservation !== undefined) {
            Swal.fire(' 🤩 Excelente !!', 'Haz creado el pago de la cita, recuerda hacer seguimiento al pago', 'success');
          }else{
            this.toastr.success('se creo la orden de servicio y sus detalles!!', 'Ok!', {
              timeOut: 3000,
            });
          }
          await this.vouchers.onSubmit();
          this.spinner.hide();
          this.activeModal.close('Save click');

        /*this.serviceDiscount.createAllDiscount(this.listFielOs).subscribe(
          res =>{
            this.serviceOrder.createServiceOrderPaymentDetail(res).subscribe(
              res =>{
                const detalle : any = res;
                this.serviceContract.updateContractDetailByOrderService(detalle).subscribe(
                  res=>{

                  }
                )
                this.spinner.hide();
                if (this.id_reservation !== undefined) {
                  Swal.fire(' 🤩 Excelente !!', 'Haz creado el pago de la cita, recuerda hacer seguimiento al pago', 'success');
                }else{
                  this.toastr.success('se creo la orden de servicio y sus detalles!!', 'Ok!', {
                    timeOut: 3000,
                  });
                }
                this.vouchers.onSubmit();
                
                this.activeModal.close('Save click');
              }
            ),
            err => {
              this.spinner.hide()
              console.log(err.error);
              this.toastr.error('hubo un error al crear la orden de servicio y sus detalles!!', 'Error!', {
                timeOut: 3000,
              });
            }
          }
        ),*/
          err =>{
            this.spinner.hide()
          console.log(err.error);
          this.toastr.error('error al crear el descuento !!', 'Error!', {
            timeOut: 3000,
          });
        }

      },
      err => {
        this.spinner.hide()
        console.log(err.error);
        this.toastr.error('hubo un error al crear la orden de servicio!!', 'Error!', {
          timeOut: 3000,
        });
      }

      )
    
  }

  showProgressModal() {
    this.progressModalRef = this.modalSerive.open(ProgressBarAnimatedComponent, { centered: true });
    // Puedes pasar opciones al modal si es necesario
}

hideProgressModal() {
  if (this.progressModalRef) {
      this.progressModalRef.close();
  }
}

updateProgress(value: number) {
  this.progressValue = value;
  if (this.progressModalRef) {
    // Accede al componente del modal y actualiza su progreso
    const modalComponent = this.progressModalRef.componentInstance as ProgressBarAnimatedComponent;
    modalComponent.progressValue = value;
  }
}

updateClient(cliente:string, historia_clinica:string,numero_op_banks:string,url_soles:string,url_dolares:string){

    const modalComponent = this.progressModalRef.componentInstance as ProgressBarAnimatedComponent;
    modalComponent.cliente = cliente;
    modalComponent.historia_clinica = historia_clinica;
    modalComponent.numero_op_banks = numero_op_banks;
    modalComponent.url_soles = url_soles;
    modalComponent.url_dolares = url_dolares;

}
  async newSave(): Promise<void> {

    Swal.fire({
      title: 'Esta seguro que desea crear el comprobante?',
      text: 'Esta accion es irreversible!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar!',
    }).then(async (result) => {
      console.log(result);

      const delay = Math.floor(Math.random() * (15 - 2 + 1) + 2) * 1000;
      const minutos = delay/1000;
      this.toastr.info(`Su factura saldrá en: ${minutos} segundos`,"IMPORTANTE !!!!",{timeOut: delay,progressBar: true});
      this.spinner.show();
      // Espera el tiempo aleatorio generado
      await new Promise(resolve => setTimeout(resolve, delay));
      this.spinner.hide();
      await this.showProgressModal();
      if(this.vouchers.uploadedFiles.length === 0){
        this.vouchers.uploadedFiles.length;
        this.toastr.warning("Debe seleccionar un archivo","Atención",{timeOut: 3000,progressBar: true});
        this.hideProgressModal();
        return;
      }
  
      if(this.listFielOs.length === 0){
        this.toastr.warning("Debe agregar una Orden de Servicio","Atención",{timeOut: 3000,progressBar: true});
        this.hideProgressModal();
        return;
      }
      await this.updateProgress(10);
  
      let validacion = await this.validacion_historial_clinico();
      //console.log(validacion);
      
      let reniec;
      let nombre_completo = '';
      let numero_documento = '';
      let direccion = '';
      console.log('this.historial_clinico_paciente',this.historial_clinico_paciente);
      
      if(validacion){
        /*if(this.historial_clinico_paciente.invoise_type_document !== 'CE' &&
          this.historial_clinico_paciente.invoise_type_document.toUpperCase() !== 'PASAPORTE'
         ){*/
          //reniec = await this.validacion_reniec();
          if(this.historial_clinico_paciente.invoise_type_document === 'DNI'){
            reniec = await this.validacion_reniec();
            reniec.nombres = reniec.nombres.replace(/[^a-zA-Z\s]/g, '');
            reniec.apellidoPaterno = reniec.apellidoPaterno.replace(/[^a-zA-Z\s]/g, '');
            reniec.apellidoMaterno = reniec.apellidoMaterno.replace(/[^a-zA-Z\s]/g, '');
            nombre_completo = reniec.nombres +' '+reniec.apellidoPaterno+' '+reniec.apellidoMaterno;
            numero_documento = reniec.numeroDocumento;
            
          }else if(this.historial_clinico_paciente.invoise_type_document === 'RUC'){
            reniec = await this.validacion_reniec();
            nombre_completo = reniec.razonSocial;
            numero_documento =reniec.numeroDocumento;
            if (
              this.historial_clinico_paciente.address == null ||
              this.historial_clinico_paciente.district?.name == null ||
              this.historial_clinico_paciente.district?.provinces?.name == null ||
              this.historial_clinico_paciente.district?.provinces?.deparments?.name == null
            ) {
              direccion = null;
            } else {
              // Si todos los valores son válidos, concatenar la dirección
              direccion = [
                this.historial_clinico_paciente.address,
                this.historial_clinico_paciente.district.name,
                this.historial_clinico_paciente.district.provinces.name,
                this.historial_clinico_paciente.district.provinces.deparments.name
              ].join(' - ');
            }
  
            //console.log(direccion);
            
          }else{
            nombre_completo = this.historial_clinico_paciente.attorney
            direccion = [
              this.historial_clinico_paciente.address,
              this.historial_clinico_paciente.district.name,
              this.historial_clinico_paciente.district.provinces.name,
              this.historial_clinico_paciente.district.provinces.deparments.name
            ].join(' - ');
            numero_documento = this.historial_clinico_paciente.invoise_num_document
          }
         /*}*//*else{
          this.toastr.warning('Este cliente tiene carnet de extranjeria o pasaporte, por favor de ingresar un documneto de identidad','Error!!',{ timeOut: 3000});
          this.hideProgressModal();
          return;
         }*/
         //console.log(nombre_completo);
         //this.spinner.hide();
  
         /*INICIA LA CREACION DE LA ORDEN DE SERVICIO PRINCIPAL*/
         try {
          let serviceOrder: ServiceOrderNew = new ServiceOrderNew();
          serviceOrder.clinichistory = this.idclinchistory;
          serviceOrder.doctor = this.listFielOs[0].idDoctor;
          serviceOrder.reservation = null;
          serviceOrder.dateserviceorder = this.listFielOs[0].fecha;
          serviceOrder.idstatusborrado = 1;
      
          const service_order_create: any = {
            serviceOrder: serviceOrder,
            listado_service_order_detalle: this.listFielOs
          };
  
          await this.updateProgress(10);
      
          // Crear la orden de servicio
          const res = await this.serviceOrder.createServiceOrder(service_order_create).toPromise();
          this.serviceorder = res.id;
          const detalle: any = res;
  
  
          let condicion = true;
  
          let datos_clientes;
  
          /*TRAE DATOS DEL CLIENTE CON LA ORDER DE SERVICIO CREADA*/
          while (condicion) {
            datos_clientes = await this.serviceOrder.consulta_datos_cliente(this.serviceorder).toPromise();
            if(datos_clientes.message == false){
              condicion = true;
            }else{
              condicion = false;
            }
          }
          //console.log('datos_clientes',datos_clientes);
  
  
          // Actualizar detalles del contrato por orden de servicio CON E
          /*try {
            await this.serviceContract.updateContractDetailByOrderService(detalle).toPromise();
          } catch (error) {
            throw new Error('Error al actualizar detalles del contrato por orden de servicio');
          }*/
      
          // Mostrar mensaje de éxito
          if (this.id_reservation !== undefined) {
            Swal.fire('🤩 Excelente !!', 'Has creado el pago de la cita, recuerda hacer seguimiento al pago', 'success');
          } else {
            this.toastr.success('Se creó la orden de servicio y sus detalles!!', 'Ok!', { timeOut: 3000 });
          }
      
          await this.updateProgress(10);
          //console.log(this.paymentMethod);
  
          let validar_facturacion = true;
          let bancos = '';
  
          /*VALIDA SI EL METODO DE PAGO ES EFECTIVO , NOTA DE CREDITO O DESCUENTO DE PLANILLA , SOLO CREARA LA OS MAS NO LA FACTURACION*/
          for (let index = 0; index < this.listFielOs.length; index++) {
            //console.log(validar_facturacion);
            if(this.listFielOs[index].idMethod == 1 || this.listFielOs[index].idMethod == 7 || this.listFielOs[index].idMethod==14 || this.listFielOs[index].idMethod==5){
              validar_facturacion = false;
              this.hideProgressModal();
              break;
            }
            bancos += this.listFielOs[index].methodPaymennt+' - N° '+this.listFielOs[index].operationNumber+' / ';
            
          }
  
          /*COMIENZA LA CREACIÓN DE LA FACTURA*/
          if(validar_facturacion == true){
                // Consultar detalles de la orden de servicio
              let ordenServiceDetail;

              /*CONSULTA LA ORDEN DE SERVICIO CREADA Y DETALLE*/
              try {
                ordenServiceDetail = await this.consulta(this.serviceorder);
                //console.log('resultado', ordenServiceDetail);
              } catch (error) {
                throw new Error('Error al consultar detalles de la orden de servicio');
              }
  
              //let data = null;

              /*LE DAS LA ESTRUCTURA PARA LA FACTURACION*/
              let nuevo_formato;
              try {
                nuevo_formato = await this.invoiceStructure(datos_clientes,ordenServiceDetail, this.listFielOs,nombre_completo,numero_documento,direccion)
                //console.log('resultado',nuevo_formato);
  
                await this.updateProgress(10);
                
              } catch (error) {
                throw new Error('Error al generar formato'+ error);
              }
  
              //let urls;
          
              let urlSoles: string | null = null;
              let urlDolares: string | null = null;

              /*ENVIA LA ESTRUCTURA GENERADA PARA PODER GENERAR LA FACTURACIÓN*/
              try {
                const resp = await this.serviceBillingPaymentsServic.send_invoice_mi_fact(nuevo_formato).toPromise()
  
                urlSoles =resp.comprobante_soles;
                urlDolares = resp.comprobante_dolares;
                //console.log(urlSoles,urlDolares);
                //console.log('respuesta de facturación',resp);
                
              } catch (error) {
                throw new Error('Error al generar formato la factura');
              }
  
              await this.updateProgress(10);
              //console.log('urls.data',urls.data);
              
              if(urlDolares){
                const newWindow = window.open(urlDolares, '_blank');
                if (newWindow) {
                  newWindow.focus();
                } else {
                  this.spinner.hide();
                }
              }
              if(urlSoles){
                const newWindow = window.open(urlSoles, '_blank');
                if (newWindow) {
                  newWindow.focus();
                } else {
                  this.spinner.hide();
                }
              }
  
              /*let cantidad = 0;
              if (urls.comprobante_dolares !== null) {
                cantidad++;
              }
              if (urls.comprobante_soles !== null) {
                cantidad++;
              }*/
                await this.updateProgress(10);
              
              /*SI HAY UN URLSOLES APERTURA EL MODAL DDE VISTA PARA DESCARGAR COMPROBANTE*/
              if(urlSoles || urlDolares){
                await this.updateProgress(10);
                await this.updateClient(nombre_completo,this.historial_clinico_paciente.history,bancos,urlSoles,urlDolares);
              }
              
              let mensaje = `Descargue su Comprobante de Pago aquí: https://invoice.maxillaris.pe/boletas.html?idServiceOrder=${this.serviceorder}`
                
                /*if(urlDolares) {
                  mensaje += `\nDólares: ${urlDolares}`;
           
                }
                if(urlSoles) {
                  mensaje += `\nSoles: ${urlSoles}`;
                }*/
  
                if(this.historial_clinico_paciente.cellphone){
                  const wsp = `https://api.whatsapp.com/send?phone=51${this.historial_clinico_paciente.cellphone}&text=${encodeURIComponent(mensaje)}`;
                  window.open(wsp, '_blank');
                }else{
                  this.toastr.warning('Este usuario no cuenta con un número de celular registrado, no se pudo enviar al Whatsapp','Advertencia',{timeOut: 3000})
                }
              /*if(urls.Mensaje){
                Swal.fire('🤩 Excelente !!', 'Has creado el pago de la cita, en breve procesaremos el pago', 'success').then(function() {
                  Swal.fire('⚠️ Recuerda !!', 'Si has cometido un error, AVÍSALO CUANTO ANTES', 'warning');
              });
              
                
              }*/
              //console.log("ID invoice extra: ",this.type_invoice_extract);
              //console.log("Cantidad: ",cantidad);
              //console.log("service_order id = ",this.serviceorder);
              
              
              
              /*try {
                const actualizacion = await this.serviceOrder.update_invoice(this.type_invoice_extract, cantidad, this.serviceorder).toPromise();
                //console.log('Actualización exitosa: ',actualizacion);
              } catch (error) {
                  console.error('Error al actualizar el invoice:', error);
                  // Puedes agregar más lógica para manejar el error, como mostrar un mensaje al usuario o enviar un reporte
              }*/
  
  
  
              const agrupadoPorTarifa = {};
                /*AGRUPAS LAS ORDENES SERVICIO DETALLE POR TARIFA*/
              for (let index = 0; index < this.listFielOs.length; index++) {
                const item = this.listFielOs[index];
                const idTarifa = item.idTariff;
  
                if (!agrupadoPorTarifa[idTarifa]) {
                  // Inicializar el objeto para cada idTarifa
                  agrupadoPorTarifa[idTarifa] = {
                    sumaSoles: 0,
                    sumaTotalTarifa: 0,
                    sumaDolares: 0,
                    idCoinTarifa: 0,
                    exchange:0
                  };
                }
  
                if (item.idCoin == 1) {
                  agrupadoPorTarifa[idTarifa].sumaSoles += item.value;
                  agrupadoPorTarifa[idTarifa].sumaTotalTarifa = item.valueTariff;
                  agrupadoPorTarifa[idTarifa].idCoinTarifa = item.idCoinTariff;
                  agrupadoPorTarifa[idTarifa].exchange = item.exchangueRate;
                } else {
                  agrupadoPorTarifa[idTarifa].sumaDolares += item.value;
                  agrupadoPorTarifa[idTarifa].sumaTotalTarifa = item.valueTariff;
                  agrupadoPorTarifa[idTarifa].idCoinTarifa = item.idCoinTariff;
                  agrupadoPorTarifa[idTarifa].exchange = item.exchangueRate;
                }
  
              }
              
              //console.log('agrupadoPorTarifa',agrupadoPorTarifa);
              
  
              //console.log(sumaSoles,'|',sumaTotalSoles,'|',sumaDolares,'|',sumaTOtalDolares);
              
              //if(false){
                //console.log('ingreso');
  
  
  
                let serviceOrder: ServiceOrderNew = new ServiceOrderNew();
                let listFielOs:ServiceOrderPaymentDetailNew[]=[];
  
                let serviceOrdenDetailSon: ServiceOrderPaymentDetailNew [] = [];
  
                //console.log("this.listFielOs: ", this.listFielOs);
                
  
                //console.log('se ejecuta hasta aca');
                
                for (let index = 0; index < this.listFielOs.length; index++) {
                  //console.log('index',index);
  
                  /*SI LA LISTA PARA LA CREACION DE LA ORDEN HIJO YA TIENE EL MISMO ID TARIFA DE LA POSICON ACTUAL , SALTA A LA SIGUIENTE POSICON DE LA ARRAY*/
                  if(serviceOrdenDetailSon.some(detail => detail.idTariff === this.listFielOs[index].idTariff)){
                    continue;
                  }
                  
                  const data = agrupadoPorTarifa[this.listFielOs[index].idTariff]
  
                  //console.log('data',data);
                  
                  let total = 0;
                  if(data.idCoinTarifa == 1){
                    total = data.sumaSoles + (data.sumaDolares * data.exchange)
                  }else{
                    total = data.sumaDolares + (data.sumaSoles / data.exchange)
                  }
                  
  
                  const diferencia = Math.abs(total - this.listFielOs[index].valueTariff);
  
  
                  //console.log('this.listFielOs[index].valueTariff',this.listFielOs[index].valueTariff);
                  //console.log('total',total);
                  //console.log('diferencia',diferencia);
                  //console.log('boleano',total < this.listFielOs[index].valueTariff);
                  
                  
                  if(diferencia > 0.20 && total < this.listFielOs[index].valueTariff){
                        serviceOrder.clinichistory = Number(this.idclinchistory);
                        serviceOrder.doctor = this.listFielOs[0].idDoctor;
                        serviceOrder.reservation = null;
                        serviceOrder.dateserviceorder = this.listFielOs[0].fecha;
                        serviceOrder.idstatusborrado = 1;
  
                    if (this.listFielOs[index].idCoin == this.listFielOs[index].idCoinTariff) {
  
                      if(this.listFielOs[index].value < this.listFielOs[index].valueTariff){
  
                        const diferencia_tarifa = this.listFielOs[index].valueTariff - total;
  
                        let total_New = diferencia_tarifa;
  
                        if (this.listFielOs[index].amountDiscount> 0) {
                          if(this.listFielOs[index].typeDiscount == true){
                            //console.log(this.listFielOs[index].typeDiscount);
                            //console.log(this.listFielOs[index].amountDiscount);
                            total_New = diferencia_tarifa - Math.ceil(diferencia_tarifa *(this.listFielOs[index].amountDiscount/100));
                            
                          }else{
                            total_New= diferencia_tarifa;
                            this.listFielOs[index].idDiscount = null;
                          }
                
                          
                        }
                        let newDetail : ServiceOrderPaymentDetailNew = {
                          fecha:this.listFielOs[index].fecha,
                          idCoin:this.listFielOs[index].idCoin,
                          coin:this.listFielOs[index].coin,
                          idDoctor:this.listFielOs[index].idDoctor,
                          doctor:this.listFielOs[index].doctor,
                          idBussiness:this.listFielOs[index].idBussiness,
                          bussiness:this.listFielOs[index].bussiness,
                          idSpeciality:this.listFielOs[index].idSpeciality,
                          speciality:this.listFielOs[index].speciality,
                          idTariff:this.listFielOs[index].idTariff,
                          tariff:this.listFielOs[index].tariff,
                          valueTariff:diferencia_tarifa,
                          idMethod:this.listFielOs[index].idMethod,
                          methodPaymennt:this.listFielOs[index].methodPaymennt,
                          idDiscount:this.listFielOs[index].idDiscount,
                          discount: this.listFielOs[index].discount,
                          reasonDiscount:this.listFielOs[index].reasonDiscount,
                          quantity:this.listFielOs[index].quantity,
                          value:diferencia_tarifa,
                          amountDiscount:this.listFielOs[index].amountDiscount,
                          total:total_New,
                          idClinicHistory:this.listFielOs[index].idClinicHistory,
                          typeDiscount:this.listFielOs[index].typeDiscount,
                          idContrato :this.listFielOs[index].idContrato,
                          idReservation : this.listFielOs[index].idReservation,
                          idAccountbank: this.listFielOs[index].idAccountbank,
                          AccountBank: this.listFielOs[index].AccountBank,
                          operationNumber: this.listFielOs[index].operationNumber,
                          paymentDate: this.listFielOs[index].paymentDate,
                          idMethodCard: this.listFielOs[index].idMethodCard,
                          methodCard: this.listFielOs[index].methodCard,
                          idExchanfueRate:this.listFielOs[index].idExchanfueRate,
                          exchangueRate: this.listFielOs[index].exchangueRate,
                        }
  
                        serviceOrdenDetailSon.push(newDetail)
                      }
                    }else if(this.listFielOs[index].idCoin !== this.listFielOs[index].idCoinTariff){
  
                      //console.log('parte1');
                      
                      if(this.listFielOs[index].idCoinTariff == 1 && this.listFielOs[index].idCoin ==2){
                        //console.log('parte1',this.listFielOs[index]);
                        const valor_dolares_soles = Number(this.listFielOs[index].value * this.listFielOs[index].exchangueRate);
  
                        if(valor_dolares_soles < this.listFielOs[index].valueTariff){
  
                          const diferencia_tarifa = this.listFielOs[index].valueTariff - valor_dolares_soles;
  
                          let total_New = diferencia_tarifa;
  
                          if (this.listFielOs[index] .amountDiscount> 0) {
                            if(this.listFielOs[index].typeDiscount == true){
                              //console.log(this.listFielOs[index].typeDiscount);
                              //console.log(this.listFielOs[index].amountDiscount);
                              total_New = diferencia_tarifa - Math.ceil(diferencia_tarifa *(this.listFielOs[index].amountDiscount/100));
                              
                            }else{
                              total_New= diferencia_tarifa;
                              this.listFielOs[index].idDiscount = null;
                            }
                  
                            
                          }
  
                          let newDetail : ServiceOrderPaymentDetailNew = {
                            fecha:this.listFielOs[index].fecha,
                            idCoin:this.listFielOs[index].idCoinTariff,
                            coin:this.listFielOs[index].coin,
                            idDoctor:this.listFielOs[index].idDoctor,
                            doctor:this.listFielOs[index].doctor,
                            idBussiness:this.listFielOs[index].idBussiness,
                            bussiness:this.listFielOs[index].bussiness,
                            idSpeciality:this.listFielOs[index].idSpeciality,
                            speciality:this.listFielOs[index].speciality,
                            idTariff:this.listFielOs[index].idTariff,
                            tariff:this.listFielOs[index].tariff,
                            valueTariff:diferencia_tarifa,
                            idMethod:this.listFielOs[index].idMethod,
                            methodPaymennt:this.listFielOs[index].methodPaymennt,
                            idDiscount:this.listFielOs[index].idDiscount,
                            discount: this.listFielOs[index].discount,
                            reasonDiscount:this.listFielOs[index].reasonDiscount,
                            quantity:this.listFielOs[index].quantity,
                            value:diferencia_tarifa,
                            amountDiscount:this.listFielOs[index].amountDiscount,
                            total:total_New,
                            idClinicHistory:this.listFielOs[index].idClinicHistory,
                            typeDiscount:this.listFielOs[index].typeDiscount,
                            idContrato :this.listFielOs[index].idContrato,
                            idReservation : this.listFielOs[index].idReservation,
                            idAccountbank: this.listFielOs[index].idAccountbank,
                            AccountBank: this.listFielOs[index].AccountBank,
                            operationNumber: this.listFielOs[index].operationNumber,
                            paymentDate: this.listFielOs[index].paymentDate,
                            idMethodCard: this.listFielOs[index].idMethodCard,
                            methodCard: this.listFielOs[index].methodCard,
                            idExchanfueRate:this.listFielOs[index].idExchanfueRate,
                            exchangueRate: this.listFielOs[index].exchangueRate,
                          }
      
                          serviceOrdenDetailSon.push(newDetail)
                        }
                        
                      }else if (this.listFielOs[index].idCoinTariff == 2 && this.listFielOs[index].idCoin ==1){
  
                        //console.log('parte3',this.listFielOs[index]);
                        const valor_soles_dolares = Number((this.listFielOs[index].value / this.listFielOs[index].exchangueRate).toFixed(2));
                      
                        //console.log('valor_soles_dolares',valor_soles_dolares);
                        if(valor_soles_dolares < this.listFielOs[index].valueTariff){
      
                          const diferencia_tarifa = this.listFielOs[index].valueTariff - valor_soles_dolares;
      
                          let total_New = diferencia_tarifa;
      
                          if (this.listFielOs[index] .amountDiscount> 0) {
                            if(this.listFielOs[index].typeDiscount == true){
                              //console.log(this.listFielOs[index].typeDiscount);
                              //console.log(this.listFielOs[index].amountDiscount);
                              total_New = diferencia_tarifa - Math.ceil(diferencia_tarifa *(this.listFielOs[index].amountDiscount/100));
                              
                            }else{
                              total_New= diferencia_tarifa;
                              this.listFielOs[index].idDiscount = null;
                            }
                          }
                          let newDetail : ServiceOrderPaymentDetailNew = {
                            fecha:this.listFielOs[index].fecha,
                            idCoin:this.listFielOs[index].idCoinTariff,
                            coin:this.listFielOs[index].coin,
                            idDoctor:this.listFielOs[index].idDoctor,
                            doctor:this.listFielOs[index].doctor,
                            idBussiness:this.listFielOs[index].idBussiness,
                            bussiness:this.listFielOs[index].bussiness,
                            idSpeciality:this.listFielOs[index].idSpeciality,
                            speciality:this.listFielOs[index].speciality,
                            idTariff:this.listFielOs[index].idTariff,
                            tariff:this.listFielOs[index].tariff,
                            valueTariff:diferencia_tarifa,
                            idMethod:this.listFielOs[index].idMethod,
                            methodPaymennt:this.listFielOs[index].methodPaymennt,
                            idDiscount:this.listFielOs[index].idDiscount,
                            discount: this.listFielOs[index].discount,
                            reasonDiscount:this.listFielOs[index].reasonDiscount,
                            quantity:this.listFielOs[index].quantity,
                            value:diferencia_tarifa,
                            amountDiscount:this.listFielOs[index].amountDiscount,
                            total:total_New,
                            idClinicHistory:this.listFielOs[index].idClinicHistory,
                            typeDiscount:this.listFielOs[index].typeDiscount,
                            idContrato :this.listFielOs[index].idContrato,
                            idReservation : this.listFielOs[index].idReservation,
                            idAccountbank: this.listFielOs[index].idAccountbank,
                            AccountBank: this.listFielOs[index].AccountBank,
                            operationNumber: this.listFielOs[index].operationNumber,
                            paymentDate: this.listFielOs[index].paymentDate,
                            idMethodCard: this.listFielOs[index].idMethodCard,
                            methodCard: this.listFielOs[index].methodCard,
                            idExchanfueRate:this.listFielOs[index].idExchanfueRate,
                            exchangueRate: this.listFielOs[index].exchangueRate,
                          }
  
                          serviceOrdenDetailSon.push(newDetail)
  
                          
                        }
  
                      }
                    }
                    
                  }
  
                }
                const service_order_create_new: any = {
                  serviceOrder: serviceOrder,
                  listado_service_order_detalle: serviceOrdenDetailSon
                };
                //console.log("service_order_create_new: ", service_order_create_new);
                
                //console.log('service_order_create_new',service_order_create_new);
                if(service_order_create_new.listado_service_order_detalle.length > 0){
                Swal.fire({
                  title: 'Esta seguro que desea crear una orden de servicio hijo?',
                  text: 'Esta accion es irreversible!',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Si, continuar!',
                }).then(async (result) => {

                  if (result.value) {

                      try {
                        const res = await this.serviceOrder.createServiceOrder(service_order_create_new).toPromise();
                        this.toastr.success('Se creo una orden de servicio hijo','OK!!!',{timeOut:3000})
                      } catch (error) {
                        console.error('Error al crear orden de servicio:', error);
                        this.toastr.error('Error al crear orden de servicio hijo','Error!!!',{timeOut:3000})
                      }
                      
                   
                  }
                  console.log(result);
                })
               
              }
          }
          
      
          // Subir archivos
          try {
            //console.log('ingreso');
            //console.log('ingreso al try cath onsubmit 2');
            await this.vouchers.onSubmit();
          } catch (error) {
            throw new Error('Error al subir archivos');
          }
          //console.log('ingreso 2');
          this.activeModal.close('Save click');
      
        } catch (error) {
          this.hideProgressModal();
          console.error(error);
          this.toastr.error(error.message || 'Hubo un error al crear la orden de servicio!!', 'Error!', { timeOut: 3000 });
        }
      }else{
        this.hideProgressModal();
        this.toastr.warning('Hay errores en los datos del cliente','Advertencia !!', {timeOut : 3000})
      }
  
  

    })
    

  }

}
