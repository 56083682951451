<form class="was-validated" (ngSubmit)="onSubmit()" ngNativeValidate>
    <div class="modal-header modal_header">
        <h5 class="modal-title titulo-modal">{{this.title}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="form-control-label" for="name">Doctor <small class="text-danger">(*)</small></label>
                    <ng-select [items]="doctorList"
                    bindLabel="name"
                    bindValue="id"
                    placeholder="Seleccione"
                    name="doctor"
                    [clearable]="false"
                    [(ngModel)]="this.formInput.doctor"></ng-select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-control-label" for="name">Titulo <small class="text-danger">(*)</small></label>
                    <input type="text" class="form-control" minlength="5" name="title" [(ngModel)]="formInput.title" required=""
                    placeholder="Titulo de la nota">
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-control-label" for="name">Nota <small class="text-danger">(*)</small></label>
                    <textarea name="reason" cols="30" rows="3" placeholder="Digite la nota"
                    class="form-control" required="" [(ngModel)]="formInput.note"></textarea>
                </div>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" *ngIf="!loaderInsert">
            <i class="fas fa fa-save"></i> Registrar
        </button>
        <button type="submit" class="btn btn-primary" *ngIf="loaderInsert" disabled>
            <span class="spinner-border spinner-border-sm" role="status"></span>
            <span class="load-text"> Registrando...</span>
        </button>
        <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')"
        >
        Salir
        </button>
    </div>
</form>