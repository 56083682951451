<div class="modal-header modal-header-default modal_header">
    <h5 class="modal-title titulo-modal">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.close('Close click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div class="dt-responsive table-responsive">
        <table datatable [dtOptions]="dtOptions" class="table table-striped row-border table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>Descripcion</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of pendientQuotas">
                <td><input class="cpv-check" type="radio" [checked]="selectedItem === item" (change)="toggleSelection(item)" [disabled]="isDisabled(item)"></td>
                <td>{{item.date | date: "dd-MM-yyyy"}}</td>
                <td>{{item.description}}</td>
                <td>{{item.amount | number: '.2'}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Descripcion</th>
                <th>Valor</th>
            </tr>
          </tfoot>
        </table>
      </div>
</div>
<div class="modal-footer">
    <button type="button" uiSref="work" class="btn btn-outline-primary"
        (click)="Seleccionar()" type="button">
        Guardar
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Salir
    </button>
</div>
