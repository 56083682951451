import { Transferable } from '../../../../shared/interfaces/iTransferable';
import { ProgrammingDto } from '../dtos/programming-dto';

export class Programming implements Transferable<ProgrammingDto> {
    iddoctor: number;
    doctor: string;
    date: string;
    schedule: any[];

    ToDTO(): ProgrammingDto {
        const dto: ProgrammingDto = ({
            iddoctor: this.iddoctor,
            doctor: this.doctor,
            date: this.date,
            schedule: this.schedule
        });
        return dto;
    }

    FromDTO(from: ProgrammingDto): Programming {
        this.iddoctor = from.iddoctor;
        this.doctor = from.doctor;
        this.date = from.date;
        this.schedule = from.schedule;
        return this;
    }
}