import { Component, OnInit } from '@angular/core';
import { Programming } from '../../../doctor-diary/models/programming.model';
import { Store } from '@ngrx/store';
import { DoctorDiaryService } from '../../../doctor-diary/services/public/doctor-diary.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-doctor-diary-two',
  templateUrl: './doctor-diary-two.component.html',
  styleUrls: ['./doctor-diary-two.component.scss']
})
export class DoctorDiaryTwoComponent implements OnInit {

  programming: Programming[] = [];
  session: any = {};
  showFilters = false;
  loading = false;
  sesiones : any;

  constructor(
    private store: Store<{ session: any }>,
    private service: DoctorDiaryService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private users: AuthService
  ) { }

  ngOnInit(): void {
    this.getSession();

    /*this.sesiones=[
      {
      "id": 18,
      "numero_celular": "959327510",
      "email": "jlopzmonier@gmail.com",
      "agenda_sv": "2",
      "created_at": "2024-08-09T19:21:04.351Z",
      "status_borrado": false,
      "user": {
          "id": 190,
          "username": "jean.lopez",
          "email": "jlopzmonier@gmail.com",
          "password": "$2a$10$2fKHS8yFTD5JmYWCT6avKOfkD5qyxnAmewL9sBfEHD7rzD9JWWeRy",
          "campus": [
              1
          ],
          "estado": 1,
          "createdAt": "2023-08-10T16:51:38.568Z",
          "updatedAt": "2023-08-10T16:51:38.568Z",
          "roles": {
              "idrole": 8,
              "name": "Ventas",
              "dashboard": "RECEPCIÓN",
              "description": "Asesores de Ventas",
              "estado": 1,
              "createdAt": "2021-05-07T21:14:01.886Z",
              "updatedAt": "2021-05-07T21:14:01.886Z"
          }
      },
      "cargo": {
          "id": 2,
          "nombre": "EJECUTIVO OFM",
          "descripcion": "EJECUTIVO OFM",
          "createdAt": "2024-08-09T18:35:29.803Z",
          "statusBorrado": false
      },
      "area": {
          "id": 1,
          "nombre": "OFM",
          "descripcion": "CAMPAÑA OFM",
          "createdAt": "2024-08-09T18:14:32.907Z",
          "statusBorrado": false
      },
      "teamLeader": {
          "id": 2,
          "nombre_de_equipo": "EQUIPO DE JEAN LÓPEZ",
          "createdAt": "2024-08-09T18:09:22.882Z",
          "statusBorrado": false
      },
      "gerenteArea": {
          "id": 1,
          "nombre_de_gerencia": "GERENTE COMERCIAL",
          "createdAt": "2024-08-09T18:04:18.865Z",
          "statusBorrado": false
      }
  },]*/
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.showFilters = true;
          this.getUserDetail();
          if (sess.doctor) {
            // this.filterDoctor = sess.doctor.id;
          }

          /*if(this.session.roles.name == 'ADMIN'){
            Swal.fire({
              title: 'Atención!!!!',
              text: 'Su usuario no esta permitido el ingreso a esta agenda.',
              type: 'error',
              showCloseButton: false,
              showCancelButton: false,
            }).then((willInsert) => {
              if (willInsert.value){
                this.router.navigate(['/inicio']);
              }
            })
          }*/
        }
      });
  }

  getUserDetail(): void {

    this.users.getUsersDetail(this.session.id).subscribe(
      user=>{
        this.sesiones=user;
        //agenda 2 usuarios: asistente dentales, ejecutivo apnea y ejecutivo oi
        
      },error=>{
        console.log({ error });
        this.loading = false;
      }
    )
  }

  setSchedule(event: any): void {
    this.loading = true;
    const idcampus = this.session.idcampus;
    this.programming = [];
    this.spinner.show();
    
    this.service.getProgramming(event, idcampus,2).subscribe({
      next: (resp) => {
        //console.log('respuesta',resp);
        
        this.loading = false;
        this.programming = resp;
        this.spinner.hide();
      },
      error: (error) => {
        console.log({ error });
        this.loading = false;
      }
    });
  }

}
