import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { ProgramationService } from 'src/app/service/programation.service';
import { UnionDoctorPatientAttentionService } from 'src/app/service/union-doctor-patient-attention.service';
import { DoctorDiaryService } from '../../doctor-diary/services/public/doctor-diary.service';
import { ProgramationModel } from 'src/app/models/programation.model';
import { TariffService } from 'src/app/service/tariff.service';
import { ReservationService } from 'src/app/service/main/reservation.service';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2';

class FormFields {
  get Doctor(): string { return 'Doctor'; }
  get Speciality(): string { return 'Speciality'}
  get selectedPatient(): string { return 'selectedPatient' }
  get Tariff(): string { return 'Tariff'}
  get Interval(): string { return 'Interval'}
}

@Component({
  selector: 'app-doctor-diary-attention-patient-update',
  templateUrl: './doctor-diary-attention-patient-update.component.html',
  styleUrls: ['./doctor-diary-attention-patient-update.component.scss']
})
export class DoctorDiaryAttentionPatientUpdateComponent implements OnInit {

  @Input() data: any;
  
  title = 'Agendar Nueva Cita';
  listPatient : any [] = [];
  searchControl = new FormControl();
  filteredPatients: any[];
  selectedPatient: any;

  formData: FormGroup;
  formFields: FormFields = new FormFields();
  selectedPatientValue: any; 

  listSpecialty : any [] = [];
  listDoctors : any [] = [];
  listTarriff : any [] = [];


  horarios: any[] = [];
  horariosFiltrados: any[] = [];
  horariosPorPagina: number = 15;
  paginaActual: number = 1;
  minutes_attention: number = 0;
  horaBusqueda:any = '';
  selectedHorarioId: number | null = null; 
  enableDoctor : boolean = true;
  interval_principal : number = 0;
  minuts_principal : number = 0;
  dateToday :any ;
  interval_total : number = 0;
  id_doctor_programming :number = 0;
  fechaSeleccionada: string;

  patientId:number = 0;

  reservation:any;
  session: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    public _serviceClinicHistory: ClinicHistoryService,
    private fb: FormBuilder,
    private serviceUnionDoctorPatientAttention: UnionDoctorPatientAttentionService,
    private toastr: ToastrService,
    private programationService: ProgramationService,
    private spinner: NgxSpinnerService,
    private doctorProgramming :DoctorDiaryService,
    private serviceRservation : ReservationService,
    private store: Store<{ session: any }>,
  ) { }

  ngOnInit(): void {

    
    this.horariosFiltrados = this.horarios;
    this.initForm()
    this.information();
    this.getSession();

    console.log('modal de reprogramacion');
    
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
        }
      });
  }

  initForm():void{

        //console.log('aqui',this.reservation);
        
        //this.setListDoctors();

        this.formData = this.fb.group({});
        this.formData.addControl(this.formFields.Doctor, new FormControl(null, [Validators.required]));
        this.formData.addControl(this.formFields.Speciality, new FormControl(null, [Validators.required]));
        this.formData.addControl(this.formFields.selectedPatient,new FormControl(null))
        this.formData.addControl(this.formFields.Tariff, new FormControl(null, [Validators.required]));
        this.formData.addControl(this.formFields.Interval, new FormControl(0, [Validators.required]));
  }

  information():void{
    //console.log(this.data.data.id);
    
    this.serviceRservation.getDetailReservation(this.data.data.id).subscribe(
      resp=>{
        //console.log('respuesta',resp);
        this.setListSpecialities();
        this.reservation = resp;
        this.patientId =  this.reservation.reservation_id_patient;
        const patient =  this.reservation.reservation_name_patient+' '+this.reservation.reservation_last_father_patient+' '+this.reservation.reservation_last_mother_patient;
        this.dateToday = this.reservation.reservation_date;
        this.fechaSeleccionada = this.dateToday;

        this.formData.patchValue({
          [this.formFields.Doctor]: this.reservation.reservation_id_doctor,
          [this.formFields.Speciality]:this.reservation.reservation_tariff_info,
          [this.formFields.selectedPatient]:patient,
          [this.formFields.Tariff]:this.reservation.reservation_tariff_id
        });
        this.setListDoctors();
  
      }
    );

  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required')) {
      return true;
    }
    return false;
  }

  identifyRol(req:number){
    //console.log(this.session)
    if(this.session.roles.idrole !== 2){
      Swal.fire({
        title: 'Error!!!!',
        text: `No tienes permiso para realizar esta acción`,
        type: 'error',
        showCloseButton: true,
        showCancelButton: false
      }).then(() => {
        if(req == 1){
          this.formData.patchValue({
            [this.formFields.Doctor]: this.reservation.reservation_id_doctor,
          });
        }else if(req == 2){
          this.formData.patchValue({
            [this.formFields.Speciality]: this.reservation.reservation_tariff_info,
          })
        }else if(req == 3){
          this.formData.patchValue({
            [this.formFields.Tariff]: this.reservation.reservation_tariff_id
          })
        }
        
      });
    }else{
      if(req == 1 || req == 2){
        this.setListTariff();
      }else if(req == 3){
        this.setTariffHors();
      }
    }
  }

  setListDoctors(){
    const fecha = this.dateToday;

      // Realiza las acciones necesarias con el valor seleccionado del paciente
      this.serviceUnionDoctorPatientAttention.find_a_doctor(this.patientId,this.dateToday).subscribe(
        resp=>{   
          //console.log('respuesta',resp);
               
          this.listDoctors = [];
          this.listDoctors = resp.data;
          this.enableDoctor = resp.validacion;
  
        },error=>{
          console.log(error);
          this.toastr.error("Ocurrio un error al buscar el doctor","Error", { timeOut: 3000, progressBar: true })
        }
      );
    
  }

  setListSpecialities():void{

    this.serviceUnionDoctorPatientAttention.find_family().subscribe(
      resp =>{
        this.listSpecialty = resp;
        this.setListTariff();
        
      },error =>{
        console.log(error);
        this.toastr.error("Ocurrio un error al buscar el doctor","Error", { timeOut: 3000, progressBar: true })
      }
    )
  }

  setListTariff():void{
    
    const especialidad = this.formData.get(this.formFields.Speciality).value;

    if(especialidad !== null){
      this.serviceUnionDoctorPatientAttention.tariffInfo(especialidad).subscribe(
        resp=>{
          this.listTarriff=resp;
          this.setTariffHors();
        }
      )
      
    }
  }

  async setTariffHors():Promise<void>{
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const tarifa = this.formData.get(this.formFields.Tariff).value;

    if(doctor !== null && tarifa !== null){
      await this.serviceUnionDoctorPatientAttention.tariffInfoHors(tarifa,doctor).subscribe(
        resp=>{
          this.interval_principal = resp[0].intervalo_minimo;

          this.formData.patchValue({
            [this.formFields.Interval]: this.interval_principal
          });
          this.minutes_attention = resp[0].tiempo * this.interval_principal;
          this.minuts_principal = resp[0].tiempo;

          this.setListHoursByDoctor();
        }
      )
    }
  }

  validate_interval():void{
    const intervals = this.formData.get(this.formFields.Interval).value;
    
    if(intervals < this.interval_principal){
      this.toastr.error("El intervalo ingresado no puede ser menor al intervalo original","Error", { timeOut: 3000, progressBar: true })
      this.formData.patchValue({
        [this.formFields.Interval]: this.interval_principal
      });
      return;
    }else if (intervals >= this.interval_principal){
      this.minutes_attention = intervals * this.minuts_principal;
    }
  }

  setListHoursByDoctor():void{
    this.spinner.show();
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const fecha = this.dateToday;
    console.log(doctor);
    
    this.doctorProgramming.get_reserv_free_prueba(doctor,fecha,this.minutes_attention).subscribe(
      resp =>{
        //console.log('respuesta',resp)
        const data = resp[0];
        //console.log('data',data);
        
        this.interval_total = data[0].interval;
        this.horarios = [];

        let id = 0; 
        while (id < data.length) {

          this.horarios.push({
            id: id,
            horario: `${data[id].since} - ${data[id].until}`
        });
        id++;
        }
        this.horariosFiltrados = this.horarios;

        this.spinner.hide();
        this.horariosPaginados();
          
      }
    )
    
  }

  horariosPaginados(): any[] {

    const inicio = (this.paginaActual - 1) * this.horariosPorPagina;
    return this.horariosFiltrados.slice(inicio, inicio + this.horariosPorPagina);
  }

  busqueda():void{
  
    this.horariosFiltrados = this.horarios.filter(horario => horario.horario.includes(this.horaBusqueda));
    this.paginaActual = 1; // Reset to first page after filter
    
  }

  get paginas(): number[] {
    return Array(this.numeroTotalPaginas()).fill(0).map((x, i) => i + 1);
  }

  numeroTotalPaginas(): number {
    return Math.ceil(this.horariosFiltrados.length / this.horariosPorPagina);
  }

  cambiarPagina(pagina: number) {
    if (pagina >= 1 && pagina <= this.numeroTotalPaginas()) {
      this.paginaActual = pagina;
    }
  }

  resetBusqueda(): void {
    this.horaBusqueda = '';
    this.horariosFiltrados = this.horarios;
    this.paginaActual = 1; // Reset to first page after reset
  }

  selectHorario(id: number): void {
    this.selectedHorarioId = id;
  }

  async save():Promise<void>{
    this.spinner.show();
    const cita = this.horariosFiltrados.find(item => item.id == this.selectedHorarioId);
    const env = this.formData.get(this.formFields.Doctor).value;
    const enviroments = this.listDoctors.find(item => item.id_doctor == env);
    //const paciente = this.formData.get(this.formFields.selectedPatient).value;
    const tarifa = this.formData.get(this.formFields.Tariff).value
    const [inicio, fin] = cita.horario.split(" - ");

    if(cita === undefined || cita ===  null){
      this.toastr.error("Debe escoger un horario","Error", { timeOut: 3000, progressBar: true })
      return;
    }

    if(tarifa === undefined || tarifa ===  null){
      this.toastr.error("Debe escoger un horario","Error", { timeOut: 3000, progressBar: true })
      return;
    }
    
    const reservation : ProgramationModel = {
      id:this.reservation.reservation_id,
      qdetail : 0,
      date: this.dateToday,
      appointment: cita.horario,
      state: 1,
      environment: enviroments.id_environmentdoctor,
      doctor: env,
      patient:this.reservation.reservation_id_patient,
      tariff:tarifa,
      pending_payment: false,
      interval :this.minutes_attention,
      since:inicio,
      until:fin
    }
    //console.log('reservation',reservation);
    

    await this.programationService.updateReservation(this.reservation.reservation_id,reservation).subscribe(
      resp=>{
        this.spinner.hide();
        //console.log(resp);
        //console.log('doctor',this.id_doctor_programming);
        const diference_interval = this.interval_total -this.minutes_attention;
        //console.log(diference_interval);



        this.activeModal.close('Save click')
        
      },error=>{
        this.spinner.hide();
        console.log(error);
        this.toastr.error("Ocurrio un errror en la insercion de reserva","Error", { timeOut: 3000, progressBar: true })
        
      }
    )
  }

  fechaDeHoy(): string {
    const hoy = new Date();
    return hoy.toISOString().split('T')[0]; // Formato yyyy-mm-dd
  }

  FiltrarPorFecha() {
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const fecha_selected = this.fechaSeleccionada
    //console.log(doctor === 'Ingrese Paciente');
    //console.log(fecha_selected=== undefined);
    if (doctor === 'Ingrese Paciente' && fecha_selected === undefined) {
      this.toastr.error("Debes seleccionar un doctor y una fecha","Error", { timeOut: 3000, progressBar: true })
    }else{
      //console.log("Fecha seleccionada:", fecha_selected);
      //console.log("Este es el doctor:", doctor);
      this.setListHoursByDoctor_filter_date(fecha_selected)
    }
  }

  setListHoursByDoctor_filter_date(fecha_selectd: string):void{
    this.spinner.show();
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const fecha = fecha_selectd;
    //console.log(doctor);
    this.doctorProgramming.get_reserv_free_prueba(doctor,fecha,this.minutes_attention).subscribe(
      resp =>{
        //console.log('respuesta',resp)
        if(resp.length > 0){
          const data = resp[0];
          //console.log('data',data);
          this.interval_total = data[0].interval;
          this.horarios = [];
          let id = 0;
          while (id < data.length) {
            this.horarios.push({
              id: id,
              horario: `${data[id].since} - ${data[id].until}`
          });
          id++;
          }
          this.horariosFiltrados = this.horarios;
          this.spinner.hide();
          this.horariosPaginados();
        }else{
          this.toastr.warning('No hay horarios agendados para este doctor',"Advertencia", { timeOut: 3000, progressBar: true })
          this.horariosFiltrados = [];
        }
        
      }
    )
  }


  accionAntes() {
    const fechaActual = new Date(this.fechaSeleccionada);
    fechaActual.setDate(fechaActual.getDate() - 1); // Retrocede un día
    this.actualizarFecha(fechaActual);
  }

  accionDespues() {
    const fechaActual = new Date(this.fechaSeleccionada);
    fechaActual.setDate(fechaActual.getDate() + 1); // Avanza un día
    this.actualizarFecha(fechaActual);
  }

  actualizarFecha(fecha: Date) {
    const fecha_final = new Date(fecha); 
    const fecha_formateada = fecha_final.toISOString().split('T')[0];
    this.fechaSeleccionada = fecha_formateada;

    this.FiltrarPorFecha();
  }
}
