<app-card [hidHeader]="true">
    <h5 class="card-title">Odontograma #{{data.id}} - Fecha {{data.createdAt | date:"dd-MM-yyyy" }}</h5>
    <p class="card-text">Detalle del odontograma</p>
    <!--Odontograma-->
    <div class="row" style="background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);">
        <div id="tr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">                        
            <div *ngFor="let i of teethsDecrement;" data-name="value" [id]="'teeth1'+i" class="diente">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">
                    1{{i}}
                </span>
                <div (click)="setTeeth(0,i,'t')"
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].t && detail[0].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[0].teeth[i-1].t && detail[0].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[0].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].t.toothStatus === 5
                }" class="cuadro click" [id]="'t1'+i"></div>

                <div (click)="setTeeth(0,i,'l')"
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].l && detail[0].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[0].teeth[i-1].l && detail[0].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[0].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].l.toothStatus === 5
                }" class="cuadro izquierdo click" [id]="'l1'+i"></div>

                <div (click)="setTeeth(0,i,'b')" 
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].b && detail[0].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[0].teeth[i-1].b && detail[0].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[0].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].b.toothStatus === 5
                }" class="cuadro debajo click" [id]="'b1'+i"></div>

                <div (click)="setTeeth(0,i,'r')" 
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].r && detail[0].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[0].teeth[i-1].r && detail[0].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[0].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].r.toothStatus === 5
                }" class="cuadro derecha click" [id]="'r1'+i"></div>

                <div (click)="setTeeth(0,i,'c')" 
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].c && detail[0].teeth[i-1].c.toothStatus === 1,
                    'click-blue': detail[0].teeth[i-1].c && detail[0].teeth[i-1].c.toothStatus === 2,
                    'click-delete': detail[0].teeth[i-1].c.toothStatus === 3
                }" class="centro click" [id]="'c1'+i"></div>
            </div>
            <div *ngFor="let item of extractDecrement; let i = index">
                <i *ngIf="detail[0].teeth[i].c.toothStatus === 4" class="fa fa-times fa-3x fa-fw to-extract" [ngStyle]="{'left': item}"></i>
            </div>
        </div>
        <div id="tl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of teethsInclement;" class="diente" [id]="'teeth2'+i">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">2{{i}}</span>
                <div (click)="setTeeth(1,i,'t')" [id]="'t2' + i" 
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].t && detail[1].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[1].teeth[i-1].t && detail[1].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[1].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].t.toothStatus === 5
                }" class="cuadro click"></div>
                <div (click)="setTeeth(1,i,'l')" [id]="'l2' + i" 
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].l && detail[1].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[1].teeth[i-1].l && detail[1].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[1].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].l.toothStatus === 5
                }" class="cuadro izquierdo click"></div>

                <div (click)="setTeeth(1,i,'b')" [id]="'b2' + i" 
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].b && detail[1].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[1].teeth[i-1].b && detail[1].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[1].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].b.toothStatus === 5
                }" class="cuadro debajo click"></div>

                <div (click)="setTeeth(1,i,'r')" [id]="'r2' + i" 
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].r && detail[1].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[1].teeth[i-1].r && detail[1].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[1].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].r.toothStatus === 5
                }" class="cuadro derecha click"></div>

                <div (click)="setTeeth(1,i,'c')" [id]="'c2' + i" 
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].c && detail[1].teeth[i-1].c.toothStatus === 1,
                    'click-blue': detail[1].teeth[i-1].c && detail[1].teeth[i-1].c.toothStatus === 2,
                    'click-delete': detail[1].teeth[i-1].c.toothStatus === 3
                }" class="centro click"></div>

            </div>
            <div *ngFor="let item of extractIncrement; let i = index">
                <i *ngIf="detail[1].teeth[i].c.toothStatus === 4" class="fa fa-times fa-3x fa-fw to-extract" [ngStyle]="{'left': item}"></i>
            </div>
        </div>
        <div id="tlr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
            <div *ngFor="let i of [5,4,3,2,1]" [id]="'teethM5' + i" style="left: -25%;" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">5{{i}}</span>

                <div (click)="setTeeth(4,i,'t')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].t && detail[4].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[4].teeth[i-1].t && detail[4].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[4].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].t.toothStatus === 5
                }" [id]="'tleche5' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(4,i,'l')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].l && detail[4].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[4].teeth[i-1].l && detail[4].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[4].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].l.toothStatus === 5
                }" [id]="'lleche5' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(4,i,'b')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].b && detail[4].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[4].teeth[i-1].b && detail[4].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[4].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].b.toothStatus === 5
                }" [id]="'bleche5' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(4,i,'r')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].r && detail[4].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[4].teeth[i-1].r && detail[4].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[4].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].r.toothStatus === 5
                }" [id]="'rleche5' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(4,i,'c')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].c && detail[4].teeth[i-1].c.toothStatus === 1,
                    'click-delete': detail[4].teeth[i-1].c.toothStatus === 3
                }" [id]="'cleche5' + i" class="centro-leche click"></div>
            </div>
            <div *ngFor="let item of extractMilkIncrement; let i = index">
                <i *ngIf="detail[4].teeth[i].c.toothStatus === 4"
                    class="fa fa-times fa-2x fa-fw to-extract-milk" [ngStyle]="{'left': item}"></i>
            </div>
        </div>
        <div id="tll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of [1,2,3,4,5]" [id]="'teethM6' + i" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">6{{i}}</span>

                <div (click)="setTeeth(5,i,'t')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].t && detail[5].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[5].teeth[i-1].t && detail[5].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[5].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].t.toothStatus === 5
                }" [id]="'tleche6' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(5,i,'l')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].l && detail[5].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[5].teeth[i-1].l && detail[5].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[5].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].l.toothStatus === 5
                }" [id]="'lleche6' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(5,i,'b')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].b && detail[5].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[5].teeth[i-1].b && detail[5].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[5].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].b.toothStatus === 5
                }" [id]="'bleche6' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(5,i,'r')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].r && detail[5].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[5].teeth[i-1].r && detail[5].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[5].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].r.toothStatus === 5
                }" [id]="'rleche6' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(5,i,'c')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].c && detail[5].teeth[i-1].c.toothStatus === 1,
                    'click-delete': detail[5].teeth[i-1].c.toothStatus === 3
                }" [id]="'cleche6' + i" class="centro-leche click"></div>
            </div>
            <div *ngFor="let item of extractMilkDecrement; let i = index">
                <i *ngIf="detail[5].teeth[i].c.toothStatus === 4"
                class="fa fa-times fa-2x fa-fw to-extract-milk" [ngStyle]="{'left': item}"></i>
            </div>
        </div>

        <!--Odontograma inferior-->
        <div id="blr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">                        
            <div *ngFor="let i of [5,4,3,2,1]" [id]="'teethM8' + i" style="left: -25%;" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">8{{i}}</span>

                <div (click)="setTeeth(7,i,'t')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].t && detail[7].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[7].teeth[i-1].t && detail[7].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[7].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].t.toothStatus === 5
                }"  [id]="'tleche8' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(7,i,'l')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].l && detail[7].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[7].teeth[i-1].l && detail[7].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[7].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].l.toothStatus === 5
                }"  [id]="'lleche8' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(7,i,'b')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].b && detail[7].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[7].teeth[i-1].b && detail[7].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[7].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].b.toothStatus === 5
                }"  [id]="'bleche8' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(7,i,'r')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].r && detail[7].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[7].teeth[i-1].r && detail[7].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[7].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].r.toothStatus === 5
                }"  [id]="'rleche8' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(7,i,'c')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].c && detail[7].teeth[i-1].c.toothStatus === 1,
                    'click-blue': detail[7].teeth[i-1].c && detail[7].teeth[i-1].c.toothStatus === 2,
                    'click-delete': detail[7].teeth[i-1].c.toothStatus === 3
                }"  [id]="'cleche8' + i" class="centro-leche click">
                </div>
            </div>
            <div *ngFor="let item of extractMilkIncrement; let i = index">
                <i *ngIf="detail[7].teeth[i].c.toothStatus === 4"
                    class="fa fa-times fa-2x fa-fw to-extract-milk" [ngStyle]="{'left': item}"></i>
            </div>
        </div>
        <div id="bll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of [1,2,3,4,5]" [id]="'teethM7' + i" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">7{{i}}</span>

                <div (click)="setTeeth(6,i,'t')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].t && detail[6].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[6].teeth[i-1].t && detail[6].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[6].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].t.toothStatus === 5
                }" [id]="'tleche7' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(6,i,'l')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].l && detail[6].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[6].teeth[i-1].l && detail[6].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[6].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].l.toothStatus === 5
                }" [id]="'lleche7' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(6,i,'b')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].b && detail[6].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[6].teeth[i-1].b && detail[6].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[6].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].b.toothStatus === 5
                }" [id]="'bleche7' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(6,i,'r')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].r && detail[6].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[6].teeth[i-1].r && detail[6].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[6].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].r.toothStatus === 5
                }" [id]="'rleche7' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(6,i,'c')"
                [ngClass]="{
                    'click-red': detail[6].teeth[i-1].c && detail[6].teeth[i-1].c.toothStatus === 1,
                    'click-blue': detail[6].teeth[i-1].c && detail[6].teeth[i-1].c.toothStatus === 2,
                    'click-delete': detail[6].teeth[i-1].c.toothStatus === 3
                }" [id]="'cleche7' + i" class="centro-leche click"></div>
            </div>
            <div *ngFor="let item of extractMilkDecrement; let i = index">
                <i *ngIf="detail[6].teeth[i].c.toothStatus === 4"
                    class="fa fa-times fa-2x fa-fw to-extract-milk" [ngStyle]="{'left': item}"></i>
            </div>                 
        </div>
        <div id="br" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">                        
            <div *ngFor="let i of teethsDecrement;" data-name="value" [id]="'teeth4'+i" class="diente">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">
                    4{{i}}
                </span>
                <div (click)="setTeeth(3,i,'t')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].t && detail[3].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[3].teeth[i-1].t && detail[3].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[3].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].t.toothStatus === 5
                }" class="cuadro click" [id]="'t4'+i"></div>

                <div (click)="setTeeth(3,i,'l')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].l && detail[3].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[3].teeth[i-1].l && detail[3].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[3].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].l.toothStatus === 5
                }" class="cuadro izquierdo click" [id]="'l4'+i"></div>

                <div (click)="setTeeth(3,i,'b')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].b && detail[3].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[3].teeth[i-1].b && detail[3].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[3].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].b.toothStatus === 5
                }" class="cuadro debajo click" [id]="'b4'+i"></div>

                <div (click)="setTeeth(3,i,'r')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].r && detail[3].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[3].teeth[i-1].r && detail[3].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[3].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].r.toothStatus === 5
                }" class="cuadro derecha click" [id]="'r4'+i"></div>

                <div (click)="setTeeth(3,i,'c')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].c && detail[3].teeth[i-1].c.toothStatus === 1,
                    'click-blue': detail[3].teeth[i-1].c && detail[3].teeth[i-1].c.toothStatus === 2,
                    'click-delete': detail[3].teeth[i-1].c.toothStatus === 3
                }" class="centro click" [id]="'c4'+i"></div>
            </div>
            <div *ngFor="let item of extractDecrement; let i = index">
                <i *ngIf="detail[3].teeth[i].c.toothStatus === 4" class="fa fa-times fa-3x fa-fw to-extract" [ngStyle]="{'left': item}"></i>
            </div>                 
        </div>
        <div id="bl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of teethsInclement;" class="diente" [id]="'teeth3'+i">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">3{{i}}</span>

                <div (click)="setTeeth(2,i,'t')" 
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].t && detail[2].teeth[i-1].t.toothStatus === 1,
                    'click-blue': detail[2].teeth[i-1].t && detail[2].teeth[i-1].t.toothStatus === 2,
                    'click-delete': detail[2].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].t.toothStatus === 5
                }" [id]="'t3' + i" class="cuadro click"></div>

                <div (click)="setTeeth(2,i,'l')" 
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].l && detail[2].teeth[i-1].l.toothStatus === 1,
                    'click-blue': detail[2].teeth[i-1].l && detail[2].teeth[i-1].l.toothStatus === 2,
                    'click-delete': detail[2].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].l.toothStatus === 5
                }" [id]="'l3' + i" class="cuadro izquierdo click"></div>

                <div (click)="setTeeth(2,i,'b')" 
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].b && detail[2].teeth[i-1].b.toothStatus === 1,
                    'click-blue': detail[2].teeth[i-1].b && detail[2].teeth[i-1].b.toothStatus === 2,
                    'click-delete': detail[2].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].b.toothStatus === 5
                }" [id]="'b3' + i" class="cuadro debajo click"></div>

                <div (click)="setTeeth(2,i,'r')" 
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].r && detail[2].teeth[i-1].r.toothStatus === 1,
                    'click-blue': detail[2].teeth[i-1].r && detail[2].teeth[i-1].r.toothStatus === 2,
                    'click-delete': detail[2].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].r.toothStatus === 5
                }" [id]="'r3' + i" class="cuadro derecha click"></div>

                <div (click)="setTeeth(2,i,'c')" 
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].c && detail[2].teeth[i-1].c.toothStatus === 1,
                    'click-blue': detail[2].teeth[i-1].c && detail[2].teeth[i-1].c.toothStatus === 2,
                    'click-delete': detail[2].teeth[i-1].c.toothStatus === 3
                }" [id]="'c3' + i" class="centro click"></div>
            </div>
            <div *ngFor="let item of extractIncrement; let i = index">
                <i *ngIf="detail[2].teeth[i].c.toothStatus === 4" class="fa fa-times fa-3x fa-fw to-extract" [ngStyle]="{'left': item}"></i>
            </div>
        </div>
        <!--end odontograma inferior-->
    </div>
    <!--Leyendas-->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-left">
                            <div style="height: 20px; width:20px; display:inline-block;" class="click-red"></div> = Fractura/Carie
                            <br>
                            <div style="height: 5px; width:20px; display:inline-block;" class="click-red"></div> = Puente Entre Piezas
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center">
                            <div style="height: 20px; width:20px; display:inline-block;" class="click-blue"></div> = Obturación
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-right">
                            <span style="display:inline-block;"> Pieza Extraída</span> = <img style="display:inline-block;" src="assets/images/odontograma/extraccion.png">
                            <br> Idicada Para Extracción = <i style="color:#dc3545;" class="fa fa-times fa-2x"></i>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</app-card>