import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnumeracionInvoiceDto } from '../../app/models/enumeracion-invoice'

@Injectable({
  providedIn: 'root'
})
export class EnumeracionInvoiceService {

  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http:HttpClient) { }

  getAll(): Observable<EnumeracionInvoiceDto[]>{
    return this.http.get<EnumeracionInvoiceDto[]>(`${this.baseUrl}/enumeracion_ivoice`);
  }

  getLastEnumeracion(invoice:string): Observable<EnumeracionInvoiceDto[]>{
    return this.http.get<EnumeracionInvoiceDto[]>(`${this.baseUrl}/enumeracion_ivoice/enumeracion_ivoice/${invoice}`)
  }


  update(id:number, data :EnumeracionInvoiceDto): Observable<EnumeracionInvoiceDto>{
    return this.http.put<EnumeracionInvoiceDto>(`${this.baseUrl}/enumeracion_ivoice/${id}`,data)
  }
}
