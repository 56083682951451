import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
;
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ProgramationModel } from 'src/app/models/programation.model';
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { ProgramationService } from 'src/app/service/programation.service';
import { UnionDoctorPatientAttentionService } from 'src/app/service/union-doctor-patient-attention.service';
import { DoctorDiaryService } from '../../services/public/doctor-diary.service';
import * as moment from 'moment';


class FormFields {
  get Doctor(): string { return 'Doctor'; }
  get Speciality(): string { return 'Speciality'}
  get selectedPatient(): string { return 'selectedPatient' }
  get Tariff(): string { return 'Tariff'}
  get Interval(): string { return 'Interval'}
}

@Component({
  selector: 'app-doctor-diary-attention-patient',
  templateUrl: './doctor-diary-attention-patient.component.html',
  styleUrls: ['./doctor-diary-attention-patient.component.scss']
})
export class DoctorDiaryAttentionPatientComponent implements OnInit {

  title = 'Agendar Nueva Cita';
  listPatient : any [] = [];
  searchControl = new FormControl();
  filteredPatients: any[];
  selectedPatient: any;

  formData: FormGroup;
  formFields: FormFields = new FormFields();
  selectedPatientValue: any; 

  listSpecialty : any [] = [];
  listDoctors : any [] = [];
  listTarriff : any [] = [];


  horarios: any[] = [];
  horariosFiltrados: any[] = [];
  horariosPorPagina: number = 15;
  paginaActual: number = 1;
  minutes_attention: number = 0;
  horaBusqueda:any = '';
  selectedHorarioId: number | null = null; 
  enableDoctor : boolean = true;
  interval_principal : number = 0;
  minuts_principal : number = 0;
  dateToday :any ;
  interval_total : number = 0;
  id_doctor_programming :number = 0;
  fechaSeleccionada: string;

  constructor(
    public activeModal: NgbActiveModal,
    public _serviceClinicHistory: ClinicHistoryService,
    private fb: FormBuilder,
    private serviceUnionDoctorPatientAttention: UnionDoctorPatientAttentionService,
    private toastr: ToastrService,
    private programationService: ProgramationService,
    private spinner: NgxSpinnerService,
    private doctorProgramming :DoctorDiaryService,
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.horariosFiltrados = this.horarios;
    this.fechaSeleccionada = this.dateToday;
  }

  initForm():void{
    this.formData = this.fb.group({});
    this.formData.addControl(this.formFields.Doctor, new FormControl('Ingrese Paciente', [Validators.required]));
    this.formData.addControl(this.formFields.Speciality, new FormControl(null, [Validators.required]));
    this.formData.addControl(this.formFields.selectedPatient,new FormControl())
    this.formData.addControl(this.formFields.Tariff, new FormControl(null, [Validators.required]));
    this.formData.addControl(this.formFields.Interval, new FormControl(0, [Validators.required]));
  }

  async search(event) {
    const query = event.query;
    await this._serviceClinicHistory.get_specific_clinic(query).subscribe(data => {
      this.filteredPatients = data.map(patient => ({
        ...patient,
        fullName: `${patient.history} ${patient.name} ${patient.lastNameFather} ${patient.name} ${patient.documentNumber}`
      }));
    });
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required')) {
      return true;
    }
    return false;
  }

  setListDoctors(event:any){
    const fecha = this.dateToday;

    const paciente = this.formData.get(this.formFields.selectedPatient).valueChanges.subscribe(value => {
      this.selectedPatientValue = value;
      // Realiza las acciones necesarias con el valor seleccionado del paciente
      this.serviceUnionDoctorPatientAttention.find_a_doctor(this.selectedPatientValue.id,this.dateToday).subscribe(
        resp=>{
          this.listDoctors = [];
          this.listDoctors = resp.data;
          this.enableDoctor = resp.validacion;
          this.setListSpecialities();
        },error=>{
          console.log(error);
          this.toastr.error("Ocurrio un error al buscar el doctor","Error", { timeOut: 3000, progressBar: true })
        }
      )
  });
    
  }

  setListSpecialities():void{

    this.serviceUnionDoctorPatientAttention.find_family().subscribe(
      resp =>{
        this.listSpecialty = resp;
      },error =>{
        console.log(error);
        this.toastr.error("Ocurrio un error al buscar el doctor","Error", { timeOut: 3000, progressBar: true })
      }
    )
  }

  setListTariff():void{
    
    const especialidad = this.formData.get(this.formFields.Speciality).value;

    if(especialidad !== null){
      this.serviceUnionDoctorPatientAttention.tariffInfo(especialidad).subscribe(
        resp=>{
          this.listTarriff=resp;
        }
      )
      
    }
  }

  async setTariffHors():Promise<void>{
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const tarifa = this.formData.get(this.formFields.Tariff).value;

    if(doctor !== null && tarifa !== null){
      await this.serviceUnionDoctorPatientAttention.tariffInfoHors(tarifa,doctor).subscribe(
        resp=>{
          this.interval_principal = resp[0].intervalo_minimo;

          this.formData.patchValue({
            [this.formFields.Interval]: this.interval_principal
          });
          this.minutes_attention = resp[0].tiempo * this.interval_principal;
          this.minuts_principal = resp[0].tiempo;
        }
      )
    }
  }

  validate_interval():void{
    const intervals = this.formData.get(this.formFields.Interval).value;
    
    if(intervals < this.interval_principal){
      this.toastr.error("El intervalo ingresado no puede ser menor al intervalo original","Error", { timeOut: 3000, progressBar: true })
      this.formData.patchValue({
        [this.formFields.Interval]: this.interval_principal
      });
      return;
    }else if (intervals >= this.interval_principal){
      this.minutes_attention = intervals * this.minuts_principal;
    }
  }

  setListHoursByDoctor():void{
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const fecha = this.dateToday;
    
    this.doctorProgramming.get_reserv_free_prueba(doctor,fecha,this.minutes_attention).subscribe(
      resp =>{

        const data = resp[0];
     
        this.interval_total = data[0].interval;
        this.horarios = [];

        let id = 0; 
        while (id < data.length) {

          this.horarios.push({
            id: id,
            horario: `${data[id].since} - ${data[id].until}`
        });
        id++;
        }


        this.horariosFiltrados = this.horarios;
        this.horariosPaginados();
        /*console.log((resp));
        this.interval_total = resp[0].interval_register;
        this.id_doctor_programming = resp[0].dp_id
        this.horarios = [];
        const inicio = new Date(`1970-01-01T${resp[0].dp_time_since}Z`);
        const fin = new Date(`1970-01-01T${resp[0].dp_time_until}Z`);
        

          let actual = new Date(inicio);
          let id = 1; 
          while (actual < fin) {
            const siguiente = new Date(actual);
            siguiente.setMinutes(actual.getMinutes() + this.minutes_attention);
        
            // Formatear las horas para mantener el formato "HH:MM:SS"
            const formatoActual = actual.toISOString().substr(11, 8);
            const formatoSiguiente = siguiente.toISOString().substr(11, 8);
        
            if (siguiente <= fin) {
              this.horarios.push({
                  id: id++,
                  horario: `${formatoActual} - ${formatoSiguiente}`
              });
          }
    
            // Mover al siguiente intervalo
            actual = siguiente;
          }
          this.horariosFiltrados = this.horarios;
          this.horariosPaginados();*/
          
      }
    )
    
  }

  horariosPaginados(): any[] {

    const inicio = (this.paginaActual - 1) * this.horariosPorPagina;
    return this.horariosFiltrados.slice(inicio, inicio + this.horariosPorPagina);
  }

  busqueda():void{
  
    this.horariosFiltrados = this.horarios.filter(horario => horario.horario.includes(this.horaBusqueda));
    this.paginaActual = 1; // Reset to first page after filter
    
  }

  get paginas(): number[] {
    return Array(this.numeroTotalPaginas()).fill(0).map((x, i) => i + 1);
  }

  numeroTotalPaginas(): number {
    return Math.ceil(this.horariosFiltrados.length / this.horariosPorPagina);
  }

  cambiarPagina(pagina: number) {
    if (pagina >= 1 && pagina <= this.numeroTotalPaginas()) {
      this.paginaActual = pagina;
    }
  }

  resetBusqueda(): void {
    this.horaBusqueda = '';
    this.horariosFiltrados = this.horarios;
    this.paginaActual = 1; // Reset to first page after reset
  }

  selectHorario(id: number): void {
    this.selectedHorarioId = id;
  }

  async save():Promise<void>{
    this.spinner.show();
    const cita = this.horariosFiltrados.find(item => item.id == this.selectedHorarioId);
    const env = this.formData.get(this.formFields.Doctor).value;
    const enviroments = this.listDoctors.find(item => item.id_doctor == env);
    const paciente = this.formData.get(this.formFields.selectedPatient).value;
    const tarifa = this.formData.get(this.formFields.Tariff).value
    const [inicio, fin] = cita.horario.split(" - ");
    
    const reservation : ProgramationModel = {
      qdetail : 0,
      date: this.dateToday,
      appointment: cita.horario,
      state: 1,
      environment: enviroments.id_environmentdoctor,
      doctor: env,
      patient:paciente,
      tariff:tarifa,
      pending_payment: false,
      interval :this.minutes_attention,
      since:inicio,
      until:fin
    }

    await this.programationService.insert(reservation).subscribe(
      resp=>{
        this.spinner.hide();

        const diference_interval = this.interval_total -this.minutes_attention;


        /*this.serviceUnionDoctorPatientAttention.doctor_programing_seg(this.id_doctor_programming ,diference_interval).subscribe(
          resp =>{
            
          },error =>{
            console.log(error);
            
          }
        )*/

        this.activeModal.close('Save click')
        
      },error=>{
        this.spinner.hide();
        console.log(error);
        this.toastr.error("Ocurrio un errror en la insercion de reserva","Error", { timeOut: 3000, progressBar: true })
        
      }
    )
  }

  fechaDeHoy(): string {
    const hoy = new Date();
    return hoy.toISOString().split('T')[0]; // Formato yyyy-mm-dd
  }

  FiltrarPorFecha() {
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const fecha_selected = this.fechaSeleccionada

    if (doctor === 'Ingrese Paciente' && fecha_selected === undefined) {
      this.toastr.error("Debes seleccionar un doctor y una fecha","Error", { timeOut: 3000, progressBar: true })
    }else{

      this.setListHoursByDoctor_filter_date(fecha_selected)
    }
  }

  setListHoursByDoctor_filter_date(fecha_selectd: string):void{
    const doctor = this.formData.get(this.formFields.Doctor).value;
    const fecha = fecha_selectd;
    console.log(doctor);
    this.doctorProgramming.get_reserv_free_prueba(doctor,fecha,this.minutes_attention).subscribe(
      resp =>{
        if(resp.length > 0){
          const data = resp[0];
          this.interval_total = data[0].interval;
          this.horarios = [];
          let id = 0;
          while (id < data.length) {
            this.horarios.push({
              id: id,
              horario: `${data[id].since} - ${data[id].until}`
          });
          id++;
          }
          this.horariosFiltrados = this.horarios;
          this.horariosPaginados();
        }else{
          this.toastr.warning('No hay horarios agendados para este doctor',"Advertencia", { timeOut: 3000, progressBar: true })
          this.horariosFiltrados = [];
        }
        
      }
    )
  }


  accionAntes() {
    const fechaActual = new Date(this.fechaSeleccionada);
    fechaActual.setDate(fechaActual.getDate() - 1); // Retrocede un día
    this.actualizarFecha(fechaActual);
  }

  accionDespues() {
    const fechaActual = new Date(this.fechaSeleccionada);
    fechaActual.setDate(fechaActual.getDate() + 1); // Avanza un día
    this.actualizarFecha(fechaActual);
  }

  actualizarFecha(fecha: Date) {
    const fecha_final = new Date(fecha); 
    const fecha_formateada = fecha_final.toISOString().split('T')[0];
    this.fechaSeleccionada = fecha_formateada;

    this.FiltrarPorFecha();
  }
}
