<div class="modal-header modal_header">
    <h5 class="modal-title titulo-modal">Terminos de la Cotización {{this.id}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <h4>Incluye</h4>
            <hr>
        </div>
        <div class="col-md-12">
            <table class="table table-sm">
                <thead>
                    <th>Descripción</th>
                    <th>Acción</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="form-group">
                                <textarea class="form-control" [name]="'description_i'" cols="70" rows="1"
                                [(ngModel)]="description_i"></textarea>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-icon btn-success btn-sm" (click)="addItem('INCLUYE')"
                            ngbPopover="Agregar" triggers="mouseenter:mouseleave">
                                <i class="fas fa-plus-circle"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let it of this.listIncludes; let i=index">
                        <td>
                            <div class="form-group">
                                <textarea class="form-control" [name]="'description_i_'+i" cols="70" rows="1"
                                [(ngModel)]="it.description">{{it.description}}</textarea>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-icon btn-danger btn-sm" (click)="removeItem(i,it)"
                            ngbPopover="Eliminar" triggers="mouseenter:mouseleave">
                                <i class="feather icon-trash-2"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-9">
            <h4>Controles</h4>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <input class="form-control" [name]="'control'"
                [(ngModel)]="control">
            </div>
        </div>
        <div class="col-md-12">
            <hr>
        </div>
        <div class="col-md-12">
            <table class="table table-sm">
                <thead>
                    <th>Descripción</th>
                    <th>Acción</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="form-group">
                                <textarea class="form-control" [name]="'description_c'" cols="70" rows="1"
                                [(ngModel)]="description_c"></textarea>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-icon btn-success btn-sm" (click)="addItem('CONTROLES')"
                            ngbPopover="Agregar" triggers="mouseenter:mouseleave">
                                <i class="fas fa-plus-circle"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let it of this.listControls; let i=index">
                        <td>
                            <div class="form-group">
                                <textarea class="form-control" [name]="'description_c_'+i" cols="70" rows="2"
                                [(ngModel)]="it.description">{{it.description}}</textarea>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-icon btn-danger btn-sm" (click)="removeItem(i,it)"
                            ngbPopover="Eliminar" triggers="mouseenter:mouseleave">
                                <i class="feather icon-trash-2"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-9">
            <h4>Aparatología Adicional</h4>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <input class="form-control" [name]="'aparato_adicional'"
                [(ngModel)]="aparato_adicional">
            </div>
        </div>
        <div class="col-md-12">
            <hr>
        </div>
        <div class="col-md-12">
            <table class="table table-sm">
                <thead>
                    <th>Descripción</th>
                    <th>Acción</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="form-group">
                                <textarea class="form-control" [name]="'description_a'" cols="70" rows="1"
                                [(ngModel)]="description_a"></textarea>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-icon btn-success btn-sm" (click)="addItem('ADICIONALES')"
                            ngbPopover="Agregar" triggers="mouseenter:mouseleave">
                                <i class="fas fa-plus-circle"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let it of this.listAparatos; let i=index">
                        <td>
                            <div class="form-group">
                                <textarea class="form-control" [name]="'description_c_'+i" cols="70" rows="1">{{it.description}}</textarea>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-icon btn-danger btn-sm" (click)="removeItem(i,it)"
                            ngbPopover="Eliminar" triggers="mouseenter:mouseleave">
                                <i class="feather icon-trash-2"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12">
            <button type="button" uiSref="work" class="btn btn-primary btn-raised" (click)="onSubmit()">
                Guardar
                <span style="margin-left:10px;">
                    <i class="feather icon-save"></i>
                </span>
            </button>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')">
      Salir
    </button>
</div>