<div class="modal-header modal_header">
    <h5 class="modal-title titulo-modal">Detalle de la cita</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.close('Close')"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div>
        <div class="app-card-header">
            <div class="row align-items-center">
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-auto col pr-0">
                            <img class="img-radius img-fluid wid-60" src="assets/images/user/doctor-icono-avatar.jpeg"
                                alt="User image">
                        </div>
                        <div class="col">
                            <h6 class="mb-1">{{ formInput.doctor }}</h6>
                            <p class="mb-0">Doctor</p>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="this.formInput.doctor2">
                    <div class="row align-items-center">
                        <div class="col-auto col pr-0">
                            <img class="img-radius img-fluid wid-60" src="assets/images/user/doctor-icono-avatar.jpeg"
                                alt="User image">
                        </div>
                        <div class="col">
                            <h6 class="mb-1">{{ formInput.doctor2 }}</h6>
                            <p class="mb-0">Doctor OI</p>
                        </div>
                    </div>
                </div>
                <div class="col col-auto">
                    <ng-container *ngIf="validacionUsuario">
                        <div class="checkbox checkbox-fill d-inline" *ngIf="status < 3">
                            <input type="checkbox" name="state" id="state" [(ngModel)]="this.confirm"
                                (change)="setConfirm()">
                            <label for="state" class="cr">Confirmado</label>
                        </div><br>
                        <div class="checkbox checkbox-fill d-inline" *ngIf="status > 1">
                            <input type="checkbox" name="attended" id="attended" [(ngModel)]="this.attended"
                                (change)="setAttended()">
                            <label for="attended" class="cr">Atendido</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row text-center space">
            <div class="col-md-12">
                <hr>
            </div>
            <div class="col">
                <h6 class="mb-1">Paciente</h6>
                <!--<p class="mb-0">{{ formInput.patient }}</p>-->
                <p><a href="javascript:void(0)" (click)="goToClinicHistory(formInput.idpatient);">{{formInput.patient}}</a></p>
            </div>
            <div class="col">
                <h6 class="mb-1">Ambiente</h6>
                <p class="mb-0">{{ formInput.environment }}</p>
            </div>
            <div class="col">
                <h6 class="mb-1">Horario</h6>
                <p class="mb-0">{{ formInput.appointment }}</p>
            </div>
        </div>
        <div class="m-b-20">
            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
                <div class="col-md-6">
                    <h6>Tratamiento</h6>
                    <p>{{ formInput.product }}</p>
                </div>
                <div class="col-md-6">
                    <h6>Motivo</h6>
                    <p>{{ formInput.reason }}</p>
                </div>
            </div>
            <div class="row" *ngIf="formInput.pending_payment">
                <div class="col-md-6">
                    <h6>Pago pendiente</h6>
                    <p>{{formInput.amount}} {{formInput.coin}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<div class="modal-footer">
     <button type="button" class="btn btn-success ml-1" ngbTooltip="Historia clínica"
    (click)="setClinicHistory(formInput)">
        Historia clínica
    </button> 
    <span class="badge badge-pill badge-primary ml-2" *ngIf="status === 3" >{{payment_attention_state}}</span>
    <span class="badge badge-pill badge-danger ml-2" *ngIf="status !== 3">No se ha seleccionado el pago</span>
    
    <button type="button" class="btn btn-info ml-1" ngbTooltip="Auditoria" (click)="setAudit(formInput.id)"
        *ngIf="this.iduser === 3 || this.iduser === 126">
        Auditoria
    </button>
    <button type="button" class="btn btn-warning ml-1" ngbTooltip="Cancelar Cita"
        (click)="cancelAppointment(formInput)">
        Cancelar Cita
    </button>
    <button type="button" class="btn btn-primary ml-1" ngbTooltip="Reprogramar" (click)="setReProgramation()">
        Reprogramar
    </button>
    <button type="button" class="btn btn-success ml-1" ngbTooltip="Editar" (click)="openModalAutorization(formInput)">
        Editar
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="onClose()">
        Salir
    </button>
</div> -->
<div class="modal-footer d-flex justify-content-between">
    <ng-container *ngIf="validacionUsuario">
        <span class="badge badge-pill badge-primary ml-2" *ngIf="validation_form_payments_union !== false"> <h6 class="ml-2 font-weight-bold text-white" style="font-size: 0.8rem;">{{payment_attention_state}}</h6></span>
        <button type="button" class="btn badge-pill badge-danger ml-2 p-0" *ngIf="validation_form_payments_union === false" (click)="asignet_payment_future()">
            <h6 class="ml-2 font-weight-bold text-white m-0" style="font-size: 0.9rem;">No se ha seleccionado el pago</h6>
        </button>
    </ng-container>
    <div class="ml-auto">
        <ng-container *ngIf="validacionUsuario">
            <button type="button" class="btn btn-info ml-1" ngbTooltip="Auditoria" (click)="setAudit(formInput.id)"
                *ngIf="this.iduser === 3 || this.iduser === 126 || this.iduser === 123 || this.iduser === 174">
                Auditoria
            </button>
            <button type="button" class="btn btn-warning ml-1" ngbTooltip="Cancelar Cita"
                (click)="cancelAppointment(formInput)">
                Cancelar Cita
            </button>
            <button type="button" class="btn btn-primary ml-1" ngbTooltip="Reprogramar" (click)="setReProgramation()">
                Reprogramar
            </button>
            <button type="button" class="btn btn-success ml-1" ngbTooltip="Editar" (click)="openModalAutorization(formInput)">
                Editar
            </button>
        </ng-container>
        
        <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="onClose()">
            Salir
        </button>
    </div>
</div>
