import { Transferable } from '../../../../shared/interfaces/iTransferable';
import { ProgrammingEventDto } from '../dtos/programming-event-dto';

export class ProgrammingEvent implements Transferable<ProgrammingEventDto> {
    iddoctor: number;
    doctor: string;
    date: string;
    events: any[];
    business: any[];
    apointments: any[];
    locks: [];

    ToDTO(): ProgrammingEventDto {
        const dto: ProgrammingEventDto = ({
            iddoctor: this.iddoctor,
            doctor: this.doctor,
            date: this.date,
            events: this.events,
            business: this.business,
            appointments: this.apointments,
            locks: this.locks,
        });
        return dto;
    }

    FromDTO(from: ProgrammingEventDto): ProgrammingEvent {
        this.iddoctor = from.iddoctor;
        this.doctor = from.doctor;
        this.date = from.date;
        this.events = from.events;
        this.business = from.business
        return this;
    }
}