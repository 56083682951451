import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceBillingPaymentsService {
  
  private baseUrl: string = `${environment.apiUrl}/service_billing_payments`;

  constructor(
    private http:HttpClient
  ) { }

  send_invoice_mi_fact(createInvoiceDto:any):Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/send_invoice_mi_fact`,createInvoiceDto)
  }

  update_invoice(catidad:number,correlativo_id:number,id_service_order:number):Observable<any>{
    return this.http.patch<any>(`${this.baseUrl}/update_invoice/${catidad}/${correlativo_id}/${id_service_order}`,null)
  }

  getInvoicesMade(since:string,until:string):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/getInvoicesMade/${since}/${until}`)
  }

  getInvoicesMadeForPatient(since:string,until:string, id_clinic_history:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/getInvoicesMadeForPatient/${since}/${until}/${id_clinic_history}`)
  }

  getInvoicesDocuments(id_clinic_history:number):Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/medical-act/get-files-quantity/${id_clinic_history}`)
  }

  getInvoicesDocumentsListForType(id_clinic_history:number, id_archivo_padre: number):Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/medical-act/get-files-clinichistory/${id_clinic_history}/${id_archivo_padre}`)
  }

  getInvoicesDocumentsListForTypeView(nombre_archivo: string):Observable<any[]>{
    const data = `${environment.apiUrlDownload}/${nombre_archivo}`
    return of([data]);
  }

  report_invoice(since:string,until:string):Observable<any>{
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${this.baseUrl}/report_invoice/${since}/${until}`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  report_invoice_tariff(since:string,until:string):Observable<any>{
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${this.baseUrl}/report_invoice_tariff/${since}/${until}`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getInvoicesMadeService(idinvoiceresult:number):Observable<any[]>{
    return this.http.get<any[]>(`${this.baseUrl}/report_invoice_tariff_with_service/${idinvoiceresult}`)
  }
}
