import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppointmentDetailComponent } from 'src/app/components/appointment-detail/appointment-detail.component';
import { AuthService } from 'src/app/service/auth.service';

import { Programming } from '../../models/programming.model';
import { ModalDoctorDiaryAppointmentComponent } from '../modals/modal-doctor-diary-appointment/modal-doctor-diary-appointment.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-doctor-diary-detail',
  templateUrl: './doctor-diary-detail.component.html',
  styleUrls: ['./doctor-diary-detail.component.scss']
})
export class DoctorDiaryDetailComponent implements OnInit {

  canInsert: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  @Input() programming: Programming[];
  @Output() setSave = new EventEmitter<string>();

  scheduleCalendar: any[] = [];
  hoursCalendar: any[] = [];
  schedule: any[] = [];
  sesiones : any;
  validator_sesion = false;
  session: any = {};

  constructor(
    private store: Store<{ session: any }>,
    private toastr: ToastrService,
    private modalSerive: NgbModal,
    private auth: AuthService,
    private route: ActivatedRoute,
    private users: AuthService
  ) { }

  ngOnInit(): void {
    this.setHours();
    this.setSchedule();
    this.getSession();
  }

  initPermissions(): void {
    this.route.data.subscribe(res => {
      this.auth.hasPermission(res.permissions).subscribe({
        next: (resp) => {
          this.canInsert = !resp.can_insert;
          this.canUpdate = !resp.can_update;
          this.canDelete = !resp.can_delete;
        }
      });
    });
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.getUserDetail();
          if (sess.doctor) {
            // this.filterDoctor = sess.doctor.id;
            
          }
        }
      });
  }

  setHours(): void {
    this.scheduleCalendar = [];
    for (let i = 8; i < 22; i++) {
      const hours: string[] = [];
      const hoursBeta: any[] = [];
      let intervalMin = 0;
      for (let j = 0; j < 12; j++) {
        let mins = '';
        if (intervalMin < 10) {
          mins = `0${intervalMin}`;
        } else {
          mins = `${intervalMin}`;
        }
        hours.push(
          `${i}:${mins}`
        );
        hoursBeta.push({
          houra: `${i}:${mins}`,
          hourb: `${i}${mins}`
        });
        intervalMin += 5;
      }
      this.scheduleCalendar.push({
        hour: `${i}:00`,
        hoursBeta,
        hours
      });
    }

  }

  getUserDetail(): void {

    this.users.getUsersDetail(this.session.id).subscribe(
      user=>{
        this.sesiones=user;
        console.log(this.sesiones);
        console.log("Finalmenteee: ",this.sesiones[0].cargo.nombre);
        if (this.sesiones[0].agenda_sv === "100") {
          this.validator_sesion = true
        }else{
          this.validator_sesion = false
        }
      },error=>{
        console.log({ error });
      }
    )
  }

  setSchedule(): void {
    const hoursoclock = [800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100];
    this.hoursCalendar = [];
    const intervalBase = 5;
    this.programming.forEach(ele => {
      const hours = [];
      //console.log('ele',ele);
      
      ele.schedule.forEach(element => {
        //console.log('element',element);
        
        const since = Number(String(element.since).replace(':', ''));
        let until = Number(String(element.until).replace(':', ''));
        
        
        if (hoursoclock.includes(until)) {
          until -= 45;
        } else {
          until -= intervalBase;   
        }
        if (element.type === 3) {
          until = since + intervalBase;
        }
        hours.push(
          {
            mainData: ele,
            data: element,
            date: this.programming[0].date,
            sinceMod: since,
            untilMod: until,
            type: element.type,
            since: element.since,
            until: element.until,
            environtment: element.environtment,
            environtment_name: element.environtment_name,
          }
        );
        
      });
      this.hoursCalendar.push({
        doctor: ele.doctor,
        iddoctor: ele.iddoctor,
        hours
      });
      
    });
  }

  addMedicalAppointment(data: any): void {
    console.log("Esntro al boton que pinta: ",this.validator_sesion);
    if (this.validator_sesion === true) {  
      if (this.canInsert) {
        this.toastr.error(
          'No tiene permiso para agregar citas',
          'Atención',
          { timeOut: 5000, progressBar: true }
        );
        return;
      }
      const modal = this.modalSerive.open(ModalDoctorDiaryAppointmentComponent, { size: 'lg' });
      modal.result.then((result: any) => {
        if (result === 'Save click') {
          this.setSave.emit(this.programming[0].date);
          
        }
      });
      modal.componentInstance.data = data;
    }else{
      this.toastr.warning(
        'No tiene permiso para agregar citas, dirigase a la agenda correcta',
        'Atención',
        { timeOut: 5000, progressBar: true }
      );
    }
  }

  setAppointmentDetail(id: number): void {
    const modal = this.modalSerive.open(AppointmentDetailComponent, { size: 'lg' });
    modal.result.then((result: any) => {
      if (result === 'Save click') {
        this.setSave.emit(this.programming[0].date);
      } else if (result === 'Refresh') {
        this.setSave.emit(this.programming[0].date);
      }
    });
    modal.componentInstance.id = id;
    modal.componentInstance.identificar = 1;
  }


}
