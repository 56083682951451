<form>
    <div class="row">
        <div class="col-md-12 col-md-offset-3">
            <div class="card hd-body">
                <div class="row align-items-center">
                    <div class="col border-right">
                        <div class="card-body">
                            <div class="ticket-customer">
                                Historia Clínica:
                                <label class="font-weight-bold" for="history">{{this.data.history}}</label>
                                | Paciente: <label class="font-weight-bold" for="history">
                                    {{this.data.name}}
                                    {{this.data.lastNameFather}}
                                    {{this.data.lastNameMother}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-md-offset-3">
            <div id="controls" class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3" *ngIf="this.idodontograma === 0">
                            <div class="btn-group" data-toggle="buttons">
                                <div class="form-group mb-md" >
                                    <label class="control-label mb-xs">Estado Dental</label>
                                    <select class="form-control custom-select" [(ngModel)]="toothStatus"
                                    name="estadopiezadental" (change)="getTariff()">
                                        <option value="">Seleccione</option>
                                        <option [value]="ed.id" *ngFor="let ed of dentalStatus">{{ed.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-7" *ngIf="this.idodontograma === 0">
                            <div class="btn-group" data-toggle="buttons">
                                <div class="form-group mb-md" >
                                    <label class="control-label mb-xs">Tratamiento</label>
                                    <select class="custom-select" [(ngModel)]="idtariff"
                                    name="medication">
                                        <option value="0">Seleccione estado dental</option>
                                        <option [value]="it.id" *ngFor="let it of this.listTariffs">{{it.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-2" *ngIf="this.idodontograma === 0">
                            <button type="button" uiSref="work" class="btn btn-primary btn-raised mt-3"
                            (click)="save()">
                                Guardar [F3]
                            </button>
                        </div>
                        <div class="col-md-12">
                            <div class="ticket-customer">
                                Fecha de registro:
                                <label class="font-weight-bold" for="history">{{this.dateRegister | date:'dd-MM-yyyy'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="tr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of teethsDecrement;" data-name="value" [id]="'teeth1'+i" class="diente">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">
                    1{{i}}
                </span>
                <div (click)="setTeeth(0,i,'t')"
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].t && (detail[0].teeth[i-1].t.toothStatus === 1 || detail[0].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[0].teeth[i-1].t && detail[0].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[0].teeth[i-1].t && detail[0].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[0].teeth[i-1].t && detail[0].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[0].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[0].teeth[i-1].t.toothStatus === 8 || detail[0].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[0].teeth[i-1].t.toothStatus === 16 || detail[0].teeth[i-1].t.toothStatus === 17 || detail[0].teeth[i-1].t.toothStatus === 18
                    || detail[0].teeth[i-1].t.toothStatus === 19 || detail[0].teeth[i-1].t.toothStatus === 20 || detail[0].teeth[i-1].t.toothStatus === 21 || detail[0].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[0].teeth[i-1].t.toothStatus === 25
                }" class="cuadro click" [id]="'t1'+i"></div>

                <div (click)="setTeeth(0,i,'l')"
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].l && (detail[0].teeth[i-1].l.toothStatus === 1 || detail[0].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[0].teeth[i-1].l && detail[0].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[0].teeth[i-1].l && detail[0].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[0].teeth[i-1].l && detail[0].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[0].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[0].teeth[i-1].l.toothStatus === 8 || detail[0].teeth[i-1].l.toothStatus === 9,
                    'click-protesis': detail[0].teeth[i-1].l.toothStatus === 16 || detail[0].teeth[i-1].l.toothStatus === 17 || detail[0].teeth[i-1].l.toothStatus === 18
                    || detail[0].teeth[i-1].l.toothStatus === 19 || detail[0].teeth[i-1].l.toothStatus === 20 || detail[0].teeth[i-1].l.toothStatus === 21 || detail[0].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[0].teeth[i-1].l.toothStatus === 25
                }" class="cuadro izquierdo click" [id]="'l1'+i"></div>

                <div (click)="setTeeth(0,i,'b')"
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].b && (detail[0].teeth[i-1].b.toothStatus === 1 || detail[0].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[0].teeth[i-1].b && detail[0].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[0].teeth[i-1].b && detail[0].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[0].teeth[i-1].b && detail[0].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[0].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[0].teeth[i-1].b.toothStatus === 8 || detail[0].teeth[i-1].b.toothStatus === 9,
                    'click-protesis': detail[0].teeth[i-1].b.toothStatus === 16 || detail[0].teeth[i-1].b.toothStatus === 17 || detail[0].teeth[i-1].b.toothStatus === 18
                    || detail[0].teeth[i-1].b.toothStatus === 19 || detail[0].teeth[i-1].b.toothStatus === 20 || detail[0].teeth[i-1].b.toothStatus === 21 || detail[0].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[0].teeth[i-1].b.toothStatus === 25
                }" class="cuadro debajo click" [id]="'b1'+i"></div>

                <div (click)="setTeeth(0,i,'r')"
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].r && (detail[0].teeth[i-1].r.toothStatus === 1 || detail[0].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[0].teeth[i-1].r && detail[0].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[0].teeth[i-1].r && detail[0].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[0].teeth[i-1].r && detail[0].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[0].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[0].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[0].teeth[i-1].r.toothStatus === 8 || detail[0].teeth[i-1].r.toothStatus === 9,
                    'click-protesis': detail[0].teeth[i-1].r.toothStatus === 16 || detail[0].teeth[i-1].r.toothStatus === 17 || detail[0].teeth[i-1].r.toothStatus === 18
                    || detail[0].teeth[i-1].r.toothStatus === 19 || detail[0].teeth[i-1].r.toothStatus === 20 || detail[0].teeth[i-1].r.toothStatus === 21 || detail[0].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[0].teeth[i-1].r.toothStatus === 25
                }" class="cuadro derecha click" [id]="'r1'+i"></div>

                <div (click)="setTeeth(0,i,'c')"
                [ngClass]="{
                    'click-red': detail[0].teeth[i-1].c && (detail[0].teeth[i-1].c.toothStatus === 1 || detail[0].teeth[i-1].c.toothStatus === 7),
                    'click-blue': detail[0].teeth[i-1].c && detail[0].teeth[i-1].c.toothStatus === 2,
                    'click-desgaste': detail[0].teeth[i-1].c && detail[0].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[0].teeth[i-1].c && detail[0].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[0].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[0].teeth[i-1].c.toothStatus === 8 || detail[0].teeth[i-1].c.toothStatus === 9,
                    'click-protesis': detail[0].teeth[i-1].c.toothStatus === 16 || detail[0].teeth[i-1].c.toothStatus === 17 || detail[0].teeth[i-1].c.toothStatus === 18
                    || detail[0].teeth[i-1].c.toothStatus === 19 || detail[0].teeth[i-1].c.toothStatus === 20 || detail[0].teeth[i-1].c.toothStatus === 21 || detail[0].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[0].teeth[i-1].c.toothStatus === 25
                }" class="centro click" [id]="'c1'+i"></div>
            </div>
            <div *ngFor="let item of extractDecrement; let i = index">
                <i *ngIf="detail[0].teeth[i].c.toothStatus === 4 || detail[0].teeth[i].c.toothStatus === 6"
                class="fa fa-times fa-3x fa-fw" [ngStyle]="{'left': item}"
                [ngClass]="{'to-extract': detail[0].teeth[i].c.toothStatus === 4,
                'absent': detail[0].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
        <div id="tl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of teethsInclement;" class="diente" [id]="'teeth2'+i">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">2{{i}}</span>
                <div (click)="setTeeth(1,i,'t')" [id]="'t2' + i"
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].t && (detail[1].teeth[i-1].t.toothStatus === 1 || detail[1].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[1].teeth[i-1].t && detail[1].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[1].teeth[i-1].t && detail[1].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[1].teeth[i-1].t && detail[1].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[1].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[1].teeth[i-1].t.toothStatus === 8 || detail[1].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[1].teeth[i-1].t.toothStatus === 16 || detail[1].teeth[i-1].t.toothStatus === 17 || detail[1].teeth[i-1].t.toothStatus === 18
                    || detail[1].teeth[i-1].t.toothStatus === 19 || detail[1].teeth[i-1].t.toothStatus === 20 || detail[1].teeth[i-1].t.toothStatus === 21 || detail[1].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[1].teeth[i-1].t.toothStatus === 25
                }" class="cuadro click"></div>
                <div (click)="setTeeth(1,i,'l')" [id]="'l2' + i"
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].l && (detail[1].teeth[i-1].l.toothStatus === 1 || detail[1].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[1].teeth[i-1].l && detail[1].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[1].teeth[i-1].l && detail[1].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[1].teeth[i-1].l && detail[1].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[1].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[1].teeth[i-1].l.toothStatus === 8 || detail[1].teeth[i-1].l.toothStatus === 9,
                    'click-protesis': detail[1].teeth[i-1].l.toothStatus === 16 || detail[1].teeth[i-1].l.toothStatus === 17 || detail[1].teeth[i-1].l.toothStatus === 18
                    || detail[1].teeth[i-1].l.toothStatus === 19 || detail[1].teeth[i-1].l.toothStatus === 20 || detail[1].teeth[i-1].l.toothStatus === 21 || detail[1].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[1].teeth[i-1].l.toothStatus === 25
                }" class="cuadro izquierdo click"></div>

                <div (click)="setTeeth(1,i,'b')" [id]="'b2' + i"
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].b && (detail[1].teeth[i-1].b.toothStatus === 1 || detail[1].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[1].teeth[i-1].b && detail[1].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[1].teeth[i-1].b && detail[1].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[1].teeth[i-1].b && detail[1].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[1].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[1].teeth[i-1].t.toothStatus === 8 || detail[1].teeth[i-1].b.toothStatus === 9,
                    'click-protesis': detail[1].teeth[i-1].b.toothStatus === 16 || detail[1].teeth[i-1].b.toothStatus === 17 || detail[1].teeth[i-1].b.toothStatus === 18
                                   || detail[1].teeth[i-1].b.toothStatus === 19 || detail[1].teeth[i-1].b.toothStatus === 20 || detail[1].teeth[i-1].b.toothStatus === 21 || detail[1].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[1].teeth[i-1].b.toothStatus === 25
                }" class="cuadro debajo click"></div>

                <div (click)="setTeeth(1,i,'r')" [id]="'r2' + i"
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].r && (detail[1].teeth[i-1].r.toothStatus === 1 || detail[1].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[1].teeth[i-1].r && detail[1].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[1].teeth[i-1].r && detail[1].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[1].teeth[i-1].r && detail[1].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[1].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[1].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[1].teeth[i-1].r.toothStatus === 8 || detail[1].teeth[i-1].r.toothStatus === 9,
                    'click-protesis': detail[1].teeth[i-1].r.toothStatus === 16 || detail[1].teeth[i-1].r.toothStatus === 17 || detail[1].teeth[i-1].r.toothStatus === 18
                                   || detail[1].teeth[i-1].r.toothStatus === 19 || detail[1].teeth[i-1].r.toothStatus === 20 || detail[1].teeth[i-1].r.toothStatus === 21 || detail[1].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[1].teeth[i-1].r.toothStatus === 25
                }" class="cuadro derecha click"></div>

                <div (click)="setTeeth(1,i,'c')" [id]="'c2' + i"
                [ngClass]="{
                    'click-red': detail[1].teeth[i-1].c && (detail[1].teeth[i-1].c.toothStatus === 1 || detail[1].teeth[i-1].c.toothStatus === 7),
                    'click-blue': detail[1].teeth[i-1].c && detail[1].teeth[i-1].c.toothStatus === 2,
                    'click-desgaste': detail[1].teeth[i-1].c && detail[1].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[1].teeth[i-1].c && detail[1].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[1].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[1].teeth[i-1].c.toothStatus === 8 || detail[1].teeth[i-1].c.toothStatus === 9,
                    'click-protesis': detail[1].teeth[i-1].c.toothStatus === 16 || detail[1].teeth[i-1].c.toothStatus === 17 || detail[1].teeth[i-1].c.toothStatus === 18
                                   || detail[1].teeth[i-1].c.toothStatus === 19 || detail[1].teeth[i-1].c.toothStatus === 20 || detail[1].teeth[i-1].c.toothStatus === 21 || detail[1].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[1].teeth[i-1].c.toothStatus === 25
                }" class="centro click"></div>

            </div>
            <div *ngFor="let item of extractIncrement; let i = index">
                <i *ngIf="detail[1].teeth[i].c.toothStatus === 4 || detail[1].teeth[i].c.toothStatus === 6"
                class="fa fa-times fa-3x fa-fw" [ngStyle]="{'left': item}"
                [ngClass]="{'to-extract': detail[1].teeth[i].c.toothStatus === 4,
                'absent': detail[1].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
        <div id="tlr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
            <div *ngFor="let i of [5,4,3,2,1]" [id]="'teethM5' + i" style="left: -25%;" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">5{{i}}</span>

                <div (click)="setTeeth(4,i,'t')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].t && (detail[4].teeth[i-1].t.toothStatus === 1 || detail[4].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[4].teeth[i-1].t && detail[4].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[4].teeth[i-1].t && detail[4].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[4].teeth[i-1].t && detail[4].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[4].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[4].teeth[i-1].t.toothStatus === 8 || detail[4].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[4].teeth[i-1].t.toothStatus === 16 || detail[4].teeth[i-1].t.toothStatus === 17 || detail[4].teeth[i-1].t.toothStatus === 18
                                   || detail[4].teeth[i-1].t.toothStatus === 19 || detail[4].teeth[i-1].t.toothStatus === 20 || detail[4].teeth[i-1].t.toothStatus === 21 || detail[4].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[4].teeth[i-1].t.toothStatus === 25
                }" [id]="'tleche5' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(4,i,'l')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].l && (detail[4].teeth[i-1].l.toothStatus === 1 || detail[4].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[4].teeth[i-1].l && detail[4].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[4].teeth[i-1].l && detail[4].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[4].teeth[i-1].l && detail[4].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[4].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[4].teeth[i-1].l.toothStatus === 8 || detail[4].teeth[i-1].l.toothStatus === 9,
                    'click-protesis': detail[4].teeth[i-1].l.toothStatus === 16 || detail[4].teeth[i-1].l.toothStatus === 17 || detail[4].teeth[i-1].l.toothStatus === 18
                                   || detail[4].teeth[i-1].l.toothStatus === 19 || detail[4].teeth[i-1].l.toothStatus === 20 || detail[4].teeth[i-1].l.toothStatus === 21 || detail[4].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[4].teeth[i-1].l.toothStatus === 25
                }" [id]="'lleche5' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(4,i,'b')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].b && (detail[4].teeth[i-1].b.toothStatus === 1 || detail[4].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[4].teeth[i-1].b && detail[4].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[4].teeth[i-1].b && detail[4].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[4].teeth[i-1].b && detail[4].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[4].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[4].teeth[i-1].b.toothStatus === 8 || detail[4].teeth[i-1].b.toothStatus === 9,
                    'click-protesis': detail[4].teeth[i-1].b.toothStatus === 16 || detail[4].teeth[i-1].b.toothStatus === 17 || detail[4].teeth[i-1].b.toothStatus === 18
                                   || detail[4].teeth[i-1].b.toothStatus === 19 || detail[4].teeth[i-1].b.toothStatus === 20 || detail[4].teeth[i-1].b.toothStatus === 21 || detail[4].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[4].teeth[i-1].b.toothStatus === 25
                }" [id]="'bleche5' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(4,i,'r')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].r && (detail[4].teeth[i-1].r.toothStatus === 1 || detail[4].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[4].teeth[i-1].r && detail[4].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[4].teeth[i-1].r && detail[4].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[4].teeth[i-1].r && detail[4].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[4].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[4].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[4].teeth[i-1].r.toothStatus === 8 || detail[4].teeth[i-1].r.toothStatus === 9,
                    'click-protesis': detail[4].teeth[i-1].r.toothStatus === 16 || detail[4].teeth[i-1].r.toothStatus === 17 || detail[4].teeth[i-1].r.toothStatus === 18
                                   || detail[4].teeth[i-1].r.toothStatus === 19 || detail[4].teeth[i-1].r.toothStatus === 20 || detail[4].teeth[i-1].r.toothStatus === 21 || detail[4].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[4].teeth[i-1].r.toothStatus === 25
                }" [id]="'rleche5' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(4,i,'c')" [ngClass]="{
                    'click-red': detail[4].teeth[i-1].c && (detail[4].teeth[i-1].c.toothStatus === 1 || detail[4].teeth[i-1].c.toothStatus === 7),
                    'click-desgaste': detail[4].teeth[i-1].c && detail[4].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[4].teeth[i-1].c && detail[4].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[4].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[4].teeth[i-1].c.toothStatus === 8 || detail[4].teeth[i-1].c.toothStatus === 9,
                    'click-protesis': detail[4].teeth[i-1].c.toothStatus === 16 || detail[4].teeth[i-1].c.toothStatus === 17 || detail[4].teeth[i-1].c.toothStatus === 18
                                   || detail[4].teeth[i-1].c.toothStatus === 19 || detail[4].teeth[i-1].c.toothStatus === 20 || detail[4].teeth[i-1].c.toothStatus === 21 || detail[4].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[4].teeth[i-1].c.toothStatus === 25
                }" [id]="'cleche5' + i" class="centro-leche click"></div>
            </div>
            <div *ngFor="let item of extractMilkIncrement; let i = index">
                <i *ngIf="detail[4].teeth[i].c.toothStatus === 4 || detail[4].teeth[i].c.toothStatus === 6"
                class="fa fa-times fa-2x fa-fw" [ngStyle]="{'left': item}"
                [ngClass]="{'to-extract-milk': detail[4].teeth[i].c.toothStatus === 4,
                'absent-milk': detail[4].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
        <div id="tll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of [1,2,3,4,5]" [id]="'teethM6' + i" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">6{{i}}</span>

                <div (click)="setTeeth(5,i,'t')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].t && (detail[5].teeth[i-1].t.toothStatus === 1 || detail[5].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[5].teeth[i-1].t && detail[5].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].t && detail[5].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[5].teeth[i-1].t && detail[5].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[5].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[05].teeth[i-1].t.toothStatus === 8 || detail[5].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[5].teeth[i-1].t.toothStatus === 16 || detail[5].teeth[i-1].t.toothStatus === 17 || detail[5].teeth[i-1].t.toothStatus === 18
                                   || detail[5].teeth[i-1].t.toothStatus === 19 || detail[5].teeth[i-1].t.toothStatus === 20 || detail[5].teeth[i-1].t.toothStatus === 21 || detail[5].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[5].teeth[i-1].t.toothStatus === 25
                }" [id]="'tleche6' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(5,i,'l')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].l && (detail[5].teeth[i-1].l.toothStatus === 1 || detail[5].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[5].teeth[i-1].l && detail[5].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].l && detail[5].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[5].teeth[i-1].l && detail[5].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[5].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[5].teeth[i-1].l.toothStatus === 8 || detail[5].teeth[i-1].l.toothStatus === 9,
                    'click-protesis': detail[5].teeth[i-1].l.toothStatus === 16 || detail[5].teeth[i-1].l.toothStatus === 17 || detail[5].teeth[i-1].l.toothStatus === 18
                                   || detail[5].teeth[i-1].l.toothStatus === 19 || detail[5].teeth[i-1].l.toothStatus === 20 || detail[5].teeth[i-1].l.toothStatus === 21 || detail[5].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[5].teeth[i-1].l.toothStatus === 25
                }" [id]="'lleche6' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(5,i,'b')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].b && (detail[5].teeth[i-1].b.toothStatus === 1 || detail[5].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[5].teeth[i-1].b && detail[5].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].b && detail[5].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[5].teeth[i-1].b && detail[5].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[5].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[5].teeth[i-1].b.toothStatus === 8 || detail[5].teeth[i-1].b.toothStatus === 9,
                    'click-protesis': detail[5].teeth[i-1].b.toothStatus === 16 || detail[5].teeth[i-1].b.toothStatus === 17 || detail[5].teeth[i-1].b.toothStatus === 18
                                   || detail[5].teeth[i-1].b.toothStatus === 19 || detail[5].teeth[i-1].b.toothStatus === 20 || detail[5].teeth[i-1].b.toothStatus === 21 || detail[5].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[5].teeth[i-1].b.toothStatus === 25
                }" [id]="'bleche6' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(5,i,'r')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].r && (detail[5].teeth[i-1].r.toothStatus === 1 || detail[5].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[5].teeth[i-1].r && detail[5].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].r && detail[5].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[5].teeth[i-1].r && detail[5].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[5].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[5].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[5].teeth[i-1].r.toothStatus === 8 || detail[5].teeth[i-1].r.toothStatus === 9,
                    'click-protesis': detail[5].teeth[i-1].r.toothStatus === 16 || detail[5].teeth[i-1].r.toothStatus === 17 || detail[5].teeth[i-1].r.toothStatus === 18
                                   || detail[5].teeth[i-1].r.toothStatus === 19 || detail[5].teeth[i-1].r.toothStatus === 20 || detail[5].teeth[i-1].r.toothStatus === 21 || detail[5].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[5].teeth[i-1].r.toothStatus === 25
                }" [id]="'rleche6' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(5,i,'c')"
                [ngClass]="{
                    'click-red': detail[5].teeth[i-1].c && (detail[5].teeth[i-1].c.toothStatus === 1 || detail[5].teeth[i-1].c.toothStatus === 7),
                    'click-desgaste': detail[5].teeth[i-1].c && detail[5].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[5].teeth[i-1].c && detail[5].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[5].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[5].teeth[i-1].c.toothStatus === 8 || detail[5].teeth[i-1].c.toothStatus === 9,
                    'click-protesis': detail[5].teeth[i-1].c.toothStatus === 16 || detail[5].teeth[i-1].c.toothStatus === 17 || detail[5].teeth[i-1].c.toothStatus === 18
                                   || detail[5].teeth[i-1].c.toothStatus === 19 || detail[5].teeth[i-1].c.toothStatus === 20 || detail[5].teeth[i-1].c.toothStatus === 21 || detail[5].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[5].teeth[i-1].c.toothStatus === 25
                }" [id]="'cleche6' + i" class="centro-leche click"></div>
            </div>
            <div *ngFor="let item of extractMilkDecrement; let i = index">
                <i *ngIf="detail[5].teeth[i].c.toothStatus === 4 || detail[5].teeth[i].c.toothStatus === 6"
                    class="fa fa-times fa-2x fa-fw" [ngStyle]="{'left': item}"
                    [ngClass]="{'to-extract-milk': detail[5].teeth[i].c.toothStatus === 4,
                'absent-milk': detail[5].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div id="blr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
            <div *ngFor="let i of [5,4,3,2,1]" [id]="'teethM8' + i" style="left: -25%;" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">8{{i}}</span>

                <div (click)="setTeeth(7,i,'t')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].t && (detail[7].teeth[i-1].t.toothStatus === 1 || detail[7].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[7].teeth[i-1].t && detail[7].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[7].teeth[i-1].t && detail[7].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[7].teeth[i-1].t && detail[7].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[7].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[7].teeth[i-1].t.toothStatus === 8 || detail[7].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[7].teeth[i-1].t.toothStatus === 16 || detail[7].teeth[i-1].t.toothStatus === 17 || detail[7].teeth[i-1].t.toothStatus === 18
                                   || detail[7].teeth[i-1].t.toothStatus === 19 || detail[7].teeth[i-1].t.toothStatus === 20 || detail[7].teeth[i-1].t.toothStatus === 21 || detail[7].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[7].teeth[i-1].t.toothStatus === 25
                }"  [id]="'tleche8' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(7,i,'l')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].l && (detail[7].teeth[i-1].l.toothStatus === 1 || detail[7].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[7].teeth[i-1].l && detail[7].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].l && detail[5].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[7].teeth[i-1].l && detail[7].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[7].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[7].teeth[i-1].t.toothStatus === 8 || detail[7].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[7].teeth[i-1].l.toothStatus === 16 || detail[7].teeth[i-1].l.toothStatus === 17 || detail[7].teeth[i-1].l.toothStatus === 18
                                   || detail[7].teeth[i-1].l.toothStatus === 19 || detail[7].teeth[i-1].l.toothStatus === 20 || detail[7].teeth[i-1].l.toothStatus === 21 || detail[7].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[7].teeth[i-1].l.toothStatus === 25
                }"  [id]="'lleche8' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(7,i,'b')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].b && (detail[7].teeth[i-1].b.toothStatus === 1 || detail[7].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[7].teeth[i-1].b && detail[7].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].b && detail[5].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[7].teeth[i-1].b && detail[7].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[7].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[7].teeth[i-1].t.toothStatus === 8 || detail[7].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[7].teeth[i-1].b.toothStatus === 16 || detail[7].teeth[i-1].b.toothStatus === 17 || detail[7].teeth[i-1].b.toothStatus === 18
                                   || detail[7].teeth[i-1].b.toothStatus === 19 || detail[7].teeth[i-1].b.toothStatus === 20 || detail[7].teeth[i-1].b.toothStatus === 21 || detail[7].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[7].teeth[i-1].b.toothStatus === 25
                }"  [id]="'bleche8' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(7,i,'r')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].r && (detail[7].teeth[i-1].r.toothStatus === 1 || detail[7].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[7].teeth[i-1].r && detail[7].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].r && detail[5].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[7].teeth[i-1].r && detail[7].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[7].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[7].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[7].teeth[i-1].t.toothStatus === 8 || detail[7].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[7].teeth[i-1].r.toothStatus === 16 || detail[7].teeth[i-1].r.toothStatus === 17 || detail[7].teeth[i-1].r.toothStatus === 18
                                   || detail[7].teeth[i-1].r.toothStatus === 19 || detail[7].teeth[i-1].r.toothStatus === 20 || detail[7].teeth[i-1].r.toothStatus === 21 || detail[7].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[7].teeth[i-1].r.toothStatus === 25
                }"  [id]="'rleche8' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(7,i,'c')"
                [ngClass]="{
                    'click-red': detail[7].teeth[i-1].c && (detail[7].teeth[i-1].c.toothStatus === 1 || detail[7].teeth[i-1].c.toothStatus === 7),
                    'click-blue': detail[7].teeth[i-1].c && detail[7].teeth[i-1].c.toothStatus === 2,
                    'click-desgaste': detail[5].teeth[i-1].c && detail[5].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[7].teeth[i-1].c && detail[7].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[7].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[7].teeth[i-1].t.toothStatus === 8 || detail[7].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[7].teeth[i-1].c.toothStatus === 16 || detail[7].teeth[i-1].c.toothStatus === 17 || detail[7].teeth[i-1].c.toothStatus === 18
                                   || detail[7].teeth[i-1].c.toothStatus === 19 || detail[7].teeth[i-1].c.toothStatus === 20 || detail[7].teeth[i-1].c.toothStatus === 21 || detail[7].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[7].teeth[i-1].c.toothStatus === 25
                }"  [id]="'cleche8' + i" class="centro-leche click">
                </div>
            </div>
            <div *ngFor="let item of extractMilkIncrement; let i = index">
                <i *ngIf="detail[7].teeth[i].c.toothStatus === 4  || detail[7].teeth[i].c.toothStatus === 6"
                    class="fa fa-times fa-2x fa-fw" [ngStyle]="{'left': item}"
                    [ngClass]="{'to-extract-milk': detail[7].teeth[i].c.toothStatus === 4,
                'absent-milk': detail[7].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
        <div id="bll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of [1,2,3,4,5]" [id]="'teethM7' + i" class="diente-leche">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-primary">7{{i}}</span>

                <div (click)="setTeeth(6,i,'t')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].t && (detail[6].teeth[i-1].t.toothStatus === 1 || detail[6].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[6].teeth[i-1].t && detail[6].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[6].teeth[i-1].t && detail[6].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[6].teeth[i-1].t && detail[6].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[6].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[6].teeth[i-1].t.toothStatus === 8 || detail[6].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[6].teeth[i-1].t.toothStatus === 16 || detail[6].teeth[i-1].t.toothStatus === 17 || detail[6].teeth[i-1].t.toothStatus === 18
                                   || detail[6].teeth[i-1].t.toothStatus === 19 || detail[6].teeth[i-1].t.toothStatus === 20 || detail[6].teeth[i-1].t.toothStatus === 21 || detail[6].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[6].teeth[i-1].t.toothStatus === 25
                }" [id]="'tleche7' + i" class="cuadro-leche top-leche click"></div>

                <div (click)="setTeeth(6,i,'l')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].l && (detail[6].teeth[i-1].l.toothStatus === 1 || detail[6].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[6].teeth[i-1].l && detail[6].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[6].teeth[i-1].l && detail[6].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[6].teeth[i-1].l && detail[6].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[6].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[6].teeth[i-1].t.toothStatus === 8 || detail[6].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[6].teeth[i-1].l.toothStatus === 16 || detail[6].teeth[i-1].l.toothStatus === 17 || detail[6].teeth[i-1].l.toothStatus === 18
                                   || detail[6].teeth[i-1].l.toothStatus === 19 || detail[6].teeth[i-1].l.toothStatus === 20 || detail[6].teeth[i-1].l.toothStatus === 21 || detail[6].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[6].teeth[i-1].l.toothStatus === 25
                }" [id]="'lleche7' + i" class="cuadro-leche izquierdo-leche click"></div>

                <div (click)="setTeeth(6,i,'b')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].b && (detail[6].teeth[i-1].b.toothStatus === 1 || detail[6].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[6].teeth[i-1].b && detail[6].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[6].teeth[i-1].b && detail[6].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[6].teeth[i-1].b && detail[6].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[6].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[6].teeth[i-1].t.toothStatus === 8 || detail[6].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[6].teeth[i-1].b.toothStatus === 16 || detail[6].teeth[i-1].b.toothStatus === 17 || detail[6].teeth[i-1].b.toothStatus === 18
                                   || detail[6].teeth[i-1].b.toothStatus === 19 || detail[6].teeth[i-1].b.toothStatus === 20 || detail[6].teeth[i-1].b.toothStatus === 21 || detail[6].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[6].teeth[i-1].b.toothStatus === 25
                }" [id]="'bleche7' + i" class="cuadro-leche debajo-leche click"></div>

                <div (click)="setTeeth(6,i,'r')" [ngClass]="{
                    'click-red': detail[6].teeth[i-1].r && (detail[6].teeth[i-1].r.toothStatus === 1 || detail[6].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[6].teeth[i-1].r && detail[6].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[6].teeth[i-1].r && detail[6].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[6].teeth[i-1].r && detail[6].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[6].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[6].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[6].teeth[i-1].t.toothStatus === 8 || detail[6].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[6].teeth[i-1].r.toothStatus === 16 || detail[6].teeth[i-1].r.toothStatus === 17 || detail[6].teeth[i-1].r.toothStatus === 18
                                   || detail[6].teeth[i-1].r.toothStatus === 19 || detail[6].teeth[i-1].r.toothStatus === 20 || detail[6].teeth[i-1].r.toothStatus === 21 || detail[6].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[6].teeth[i-1].r.toothStatus === 25
                }" [id]="'rleche7' + i" class="cuadro-leche derecha-leche click click"></div>

                <div (click)="setTeeth(6,i,'c')"
                [ngClass]="{
                    'click-red': detail[6].teeth[i-1].c && (detail[6].teeth[i-1].c.toothStatus === 1 || detail[6].teeth[i-1].c.toothStatus === 7),
                    'click-blue': detail[6].teeth[i-1].c && detail[6].teeth[i-1].c.toothStatus === 2,
                    'click-desgaste': detail[6].teeth[i-1].c && detail[6].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[6].teeth[i-1].c && detail[6].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[6].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[6].teeth[i-1].t.toothStatus === 8 || detail[6].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[6].teeth[i-1].c.toothStatus === 16 || detail[6].teeth[i-1].c.toothStatus === 17 || detail[6].teeth[i-1].c.toothStatus === 18
                                   || detail[6].teeth[i-1].c.toothStatus === 19 || detail[6].teeth[i-1].c.toothStatus === 20 || detail[6].teeth[i-1].c.toothStatus === 21 || detail[6].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[6].teeth[i-1].c.toothStatus === 25
                }" [id]="'cleche7' + i" class="centro-leche click"></div>
            </div>
            <div *ngFor="let item of extractMilkDecrement; let i = index">
                <i *ngIf="detail[6].teeth[i].c.toothStatus === 4  || detail[6].teeth[i].c.toothStatus === 6"
                    class="fa fa-times fa-2x fa-fw" [ngStyle]="{'left': item}"
                    [ngClass]="{'to-extract-milk': detail[6].teeth[i].c.toothStatus === 4,
                'absent-milk': detail[6].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
        <div id="br" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of teethsDecrement;" data-name="value" [id]="'teeth4'+i" class="diente">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">
                    4{{i}}
                </span>
                <div (click)="setTeeth(3,i,'t')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].t && (detail[3].teeth[i-1].t.toothStatus === 1 || detail[3].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[3].teeth[i-1].t && detail[3].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[3].teeth[i-1].t && detail[3].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[3].teeth[i-1].t && detail[3].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[3].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[3].teeth[i-1].t.toothStatus === 8 || detail[3].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[3].teeth[i-1].t.toothStatus === 16 || detail[3].teeth[i-1].t.toothStatus === 17 || detail[3].teeth[i-1].t.toothStatus === 18
                                   || detail[3].teeth[i-1].t.toothStatus === 19 || detail[3].teeth[i-1].t.toothStatus === 20 || detail[3].teeth[i-1].t.toothStatus === 21 || detail[3].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[3].teeth[i-1].t.toothStatus === 25
                }" class="cuadro click" [id]="'t4'+i"></div>

                <div (click)="setTeeth(3,i,'l')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].l && (detail[3].teeth[i-1].l.toothStatus === 1 || detail[3].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[3].teeth[i-1].l && detail[3].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[3].teeth[i-1].l && detail[3].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[3].teeth[i-1].l && detail[3].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[3].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[3].teeth[i-1].t.toothStatus === 8 || detail[3].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[3].teeth[i-1].l.toothStatus === 16 || detail[3].teeth[i-1].l.toothStatus === 17 || detail[3].teeth[i-1].l.toothStatus === 18
                                   || detail[3].teeth[i-1].l.toothStatus === 19 || detail[3].teeth[i-1].l.toothStatus === 20 || detail[3].teeth[i-1].l.toothStatus === 21 || detail[3].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[3].teeth[i-1].l.toothStatus === 25
                }" class="cuadro izquierdo click" [id]="'l4'+i"></div>

                <div (click)="setTeeth(3,i,'b')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].b && (detail[3].teeth[i-1].b.toothStatus === 1 || detail[3].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[3].teeth[i-1].b && detail[3].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[3].teeth[i-1].b && detail[3].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[3].teeth[i-1].b && detail[3].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[3].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[3].teeth[i-1].t.toothStatus === 8 || detail[3].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[3].teeth[i-1].b.toothStatus === 16 || detail[3].teeth[i-1].b.toothStatus === 17 || detail[3].teeth[i-1].b.toothStatus === 18
                                   || detail[3].teeth[i-1].b.toothStatus === 19 || detail[3].teeth[i-1].b.toothStatus === 20 || detail[3].teeth[i-1].b.toothStatus === 21 || detail[3].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[3].teeth[i-1].b.toothStatus === 25
                }" class="cuadro debajo click" [id]="'b4'+i"></div>

                <div (click)="setTeeth(3,i,'r')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].r && (detail[3].teeth[i-1].r.toothStatus === 1 || detail[3].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[3].teeth[i-1].r && detail[3].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[3].teeth[i-1].r && detail[3].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[3].teeth[i-1].r && detail[3].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[3].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[3].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[3].teeth[i-1].t.toothStatus === 8 || detail[3].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[3].teeth[i-1].r.toothStatus === 16 || detail[3].teeth[i-1].r.toothStatus === 17 || detail[3].teeth[i-1].r.toothStatus === 18
                                   || detail[3].teeth[i-1].r.toothStatus === 19 || detail[3].teeth[i-1].r.toothStatus === 20 || detail[3].teeth[i-1].r.toothStatus === 21 || detail[3].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[3].teeth[i-1].r.toothStatus === 25
                }" class="cuadro derecha click" [id]="'r4'+i"></div>

                <div (click)="setTeeth(3,i,'c')"
                [ngClass]="{
                    'click-red': detail[3].teeth[i-1].c && (detail[3].teeth[i-1].c.toothStatus === 1 || detail[3].teeth[i-1].c.toothStatus === 7),
                    'click-blue': detail[3].teeth[i-1].c && detail[3].teeth[i-1].c.toothStatus === 2,
                    'click-desgaste': detail[3].teeth[i-1].c && detail[3].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[3].teeth[i-1].c && detail[3].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[3].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[3].teeth[i-1].t.toothStatus === 8 || detail[3].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[3].teeth[i-1].c.toothStatus === 16 || detail[3].teeth[i-1].c.toothStatus === 17 || detail[3].teeth[i-1].c.toothStatus === 18
                                   || detail[3].teeth[i-1].c.toothStatus === 19 || detail[3].teeth[i-1].c.toothStatus === 20 || detail[3].teeth[i-1].c.toothStatus === 21 || detail[3].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[3].teeth[i-1].c.toothStatus === 25
                }" class="centro click" [id]="'c4'+i"></div>
            </div>
            <div *ngFor="let item of extractDecrement; let i = index">
                <i *ngIf="detail[3].teeth[i].c.toothStatus === 4  || detail[3].teeth[i].c.toothStatus === 6"
                class="fa fa-times fa-3x fa-fw to-extract" [ngStyle]="{'left': item}"
                [ngClass]="{'to-extract': detail[3].teeth[i].c.toothStatus === 4,
                'absent': detail[3].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
        <div id="bl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div *ngFor="let i of teethsInclement;" class="diente" [id]="'teeth3'+i">
                <span style="margin-left: 45px; margin-bottom:5px; display: inline-block !important; border-radius: 10px !important;"
                class="badge badge-info">3{{i}}</span>

                <div (click)="setTeeth(2,i,'t')"
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].t && (detail[2].teeth[i-1].t.toothStatus === 1 || detail[2].teeth[i-1].t.toothStatus === 7),
                    'click-blue': detail[2].teeth[i-1].t && detail[2].teeth[i-1].t.toothStatus === 2,
                    'click-desgaste': detail[2].teeth[i-1].t && detail[2].teeth[i-1].t.toothStatus === 10,
                    'click-obturada': detail[2].teeth[i-1].t && detail[2].teeth[i-1].t.toothStatus === 15,
                    'click-delete': detail[2].teeth[i-1].t.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].t.toothStatus === 5,
                    'click-gray-light': detail[2].teeth[i-1].t.toothStatus === 8 || detail[2].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[2].teeth[i-1].t.toothStatus === 16 || detail[2].teeth[i-1].t.toothStatus === 17 || detail[2].teeth[i-1].t.toothStatus === 18
                                   || detail[2].teeth[i-1].t.toothStatus === 19 || detail[2].teeth[i-1].t.toothStatus === 20 || detail[2].teeth[i-1].t.toothStatus === 21 || detail[2].teeth[i-1].t.toothStatus === 22,
                    'click-remanente': detail[2].teeth[i-1].t.toothStatus === 25
                }" [id]="'t3' + i" class="cuadro click"></div>

                <div (click)="setTeeth(2,i,'l')"
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].l && (detail[2].teeth[i-1].l.toothStatus === 1 || detail[2].teeth[i-1].l.toothStatus === 7),
                    'click-blue': detail[2].teeth[i-1].l && detail[2].teeth[i-1].l.toothStatus === 2,
                    'click-desgaste': detail[2].teeth[i-1].l && detail[2].teeth[i-1].l.toothStatus === 10,
                    'click-obturada': detail[2].teeth[i-1].l && detail[2].teeth[i-1].l.toothStatus === 15,
                    'click-delete': detail[2].teeth[i-1].l.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].l.toothStatus === 5,
                    'click-gray-light': detail[2].teeth[i-1].t.toothStatus === 8 || detail[2].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[2].teeth[i-1].l.toothStatus === 16 || detail[2].teeth[i-1].l.toothStatus === 17 || detail[2].teeth[i-1].l.toothStatus === 18
                                   || detail[2].teeth[i-1].l.toothStatus === 19 || detail[2].teeth[i-1].l.toothStatus === 20 || detail[2].teeth[i-1].l.toothStatus === 21 || detail[2].teeth[i-1].l.toothStatus === 22,
                    'click-remanente': detail[2].teeth[i-1].l.toothStatus === 25
                }" [id]="'l3' + i" class="cuadro izquierdo click"></div>

                <div (click)="setTeeth(2,i,'b')"
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].b && (detail[2].teeth[i-1].b.toothStatus === 1 || detail[2].teeth[i-1].b.toothStatus === 7),
                    'click-blue': detail[2].teeth[i-1].b && detail[2].teeth[i-1].b.toothStatus === 2,
                    'click-desgaste': detail[2].teeth[i-1].b && detail[2].teeth[i-1].b.toothStatus === 10,
                    'click-obturada': detail[2].teeth[i-1].b && detail[2].teeth[i-1].b.toothStatus === 15,
                    'click-delete': detail[2].teeth[i-1].b.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].b.toothStatus === 5,
                    'click-gray-light': detail[2].teeth[i-1].t.toothStatus === 8 || detail[2].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[2].teeth[i-1].b.toothStatus === 16 || detail[2].teeth[i-1].b.toothStatus === 17 || detail[2].teeth[i-1].b.toothStatus === 18
                                   || detail[2].teeth[i-1].b.toothStatus === 19 || detail[2].teeth[i-1].b.toothStatus === 20 || detail[2].teeth[i-1].b.toothStatus === 21 || detail[2].teeth[i-1].b.toothStatus === 22,
                    'click-remanente': detail[2].teeth[i-1].b.toothStatus === 25
                }" [id]="'b3' + i" class="cuadro debajo click"></div>

                <div (click)="setTeeth(2,i,'r')"
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].r && (detail[2].teeth[i-1].r.toothStatus === 1 || detail[2].teeth[i-1].r.toothStatus === 7),
                    'click-blue': detail[2].teeth[i-1].r && detail[2].teeth[i-1].r.toothStatus === 2,
                    'click-desgaste': detail[2].teeth[i-1].r && detail[2].teeth[i-1].r.toothStatus === 10,
                    'click-obturada': detail[2].teeth[i-1].r && detail[2].teeth[i-1].r.toothStatus === 15,
                    'click-delete': detail[2].teeth[i-1].r.toothStatus === 3,
                    'border-red': detail[2].teeth[i-1].r.toothStatus === 5,
                    'click-gray-light': detail[2].teeth[i-1].t.toothStatus === 8 || detail[2].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[2].teeth[i-1].r.toothStatus === 16 || detail[2].teeth[i-1].r.toothStatus === 17 || detail[2].teeth[i-1].r.toothStatus === 18
                                   || detail[2].teeth[i-1].r.toothStatus === 19 || detail[2].teeth[i-1].r.toothStatus === 20 || detail[2].teeth[i-1].r.toothStatus === 21 || detail[2].teeth[i-1].r.toothStatus === 22,
                    'click-remanente': detail[2].teeth[i-1].r.toothStatus === 25
                }" [id]="'r3' + i" class="cuadro derecha click"></div>

                <div (click)="setTeeth(2,i,'c')"
                [ngClass]="{
                    'click-red': detail[2].teeth[i-1].c && (detail[2].teeth[i-1].c.toothStatus === 1 || detail[2].teeth[i-1].c.toothStatus === 7),
                    'click-blue': detail[2].teeth[i-1].c && detail[2].teeth[i-1].c.toothStatus === 2,
                    'click-desgaste': detail[2].teeth[i-1].c && detail[2].teeth[i-1].c.toothStatus === 10,
                    'click-obturada': detail[2].teeth[i-1].c && detail[2].teeth[i-1].c.toothStatus === 15,
                    'click-delete': detail[2].teeth[i-1].c.toothStatus === 3,
                    'click-gray-light': detail[2].teeth[i-1].t.toothStatus === 8 || detail[2].teeth[i-1].t.toothStatus === 9,
                    'click-protesis': detail[2].teeth[i-1].c.toothStatus === 16 || detail[2].teeth[i-1].c.toothStatus === 17 || detail[2].teeth[i-1].c.toothStatus === 18
                                   || detail[2].teeth[i-1].c.toothStatus === 19 || detail[2].teeth[i-1].c.toothStatus === 20 || detail[2].teeth[i-1].c.toothStatus === 21 || detail[2].teeth[i-1].c.toothStatus === 22,
                    'click-remanente': detail[2].teeth[i-1].c.toothStatus === 25
                }" [id]="'c3' + i" class="centro click"></div>
            </div>
            <div *ngFor="let item of extractIncrement; let i = index">
                <i *ngIf="detail[2].teeth[i].c.toothStatus === 4  || detail[2].teeth[i].c.toothStatus === 6"
                class="fa fa-times fa-3x fa-fw" [ngStyle]="{'left': item}"
                [ngClass]="{'to-extract-milk-cpves6': detail[2].teeth[i].c.toothStatus === 4,
                'absent': detail[2].teeth[i].c.toothStatus === 6}"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-left">
                            <div style="height: 20px; width:20px; display:inline-block;" class="click-red"></div> = Fractura/Carie
                            <br>
                            <div style="height: 5px; width:20px; display:inline-block;" class="click-red"></div> = Puente Entre Piezas
                            <br>
                            <img style="display:inline-block;" src="assets/images/odontograma/extraccion.png"> = <span style="display:inline-block;"> Corona definitiva/Temporal</span>
                            <br>
                            <div style="height: 20px; width:20px; display:inline-block;" class="click-obturada"></div> = Obturada
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center">
                            <div style="height: 20px; width:20px; display:inline-block;" class="click-blue"></div> = Obturación
                            <br> <i style="color:#808080;" class="fa fa-times fa-2x"></i> = Ausente
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-right">
                            <span style="display:inline-block;"> Pieza Extraída</span> = <img style="display:inline-block;" src="assets/images/odontograma/extraccion.png">
                            <br> Idicada Para Extracción = <i style="color:#dc3545;" class="fa fa-times fa-2x"></i>
                            <br>
                            Desgaste Oclusal/Incisal = <div style="height: 20px; width:20px; display:inline-block;" class="click-desgaste"></div>
                            <br>
                            Remanente Radicular = <div style="height: 20px; width:20px; display:inline-block;" class="click-remanente"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="this.quotationDetail.length > 0">
            <table class="table table-sm">
                <thead>
                    <th>Estado dental</th>
                    <th>Tratamiento</th>
                    <th>Cantidad</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.quotationDetail">
                        <td>{{item.dentalstatus.name}}</td>
                        <td>{{item.tariff.name}}</td>
                        <td>{{item.quantity}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>
