import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-modal-autorization',
  templateUrl: './modal-autorization.component.html',
  styleUrls: ['./modal-autorization.component.scss']
})
export class ModalAutorizationComponent implements OnInit {

  // FORM
  formData: FormGroup;

  title = 'Autorizar';
  message: string;
  data: any;
  module: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formData = this.fb.group({});
    this.formData.addControl('password', new FormControl(null, [Validators.required]));
  }

  save(): void {
    if (this.formData.invalid) {
      return;
    }
    const data = {
      password: this.formData.get('password').value,
      data: this.data,
      module: this.module
    }
    this.spinner.show();
    this.authService.getAutorization(data).subscribe({
      next: () => {
        this.activeModal.close('Save click');
        this.spinner.hide();
        this.toastr.success(
          'Acción autorizada',
          'Ok!',
          { timeOut: 4000 }
        );
      },
      error: (error) => {
        this.spinner.hide();
        this.toastr.warning(
          'Acción no autorizada',
          'Atención!',
          { timeOut: 4000 }
        );
      }
    });
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required')) {
      return true;
    }
    return false;
  }

}
