export class ServiceOrderBilling {
    datosOrdenServicio: DatosOrdenServicio = new DatosOrdenServicio();
    datosCliente: DatosCliente = new DatosCliente();
    datosFactura: DatosFactura = new DatosFactura();
    datosPagoCliente: DatosPagoCliente = new DatosPagoCliente();
    datosAuditoria: DatosAuditoria = new DatosAuditoria();
}

export class DatosOrdenServicio {
    id_orden_servicio: number | null = null;
    id_order_servicio_detalle: number | null = null;
    cantidad_orden_servicio_detalle: number | null = null;
    moneda_orden_servicio_detalle: number | null = null;
    monto_orden_servicio_detalle: number | null = null;
    id_bussiness_line: number | null = null;
    id_specialty:number | null = null;
}

export class DatosCliente {
    id_cliente: number | null = null;
    tipo_documento_cliente: string | null = null;
    numero_documento_cliente: string | null = null;
    nombre_cliente_a_facturar: string | null = null;
    direccion_del_cliente:string | null = null;
    correo_electronico_cliente:string | null = null;
    nombre_paciente:string | null = null;
}

export class DatosFactura {
    fecha_factura: Date | null = null;
    fecha_abono: Date | null = null;
    serie_recibo: string | null = null;
    numero_recibo: number | null = null;
    id_tarifa: number | null = null;
    descripcion_tarifa: string | null = null;
    monto_detalle_pago_dolares: number | null = null;
    monto_detalle_pago_soles: number | null = null;
}

export class DatosPagoCliente {
    id_moneda_del_como_pago: number | null = null;
    id_tipo_cambio: number | null = null;
    tipo_de_cambio:number | null = null;
    id_tipo_comprobante: string | null = null;
    metodo_pago: number | null = null;
    tipo_de_tarjeta: number | null = null;
    cuenta_bancaria: number | null = null;
    numero_operacion: number | null = null;
}

export class DatosAuditoria {
    id_creador_orden_servicio: number | null = null;
    id_facturador_orden_servicio: number | null = null;
}
