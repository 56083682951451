<form class="was-validated" (ngSubmit)="onSubmit()" ngNativeValidate>
    <div class="modal-header modal_header">
        <h5 class="modal-title titulo-modal">{{title}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="data">
            <div class="row">
                <div class="col-md-2">
                    <label for="history">Historia clinica</label>
                    <h6>{{data.history}}</h6>
                </div>
                <div class="col-md-6">
                    <label for="patient">Paciente</label>
                    <h6>{{data.patient}}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label for="amount">Monto</label>
                    <h6>{{data.coin}} {{data.amount}}</h6>
                </div>
                <div class="col-md-6">
                    <label for="doctor">Doctor</label>
                    <h6>{{data.doctor}}</h6>
                </div>
            </div>
        </ng-container>
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label class="form-control-label" for="name">Grupo <small class="text-danger">(*)</small></label>
                    <select name="group" [(ngModel)]="group" class="form-control custom-select" required>
                        <option value="">Seleccione</option>
                        <option *ngFor="let item of listGroup" [value]="item.id">
                            {{item.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-8">
                <div class="form-group">
                    <label class="form-control-label" for="name">Descripción <small
                            class="text-danger">(*)</small></label>
                    <input name="description" [(ngModel)]="description" class="form-control form-control-sm"
                        placeholder="Descripción del archivo" maxlength="250" required>
                </div>
            </div>
            <div class="col-12">
                <file-upload [(ngModel)]="uploadedFiles" name="files" fileslimit="1" accept="'image/*'"></file-upload>
                <small class="text-secondary">Nota: solo subir un archivo a la vez</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" uiSref="work" class="btn btn-primary btn-raised" *ngIf="!processing"
            [disabled]="uploadedFiles.length === 0"> Guardar</button>
        <button class="btn btn-raised btn-primary" *ngIf="processing" disabled>
            <span class="spinner-border spinner-border-sm" role="status"></span>
            <span class="load-text"> Procesando...</span>
        </button>
        <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.close('Close click')"
            *ngIf="!processing">
            Salir
        </button>
    </div>
</form>