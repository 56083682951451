import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExchangeRateModel } from '../models/exchange-rate.model';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService {

  private baseUrl: string = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  getLastExchange(today:string):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/exchange-rate/exchange-today/${today}`);
  }

  getAll(): Observable<ExchangeRateModel[]> {
    return this.http.get<ExchangeRateModel[]>(`${this.baseUrl}/exchange-rate`);
  }

  getLast(): Observable<ExchangeRateModel[]> {
    return this.http.get<ExchangeRateModel[]>(`${this.baseUrl}/exchange-rate/last/rate`);
  }

  insert(exchangerate: ExchangeRateModel): Observable<ExchangeRateModel> {
    return this.http.post<ExchangeRateModel>(`${this.baseUrl}/exchange-rate`, exchangerate);
  }

  update(exchangerate: ExchangeRateModel, id: number): Observable<ExchangeRateModel> {
    return this.http.put<ExchangeRateModel>(`${this.baseUrl}/exchange-rate/${id}`, exchangerate);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/exchange-rate/${id}`)
  }

  getFilter(day:string): Observable<ExchangeRateModel[]> {
    return this.http.post<ExchangeRateModel[]>(`${this.baseUrl}/exchange-rate/byDay/${day}`,null);
  }
}