import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DoctorDiaryEndpoints } from '../../config/doctor-diary-endpoints.config';
import { ProgrammingDto } from '../../dtos/programming-dto';
import { DoctorMatrixDto } from '../../dtos/doctor-matrix-dto';
import { ProgrammingEventDto } from '../../dtos/programming-event-dto';
import { ProgramationAppointment } from '../../models/programming-appointment.model';
import { MedicalActModel } from 'src/app/models/main/medical-act.model';
import { MedicalActAttention } from 'src/app/models/main/medical-act.model';

@Injectable({
    providedIn: 'root'
})
export class DoctorDiaryDataService {

    constructor(
        private http: HttpClient,
        private endpoints: DoctorDiaryEndpoints
    ) { }

    getProgramming(date: string, idcampus: number, calendar_type:number): Observable<ProgrammingDto[]> {
        const data = this.http.get<ProgrammingDto[]>(this.endpoints.getProgramming(date, idcampus,calendar_type));
        return data;
    }

    getProgrammingSin(date: string, idcampus: number): Observable<ProgrammingDto[]> {
        const data = this.http.get<ProgrammingDto[]>(this.endpoints.getProgrammingSin(date, idcampus));
        return data;
    }

    getEventProgramming(date: string, idcampus: number): Observable<ProgrammingEventDto[]> {
        return this.http.get<ProgrammingEventDto[]>(this.endpoints.getMatrixProgramming(date, idcampus));
    }

    getFilesUploaded(bodyContent: FormData, group: number, idres: number): void {        
        this.http.post(this.endpoints.getUploadedFiles( group, idres ), bodyContent);
    }

    /**
     * Upload files to the API - needs to be handled differently to standard posts.
     * Will append the files to the form data under the 'files' key.
     * @param {string} apiResource - API endpoint to hit, e.g. 'controller/action'
     * @param {File[]} files - Files to upload
     * @param {Object} body - Optional body content for the upload post; will be turned into form data
     */
    public validate(group:number, id:number, appointment: ProgramationAppointment): any {
        
        let url = this.endpoints.getValidate(group, id);

        const formData = new FormData();

        formData.append('appointment', JSON.stringify(appointment) );

        /*
        if (body) {
            //const encoded = this.encodePostBody(body);
            Object.keys(body).forEach((key: string) => formData.append(key, body[key]));
        }
        */

        return this.http.post(url, appointment, {
                headers: { 'Content-Type': `application/json` } 
            });
    }

    /**
     * Upload files to the API - needs to be handled differently to standard posts.
     * Will append the files to the form data under the 'files' key.
     * @param {string} apiResource - API endpoint to hit, e.g. 'controller/action'
     * @param {File[]} files - Files to upload
     * @param {Object} body - Optional body content for the upload post; will be turned into form data
     */
    public upload(appointment: ProgramationAppointment, medicalAct: MedicalActModel, medicalActAtt: MedicalActAttention, description: string, files: File[], group: number, id: number, body?: Object): any {
        
        let url = this.endpoints.getUploadedFiles(group, id);

        const formData = new FormData();
        formData.append('description', description );
        formData.append('appointment', JSON.stringify(appointment) );
        formData.append('medicalAct', JSON.stringify(medicalAct) );
        formData.append('medicalActAtt', JSON.stringify(medicalActAtt) );
        for (var i = 0; i < files.length; i++) {
            formData.append('file[]', files[i]);
        }

        /*
        if (body) {
            //const encoded = this.encodePostBody(body);
            Object.keys(body).forEach((key: string) => formData.append(key, body[key]));
        }
        */

        return this.http.post(url, formData, {
                reportProgress: true,
                observe: 'events',
                //headers: { 'Content-Type': `multipart/form-data` } // trigger header for the intercepter
            });
    }

}